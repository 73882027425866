import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import ElementWithPopup from '../popup/ElementWithPopup';

interface iProps {
  id: string;
  onClick: (id: string) => void;
}

const DeleteBtn: React.FC<iProps> = ({ id, onClick }) => {
  return (
    <ElementWithPopup
      onClick={() => onClick(id)}
      popupContent={'Are you sure to delete it?'}
      popupHeading={"please confirm you're about to delete it"}
    >
      <TrashIcon label="delete" testId={'trash-icon'} />
    </ElementWithPopup>
  );
};

export default DeleteBtn;
