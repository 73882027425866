import React, { useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Radio } from '@atlaskit/radio';
import { Grid, GridColumn } from '@atlaskit/page';
import AsyncCreatableContactCompany, { iContactCompanyTypeName } from '../AsyncCreatableContactCompany';
import { FlexContainer, SpaceVerticalWrapper } from '../../styles/styles';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import ModuleEnabledWrapper from '../../modulize/ModuleEnabledWrapper';
import { ModuleCode } from '../../../types/iModuleCode';
import {
  CONTACT_COMPANY_TYPE_CUSTOMER,
  CONTACT_COMPANY_TYPE_SERVICE_PROVIDER,
  CONTACT_COMPANY_TYPE_SUPPLIER,
} from '../../../types/contactCompany/iContactCompanyType';

export type iBasicModalBodyProps = {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  watch: (names?: string | string[]) => unknown;
  defaultValue?: iContactCompany;
  isCustomer: boolean;
  children?: React.ReactNode;
  verticalSpaceBeforeSelect?: string;
  searchServiceProvider?: boolean;
};
const BasicModalBody = ({
  control,
  errors,
  onChange,
  watch,
  defaultValue,
  isCustomer,
  children,
  verticalSpaceBeforeSelect = 'x-large',
  searchServiceProvider = false,
}: iBasicModalBodyProps) => {
  const [contactCompanyTypeName, setContactCompanyTypeName] = useState<iContactCompanyTypeName>(
    isCustomer === true ? CONTACT_COMPANY_TYPE_CUSTOMER : CONTACT_COMPANY_TYPE_SUPPLIER,
  );
  const [isTypeSelectionDisabled, setIsTypeSelectionDisabled] = useState(false);

  const handleChange = (...args: any[]) => {
    const [, value] = args;
    setIsTypeSelectionDisabled(value !== null);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange(...args);
  };

  const getSpace = () => {
    const gotContactCompany = watch(isCustomer ? 'customerId' : 'supplierId');
    if (gotContactCompany) return 'large';
    return verticalSpaceBeforeSelect;
  };

  const getTypeSelections = () => {
    if (searchServiceProvider !== true) {
      return null;
    }
    return (
      <ModuleEnabledWrapper checkedModule={ModuleCode.SERVICE_PROVIDER}>
        <Grid spacing={'compact'}>
          <GridColumn medium={12}>
            <FlexContainer>
              {[CONTACT_COMPANY_TYPE_CUSTOMER, CONTACT_COMPANY_TYPE_SERVICE_PROVIDER].map(typeName => {
                return (
                  <div key={typeName}>
                    <Radio
                      value={typeName}
                      label={typeName}
                      isDisabled={isTypeSelectionDisabled}
                      name={'type-name'}
                      testId={`type-name-${typeName}`}
                      isChecked={typeName === contactCompanyTypeName}
                      onChange={() => setContactCompanyTypeName(typeName as iContactCompanyTypeName)}
                    />
                  </div>
                );
              })}
            </FlexContainer>
          </GridColumn>
        </Grid>
      </ModuleEnabledWrapper>
    );
  };

  return (
    <>
      {getTypeSelections()}
      <AsyncCreatableContactCompany
        control={control}
        errors={errors}
        onChange={handleChange}
        defaultValue={defaultValue}
        contactCompanyTypeName={contactCompanyTypeName}
      />
      {children}
      <SpaceVerticalWrapper className={getSpace()} />
    </>
  );
};

export default BasicModalBody;
