import React from 'react';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Button from '@atlaskit/button';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { RootState } from '../../../redux/makeReduxStore';
import { formatDate } from '../../../services/UtilsService';
import { iLabelValuePair } from '../../../shared/UITypes/types';

export const STOCK_TRANSFER_CSV_HEADERS: Array<iLabelValuePair> = [
  { label: 'Product Code/SKU', value: 'product.productCode' },
  { label: 'Product Name', value: 'product.name' },
  { label: 'Moving Qty', value: 'movingQty' },
  { label: 'Unit', value: 'product.measurement.shortName' },
  {
    label: 'Batch/Lot No.',
    value: 'reference',
  },
  { label: 'From Location', value: 'locationName' },
  { label: 'Location Code', value: 'locationCode' },
];

const ExportCsv = ({ data }: { data: Array<iItemLocationTransfer> }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const addExtraInfo = () => {
    const operatorLabel = ['Operator', 'Operated at'];
    const operatorInfo = [
      `${_.get(user, 'firstName')} ${_.get(user, 'lastName')}`,
      formatDate(moment().toString(), 'DD MMMM YYYY, HH:mm:ss'),
    ];

    const transferLabel = STOCK_TRANSFER_CSV_HEADERS.map((h: iLabelValuePair) => h.label);
    const transferInfo = data.map((item: iItemLocationTransfer) =>
      STOCK_TRANSFER_CSV_HEADERS.map((column: iLabelValuePair) => _.get(item, column.value)),
    );
    return [operatorLabel, operatorInfo, transferLabel, ...transferInfo];
  };

  return (
    <CSVLink
      data={addExtraInfo()}
      filename={`stockTransfer-${formatDate(moment().toString(), 'DD MMMM YYYY, hh:mm:ss')}.csv`}
    >
      <Button appearance={'primary'}>Download transfer file</Button>
    </CSVLink>
  );
};

export default ExportCsv;
