import React from 'react';
import iContactCompany from '../../types/contactCompany/iContactCompany';
import { SERVICE_PROVIDER } from '../constants/ContactCompanyConstants';

const CompanyWithTypeName = ({ contactCompany }: { contactCompany?: iContactCompany }) => {
  const getPostfix = (contactCompanyType?: string) => {
    if (!contactCompanyType) return '';
    if (contactCompanyType.toUpperCase() === SERVICE_PROVIDER.toUpperCase()) {
      return 'service provider';
    }
    return '';
  };
  if (!contactCompany) return null;
  return (
    <div data-testid={'company-with-type-name'}>{`${contactCompany.name}${
      getPostfix(contactCompany.type?.name) && ' [service provider]'
    }`}</div>
  );
};

export default CompanyWithTypeName;
