import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getWarehousesByParentId = (parentId?: string) => {
  return AppService.get('/warehouseLocation', {
    filter: `parentId:${parentId || null}`,
  }).then(({ data }) => data);
};

const getWarehouseDetailById = (id: string) => {
  return AppService.get('/warehouseLocation', {
    include: 'facility,facility.address,createdBy,updatedBy',
    filter: `id:${id}`,
  }).then(({ data }) => data);
};

const getWarehouseDetail = (id: string) => {
  return AppService.get(`/warehouseLocation/${id}`, {
    include: 'createdBy,updatedBy,warehouseItemLocations.product.measurement,facility.lostAndFoundLocation',
  }).then(({ data }) => data);
};

const getProductsUnderWarehouse = (path: string) => {
  return AppService.get('/product', {
    include: 'warehouseLocations,measurement',
    like: `warehouseLocations.path:${path}`,
  }).then(({ data }) => data);
};

const updateWarehouse = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/warehouseLocation/${id}`, updateContent).then(({ data }) => data);
};

const deactivateWarehouse = (id: string) => {
  return AppService.delete(`/warehouseLocation/${id}`).then(({ data }) => data);
};

// eslint-disable-next-line
const createWarehouse = (postData: any) => {
  return AppService.post('/warehouseLocation', postData).then(({ data }) => data);
};
// eslint-disable-next-line
const moveWarehouse = (postData: any) => {
  return AppService.post('/warehouseLocation/move', postData).then(({ data }) => data);
};

const getWarehouseList = (config?: iParams) => {
  return AppService.get('/warehouseLocation', {
    ...config,
  }).then(({ data }) => data);
};

const getWarehouseListAsyncSearch = (config?: iParams) => {
  return AppService.get('/warehouseLocation', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

const getWarehouseLocationPathNames = (ids: string) => {
  return AppService.get('/warehouseLocation/names', {
    ids,
  }).then(({ data }) => data);
};

const scanLocationCode = (locationCode: string) => {
  return AppService.get('/warehouseLocation', {
    filter: `locationCode:${locationCode}`,
    include: 'createdBy,updatedBy,warehouseItemLocations.product.measurement',
  }).then(({ data }) => data);
};

const isWIPWarehouseLocation = (id: string) => {
  return AppService.get(`/warehouseLocation/${id}/isWIP`).then(({ data }) => data);
};

export default {
  getWarehousesByParentId,
  getWarehouseDetailById,
  getProductsUnderWarehouse,
  getWarehouseListAsyncSearch,
  getWarehouseDetail,
  getWarehouseList,
  getWarehouseLocationPathNames,
  scanLocationCode,
  isWIPWarehouseLocation,
  createWarehouse,
  updateWarehouse,
  moveWarehouse,
  deactivateWarehouse,
};
