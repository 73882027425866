import React, { useEffect, useState } from 'react';
import { DatePicker } from '@atlaskit/datetime-picker';
import { ErrorMessage } from '@atlaskit/form';

export type iDatePickerWithoutReadviewProps = {
  name: string;
  dateFormat?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: string | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm?: (name: string, newValue: any) => void;
  //   minDate?: string;
  placeholder?: string;
  testId?: string;
  errorMessage?: string;
};

const DatePickerWithoutReadview = ({
  name,
  dateFormat = 'DD MMM YYYY',
  defaultValue,
  onConfirm,
  //   minDate,
  placeholder,
  testId = 'date-picker',
  errorMessage,
}: iDatePickerWithoutReadviewProps) => {
  const [value, setValue] = useState<string | undefined>('');

  useEffect(() => {
    if (typeof onConfirm === 'function' && defaultValue !== value) {
      setValue(defaultValue);
      onConfirm(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // eslint-disable-next-line
  const changeValue = (dateValue: any) => {
    // if value is default value
    // don't change it
    setValue(dateValue);
    if (typeof onConfirm === 'function') {
      onConfirm(name, dateValue);
    }
  };

  return (
    <>
      <DatePicker
        id={name}
        dateFormat={dateFormat}
        value={value}
        onChange={changeValue}
        placeholder={placeholder}
        testId={testId}
      />
      {errorMessage && !value && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default DatePickerWithoutReadview;
