import React, { useState } from 'react';
import styled from 'styled-components';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import EditorImageResizeIcon from '@atlaskit/icon/glyph/editor/image-resize';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import EditorDoneIcon from '@atlaskit/icon/glyph/editor/done';
import LeftBtn from './LeftBtn';
import RightBtn from './RightBtn';
import CenteredOverlay from './CenteredOverlay';
import { iImage } from './ImageTypes';

const ThumbnailWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  padding: 2px;
  margin: 2px;
  flex-shrink: 0;
  position: relative;
`;
const ImageWrapper = styled.img`
  width: 60px;
  height: 45px;
`;
const Thumbnail = ({ image, alt, onRemove }: { image: iImage; alt?: string; onRemove: (image: iImage) => void }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  // remove images
  const removeImgWithDelay = () => {
    setIsConfirmOpen(false);
    setTimeout(() => {
      onRemove(image);
    }, 50);
  };
  // preview image
  // eslint-disable-next-line
  const previewImage = (evt: any) => {
    evt.preventDefault();
    window.open(image.url);
    return false;
  };
  return (
    <ThumbnailWrapper data-testid={'thumbnail-wrapper'}>
      {image.uploading === true && (
        <CenteredOverlay>
          <small>Loading...</small>
        </CenteredOverlay>
      )}
      <ImageWrapper data-testid={'image-wrapper'} src={image.rawSrc ? image.rawSrc : image.url} alt={alt || 'Error'} />
      {isConfirmOpen ? (
        <>
          <LeftBtn onClick={removeImgWithDelay}>
            <EditorDoneIcon label="confirm" size={'medium'} testId={'confirm-delete'} />
          </LeftBtn>
          <RightBtn onClick={() => setIsConfirmOpen(false)}>
            <EditorCloseIcon label="cancel" size={'medium'} testId={'close-confirm'} />
          </RightBtn>
        </>
      ) : (
        <>
          <LeftBtn onClick={previewImage}>
            <EditorImageResizeIcon label="preview" size={'medium'} testId={'preview'} />
          </LeftBtn>
          <RightBtn onClick={() => setIsConfirmOpen(true)}>
            <EditorRemoveIcon label="remove" size={'medium'} testId={'delete-button'} />
          </RightBtn>
        </>
      )}
    </ThumbnailWrapper>
  );
};

export default Thumbnail;
