import React, { useState } from 'react';
import Button from '@atlaskit/button';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import Spinner from '@atlaskit/spinner';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import WarehouseService from '../../../services/WarehouseService';
import AddProdsPopup from './AddProdsPopup';
import QRReader from '../../../shared/QRReader/QRReader';
import ModalPageNoConfirm from '../../../shared/modalPage/ModalPageNoConfirm';
import WarningModal from '../../../shared/modal/WarningModal';
import AsyncSearchFormOptionLabel from '../../../shared/hookForms/asyncSearch/AsyncSearchFormOptionLabel';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { FlexContainer } from '../../../shared/styles/styles';
import { apiErrorToast } from '../../../shared/toast/ToastHandler';
import { AddProdsWrapper } from '../sharedComponents/StockTransfer.style';
import { getWareHouseLocationOptionLabel } from '../../../shared/Utilities';

type iState = {
  warehouseLocation?: iWarehouseLocation;
  isWIP: boolean;
  isScanning: boolean;
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: false,
  isScanning: false,
  isWIP: false,
};
export type iAddProdsProps = {
  updateRecords: (items: Array<iItemLocationTransfer>) => void;
  records: Array<iItemLocationTransfer>;
};
const AddProds = ({ updateRecords, records }: iAddProdsProps) => {
  const [state, setState] = useState(initialState);
  const onCloseModal = () =>
    setState({
      ...state,
      warehouseLocation: undefined,
      isLoading: false,
      isWIP: false,
      isScanning: false,
    });

  const onScanLocationCode = async (locationCode?: string) => {
    if (!locationCode) return;
    setState({ ...state, isScanning: false, isLoading: true });
    try {
      const locations: Array<iWarehouseLocation> = await WarehouseService.scanLocationCode(locationCode);
      if (locations.length !== 1) throw new Error('invalid location code');
      const { isWIP }: { isWIP: boolean } = await WarehouseService.isWIPWarehouseLocation(locations[0].id);
      setState({
        ...state,
        warehouseLocation: locations[0],
        isLoading: false,
        isScanning: false,
        isWIP,
      });
    } catch (error) {
      setState({ ...state, isLoading: false });
      apiErrorToast(error);
    }
  };
  const onSelectWarehouseLocation = async (payload: iWarehouseLocation | null) => {
    if (!payload) return;
    const { id } = payload;
    setState({ ...state, isLoading: true });
    try {
      const { isWIP }: { isWIP: boolean } = await WarehouseService.isWIPWarehouseLocation(id);
      if (isWIP) {
        setState({
          ...state,
          warehouseLocation: undefined,
          isWIP,
        });
        return;
      }
      const warehouseLocation: iWarehouseLocation = await WarehouseService.getWarehouseDetail(id);
      setState({ ...state, warehouseLocation, isWIP, isLoading: false });
    } catch (error) {
      setState({ ...state, isLoading: false });
      apiErrorToast(error);
    }
  };
  return (
    <AddProdsWrapper>
      <FlexContainer>
        <div style={{ minWidth: '280px' }}>
          <AsyncSearchFormOptionLabel
            onSelect={onSelectWarehouseLocation}
            promiseFn={(keyword: string) =>
              WarehouseService.getWarehouseListAsyncSearch({
                like: `name:${keyword},locationCode:${keyword}`,
              })
            }
            searchBarPlaceholder={'Search by locationCode/name...'}
            formatOptionLabel={getWareHouseLocationOptionLabel}
          />
          {/* <AsyncSearch
            onSelect={onSelectWarehouseLocation}
            promiseFn={(keyword: string) =>
              WarehouseService.getWarehouseListAsyncSearch({
                like: `name:${keyword},locationCode:${keyword}`,
              })
            }
            optionLabel={['name', 'locationCode']}
            searchBarPlaceholder={'search by location code or name...'}
          /> */}
        </div>
        <Button
          className={'scan-button'}
          iconAfter={<CameraFilledIcon label={'scan'} />}
          onClick={() => setState({ ...state, isScanning: true })}
          testId={'scan-button'}
        />
        {state.isLoading && <Spinner />}
      </FlexContainer>
      {state.warehouseLocation && !state.isWIP && !state.isScanning && (
        <AddProdsPopup
          records={records}
          updateRecords={updateRecords}
          onCancel={onCloseModal}
          warehouseLocation={state.warehouseLocation}
        />
      )}
      <WarningModal
        isOpen={state.isWIP}
        onCancel={onCloseModal}
        modalHeading={'Invalid to take a stock transfer'}
        modalBody={
          'Current warehouse location is WIP, not allowed to take any actions against it until WIP is completed'
        }
      />
      {state.isScanning && (
        <ModalPageNoConfirm onCancel={onCloseModal} heading={'Please put your QR code into the camera range'}>
          <QRReader onScanSuccess={onScanLocationCode} />
        </ModalPageNoConfirm>
      )}
    </AddProdsWrapper>
  );
};

export default AddProds;
