import styled from 'styled-components';
import FlexSpaceBetweenContainer from '../jobDetail/styles/FlexSpaceBetweenContainer';

type IsMiddlePageProps = {
  isMiddlePage?: boolean;
};
const modalHeaderAndFooterBg = '#fafafa';
const modalHeaderAndFooterBgBorder = '1px #ccc solid';
export const PageContainer = styled.div<IsMiddlePageProps>`
  //margin: 0.5em 0.2em;
  height: calc(100vh - 200px);
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.2);
  ${({ isMiddlePage }) => isMiddlePage && 'margin: 0em ;'}

  .job-progress-page-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    opacity: 1;
    justify-content: space-between;
    height: 100%;
  }
  .invalid-value-detected {
    display: flex;
    align-items: center;
    color: #bf1650;
    &:before {
      content: '⚠ ';
    }
  }
`;
export const PageFooter = styled(FlexSpaceBetweenContainer)`
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 0.5em 0.5em;
  background: ${modalHeaderAndFooterBg};
  border-top: ${modalHeaderAndFooterBgBorder};
  height: 4rem;
`;
export const PageHeader = styled(FlexSpaceBetweenContainer)`
  flex: 0 0 auto;
  padding: 0.5em 0.5em 0em;
  box-sizing: border-box;
  height: 4rem;
  bacground: ${modalHeaderAndFooterBg};
  border-bottom: ${modalHeaderAndFooterBgBorder};
`;
export const PageBody = styled.div`
  padding: 1em 1em;
  flex: auto;
  overflow-y: scroll;
`;
