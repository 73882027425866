import React from 'react';
import Lozenge, { ThemeAppearance } from '@atlaskit/lozenge';
import iJobStatus from '../../../types/job/iJobStatus';
import {
  STATUS_CATEGORY_FINISHED,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_NEW,
} from '../../../shared/constants/JobCategoryConstants';
import { FlexContainer } from '../../../shared/styles/styles';

const decideAppearance = (categoryName?: string): ThemeAppearance => {
  switch (categoryName) {
    case STATUS_CATEGORY_NEW:
      return 'default';
    case STATUS_CATEGORY_IN_PROGRESS:
      return 'inprogress';
    case STATUS_CATEGORY_FINISHED:
      return 'success';
    default:
      return 'default';
  }
};
const StatusLozenge = ({
  status,
  categoryCode,
  children,
}: {
  status?: iJobStatus;
  categoryCode?: string;
  children?: React.ReactChild;
}) => {
  return (
    <Lozenge appearance={decideAppearance(categoryCode)}>
      <FlexContainer>
        <div>{status?.name}</div>
        {children}
      </FlexContainer>
    </Lozenge>
  );
};

export default StatusLozenge;
