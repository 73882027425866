import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.space-below {
    padding-bottom: 1rem;
  }

  &.space-above {
    padding-top: 1rem;
  }
`;

export const LabelValue = styled.span`
  width: 100%;
  overflow-wrapp: break-word;
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3333333333333333;
  padding: 10px 0 5px;
  display: inline-block;
  min-height: 12px;
`;

export const ErrorMsg = styled.p`
  margin-top: 6px;
  color: #bf1650;
  &:before {
    content: '⚠ ';
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  &.with-gap {
    gap: 1rem;
    &.small {
      gap: 0.2rem;
    }
  }
  &.space-below {
    padding-bottom: 1rem;
  }
  &.space-evenly {
    justify-content: space-evenly;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.space-above {
    padding-top: 1rem;
  }
  &.flex-start {
    align-items: flex-start;
  }
`;

export const Page = styled.div`
  padding: 1rem;
  position: relative;
`;

export const PageTitle = styled.p`
  font-size: 1.75rem;
  font-weight: 500;
  color: #172b4d;

  &.small {
    font-size: 1.1rem;
  }

  &.median {
    font-size: 1.25rem;
  }

  &.space-right {
    margin-right: 0.5rem;
  }

  &.space-below {
    margin-bottom: 0.5rem;
  }

  &.space-above {
    margin-top: 0.5rem;
  }
`;

export const PageSubtitle = styled.p`
  font-size: 1rem;
  color: #6b778c;
  font-weight: 400;
`;

export const SpaceVerticalWrapper = styled.div`
  &.x-small {
    padding: 0.25rem 0;
  }
  &.small {
    padding: 0.5rem 0;
  }
  &.median {
    padding: 1rem 0;
  }
  &.large {
    padding: 1.5rem 0;
  }
  &.x-large {
    padding: 5rem 0;
  }
`;

export const SpaceHorizontalWrapper = styled.div`
  &.x-small {
    padding: 0 0.25rem;
  }
  &.small {
    padding: 0 0.5rem;
  }
  &.median {
    padding: 0 1rem;
  }
  &.large {
    padding: 0 1.5rem;
  }
  &.x-large {
    padding: 0 5rem;
  }
`;

export const AttachmentsWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: inline-block;
  margin: 0.5rem 0;
  max-width: 100%;
`;

export const AdvancedSearchSectionWrapper = styled.div`
  border: 1px solid rgb(204, 204, 204);
  margin-top: 20px;
  padding: 10px 0;
`;

export const PrintBtnName = styled.span`
  color: rgb(66, 82, 110);
`;

export const AddressPlaceholderWrapper = styled.span`
  display: inline-block;
  font-weight: 400;
  padding-left: 0.2rem;
  color: #7a869a;
`;
export const PopUpContentContainer = styled.div`
  align-items: center;
  max-height: 200px;
  overflow: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  vertical-align: center;
  box-sizing: border-box;
  .popup-item {
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    display: block;
    width: 100%;
  }
  .popup-item:hover {
    background-color: rgb(222, 235, 255);
  }
`;

export const WasteTableWrapper = styled.div`
  > div {
    margin-bottom: 5px;
  }
`;

export const ThumbnailWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  // padding: 2px;
  margin: 2px;
  flex-shrink: 0;
  width: 180px;
  height: 170px;
  &.load-more {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file-name {
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: RGB(23, 43, 77);
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .upload-time {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: RGB(107, 119, 140);
    margin-left: 5px;
  }
  .image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    &:hover {
      .delete-button-admin {
        display: block;
      }
    }
    img {
      width: 100%;
      height: 120px;
    }
    .delete-button-admin {
      display: none;
    }
    .delete-button-tablet {
      display: block;
    }
  }
  &.small-size {
    width: 130px;
    height: 110px;
    .file-name {
      height: 14px;
      font-size: 10px;
    }
    .upload-time {
      height: 12px;
      font-size: 6px;
    }
    .image-wrapper {
      height: 70px;
    }
    img {
      height: 70px;
    }
  }
`;
export const CursorPointerWrapper = styled.div`
  cursor: pointer;
`;
