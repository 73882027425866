import React from 'react';
import { Label } from '@atlaskit/field-base';
import styled from 'styled-components';
import LabelValue from './styles/LabelValue';

const JobMemoContainer = styled.div`
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  .job-memo-content {
    width: 100%;
    background-color: #ffab00;
    padding: 10px 10px 4px 5px;
    box-sizing: border-box;
  }
`;
const JobMemo = ({ jobMemo }: { jobMemo: string }) => {
  return jobMemo ? (
    <JobMemoContainer>
      <Label isFirstChild label={'Notes'} />
      <LabelValue className={'job-memo-content'}>{jobMemo}</LabelValue>
    </JobMemoContainer>
  ) : null;
};

export default JobMemo;
