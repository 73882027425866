import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { PopupSelect } from '@atlaskit/select';
import PrintService from '../../../services/PrintService';
import CustomIconPrinter from '../../customIcon/CustomIconPrinter';
import PrintBtn from '../../buttons/PrintBtn';
import PDFPrintPopup from '../../PDFPrintPopup';
import { iApiAttr } from '../select/types';
import { INCLUDE_LOGO, PRINT_OPTIONS } from './LabelTypeConstants';
import { apiErrorToast } from '../../toast/ToastHandler';

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
const buttonBackgroundColor = { backgroundColor: `${colors.N600}` };
const buttonContentColor = { color: 'white' };
const iconBefore = <CustomIconPrinter size={'medium'} />;
const iconAfter = <ChevronDownIcon primaryColor={'white'} label={'down'} size={'medium'} />;

const getValueFromGetValues = (values: { value: string }[]) => {
  return values && Array.isArray(values) && values.length > 0 ? values.map(v => v.value).join(',') : '';
};

// Convert 0-length -> length
const getParamName = (origParamName: string) => {
  return origParamName.split('-')[1];
};

type iState = {
  isLoading: boolean;
  printPdfUrl?: string;
};
const initialState: iState = {
  isLoading: false,
};
const DynamicLabelPrinting = ({
  jobId,
  title,
  // eslint-disable-next-line
  icon,
  params,
  getValues,
  api,
  rowIndex,
  totalQty,
  afterPrintCallback,
}: {
  jobId: string;
  title?: string;
  icon?: boolean;
  params?: Array<string>;
  //    eslint-disable-next-line
  getValues?: (payload?: string | string[]) => any;
  api?: iApiAttr;
  rowIndex: number;
  totalQty?: number;
  afterPrintCallback?: () => void;
}) => {
  const [state, setState] = useState(initialState);

  const setIsLoading = (isLoading: boolean) => {
    setState({
      ...state,
      isLoading,
    });
  };
  const preparePrintParams = () => {
    if (!params || params.length === 0 || !getValues) {
      return {};
    }
    let result = {};
    params.forEach((param: string) => {
      result = {
        ...result,
        [getParamName(param)]: getValueFromGetValues(getValues(param)),
      };
    });
    return result;
  };
  const printLabel = async (includeLogo: boolean) => {
    setIsLoading(true);
    try {
      if (!api) {
        setIsLoading(false);
        return;
      }
      const collectParams = preparePrintParams();
      let response = null;
      switch (api.functionName) {
        case 'printRollLabel':
          response = await PrintService.printRollLabel(jobId, {
            rollNumber: rowIndex.toString(),
            includeLogo: includeLogo.toString(),
            printAll: 'false',
            ...collectParams,
          });
          break;
        case 'printCoreLabel':
          response = await PrintService.printCoreLabel(jobId, {
            start: rowIndex.toString(),
            end: rowIndex.toString(),
          });
          break;
        case 'printPalletLabel':
          response = await PrintService.printPalletLabel(jobId, {
            includeLogo: includeLogo.toString(),
            totalQty: totalQty?.toString() || '-1',
          });
          break;
        case 'printAllRollLabels':
          response = await PrintService.printRollLabel(jobId, {
            includeLogo: includeLogo.toString(),
            printAll: 'true',
          });
          break;
        default:
          break;
      }
      if (response) {
        setState({
          ...state,
          printPdfUrl: response,
          isLoading: false,
        });
        // printJS({ printable: response, type: 'pdf', showModal: true });
      }
      if (typeof afterPrintCallback === 'function') {
        setTimeout(() => {
          afterPrintCallback();
        }, 0);
      }
    } catch (error) {
      apiErrorToast(error);
      setIsLoading(false);
    }
  };

  const convertTitle = (name?: string) => {
    if (!name) return '';
    return name.toUpperCase().includes('ROLL') ? 'Roll' : 'Pallet';
  };
  // for print which only does not need to select print type
  const getExcludeRollPrintBody = () => <PrintBtn title={title || ''} onClick={() => printLabel(true)} />;

  // for print which does need to select print type from 'shamrock'/'plain'
  const getRollPrintBody = () => (
    <PopupSelect
      options={PRINT_OPTIONS}
      //  eslint-disable-next-line
      onChange={(selected: any) => printLabel(selected.value === INCLUDE_LOGO)}
      target={({ ref }) => (
        <Button
          iconBefore={iconBefore}
          iconAfter={iconAfter}
          style={buttonBackgroundColor}
          ref={ref}
          testId={'popup-trigger'}
        >
          <span style={buttonContentColor}>{convertTitle(title)}</span>
        </Button>
      )}
    />
  );

  return state.isLoading === true ? (
    <Spinner />
  ) : (
    <FlexContainer>
      {title?.toUpperCase().includes('CORE') ? getExcludeRollPrintBody() : getRollPrintBody()}
      <PDFPrintPopup pdfFileUrl={state.printPdfUrl} />
    </FlexContainer>
  );
};
export default DynamicLabelPrinting;
