/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Button from '@atlaskit/button';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import { handleNullException } from '../../../services/UtilsService';
import { iConfigColumn, iParams } from '../../../shared/UITypes/types';
import iStockTake from '../../../types/stockTake/iStockTake';
import { FlexContainer } from '../../../shared/styles/styles';
import iStockTakeItem from '../../../types/stockTake/iStockTakeItem';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import LinkBtn from '../../../shared/buttons/LinkBtn';
import TransparentIconBtn from '../../../shared/buttons/TransparentIconBtn';

const stockTakeTableRenderSpecialContent =
  ({ onOpenEditModal, idNameMap }: { onOpenEditModal: (id: string) => void; idNameMap?: iParams }) =>
  (item: iStockTake, column: iConfigColumn, index: number) => {
    const { type, key } = column;
    const { id } = item;
    switch (type) {
      case 'text-idNameMap':
        return <div>{handleNullException(idNameMap, handleNullException(item, key))}</div>;
      case 'operation':
        return (
          <Button appearance={'primary'} onClick={() => onOpenEditModal(id)} testId={'stock-take-operaion'}>
            Take
          </Button>
        );
      default:
        return <div />;
    }
  };

const finishTableRenderSpecialContent = (item: iStockTakeItem, column: iConfigColumn, index: number) => {
  const { type, key, dataSource } = column;
  switch (type) {
    case 'text-arrow-text':
      return (
        <FlexContainer>
          <div>{handleNullException(item, dataSource[0])}</div>
          <ArrowRightIcon label={'arrow-right'} />
          <div>{handleNullException(item, key)}</div>
        </FlexContainer>
      );

    default:
      return <div />;
  }
};

const foundItemsTableRenderSpecialContent =
  ({
    onOpenEditModal,
    onOpenDeleteModal,
    isWIP,
  }: {
    onOpenEditModal: (targetId: string) => void;
    onOpenDeleteModal: (targetId: string) => void;
    isWIP: boolean;
  }) =>
  (item: iStockTakeItem, column: iConfigColumn, index: number) => {
    const { type, key, dataSource } = column;
    switch (type) {
      case 'edit-trigger':
        return (
          <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[0])}>
            <LinkBtn
              btnName={handleNullException(item, key)}
              onClick={() => onOpenEditModal(handleNullException(item, 'id'))}
            />
          </ComposeSecondaryText>
        );

      case 'delete':
        return isWIP ? (
          <TransparentIconBtn
            icon={<TrashIcon label="delete" />}
            onClick={() => onOpenDeleteModal(handleNullException(item, 'id'))}
          />
        ) : (
          <div />
        );
      default:
        return <div />;
    }
  };
export default {
  stockTakeTableRenderSpecialContent,
  finishTableRenderSpecialContent,
  foundItemsTableRenderSpecialContent,
};
