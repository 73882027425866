import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import SectionMessage from '@atlaskit/section-message';
import { RootState } from '../../../redux/makeReduxStore';
import FormService from '../../../services/FormService';
import JobService from '../../../services/JobService';
import iFormTaggable from '../../../types/form/iFormTaggable';
import iJobShiftJob from '../../../types/job/iJobShiftJob';
// import iJobStatus from '../../../types/job/iJobStatus';
import {
  IS_NO_ROW_DONE,
  IS_OVER_PRODUCED,
  IS_PROGRESS_COMPLETED,
} from '../../dynamicForm/dynamicManufactureRows/shared/DynamicManufactureRows.constants';
import ShiftSummary from '../../dynamicForm/shiftSummary/ShiftSummary';
import CustomizeModal from '../../modal/CustomizeModal';
import WarningModal from '../../modal/WarningModal';
import PageInit from '../../PageInit';
import RestartJobShiftJob from '../../restartJobShiftJob/RestartJobShiftJob';
import {
  // addToastForAPIResponse,
  apiErrorToast,
} from '../../toast/ToastHandler';
import { BACK_END_URL } from '../../UrlMap';
import { isJobShiftJobError } from '../Helper';
// import JobAttributes from '../jobDetail/JobAttributes';
import JobKeyInfo from '../jobDetail/JobKeyInfo';
// import { LabelValuePair } from '../jobDetail/types/LabelValuePair';
import PrintJobMasterCard from '../printJobMasterCard/PrintJobMasterCard';
import JobProgress from './JobProgress';
import JobProgressPage from './JobProgressPage';
import { iDynamicItem, iJobProgressRow } from './types';
import JobProgressUtils from './JobProgress.utils';
import { handleNullException } from '../../../services/UtilsService';
import iJob from '../../../types/job/iJob';
import iJobAttribute from '../../../types/job/iJobAttribute';
import VerticalLabelGroup from '../jobDetail/VerticalLabelGroup';
import FormManagementUtils from '../../jobDetail/formManagement/FormManagement.utils';

const MarginNegativeContainer = styled.div`
  margin: -1em;
`;

const MutualBackground = styled.div`
  background-color: rgba(9, 30, 66, 0.54);
  opacity: 1;
  transition: opacity 220ms;
  padding: 0 1em;
`;

type iPreSubmitState = {
  isNoRowDone: boolean;
  isOverProduced: boolean;
};
const initialPreSubmitState: iPreSubmitState = {
  isNoRowDone: false,
  isOverProduced: false,
};
const JobProgressInPage = ({
  jobDetail,
  // qtyTotal,
  // jobNum,
  // machineName,
  // jobAttrs,
  isWIPJob,
  //  eslint-disable-next-line
  // modalJobStatus,
  // jobId,
  forwardStatus,
  closeModal,
  isModalFinished,
  isJobFinished,
  formTaggable,
}: // measurementUnit,
// isPrintRollLabelOpen,
// productAttrs,
{
  jobDetail: iJob;
  // qtyTotal: number;
  // jobNum: string;
  // machineName: string;
  // jobAttrs: LabelValuePair[];
  isWIPJob?: boolean;
  // modalJobStatus?: iJobStatus;
  // jobId: string;
  forwardStatus: () => void;
  closeModal: () => void;
  isModalFinished: boolean;
  isJobFinished?: boolean;
  formTaggable?: iFormTaggable;
  // measurementUnit: string;
  // isPrintRollLabelOpen?: boolean;
  // productAttrs: LabelValuePair[];
}) => {
  const {
    qtyTotal,
    jobNumber: jobNum,
    machine: { name: machineName },
    jobAttributes,
    id: jobId,
    product: {
      measurement: { shortName: measurementUnit },
    },
  } = jobDetail;
  const history = useHistory();
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirming, setIsConfirming] = useState(false);
  const [restartJSJ, setRestartJSJ] = useState(false);
  const { control, setValue, getValues, errors, handleSubmit, setError, clearErrors } = useForm({});
  const [rows, setRows] = useState<Array<iJobProgressRow>>([]);
  const [formName, setFormName] = useState('');
  //  eslint-disable-next-line
  const [shiftSummaryValid, setShiftSummaryValid] = useState(true);
  const [preSubmitState, setPreSubmitState] = useState(initialPreSubmitState);

  //  eslint-disable-next-line
  const setValueAndUpdateRows = (key: string, value: any, config?: Object) => {
    setValue(key, value, config);
    const newRows = rows.map((row: iJobProgressRow) => {
      const newInputs = row.inputs?.map((input: iDynamicItem) =>
        input.id === key ? { ...input, defaultValue: value } : input,
      );
      return { inputs: newInputs, id: row.id };
    });
    setRows(newRows);
  };

  //  eslint-disable-next-line
  const mapFormData = (data: any) => {
    // eslint-disable-next-line
    const collectedData: any = {
      formFieldMap: data,
      jobId,
      formTaggableId: formTaggable?.id || '',
      jobShiftJobId: currentShift?.currentJobShiftJobId,
    };
    if (isWIPJob) {
      collectedData.entityName = 'jobShiftJob';
      collectedData.entityId = currentShift?.currentJobShiftJobId || '';
    }
    return collectedData;
  };
  //  eslint-disable-next-line
  const submit = async (data: any) => {
    //  eslint-disable-next-line
    delete data[IS_NO_ROW_DONE];
    //  eslint-disable-next-line
    delete data[IS_OVER_PRODUCED];
    //  eslint-disable-next-line
    delete data[IS_PROGRESS_COMPLETED];

    setIsConfirming(true);
    try {
      const uploadData = mapFormData(data);
      const resUploadFormData = await FormService.uploadJobProgressFormData(uploadData);
      if (resUploadFormData.data.success === true) {
        forwardStatus();
      }
    } catch (error) {
      setIsConfirming(false);
      if (isJobShiftJobError(error)) {
        setRestartJSJ(true);
        return;
      }
      apiErrorToast(error);
    }
  };
  //  eslint-disable-next-line
  const withPreSubmit = async (data: any) => {
    const isNoRowDone = handleNullException(data, IS_NO_ROW_DONE);
    const sumOfActualWeight = handleNullException(data, IS_OVER_PRODUCED);
    if (isNoRowDone) {
      setPreSubmitState({ ...preSubmitState, isNoRowDone });
      return;
    }
    if (!!sumOfActualWeight && sumOfActualWeight > qtyTotal) {
      setPreSubmitState({ ...preSubmitState, isOverProduced: true });
      return;
    }
    await submit(data);
  };

  useEffect(
    () => {
      let isCancelled = false;
      async function loadJobProgress() {
        if (!formTaggable) {
          setIsLoading(false);
          return;
        }
        setIsLoading(true);
        try {
          if (!currentShift?.id) {
            throw new Error('Invalid current shift');
          }
          // validate current job shift job
          const response = await JobService.getJobShiftJobs(currentShift?.id);
          if (isCancelled) return;
          const targetJobShiftJob = response.find((item: iJobShiftJob) => item.jobId === jobId);
          if (typeof targetJobShiftJob === 'undefined') {
            setRestartJSJ(true);
            return;
          }
          const formQuestions = await FormService.getProgressFormFields(formTaggable.formId);
          const formAnswers = await FormService.getProgressFormFieldValues(formTaggable.id);
          if (isCancelled) return;
          if (formQuestions) {
            setFormName(formQuestions.name);
            setRows(JobProgressUtils.mapFormRows(jobId, formQuestions.formRows, formAnswers));
            setIsLoading(false);
          }
        } catch (error) {
          if (isCancelled) return;
          apiErrorToast(error);
          setRows([]);
          setIsLoading(false);
        }
      }
      loadJobProgress();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [
      formTaggable,
      //  eslint-disable-next-line
      currentShift?.currentJobShiftJobId,
      jobId,
    ],
  );
  const heading =
    //    eslint-disable-next-line
    isWIPJob ? `Continuing Job #:${jobNum}` : formName;
  const bottom = !isJobFinished ? "Make sure you've gone though all questions" : '';
  const backToList = () => history.push(BACK_END_URL);
  const getFinishedJobProgressContent = () => (
    <>
      <h4>Are you really sure to finish this job?</h4>
      <p style={{ marginBottom: '16px' }}>
        Please note: once you confirm, you cannot resubmit data from all job progresses again
      </p>
      {/* <PrintJobMasterCard jobId={jobId} /> */}
      <PrintJobMasterCard jobId={jobId} printFn={JobService.printJobMasterCard} btnName={'Job Card'} />
    </>
  );

  const switchModalBody = () => {
    if (isLoading) return <PageInit />;
    if (isModalFinished) return getFinishedJobProgressContent();
    return (
      <JobProgress
        errors={errors}
        rows={rows}
        control={control}
        getValues={getValues}
        setValue={setValueAndUpdateRows}
        jobShiftJobId={currentShift?.currentJobShiftJobId || ''}
        setError={setError}
        clearErrors={clearErrors}
        formTaggable={formTaggable}
        // formTaggable?: iFormTaggable;
      >
        <ShiftSummary key={`${jobId}-shiftSummary`} jobId={jobId} measurementUnit={measurementUnit} />
      </JobProgress>
    );
  };
  //  continue-> cancel -> back to job list
  //  finished-> confirm -> don't upload form data
  const getJobProgressPage = () => {
    if (restartJSJ) {
      return <RestartJobShiftJob isOpen={restartJSJ} closeValidate={() => setRestartJSJ(false)} jobId={jobId} />;
    }
    return (
      <JobProgressPage
        submitHandler={isModalFinished ? forwardStatus : handleSubmit(withPreSubmit)}
        closeHandler={isWIPJob ? backToList : closeModal}
        isMiddlePage={isWIPJob}
        heading={isModalFinished ? 'Finished' : heading}
        bottom={bottom}
        isDisabled={isJobFinished || Object.keys(errors).length > 0 || !shiftSummaryValid}
        disabledReason={isJobFinished ? 'Job completed' : undefined}
        isConfirming={isConfirming}
      >
        {switchModalBody()}
      </JobProgressPage>
    );
  };

  return (
    <>
      <MarginNegativeContainer>
        <MutualBackground>
          <JobKeyInfo jobNum={jobNum} machineName={machineName} />
          <VerticalLabelGroup group={FormManagementUtils.getJobInfoGroup(jobDetail)} />
          <VerticalLabelGroup
            group={jobAttributes.map((item: iJobAttribute) => ({
              label: item.name,
              value: item.jobAttributeValues.value,
            }))}
          />
        </MutualBackground>
        {getJobProgressPage()}
      </MarginNegativeContainer>
      <WarningModal
        isOpen={preSubmitState.isNoRowDone}
        onCancel={() => setPreSubmitState({ ...preSubmitState, isNoRowDone: false })}
        modalBody={'You can not move to next step until some rows are done'}
        modalHeading={'It looks like there is not any row done'}
      />
      <CustomizeModal
        isOpen={preSubmitState.isOverProduced}
        onCancel={() => setPreSubmitState({ ...preSubmitState, isOverProduced: false })}
        onConfirm={() => {
          setPreSubmitState({ ...preSubmitState, isOverProduced: false });
          submit(getValues());
        }}
        modalHeading={'It looks like more products are produced than expected'}
        modalBody={
          <SectionMessage appearance={'warning'}>
            <div>
              <div>Total qty scheduled in the job is: {qtyTotal}</div>
              <div>
                Total actual weight of DONE rolls is:
                {handleNullException(getValues(), IS_OVER_PRODUCED)}
              </div>
            </div>
          </SectionMessage>
        }
      />
    </>
  );
};
export default JobProgressInPage;
