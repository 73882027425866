import React, { useState } from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import ModalPage from '../../../../shared/modalPage/ModalPage';
import { iBtnAppearance } from '../../../../shared/UITypes/types';

const BtnWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
const initialState = {
  showPopup: false,
  confirming: false,
};

const defaultHeading = 'Are you sure to start?';
const defaultPopupContent = <p>Please confirm you are about to start it.</p>;

export type iElementWithModalPageProps = {
  onClick?: () => void;
  popupHeading?: string;
  popupContent?: React.ReactNode;
  isConfirming?: boolean;
  isDisabled?: boolean;
  confirmBtnAppearance?: iBtnAppearance;
  confirmBtnName?: string;
  children: React.ReactNode;
};
const ElementWithModalPage = ({
  onClick,
  popupHeading,
  popupContent,
  isConfirming,
  isDisabled = false,
  confirmBtnAppearance,
  confirmBtnName,
  children,
}: iElementWithModalPageProps) => {
  const [state, setState] = useState(initialState);

  const handleClicked = () => {
    setState({
      ...state,
      showPopup: true,
    });
  };

  const close = () => {
    setState({
      ...state,
      showPopup: false,
    });
  };
  const doConfirm = () => {
    setState({
      ...state,
      showPopup: false,
      confirming: true,
    });
    if (typeof onClick === 'function') {
      onClick();
    }
    setState({
      ...state,
      showPopup: false,
      confirming: false,
    });
  };

  const getModalContent = () => {
    if (state.showPopup !== true) return null;
    return (
      <ModalPage
        heading={popupHeading || defaultHeading}
        onConfirm={doConfirm}
        onCancel={close}
        isDisabled={isDisabled}
        confirmBtnAppearance={confirmBtnAppearance}
        confirmBtnName={confirmBtnName}
      >
        {popupContent || defaultPopupContent}
      </ModalPage>
    );
  };

  const getConfirmingBtn = (confirming: boolean) => {
    return confirming ? (
      <Spinner testId="start-spinner" />
    ) : (
      <BtnWrapper onClick={handleClicked} data-testid={'btn'}>
        {children}
      </BtnWrapper>
    );
  };

  const getBtn = () => {
    if (isConfirming === undefined) {
      return getConfirmingBtn(state.confirming);
    }
    return getConfirmingBtn(isConfirming);
  };

  return (
    <>
      {getBtn()}
      {getModalContent()}
    </>
  );
};
export default ElementWithModalPage;
