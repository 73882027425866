import React, { useState } from 'react';
import _ from 'lodash';
import Checkbox from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { ShowHideButtonWrapper, ShowHideModalBodyWrapper } from './ShowHideColumns.style';
import { addExact, divExact } from '../../calculationHelper/calculationHelper';

const initialState = {
  showPopup: false,
};

const defaultChunkSize = 10;
type iLabelArrayValuePair = {
  label: string;
  value: Array<string>;
};

export type iShowHideColumnsProps = {
  onToggleColumns: (columnName: string, value: boolean) => void;
  selectedColumns: Array<string>;
  columnLists: Array<iLabelArrayValuePair>;
};
const ShowHideColumns = ({ onToggleColumns, selectedColumns, columnLists }: iShowHideColumnsProps) => {
  const [state, setState] = useState(initialState);

  const handleClicked = () => {
    setState({
      ...state,
      showPopup: true,
    });
  };
  const close = () => {
    setState({
      ...state,
      showPopup: false,
    });
  };
  const getModalBody = () => {
    return columnLists.map((group: iLabelArrayValuePair) => {
      const groups = _.chunk(group.value, defaultChunkSize);
      return (
        <div className={'show-hide-group'} key={group.label}>
          <div className={'show-hide-group-label'}>{group.label}</div>
          <div className={'show-hide-group-rows'}>
            {groups.map((columns: Array<string>, index: number) => {
              return (
                <div
                  className={'show-hide-group-row'}
                  // eslint-disable-next-line
                  key={`${group.label}-chunk-${index}`}
                >
                  {columns.map((item: string) => (
                    <Checkbox
                      className="hideCheckboxForiOS"
                      key={item}
                      defaultChecked={!!selectedColumns.find((column: string) => item === column)}
                      label={item}
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        const val = event.currentTarget.checked;
                        onToggleColumns(item, val);
                      }}
                      name={`checkbox-${item}`}
                      testId={`checkbox-${group.label}-${item}`}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };
  const calculateWidth = () => {
    const chunks = Math.floor(
      divExact(
        columnLists.reduce((acc: number, cur: iLabelArrayValuePair) => addExact(acc, cur.value.length), 0),
        10,
      ),
    );
    if (chunks <= 2) return 'medium';
    if (chunks > 2 && chunks <= 5) return 'large';
    return '90%';
  };
  const getModal = () => {
    if (state.showPopup !== true) return null;
    return (
      <ModalTransition>
        <Modal width={calculateWidth()} testId="show-hide-columns-modal">
          <ModalHeader>
            <ModalTitle>Show/Hide Columns</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <ShowHideModalBodyWrapper>{getModalBody()}</ShowHideModalBodyWrapper>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} testId={'show-hide-columns-close-button'}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </ModalTransition>
    );
  };
  return (
    <>
      <ShowHideButtonWrapper>
        <Button
          onClick={() => handleClicked()}
          iconAfter={<SettingsIcon label={'settings'} />}
          testId={'show-hide-columns-trigger-button'}
        >
          Columns
        </Button>
      </ShowHideButtonWrapper>

      {getModal()}
    </>
  );
};
export default ShowHideColumns;
