import React from 'react';
import { GridColumn } from '@atlaskit/page';

import { addsOnValue } from '../../../services/UtilsService';
import { LabelValuePair } from './types/LabelValuePair';
import VerticalLabel from './VerticalLabel';
import { FlexContainer } from '../../styles/styles';

const VerticalLabelGroup = ({ group }: { group: LabelValuePair[] }) => (
  <FlexContainer data-testid={'flex-container'}>
    {group.map(attr => (
      <GridColumn medium={2} key={attr.label}>
        <VerticalLabel label={attr.label} value={addsOnValue(attr.value, attr.prefix, attr.postfix)} isFirstChild />
      </GridColumn>
    ))}
  </FlexContainer>
);

export default VerticalLabelGroup;
