import AppService, { iFormParams } from './AppService';

const getProgressFormFields = (formId: string) => {
  return AppService.get(`/form/${formId}`, {
    include: 'formRows.formFields',
  }).then(({ data }) => {
    return data;
  });
};

const uploadJobProgressFormData = (formData: iFormParams) => {
  return AppService.put('/formFieldValue/batchUpsert', formData);
};

const getProgressFormFieldValues = (formTaggableId: string) => {
  return AppService.get('/formFieldValue', {
    filter: `formTaggableId:${formTaggableId}`,
  }).then(({ data }) => {
    return data;
  });
};

const isJobShiftJobContinued = (jobShiftJobId: string) => {
  return AppService.get('/formTaggable/isFormValueExists', {
    entityName: 'jobShiftJob',
    entityId: jobShiftJobId,
  }).then(({ data: { isFormValueExists } }) => {
    return isFormValueExists;
  });
};

export default {
  getProgressFormFields,
  getProgressFormFieldValues,
  uploadJobProgressFormData,
  isJobShiftJobContinued,
};
