import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { iKeyValuePairs } from '../../../../shared/UITypes/types';
import iStockTake from '../../../../types/stockTake/iStockTake';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import { getHeads } from '../../../../services/UtilsService';
import { BULK_STOCK_ITEM_TABLE_COLUMNS, BULK_STOCK_PAGE_NAME } from '../shared/BulkStockTake.constants';
import { combine, getRows } from '../shared/BulkStockTake.util';

const BulkSTItemTable = ({
  stockTakeDetail,
  stockTakeItems,
  createFn,
  editFn,
  deleteFn,
}: {
  stockTakeDetail?: iStockTake;
  stockTakeItems: Array<iStockTakeItem>;
  createFn: (data: iKeyValuePairs) => Promise<void>;
  editFn: (targetId: string, data: iKeyValuePairs) => Promise<void>;
  deleteFn: (targetId: string) => Promise<void>;
}) => {
  return (
    <DynamicTable
      head={getHeads(BULK_STOCK_ITEM_TABLE_COLUMNS, BULK_STOCK_PAGE_NAME)}
      rows={getRows({
        stockTakeId: stockTakeDetail?.id,
        data: combine({ stockTakeDetail, stockTakeItems }),
        columns: BULK_STOCK_ITEM_TABLE_COLUMNS,
        createFn,
        editFn,
        deleteFn,
      })}
    />
  );
};

export default BulkSTItemTable;
