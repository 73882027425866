import styled from 'styled-components';

interface readOnlyProps {
  readOnly?: boolean;
}
// margin for bottom progress bar
const JobDetailContainer = styled.div<readOnlyProps>`
  margin-bottom: ${props => (!props.readOnly ? '90px' : '0px')};
`;

export default JobDetailContainer;
