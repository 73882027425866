import React from 'react';
import { useSelector } from 'react-redux';
import Activities from '../../../shared/activities/Activities';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_TAKES } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';

const AttachmentsAndActivity = ({ entityId, entityName }: { entityId: string; entityName: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_UPDATE, user);
  return (
    <>
      <GeneralAttachments
        entityId={entityId}
        entityName={entityName}
        thumbnailSize={'small'}
        isTablet
        canCreate={canUpdate}
        canDelete={canUpdate}
      />
      <Activities modalId={entityId} modalName={entityName} />
    </>
  );
};

export default AttachmentsAndActivity;
