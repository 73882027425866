import React, { useState } from 'react';
import LocalStorageService from '../../../services/LocalStorageService';

const useLocalStorageHook = <T,>({ key, defaultValue }: { key: string; defaultValue: T }) => {
  const [state, setState] = useState(() => {
    const valueInLocalStorage = LocalStorageService.getItem(key);
    if (valueInLocalStorage) {
      try {
        return JSON.parse(valueInLocalStorage) as T;
      } catch (error) {
        LocalStorageService.removeItem(key);
      }
    }
    // if (typeof defaultValue === 'function') return defaultValue();
    return defaultValue;
  });
  // clear function only remove the local storage, does not reset the state
  const clear = () => LocalStorageService.removeItem(key);

  React.useEffect(() => {
    LocalStorageService.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState, clear] as const;
};

export default useLocalStorageHook;
