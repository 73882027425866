import React from 'react';
import iAddress from '../../../../types/contactCompany/iAddress';
import CreateAganistSOHOC from '../createDNItem/createAganistSO/CreateAganistSOHOC';
import CreateAganistJob from '../createDNItem/createAganistJob/CreateAganistJobHOC';
import { FlexContainer, SpaceHorizontalWrapper } from '../../../../shared/styles/styles';

export type iCreateNoteItemProps = {
  dispatchNoteId: string;
  customerId?: string;
  //  eslint-disable-next-line
  onCreate: (data: any) => Promise<void>;
  DNShippingAddress?: iAddress;
  DNItemLength?: number;
  isServiceProvider?: boolean;
  customerShippingAddress?: iAddress;
};

const CreateNoteItem = ({
  dispatchNoteId,
  customerId,
  onCreate,
  DNShippingAddress,
  DNItemLength,
  isServiceProvider = false,
  customerShippingAddress,
}: iCreateNoteItemProps) => {
  return (
    <FlexContainer>
      <CreateAganistJob
        DNId={dispatchNoteId}
        customerId={customerId}
        onCreate={onCreate}
        DNItemLength={DNItemLength}
        DNShippingAddress={DNShippingAddress}
        isServiceProvider={isServiceProvider}
        customerShippingAddress={customerShippingAddress}
      />
      <SpaceHorizontalWrapper className={'small'} />
      <CreateAganistSOHOC
        DNId={dispatchNoteId}
        customerId={customerId}
        onCreate={onCreate}
        DNItemLength={DNItemLength}
        DNShippingAddress={DNShippingAddress}
      />
    </FlexContainer>
  );
};

export default CreateNoteItem;
