import React from 'react';
import Button from '@atlaskit/button';

const Reset = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button appearance={'link'} onClick={onClick} testId={'reset-button'}>
      Reset
    </Button>
  );
};

export default Reset;
