import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../shared/hooks/useShowHideColumnHook/useColumnHooksUpgrade';
import SearchBar from '../../shared/search/SearchBar';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import CustomizePagination from '../../shared/pagination/CustomizePagination';
import StockTakeTable from './list/StockTakeTable';
import SpinWhenLoading from '../../shared/spin/SpinWhenLoading';
import useStatusFilter from '../../shared/hooks/useStatusFilter/useStatusFilter';
import iStockTake from '../../types/stockTake/iStockTake';
import EntityStatusCategoryService from '../../services/EntityStatusCategoryService';
import StockTakeService from '../../services/StockTakeService';
import { FlexContainer, PageSubtitle, PageTitle } from '../../shared/styles/styles';
import { LIST_PAGE_NAME, STOCK_TAKE_COLUMNS } from './shared/StockTake.constant';
import { ALL, STATUS_CATEGORY_NEW, STATUS_CATEGORY_IN_PROGRESS } from '../../shared/constants/StatusCategories';
import { STOCK_TAKE_DETAIL_URL } from '../../shared/UrlMap';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_TAKES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';

const StockTakeList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_UPDATE, user);
  const history = useHistory();
  const { state, edit, onSearch, onFilter, onSetSort, onSetCurrentPage, onCloseModal, onOpenEditModal } =
    useListCrudHook<iStockTake>({
      getFn: StockTakeService.getStockTakeList,
      updateFn: StockTakeService.updateStockTake,
      createFn: StockTakeService.createStockTake,
      deleteFn: StockTakeService.deactivateStockTake,
      keywordColumns: 'stockTakeNum',
      sort: 'updatedAt:DESC',
      notRenderWithoutFilter: true,
      paginationApplied: true,
    });
  const { statusCluster, categories, isStatusLoading } = useStatusFilter({
    type: 'StockTake',
    targetCategories: [STATUS_CATEGORY_NEW, STATUS_CATEGORY_IN_PROGRESS],
    getFn: EntityStatusCategoryService.getEntityCategoryList,
  });
  const { columns, getShowHideColumns } = useColumnHooksUpgrade(LIST_PAGE_NAME, STOCK_TAKE_COLUMNS);
  const getPageHeader = () => (
    <FlexContainer className={'space-between'}>
      <div>
        <PageTitle className={'median'}>{LIST_PAGE_NAME}</PageTitle>
        <PageSubtitle>
          List of {state.data.length} open {LIST_PAGE_NAME}(s) waiting for take
        </PageSubtitle>
      </div>
      <div style={{ flexGrow: 0.1 }}>
        <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'search via No....'} />
      </div>
    </FlexContainer>
  );

  useEffect(
    () => {
      if (!isStatusLoading) {
        const filter =
          statusCluster.includes(ALL) || statusCluster.length === 0 ? '' : `statusId:${statusCluster.join('|')}`;
        onFilter(filter);
      }
    },
    //  eslint-disable-next-line
    [JSON.stringify(statusCluster)],
  );

  const onStart = () => {
    if (typeof edit.target === 'undefined') return;
    // do start then close modal
    history.push(STOCK_TAKE_DETAIL_URL.replace(':id', edit.target.id));
  };

  if (state.isLoading) return <SpinWhenLoading />;
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <FlexContainer className={'space-between'}>
        <div />
        {getShowHideColumns()}
      </FlexContainer>
      <StockTakeTable
        data={state.data}
        onSetSort={onSetSort}
        onOpenEditModal={onOpenEditModal}
        isLoading={state.isLoading}
        columns={columns.filter(col => {
          if (col.key === 'operation' && !canUpdate) {
            return false;
          }
          return true;
        })}
        categories={categories}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen && !!edit.target}
        onConfirm={onStart}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </BackendPage>
  );
};

export default StockTakeList;
