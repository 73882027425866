import React from 'react';
import _ from 'lodash';
import { Controller, Control, FieldError, DeepMap } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import { Field } from '@atlaskit/form';
import { ErrorMsg, FlexAlignEndContainer } from '../styleContainer';
import iFormFieldValueSet from '../../../types/form/iFormFieldValueSet';
import { AddZeroAhead } from '../../../services/UtilsService';
import AddsOn from '../addsOn/AddsOn';

// for most component where dynamicinput is imported, it's for number;
// DynamicInput can be a wrapper of InputWithController : adpat DynamicInput defaultValut/onChange to InputWithController's defaultValue/onChange
const DynamicInput = ({
  id,
  placeholder,
  control,
  defaultValue,
  onChange,
  isLarger,
  prefix,
  suffix,
  isDisabled = false,
  errors,
  isCompulsory = false,
  errorClassName,
  type = 'text',
}: {
  id: string;
  placeholder?: string;
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: Array<iFormFieldValueSet>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  isLarger?: boolean;
  prefix?: string;
  suffix?: string;
  isDisabled?: boolean;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  isCompulsory?: boolean;
  errorClassName?: string;
  type?: string;
}) => {
  const handleValueChange = (newValue: string) => {
    onChange(id, [{ value: AddZeroAhead(newValue) }], { shouldValidate: true });
  };
  const parseDefaultValue = () => {
    if (defaultValue && defaultValue.length === 1) {
      return defaultValue[0].value as string;
    }
    return '';
  };
  const validateInput = (value: Array<iFormFieldValueSet>) => {
    if (isLarger) return true;
    // is required
    if (isCompulsory && (!value || value.length !== 1 || value[0].value === '')) {
      return false;
    }
    // must be a number
    if (
      value &&
      value.length === 1 &&
      value[0].value !== '' &&
      Number.isNaN(Number(value[0].value.toString().replace(',', '')))
    ) {
      return false;
    }

    return true;
  };
  //  longer textfield
  const largerTextField = isLarger ? { flexGrow: 0.5 } : { flexGrow: 0 };

  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || [{ value: '' }]}
        rules={{
          validate: value => validateInput(value),
        }}
        render={ctrlProps => (
          <FlexAlignEndContainer style={largerTextField} data-testid={'dynamic-Input'}>
            <div className={`dynamic-input-container ${_.get(errors, id) && 'validation-error'}`}>
              <Field key={id} name={id}>
                {({ fieldProps }) => (
                  <Textfield
                    // eslint-disable-next-line
                    {...ctrlProps}
                    // eslint-disable-next-line
                    {...fieldProps}
                    name={id}
                    value={parseDefaultValue()}
                    placeholder={placeholder}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      const val = event.currentTarget.value;
                      handleValueChange(val);
                    }}
                    elemAfterInput={<AddsOn value={suffix} />}
                    elemBeforeInput={<AddsOn value={prefix} />}
                    isDisabled={isDisabled}
                    type={type}
                    className={'dynamic-input-text-field'}
                    testId={id}
                  />
                )}
              </Field>
            </div>
            {_.get(errors, id) && (
              <ErrorMsg className={errorClassName} data-testid={'error-msg'}>
                must be a number
              </ErrorMsg>
            )}
          </FlexAlignEndContainer>
        )}
      />
    </>
  );
};

export default DynamicInput;
