import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iJobShift from '../../types/job/iJobShift';

export type iJobShiftWithJobShiftJobId = iJobShift & {
  currentJobShiftJobId?: string;
};
export type ShiftState = {
  currentShift: iJobShiftWithJobShiftJobId | undefined;
};
/**
 * Initial State
 */
const initialState: ShiftState = {
  currentShift: undefined,
};

/**
 * Actions
 */
const actions = {
  updateCurrentShift: (state: ShiftState = initialState, action: PayloadAction<ShiftState>) => ({
    ...state,
    currentShift: action.payload.currentShift,
  }),
  clearShift: (state: ShiftState = initialState) => ({
    ...state,
    currentShift: undefined,
  }),
};
/**
 * Slice
 */
const ShiftSlice = createSlice({
  name: 'Shift',
  initialState,
  reducers: actions,
});
/**
 * action
 */
export const { updateCurrentShift, clearShift } = ShiftSlice.actions;
/**
 * reducer
 */
export default ShiftSlice.reducer;
