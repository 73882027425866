import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import ModalPage from '../../../shared/modalPage/ModalPage';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import StockTransferUtil from '../sharedComponents/StockTransfer.util';
import AddProdsTable from './AddProdsTable';

const getTargetWarehouseRecords = (records: Array<iItemLocationTransfer>, warehouseLocation?: iWarehouseLocation) => {
  return records.filter((item: iItemLocationTransfer) => item.warehouseLocationId === warehouseLocation?.id);
};
export type iAddProdsPopupProps = {
  records: Array<iItemLocationTransfer>;
  updateRecords: (newRecords: Array<iItemLocationTransfer>) => void;
  onCancel: () => void;
  warehouseLocation: iWarehouseLocation;
};
const AddProdsPopup = ({ records, updateRecords, onCancel, warehouseLocation }: iAddProdsPopupProps) => {
  const [localRecords, setLocalRecords] = useState<Array<iItemLocationTransfer>>(() =>
    getTargetWarehouseRecords(records, warehouseLocation),
  );
  const [isNoProduct, setIsNoProduct] = useState(false);
  //  eslint-disable-next-line
  const { control, setValue, errors, handleSubmit } = useForm();
  const onAddToList = () => {
    updateRecords(localRecords);
    onCancel();
  };
  const onNoProduct = useCallback((isEmpty: boolean) => setIsNoProduct(() => isEmpty), []);
  return (
    <ModalPage
      //   eslint-disable-next-line
      onConfirm={handleSubmit(onAddToList)}
      onCancel={onCancel}
      // isConfirming={state.isConfirming}
      isDisabled={Object.keys(errors).length > 0 || isNoProduct}
      heading={`Transfer from ${warehouseLocation.name}(${warehouseLocation.locationCode})`}
      confirmBtnName={'Add to List'}
    >
      <AddProdsTable
        warehouseLocationId={warehouseLocation.id}
        control={control}
        onChange={setValue}
        errors={errors}
        records={localRecords}
        //  eslint-disable-next-line
        onMovingQtyChange={(name: string, value: any) => {
          setLocalRecords(() => StockTransferUtil.mergeRecords([value], localRecords));
        }}
        onNoProduct={onNoProduct}
      />
    </ModalPage>
  );
};

export default AddProdsPopup;
