import React from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import { ModalPageWrapper } from './ModalPage.style';

const ModalPageNoConfirm = ({
  heading,
  children,
  onCancel,
  cancelBtnName = 'Close',
}: {
  heading: string;
  children: React.ReactNode;
  onCancel: () => void;
  cancelBtnName?: string;
}) => {
  const CloseButton = () => {
    return (
      <Button testId={'cross-button'} onClick={onCancel}>
        X
      </Button>
    );
  };

  return (
    <ModalPageWrapper data-testid={'modal-page'}>
      <div className={'modal-header'}>
        <h3>{heading}</h3>
        <CloseButton />
      </div>
      <div className={'modal-body'}>{children}</div>
      <div className={'modal-footer'}>
        <div />
        <ButtonGroup>
          <Button onClick={onCancel} testId={'cancel-button'}>
            {cancelBtnName}
          </Button>
        </ButtonGroup>
      </div>
    </ModalPageWrapper>
  );
};

export default ModalPageNoConfirm;
