import { iKeyValuePairs } from '../shared/UITypes/types';
import AppService from './AppService';

const createAddress = (content: iKeyValuePairs) => {
  return AppService.post('/address', content).then(({ data }) => data);
};

const editAddress = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/address/${id}`, updateContent).then(({ data }) => data);
};

const getAddressDetail = (id: string) => {
  return AppService.get(`/address/${id}`).then(({ data }) => data);
};

export default {
  createAddress,
  editAddress,
  getAddressDetail,
};
