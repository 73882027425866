import React, { useCallback } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import iStockTake from '../../../../types/stockTake/iStockTake';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import TableHelper from '../../../../shared/tableHelper/TableHelper';
import StockTakeUtil from '../../shared/StockTake.util';
import { iParams } from '../../../../shared/UITypes/types';
import StockTakeItemService from '../../../../services/StockTakeItemService';
import { FINISHED_STOCK_TAKE_ITEM_TABLE, FINISH_PREVIEW_TABLE_COLUMNS } from '../../shared/StockTake.constant';

/**
 * 1. the table is the same as the table in FinishModalBody: refactor point
 * 2. a table wrapper to control width of each column might be necessary
 */
const FinishedTable = ({ stockTakeDetail }: { stockTakeDetail: iStockTake }) => {
  const { id: stockTakeId } = stockTakeDetail;
  const getFnCallback = useCallback(
    (config?: iParams) => StockTakeItemService.getStockTakeItemListByStockTakeId(stockTakeId, config),
    [stockTakeId],
  );
  const { state } = useListCrudHook<iStockTakeItem>({
    getFn: getFnCallback,
    paginationApplied: true,
    sort: 'createdAt:DESC',
    filter: 'isUserInput:false',
  });

  return (
    <DynamicTable
      head={TableHelper.getHeads(FINISH_PREVIEW_TABLE_COLUMNS, FINISHED_STOCK_TAKE_ITEM_TABLE)}
      rows={TableHelper.getRows({
        data: state.data,
        columns: FINISH_PREVIEW_TABLE_COLUMNS,
        categories: [],
        renderSpecialContent: StockTakeUtil.finishTableRenderSpecialContent,
      })}
      isLoading={state.isLoading}
    />
  );
};

export default FinishedTable;
