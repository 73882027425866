import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import moment from 'moment';
import styled from 'styled-components';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import HipchatChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up';

import iPurchaseOrderItem from '../../../types/purchaseOrder/iPurchaseOrderItem';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import { getHeads, getQtyAfterConversion, getReceivedQtyBeforeConversion } from '../Purchase.utilities';
import iPoReceiving from '../../../types/purchaseOrder/iPoReceiving';
import { PageTitle } from '../../../shared/styles/styles';
import { handleNullException } from '../../../services/UtilsService';

const COLUMNS = ['Product', 'Supplier Product Code', 'Quantity', 'Received Qty'];

const ChevronContainer = styled.div`
  display: inline-block;
`;

const TitleWithChevron = styled.div`
  display: flex;
`;

const TableWrapper = styled.div``;

const PoItems = ({ poReceiving }: { poReceiving?: iPoReceiving }) => {
  const [isShown, setIsShown] = useState(false);

  const getNameContent = (item: iPurchaseOrderItem) => (
    <ComposeSecondaryText secondaryText={item.product.name}>
      <span style={{ fontWeight: 500 }}>{item.product.productCode}</span>
    </ComposeSecondaryText>
  );

  const getRows = () => {
    return poReceiving?.purchaseOrder?.purchaseOrderItems
      ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
      .map((item: iPurchaseOrderItem) => ({
        cells: [
          {
            key: item.product.productCode,
            content: getNameContent(item),
          },
          { content: handleNullException(item, 'supplierProductCode') },
          { content: getQtyAfterConversion(item, true) },
          { content: getReceivedQtyBeforeConversion(item) },
        ],
      }));
  };

  return (
    <>
      <TitleWithChevron>
        <PageTitle className={'small space-right space-below'}>PO Items</PageTitle>
        {isShown ? (
          <ChevronContainer onClick={() => setIsShown(prev => !prev)}>
            <HipchatChevronUpIcon label={'chervronUp'} />
          </ChevronContainer>
        ) : (
          <ChevronContainer onClick={() => setIsShown(prev => !prev)}>
            <HipchatChevronDownIcon label={'chervronDown'} />
          </ChevronContainer>
        )}
      </TitleWithChevron>
      {isShown && (
        <TableWrapper>
          <DynamicTable
            head={getHeads(COLUMNS, 'po-receiving-poItems')}
            rows={getRows()}
            testId={'po-receiving-poItems-table'}
          />
        </TableWrapper>
      )}
    </>
  );
};

export default PoItems;
