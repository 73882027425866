import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import iDispatchNote from '../../types/dispatchNote/iDispatchNote';
import BackendPage from '../../layout/BackendPage';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import SearchBar from '../../shared/search/SearchBar';
import CustomizePagination from '../../shared/pagination/CustomizePagination';
import GeneralPrintBtn from '../../shared/buttons/GeneralPrintBtn';
import ModalPage from '../../shared/modalPage/ModalPage';
import DispatchNoteTable from './list/DispatchNoteTable';
import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import DnModalBody from './list/DnModalBody';
import useColumnHooksUpgrade from '../../shared/hooks/useShowHideColumnHook/useColumnHooksUpgrade';
import { FlexContainer } from '../../shared/styles/styles';
import EntityStatusCategoryService from '../../services/EntityStatusCategoryService';
import { DISPATCH_NOTES_URL } from '../../shared/UrlMap';
import PrintService from '../../services/PrintService';
import { DISPATCH_COLUMNS, PAGE_NAME } from './shared/DispatchNote.constant';
import DispatchNoteService from '../../services/DispatchNoteService';
import iEntityStatus from '../../types/status/iEntityStatus';
import iEntityCategory from '../../types/status/iEntityCategory';
import { STATUS_CATEGORY_FINISHED } from '../../shared/constants/StatusCategories';
import { apiErrorToast } from '../../shared/toast/ToastHandler';
import TypeFilter from '../../shared/filter/TypeFilter';
import { mapToLabelValuePair } from '../../services/UtilsService';
import { divExact } from '../../shared/calculationHelper/calculationHelper';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/UserAccess/AccessService';
import { ACCESS_CODE_DISPATCH_NOTES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE } from '../../types/settings/UserAccess/iRoleAccess';

type iStatusState = {
  categories: Array<iEntityCategory>;
  whole: Array<iEntityStatus>;
  selected: Array<string>;
  isLoading: boolean;
};
const initialStatusState: iStatusState = {
  categories: [],
  whole: [],
  selected: [],
  isLoading: true,
};
const DispatchNoteList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [selectedDNIds, setSelectedDNIds] = useState<string[]>([]);
  const [status, setStatus] = useState(initialStatusState);
  const history = useHistory();
  const { control, setValue, errors, handleSubmit, watch } = useForm();
  const { state, edit, onCloseModal, onOpenAddModal, onSearch, onSetCurrentPage, onSetSort, onFilter, onSubmit } =
    useListCrudHook<iDispatchNote>({
      getFn: DispatchNoteService.getDispatchNoteList,
      deleteFn: DispatchNoteService.removeDispatchNote,
      createFn: DispatchNoteService.createDispatchNote,
      updateFn: DispatchNoteService.updateDispatchNote,
      sort: 'updatedAt:DESC',
      paginationApplied: true,
      notRenderWithoutFilter: true,
      createCallBack: (id: string) => history.push(`${DISPATCH_NOTES_URL}/${id}`),
      keywordColumns: 'dispatchNoteNumber',
    });
  useEffect(() => {
    let isCancelled = false;
    const fetchStatus = async () => {
      if (isCancelled) return;
      setStatus(prevState => ({ ...prevState, isLoading: true }));
      try {
        const categories: Array<iEntityCategory> = await EntityStatusCategoryService.getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:DispatchNote',
        });
        const statuses: Array<iEntityStatus> = categories
          .reduce(
            (acc: Array<iEntityStatus>, cur: iEntityCategory) =>
              cur.code === STATUS_CATEGORY_FINISHED ? acc : [...acc, ...cur.entityStatuses],

            [],
          )
          .sort((a: iEntityStatus, b: iEntityStatus) => a.sortOrder - b.sortOrder);

        setStatus(prevState => ({
          ...prevState,
          categories,
          whole: statuses,
          // selected: categories.filter(cate => cate.code === STATUS_CATEGORY_NEW)[0].entityStatuses.map(stat => stat.id),
          selected: statuses.map(stat => stat.id),
          isLoading: false,
        }));
      } catch (error) {
        apiErrorToast(error);
        setStatus(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchStatus();
    return () => {
      isCancelled = true;
    };
  }, []);

  const { columns, getShowHideColumns } = useColumnHooksUpgrade('dispatchNote', DISPATCH_COLUMNS);

  useEffect(
    () => {
      if (!status.isLoading) {
        const filter = `statusId:${status.selected.join('|')}`;
        onFilter(filter);
      }
    },
    //  eslint-disable-next-line
    [JSON.stringify(status.selected)],
  );
  const onToggleType = (toggled: string) => {
    // multi types can be selected
    if (status.selected.includes(toggled) && status.selected.length > 1) {
      setStatus({
        ...status,
        selected: status.selected.filter((s: string) => s !== toggled),
      });
    }
    // nothing is selected, reset to initial value
    else if (status.selected.includes(toggled) && status.selected.length === 1) {
      setStatus({
        ...status,
        selected: status.whole.map((item: iEntityStatus) => item.id),
      });
    } else {
      setStatus({ ...status, selected: [...status.selected, toggled] });
    }
  };
  const getPageHeader = () => {
    return (
      <>
        <PageTitleWithAddOne
          title={PAGE_NAME}
          onClickAddBtn={onOpenAddModal}
          className={'space-below'}
          isDisabled={!AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_CREATE, user)}
        />
        <FlexContainer className={'space-between'}>
          <TypeFilter
            options={mapToLabelValuePair(status.whole)}
            selectedTypes={status.selected}
            onSelect={onToggleType}
          />
          <FlexContainer>
            <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'search via No....'} />
          </FlexContainer>
        </FlexContainer>
      </>
    );
  };

  const onCheck = (id: string, value: boolean) => {
    if (value) {
      // add selected Id to the array
      setSelectedDNIds([...selectedDNIds, id]);
    } else {
      // remove selected Id from the array
      const arrAfterRemove = selectedDNIds.filter(cur => cur !== id);
      setSelectedDNIds(arrAfterRemove);
    }
  };

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <FlexContainer className={'space-between space-below'}>
        <div />
        <div
          style={{
            fontSize: '0.8rem',
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 0.02,
          }}
        >
          <GeneralPrintBtn serviceFunc={() => PrintService.printDispatchNoteManifest(selectedDNIds)}>
            Print Manifest
          </GeneralPrintBtn>
          {getShowHideColumns()}
        </div>
      </FlexContainer>
      <DispatchNoteTable
        data={state.data}
        onSetSort={onSetSort}
        onCheck={onCheck}
        isLoading={state.isLoading}
        columns={columns}
        categories={status.categories}
      />
      {edit.isModalOpen && (
        <ModalPage
          onConfirm={handleSubmit(onSubmit)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          heading={'Creating a Dispatch Note for'}
          confirmBtnName={'Create'}
        >
          <DnModalBody
            control={control}
            onChange={setValue}
            errors={errors}
            watch={watch}
            isCustomer
            searchServiceProvider
          />
        </ModalPage>
      )}
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(divExact(state.total, state.perPage))}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </BackendPage>
  );
};

export default DispatchNoteList;
