import React, { useState } from 'react';
import Button from '@atlaskit/button';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import QRReader from '../../../shared/QRReader/QRReader';
import ModalPageNoConfirm from '../../../shared/modalPage/ModalPageNoConfirm';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import AsyncSearchWithController from '../../../shared/hookForms/AsyncSearchWithController';
import { handleNullException } from '../../../services/UtilsService';
import WarehouseService from '../../../services/WarehouseService';
import { SpaceVerticalWrapper } from '../../../shared/styles/styles';
import { apiErrorToast } from '../../../shared/toast/ToastHandler';
import { SearchAndButtonWrapper } from '../sharedComponents/StockTransfer.style';
import { getWareHouseLocationOptionLabel } from '../../../shared/Utilities';

type iState = {
  isScanning: boolean;
  scannedLocation?: iWarehouseLocation;
};
const initialState: iState = {
  isScanning: false,
};
const MoveProdModalBody = ({
  control,
  onChange,
  errors,
  children,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);
  const onCloseScanning = () => setState({ ...state, isScanning: false, scannedLocation: undefined });
  const onScanLocationCode = async (locationCode?: string) => {
    if (!locationCode) return;
    setState({ ...state, isScanning: false });
    try {
      const locations: Array<iWarehouseLocation> = await WarehouseService.scanLocationCode(locationCode);
      if (locations.length !== 1) throw new Error('invalid location code');
      setState({ ...state, scannedLocation: locations[0], isScanning: false });
      onChange('destination', locations[0], { shouldValidate: true });
    } catch (error) {
      apiErrorToast(error);
    }
  };
  //  eslint-disable-next-line
  const isWIPValidate = async (newValue: any) => {
    const id = handleNullException(newValue, 'id');
    if (!id) return 'must select an element/bad element id';
    if (!newValue.allowParts) return 'this warehouse does not allow products transfer in';
    try {
      const { isWIP }: { isWIP: boolean } = await WarehouseService.isWIPWarehouseLocation(id);
      if (isWIP) return 'this warehouse location is WIP, can not transfer to';
      return true;
    } catch (error) {
      apiErrorToast(error);
      return 'Unknown network error happens, please try again';
    }
  };
  return (
    <div>
      <SearchAndButtonWrapper>
        <div style={{ minWidth: '280px' }}>
          <AsyncSearchWithController
            label={'Destination warehouse location'}
            name={'destination'}
            control={control}
            onChange={onChange}
            errors={errors}
            defaultValue={state.scannedLocation}
            promiseFn={(keyword: string) =>
              WarehouseService.getWarehouseListAsyncSearch({
                like: `name:${keyword},locationCode:${keyword}`,
                filter: 'allowParts:true',
              })
            }
            formatOptionLabel={getWareHouseLocationOptionLabel}
            isRequired
            externalValidate={isWIPValidate}
          />
        </div>
        <Button
          testId={'scan-button'}
          className={'scan-button'}
          iconAfter={<CameraFilledIcon label={'scan'} />}
          onClick={() => setState({ ...state, isScanning: true })}
        />
      </SearchAndButtonWrapper>
      <SpaceVerticalWrapper className={'small'} />
      {children}
      {state.isScanning && (
        <ModalPageNoConfirm onCancel={onCloseScanning} heading={'Please put your QR code into the camera range'}>
          <QRReader onScanSuccess={onScanLocationCode} />
        </ModalPageNoConfirm>
      )}
    </div>
  );
};

export default MoveProdModalBody;
