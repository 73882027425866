import React, { useEffect, useState } from 'react';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import _ from 'lodash';
import Textfield from '@atlaskit/textfield';
import { Field, HelperMessage } from '@atlaskit/form';
import { emailPattern } from '../constants/RegexConstants';
import AddsOn from '../dynamicForm/addsOn/AddsOn';
import { ErrorMsg } from '../styles/styles';

const InputWithController = ({
  label,
  name,
  placeholder,
  control,
  defaultValue,
  onChange,
  //  eslint-disable-next-line
  prefix,
  //    eslint-disable-next-line
  suffix,
  helpMessage,
  isDisabled = false,
  testId = 'input-react-hook-form',
  errors,
  isRequired = true,
  isNumeric = false,
  isCompact = false,
  isEmail = false,
  type = 'text',
  range,
  autoFocus = false,
  isUnitConversion = false,
  getUnitConversionSuffix,
}: {
  label: string;
  name: string;
  placeholder?: string;
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: string | number;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  prefix?: string;
  suffix?: string;
  helpMessage?: string;
  isDisabled?: boolean;
  testId?: string;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  isRequired?: boolean;
  isNumeric?: boolean;
  isEmail?: boolean;
  isCompact?: boolean;
  type?: string;
  range?: { min: number; max: number };
  autoFocus?: boolean;
  isUnitConversion?: boolean;
  getUnitConversionSuffix?: (value: string | number) => { suffix: string; qtyString: string | undefined };
}) => {
  const [value, setValue] = useState<string | number>('');
  const handleValueChange = (newValue: string) => {
    setValue(newValue);
    onChange(name, newValue, { shouldValidate: true });
  };
  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const validateInput = (newValue: string) => {
    // escape , for number
    const escapeComma = newValue?.toString()?.replace(',', '');
    // is required
    if (isRequired && !newValue) {
      return 'is required';
    }
    // is numeric
    if (isNumeric && newValue && Number.isNaN(Number(escapeComma))) {
      return 'must be a number';
    }
    if (isEmail && !emailPattern.test(newValue)) {
      return 'invalid email address';
    }
    // is in a range
    if (
      range &&
      newValue &&
      (Number.isNaN(Number(escapeComma)) || Number(escapeComma) < range.min || Number(escapeComma) > range.max)
    ) {
      return `must be in ${range.min}-${range.max}`;
    }
    return true;
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      rules={{
        validate: newValue => validateInput(newValue),
      }}
      render={ctrlProps => (
        <Field
          key={name}
          name={name}
          label={
            isRequired ? (
              <div>
                {label}
                <span style={{ color: 'red' }}>*</span>
              </div>
            ) : (
              label
            )
          }
        >
          {({ fieldProps }) => (
            <>
              <Textfield
                // eslint-disable-next-line
                {...ctrlProps}
                // eslint-disable-next-line
                {...fieldProps}
                // label={label}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  const val = event.currentTarget.value;
                  handleValueChange(val);
                }}
                elemAfterInput={
                  <AddsOn
                    value={
                      isUnitConversion && typeof getUnitConversionSuffix === 'function'
                        ? getUnitConversionSuffix(value).suffix
                        : suffix
                    }
                  />
                }
                elemBeforeInput={<AddsOn value={prefix} />}
                isDisabled={isDisabled}
                testId={testId}
                isInvalid={_.get(errors, name)}
                isCompact={isCompact}
                type={type}
                autoFocus={autoFocus}
              />
              <HelperMessage>{helpMessage}</HelperMessage>
              {_.get(errors, name) && <ErrorMsg data-testid="error-msg">{_.get(errors, name).message}</ErrorMsg>}
            </>
          )}
        </Field>
      )}
    />
  );
};

export default InputWithController;
