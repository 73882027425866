import ProductService from '../../services/ProductService';
import WarehouseService from '../../services/WarehouseService';
import { iConfigColumn } from '../../shared/UITypes/types';
import { PRODUCTS_URL, WAREHOUSE_URL } from '../../shared/UrlMap';

export const ITEM_LOCATION_LIST_TITLE = 'Stock List';
export const ITEM_LOCATION_TABLE_NAME = 'item-location-table';
export const ITEM_LOCATION_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'text-two-layer',
    urlPrefix: PRODUCTS_URL,
  },
  {
    name: 'Location',
    key: 'warehouseLocation.name',
    dataSource: ['warehouseLocation.id', 'warehouseLocation.locationCode'],
    isCompulsory: true,
    type: 'text-two-layer',
    urlPrefix: WAREHOUSE_URL,
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: ['qty'],
    isCompulsory: true,
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Unit',
    key: 'product.measurement.shortName',
    dataSource: ['product.measurement.shortName'],
    isCompulsory: true,
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Batch/Lot No. ',
    key: 'reference',
    dataSource: ['reference'],
    isCompulsory: true,
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    isSelected: false,
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
];

export const ITEMLOCATION_ADVANCED_SEARCH_CONFIG = [
  {
    name: 'productId',
    type: 'multi',
    selected: [],
    placeholder: 'All products...',
    promiseFn: ProductService.getProductListAsyncSearch,
    optionLabel: 'productCode',
    sortBy: 'productCode',
    testId: 'itemLocation-advanced-search-product',
  },
  {
    name: 'warehouseLocationId',
    type: 'multi',
    selected: [],
    placeholder: 'All warehouse locations...',
    promiseFn: WarehouseService.getWarehouseListAsyncSearch,
    optionLabel: 'name',
    sortBy: 'name',
    testId: 'itemLocation-advanced-search-warehouseLocation',
  },
  {
    name: 'reference',
    type: 'multi-text',
    selected: [],
    placeholder: 'Type batch # and press enter...',
    testId: 'itemLocation-advanced-search-batchNumber',
  },
];

export const ITEMLOCATION_ADVANCED_SEARCH_CURRENT_MACHINE_CONFIG = [
  {
    name: 'productId',
    type: 'multi',
    selected: [],
    placeholder: 'All products...',
    promiseFn: ProductService.getProductListAsyncSearch,
    optionLabel: 'productCode',
    sortBy: 'productCode',
    testId: 'itemLocation-advanced-search-product',
  },
  {
    name: 'reference',
    type: 'multi-text',
    selected: [],
    placeholder: 'Type batch # and press enter...',
    testId: 'itemLocation-advanced-search-batchNumber',
  },
];
