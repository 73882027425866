import React from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';

type iCompanyExceptionEmptyState = {
  header: string;
  description: string | React.ReactElement;
  primaryAction: React.ReactNode;
  secondaryAction?: React.ReactElement;
  imageUrl?: string;
};
const CompanyExceptionEmptyState = ({
  header,
  description,
  primaryAction,
  secondaryAction,
  imageUrl = '/lock.png',
}: iCompanyExceptionEmptyState) => {
  const getSecondaryAction = () => {
    if (secondaryAction) {
      return secondaryAction;
    }
    return (
      <Button appearance={'default'}>
        <a href={'mailto:support@miesoft.com.au'} style={{ color: '#42526E' }}>
          Contact Support
        </a>
      </Button>
    );
  };

  const props = {
    secondaryAction: getSecondaryAction(),
    imageUrl,
    header,
    description,
    primaryAction,
  };
  // eslint-disable-next-line
  return <EmptyState {...props} />;
};

export default CompanyExceptionEmptyState;
