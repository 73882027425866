import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import LinkBtn from '../buttons/LinkBtn';

export const BreadcrumbWrapper = styled.div`
  font-size: 0.9rem;
  color: ${colors.N100};
  padding-bottom: 0.5rem;
`;
const BreadcrumbsTwoLayers = ({ name, onClick, subName }: { name: string; onClick: () => void; subName: string }) => {
  return (
    <BreadcrumbWrapper data-testid={'breadcrumb-wrapper'}>
      <LinkBtn btnName={name} onClick={onClick} /> / {subName}
    </BreadcrumbWrapper>
  );
};

export default BreadcrumbsTwoLayers;
