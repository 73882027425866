import React from 'react';
import styled from 'styled-components';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { numberFormat } from '../../services/UtilsService';

interface iReadViewProps {
  isEmpty: boolean;
  isDisabled?: boolean;
}

const ReadViewContainer = styled.div`
  display: flex;
  align-items: center;
  //   line-height: ${(gridSize() * 2.5) / fontSize()};
  max-width: 100%;
  min-height: ${(gridSize() * 2.5) / fontSize()}em;
  padding: ${gridSize()}px 0px;
  word-break: break-word;
  color: ${(props: iReadViewProps) => (props.isEmpty || props.isDisabled ? '#A5ADBA' : '#091E42')};
  .large-text {
    font-weight: 300;
    font-size: 26px;
    color: RGB(23, 43, 77);
  }
  .postfix {
    margin-left: 5px;
  }
  &:hover {
    cursor: ${(props: iReadViewProps) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`;
export const EMPTY_VALUE = 'click to enter value';
const InlineEditReadView = ({
  value,
  prefix,
  postfix,
  needFormatValue = false,
  isLargeText = false,
  isDisabled = false,
  name,
}: {
  value: string;
  prefix?: string | React.ReactChild;
  postfix?: string | React.ReactChild;
  isLargeText?: boolean;
  needFormatValue?: boolean;
  isDisabled?: boolean;
  name: string;
}) => {
  if (!value)
    return (
      <ReadViewContainer isEmpty data-testid={`inline-edit-read-view-${name}`}>
        <div className={'view-value'}>{isDisabled ? 'disabled' : EMPTY_VALUE}</div>
      </ReadViewContainer>
    );
  return (
    <ReadViewContainer isEmpty={false} isDisabled={isDisabled} data-testid={`inline-edit-read-view-${name}`}>
      {prefix && <div className={`prefix ${isLargeText && 'large-text'}`}>{prefix}</div>}
      <div className={`view-value ${isLargeText && value && 'large-text'}`}>
        {needFormatValue ? numberFormat(value) : value}
      </div>
      {postfix && <div className="postfix">{postfix}</div>}
    </ReadViewContainer>
  );
};

export default InlineEditReadView;
