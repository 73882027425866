import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../redux/makeReduxStore';
import iJob from '../../types/job/iJob';
import PageInit from '../../shared/PageInit';
import JobService from '../../services/JobService';
import WithStartedJobs from '../../shared/job/WithStartedJobs';
// import Jobs from '../../shared/job/jobList/Jobs';
import iJobCategory from '../../types/job/iJobCategory';
import { STATUS_CATEGORY_IN_PROGRESS } from '../../shared/constants/JobCategoryConstants';
import iJobStatus from '../../types/job/iJobStatus';
import BackendPage from '../../layout/BackendPage';
import WelcomeMsg from '../../shared/user/WelcomeMsg';
import { addToastForAPIResponse } from '../../shared/toast/ToastHandler';
// import CurrentJobListPage from '../jobListPage/CurrentJobListPage';
import JobListPage from '../jobListPage/JobListPage';

const JobDispatchPage = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const [WIPJob, setWIPJob] = useState<iJob>();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const findWIPJob = (jobList: Array<iJob>, jobCategories: Array<iJobCategory>) => {
    const inProgressCategory = jobCategories.find((item: iJobCategory) => item.name === STATUS_CATEGORY_IN_PROGRESS);
    if (!inProgressCategory) return undefined;
    return jobList.find((job: iJob) =>
      inProgressCategory.jobStatuses.find((status: iJobStatus) => status.id === job.status.id),
    );
  };
  useEffect(() => {
    let isCancelled = false;
    const loadJobs = async () => {
      if (!user || !user.defaultOperatingMachine) {
        addToastForAPIResponse('warning', 'Please set up a machine.');
        history.push({
          pathname: '/b/profile',
        });
        return;
      }
      setIsLoading(true);
      try {
        const jobList = await JobService.getJobs(user.defaultOperatingMachine.id, true);
        const jobCategories = await JobService.getJobStatus();
        if (isCancelled) return;
        setWIPJob(findWIPJob(jobList, jobCategories));
        setIsLoading(false);
      } catch (error) {
        if (isCancelled) return;
        addToastForAPIResponse('error');
        setWIPJob(undefined);
        setIsLoading(false);
      }
    };
    loadJobs();
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (isLoading === true) {
    return <PageInit />;
  }
  return (
    <BackendPage pageHeader={<WelcomeMsg />}>
      {!currentShift && WIPJob ? <WithStartedJobs WIPJob={WIPJob} /> : <JobListPage isCurrent />}
    </BackendPage>
  );
};
export default JobDispatchPage;
