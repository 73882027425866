import React from 'react';
import iStockTake from '../../../../types/stockTake/iStockTake';
import FinishedTable from './FinishedTable';
import { ItemsTitle } from '../../shared/StockTake.style';

const Finished = ({ stockTakeDetail }: { stockTakeDetail: iStockTake }) => {
  return (
    <>
      <ItemsTitle>Items</ItemsTitle>
      <FinishedTable stockTakeDetail={stockTakeDetail} />
    </>
  );
};

export default Finished;
