import React, { useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import CustomizedLabel from './CustomizedLabel';
import { useIsFirstRender } from '../hooks/useIsFirstRender';
import { ErrorMsg } from '../styles/styles';
import AsyncSearchFormOptionLabel from './asyncSearch/AsyncSearchFormOptionLabel';
import { iSingleSelectOption } from '../UITypes/types';

const SelectWrapper = styled.div`
  min-width: 200px;
  &.min-width-120 {
    min-width: 120px;
  }
  .validation-error > div {
    border-color: #de350b;
  }
`;

const AsyncSearchWithController = <T extends { id: string }>({
  name,
  label,
  placeholder,
  control,
  onChange,
  defaultValue,
  errors,
  className,
  promiseFn,
  isRequired = false,
  isDisabled = false,
  externalValidate,
  shouldControlRenderValue = false,
  formatOptionLabel,
  testId = 'AsyncSearchWithController',
}: {
  name: string;
  label?: string;
  placeholder?: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  defaultValue?: T;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  className?: string;
  //    eslint-disable-next-line
  promiseFn: (keyword: string) => Promise<any>;
  isRequired?: boolean;
  isDisabled?: boolean;
  testId?: string;
  //    eslint-disable-next-line
  externalValidate?: (newValue: any) => any;
  formatOptionLabel: (option: iSingleSelectOption<T>) => React.ReactNode;
  shouldControlRenderValue?: boolean;
}) => {
  const isFirstRender = useIsFirstRender();
  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      isFirstRender ? onChange(name, defaultValue) : onChange(name, defaultValue, { shouldValidate: true });
    },
    //  eslint-disable-next-line
    [JSON.stringify(defaultValue)],
  );
  const handleValueChange = (selected: T | null) => onChange(name, selected, { shouldValidate: true });

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: isRequired, message: 'must select an element' },
        validate: newValue => (typeof externalValidate === 'function' ? externalValidate(newValue) : true),
      }}
      defaultValue={defaultValue || null}
      render={() => (
        <SelectWrapper className={className}>
          <CustomizedLabel label={label} htmlFor={testId} isRequired={isRequired} />
          <AsyncSearchFormOptionLabel
            onSelect={handleValueChange}
            promiseFn={promiseFn}
            shouldControlRenderValue={shouldControlRenderValue}
            searchBarPlaceholder={placeholder}
            formatOptionLabel={formatOptionLabel}
            isClearable
            isDisabled={isDisabled}
            className={`${testId} ${_.get(errors, name) && 'validation-error'}`}
            defaultValue={defaultValue}
          />
          {_.get(errors, name) && <ErrorMsg data-testid={'error-msg'}>{_.get(errors, name).message}</ErrorMsg>}
        </SelectWrapper>
      )}
    />
  );
};

export default AsyncSearchWithController;
