import AppService from './AppService';

export const getPoReceivingItems = (config?: { [key: string]: string }) => {
  return AppService.get('/purchaseOrderReceivingItem', {
    include:
      'product.measurement,toWarehouseLocation,purchaseOrderItem.measurement,purchaseOrderReceiving.status.entityStatusCategory,createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createPoReceivingItem = (postData: { [key: string]: any }) => {
  return AppService.post('/purchaseOrderReceivingItem', postData).then(({ data }) => data);
};

export const updatePoReceivingItem = (
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateContent: { [key: string]: any },
) => {
  return AppService.put(`/purchaseOrderReceivingItem/${id}`, updateContent).then(({ data }) => data);
};

export const deletePoReceivingItem = (id: string) => {
  return AppService.delete(`/purchaseOrderReceivingItem/${id}`).then(({ data }) => data);
};

export default {
  getPoReceivingItems,
  createPoReceivingItem,
  updatePoReceivingItem,
  deletePoReceivingItem,
};
