import DynamicTable from '@atlaskit/dynamic-table';
import React from 'react';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';

type iDataTable = {
  testId?: string;
  isLoading?: boolean;
  loadingSpinnerSize?: 'small' | 'large' | undefined;
  rowsPerPage?: number;
  defaultPage?: number;
  //  eslint-disable-next-line
  onSort?: (data: any) => void;
  sortKey?: string;
  sortOrder?: 'ASC' | 'DESC' | undefined;

  isFixedSize?: boolean;
  isRankable?: boolean;
  onSetPage?: () => void;

  rows: RowType[];
  head: HeadType;
};
const DataTable = ({
  isLoading = false,
  testId,
  loadingSpinnerSize,
  rowsPerPage,
  defaultPage,
  onSort,
  sortKey,
  isRankable,
  isFixedSize,
  sortOrder,
  onSetPage,
  rows,
  head,
}: iDataTable) => {
  return (
    <DynamicTable
      head={head}
      rows={rows}
      onSetPage={onSetPage}
      isRankable={isRankable}
      isFixedSize={isFixedSize}
      testId={testId}
      isLoading={isLoading}
      loadingSpinnerSize={loadingSpinnerSize}
      rowsPerPage={rowsPerPage}
      defaultPage={defaultPage}
      onSort={onSort}
      sortKey={sortKey}
      sortOrder={sortOrder}
    />
  );
};

export default DataTable;
