import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@atlaskit/spinner';
import DashboardPage from '../../pages/dashboard/DashboardPage';
import JobService from '../../services/JobService';
import { RootState } from '../../redux/makeReduxStore';
import { iJobShiftWithJobShiftJobId, updateCurrentShift } from '../../redux/reduxers/shift.slice';
import { apiErrorToast } from '../toast/ToastHandler';

type iState = {
  isLoading: boolean;
  passed: boolean;
};
const initialState: iState = {
  isLoading: true,
  passed: false,
};
const ProtectedJobShiftJob = ({ jobId, children }: { jobId: string; children: React.ReactNode }) => {
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const { currentShift } = useSelector((s: RootState) => s.shift);
  useEffect(() => {
    let isCancelled = false;
    const handleShift = async () => {
      if (currentShift && currentShift.currentJobShiftJobId) {
        setState(prevState => ({ ...prevState, isLoading: false, passed: true }));
        return;
      }
      try {
        if (!currentShift) {
          const newShift = await JobService.startJobShift(jobId);
          const currentJobShiftJob: iJobShiftWithJobShiftJobId = await JobService.createJobShiftJob(newShift.id, jobId);
          dispatch(updateCurrentShift({ currentShift: { ...newShift, currentJobShiftJobId: currentJobShiftJob.id } }));
        } else {
          const currentJobShiftJob: iJobShiftWithJobShiftJobId = await JobService.createJobShiftJob(
            currentShift.id,
            jobId,
          );
          dispatch(
            updateCurrentShift({ currentShift: { ...currentShift, currentJobShiftJobId: currentJobShiftJob.id } }),
          );
        }

        setState(prevState => ({ ...prevState, isLoading: false, passed: true }));
      } catch (error) {
        if (isCancelled) return;
        setState(prevState => ({ ...prevState, isLoading: false }));
        apiErrorToast(error);
      }
    };
    handleShift();
    return () => {
      isCancelled = true;
    };
  }, [dispatch, currentShift, jobId]);

  if (state.isLoading) {
    return <Spinner />;
  }
  if (state.passed) {
    return <>{children}</>;
  }
  return <DashboardPage />;
};

export default ProtectedJobShiftJob;
