import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DynamicTable from '@atlaskit/dynamic-table';
import { Grid } from '@atlaskit/page';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import iItemLocation from '../../types/warehouse/iItemLocation';
import ItemLocationService from '../../services/ItemLocationService';
import { AdvancedSearchSectionWrapper, FlexContainer, PageTitle } from '../../shared/styles/styles';
import SearchBar from '../../shared/search/SearchBar';
import { getHeads, handleNullException } from '../../services/UtilsService';
import CustomizePagination from '../../shared/pagination/CustomizePagination';
import BackendPage from '../../layout/BackendPage';
import { getRows } from './TableHelper';
import {
  ITEMLOCATION_ADVANCED_SEARCH_CONFIG,
  ITEMLOCATION_ADVANCED_SEARCH_CURRENT_MACHINE_CONFIG,
  ITEM_LOCATION_COLUMNS,
  ITEM_LOCATION_LIST_TITLE,
  ITEM_LOCATION_TABLE_NAME,
} from './ItemLocation.contants';
import useAdvancedSearchHooks from '../../shared/hooks/useAdvancedSearchHook/useAdvancedSearchHook';
import { RootState } from '../../redux/makeReduxStore';
import LinkBtn from '../../shared/buttons/LinkBtn';

const ItemLocationList = ({ isCurrentMachine = false }: { isCurrentMachine?: boolean }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const currentMachineId = user?.defaultOperatingMachineId || '';

  const [isAdvancedSearch, setIsAdSearch] = useState(false);

  const { getAdvancedFilterStrArr, getSearchControllers } = useAdvancedSearchHooks(
    isCurrentMachine ? ITEMLOCATION_ADVANCED_SEARCH_CURRENT_MACHINE_CONFIG : ITEMLOCATION_ADVANCED_SEARCH_CONFIG,
  );

  const { state, edit, onSetCurrentPage, onSearch, onAdvancedFilter } = useListCrudHook<iItemLocation>({
    getFn: ItemLocationService.getItemLocationList,
    sort: 'createdAt:DESC',
    paginationApplied: true,
    advancedFilter: isCurrentMachine
      ? `WarehouseLocation_warehouseLocationId.Machine_id_warehouseLocationId.id:${currentMachineId}`
      : '',
    keywordColumns: 'reference,product.name,warehouseLocation.name,warehouseLocation.locationCode',
    perPage: 10,
  });

  useEffect(() => {
    const arr = isCurrentMachine
      ? [
          ...getAdvancedFilterStrArr(),
          `WarehouseLocation_warehouseLocationId.Machine_id_warehouseLocationId.id:${currentMachineId}`,
        ]
      : [...getAdvancedFilterStrArr()];
    const filterArr = arr.filter(strFilter => strFilter !== '');
    onAdvancedFilter(filterArr.join(','));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(getAdvancedFilterStrArr())]);

  const getPageHeader = () => (
    <>
      <FlexContainer className={'space-between'}>
        <>
          <PageTitle>
            {isCurrentMachine
              ? `${handleNullException(user, 'defaultOperatingMachine.name')} Stock`
              : ITEM_LOCATION_LIST_TITLE}
          </PageTitle>
          <FlexContainer>
            <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search...'} />
            <LinkBtn onClick={() => setIsAdSearch(prev => !prev)} btnName={'Advanced'} />
          </FlexContainer>
        </>
      </FlexContainer>
      {isAdvancedSearch && (
        <AdvancedSearchSectionWrapper>
          <Grid layout={'fluid'} spacing={'cosy'}>
            {getSearchControllers()}
          </Grid>
        </AdvancedSearchSectionWrapper>
      )}
    </>
  );

  const getPageBody = () => (
    <>
      <DynamicTable
        head={getHeads(ITEM_LOCATION_COLUMNS, ITEM_LOCATION_TABLE_NAME)}
        rows={getRows(state.data, ITEM_LOCATION_COLUMNS)}
        isFixedSize
        isLoading={state.isLoading}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );

  return <BackendPage pageHeader={getPageHeader()}>{getPageBody()}</BackendPage>;
};

export default ItemLocationList;
