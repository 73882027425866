import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';
import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';
import { scheduleAtFilterStr } from './UtilsService';

const getJob = (id: string) => {
  return AppService.get(`/job/${id}`, {
    include: 'palletSystem,machine,status,product.measurement,jobAttributes,wrappingMethod',
  }).then(({ data }) => data);
};

//  future: future-true
//  today: current-true
const getJobs = (machineId: string, isCurrent: boolean) => {
  const filter = isCurrent
    ? `machineId:${machineId},current:true`
    : `machineId:${machineId},future:true,${scheduleAtFilterStr()}`;
  // : `machineId:${machineId},future:true`;

  return AppService.get('/job', {
    include: 'machine,status,product,product.measurement,jobAttributes',
    filter,
    sort: 'scheduledAt:ASC',
  }).then(({ data }) => data);
};

const getWIPJobs = (machineId: string) => {
  return AppService.get('/job', {
    include: 'machine,status,product',
    filter: `machineId:${machineId}`,
  }).then(() => []);
};

const getJobStatus = () => {
  return AppService.get('/jobStatusCategory', {
    include: 'jobStatuses',
  }).then(({ data }) => data);
};

const startJob = (jobId: string) => {
  return AppService.put(`/job/${jobId}/start`, {}).then(res => {
    return res;
  });
};

const moveJobStatus = (jobId: string) => {
  return AppService.put(`/job/${jobId}/next`, {}).then(res => {
    return res;
  });
};

const finishJob = (jobId: string) => {
  return AppService.put(`/job/${jobId}/finish`, {}).then(res => {
    return res;
  });
};

const getFormTaggableList = (jobId: string) => {
  return AppService.get('/formTaggable', { filter: `jobId:${jobId}` }).then(({ data }) => {
    return data;
  });
};

const getOpenJobShift = () => {
  return AppService.get('/jobShift/open').then(({ data }) => {
    return data;
  });
};

const startJobShift = (jobId: string) => {
  return AppService.post('/jobShift', { jobId }).then(({ data }) => {
    return data;
  });
};

const getJobShiftJobs = (jobShiftId: string) => {
  return AppService.get(`/jobShiftJob/jobShift/${jobShiftId}`, {
    sort: 'id:desc',
    include: 'job,jobShiftWastes,job.status,job.product,job.product.measurement',
  }).then(({ data }) => {
    return data;
  });
};

const getJobShiftJobDetail = (jobShiftId: string) => {
  return AppService.get(`/jobShiftJob/${jobShiftId}`, {
    include: 'jobShiftWastes',
  }).then(({ data }) => {
    return data;
  });
};

const updateJobShift = (
  jobShiftId: string,
  //  eslint-disable-next-line
  postData: any,
) => {
  return AppService.put(`/jobShift/${jobShiftId}`, postData).then(res => {
    return res;
  });
};

const finishJobShift = (jobShiftId: string) => {
  return AppService.put(`/jobShift/finish/${jobShiftId}`, {}).then(res => res);
};

const createJobShiftJob = (jobShiftId: string, jobId: string) => {
  return AppService.post('/jobShiftJob/', { jobShiftId, jobId }).then(({ data }) => {
    return data;
  });
};

const printJobMasterCard = (jobId: string) => {
  return AppService.get(`/jobPrint/${jobId}/jobCard`).then(({ data: { url } }) => {
    return url;
  });
};

const printEnvelopLabel = (jobId: string) => {
  return AppService.get(`/jobPrint/${jobId}/envelopLabel`).then(({ data: { url } }) => url);
};

//  async search force pagination
const getJobListAsyncSearch = (config?: iParams) => {
  return AppService.get('/job', {
    ...config,
    include: 'product.measurement',
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

const getJobCategories = () => {
  return AppService.get('/jobStatusCategory', {
    include: 'jobStatuses',
  }).then(({ data }) => data);
};

/**
 * @param config can't avoid include
 * need to refactor list crud hook to accept customized include
 * @returns
 */
const getJobListConfig = (config?: iParams) => {
  return AppService.get('/job', {
    include: 'product.measurement,salesOrder.status,salesOrder.shippingAddress,salesOrderItem.measurement,status',
    ...config,
  }).then(({ data }) => data);
};

export default {
  getJobs,
  getJob,
  getJobStatus,
  getWIPJobs,
  getOpenJobShift,
  getJobShiftJobs,
  getJobShiftJobDetail,
  startJob,
  moveJobStatus,
  finishJob,
  getFormTaggableList,
  startJobShift,
  updateJobShift,
  finishJobShift,
  createJobShiftJob,
  printJobMasterCard,
  getJobListAsyncSearch,
  getJobCategories,
  getJobListConfig,
  printEnvelopLabel,
};
