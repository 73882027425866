/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { AsyncSelect } from '@atlaskit/select';
import { ErrorMessage } from '@atlaskit/form';
import _ from 'lodash';

import { iLabelValuePair, iOption } from '../UITypes/types';
import { mapLabelValuePairIdPayload } from '../../services/UtilsService';

const MultiSelect = ({
  name,
  defaultValue,
  label,
  onConfirm,
  customStyles,
  placeholder,
  promiseFn,
  optionLabel,
  filterFn,
  sortBy = 'createdAt',
  sortType = 'asc',
  testId = 'inline-async-multi-select',
  errorMessage,
}: {
  name: string;
  defaultValue: iLabelValuePair[] | any;
  label?: string;
  onConfirm?: (name: string, newValue: iLabelValuePair[]) => void;
  customStyles?: Record<string, unknown>;
  placeholder?: string;
  promiseFn?: (config?: { [key: string]: string }) => Promise<any>;
  optionLabel?: string;
  filterFn?: (item: any) => boolean;
  sortBy?: string;
  sortType?: 'asc' | 'desc';
  testId?: string;
  errorMessage?: string;
}) => {
  const [value, setValue] = useState<iLabelValuePair[]>([]);

  useEffect(() => {
    if (
      typeof onConfirm === 'function' &&
      JSON.stringify(defaultValue) !== JSON.stringify(value) // only setState when value changes
    ) {
      setValue(defaultValue);
      onConfirm(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValue)]);

  const promiseOptions = (inputValue: string, callback: (options: iOption<string>[]) => void) => {
    if (typeof promiseFn !== 'function' || !optionLabel) return;
    promiseFn({ like: `${optionLabel}:${inputValue}` }).then(({ data: items }) => {
      let filtered = items;
      if (filterFn) {
        filtered = items.filter(filterFn);
      }

      const sorted = _.orderBy(filtered, [sortBy], sortType);

      const mappedOptions = mapLabelValuePairIdPayload(sorted, optionLabel);
      if (mappedOptions) {
        callback(mappedOptions);
      }
    });
  };

  // debounce
  const debouncedOptions = _.debounce(promiseOptions, 500);

  const onChange = (selectValue: any) => {
    const selected = selectValue || [];
    setValue(selected);

    if (typeof onConfirm === 'function') {
      onConfirm(name, selected);
    }
  };
  return (
    <>
      <AsyncSelect
        openMenuOnFocus
        label={label}
        loadOptions={debouncedOptions}
        onChange={onChange}
        value={value}
        styles={customStyles}
        isMulti
        hasAutocomplete
        isClearable
        placeholder={placeholder}
        testId={testId}
      />
      {errorMessage && value.length === 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default MultiSelect;
