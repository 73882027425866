import React, { useRef } from 'react';
import styled from 'styled-components';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import { iImage } from './ImageTypes';
import { onImagesSelected } from './ImageHelper';

const UploadContainer = styled.div`
  flex: 0 0 auto;
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
`;
const InvisibleInput = styled.input`
  display: none;
`;
const UploadImage = ({ onUploadImage }: { onUploadImage: (image: iImage) => void }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const openFileBrowser = () => {
    return fileRef.current?.click();
  };

  return (
    <UploadContainer onClick={openFileBrowser} data-testid={'upload-container'}>
      <CameraFilledIcon label={'camera-icon'} testId={'camera-icon'} />
      <InvisibleInput
        type="file"
        multiple
        accept="image/*"
        ref={fileRef}
        onChange={() => {
          if (fileRef !== undefined && fileRef.current !== undefined && fileRef.current !== null) {
            onImagesSelected(fileRef.current.files, onUploadImage);
          }
        }}
        data-testid={'file-input'}
      />
    </UploadContainer>
  );
};

export default UploadImage;
