import styled from 'styled-components';
import { colors, gridSize } from '@atlaskit/theme';

export const NoGrowShrinkContainer = styled.div`
  flex: 0 0 auto;
`;
export const AlignCenterContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
export const FormRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.N40};
  padding: ${gridSize()}px ${gridSize() - 2}px;
  box-sizing: border-box;
`;
export const FormRowContainerVertical = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.N40};
  padding: ${gridSize()}px ${gridSize() - 2}px;
  box-sizing: border-box;
`;
export const FormRollItemHeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${gridSize()}px ${gridSize() - 2}px 0px ${gridSize() - 2}px;
  box-sizing: border-box;
`;
export const SpaceEvenlyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const AlignJustifyCenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const ErrorMsg = styled.p`
  margin-top: 10px;
  color: #bf1650;
  &:before {
    content: '⚠ ';
  }
`;
export const FlexAlignEndContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .dynamic-input-container {
    width: 100%;
    > div {
      margin-top: 0px;
    }
  }
  .flex-start {
    align-self: flex-start;
  }
`;
export const ErrorMessageContainer = styled.div`
  margin-left: auto;
  margin-right: 0px;
  text-align: end;
  &.print-roll-label-flex-start {
    margin: 0;
    text-align: auto;
  }
`;
