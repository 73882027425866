import React, { useState, useEffect } from 'react';
// import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import Button, { ButtonGroup } from '@atlaskit/button';
// import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import ExportCsv from './ExportCsv';
import { AfterTransferWrapper } from '../sharedComponents/StockTransfer.style';
import WarehouseService from '../../../services/WarehouseService';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { apiErrorToast } from '../../../shared/toast/ToastHandler';
import { handleNullException } from '../../../services/UtilsService';

export type iAfterTransferDescriptionProps = {
  destination: iWarehouseLocation;
  data: Array<iItemLocationTransfer>;
  onRefresh: () => void;
};
const AfterTransferDescription = ({ destination, onRefresh, data }: iAfterTransferDescriptionProps) => {
  const { id } = destination;
  const [state, setState] = useState<string>();
  useEffect(() => {
    let isCancelled = false;
    const fetchPath = async () => {
      try {
        const pathNameObject = await WarehouseService.getWarehouseLocationPathNames(JSON.stringify([id]));
        if (isCancelled) return;
        const pathName = handleNullException(pathNameObject, `idNameMap.${id}`);
        if (pathName) setState(() => pathName);
      } catch (error) {
        apiErrorToast(error);
      }
    };
    fetchPath();
    return () => {
      isCancelled = true;
    };
  }, [id]);
  return (
    <AfterTransferWrapper>
      <div>
        <CheckCircleOutlineIcon label={'success'} primaryColor={'green'} size={'xlarge'} testId={'check-circle-icon'} />
      </div>
      <div className={'title'}>The following items has been successfully moved to:</div>
      <div className={'breadcrumbs'}>{state}</div>
      <div>
        {destination.locationCode}
        {/* recover the link after warehouse page is ready */}
        {/* <LinkBtnUrl
          btnName={destination.locationCode}
          url={`/b/warehouse/${destination?.id}`}
        /> */}
      </div>
      <ButtonGroup>
        <ExportCsv data={data} />
        <Button appearance={'default'} onClick={onRefresh} testId={'start-new-transfer'}>
          Start a new transfer
        </Button>
      </ButtonGroup>
    </AfterTransferWrapper>
  );
};

export default AfterTransferDescription;
