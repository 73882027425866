import React from 'react';
import { iLabelValuePair } from '../../../UITypes/types';
import FloatSection from '../styles/FloatSection';
import VerticalLabel from '../VerticalLabel';

const ProductAttributesRender = ({ attributes }: { attributes: iLabelValuePair[] }) => {
  return (
    <FloatSection>
      {attributes
        .filter(attr => !!attr.value || attr.value === 'false')
        .map(attr => (
          <VerticalLabel key={attr.label} label={attr.label} value={attr.value} />
        ))}
    </FloatSection>
  );
};
export default ProductAttributesRender;
