import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../redux/makeReduxStore';
import { ucFirst } from '../../services/UtilsService';

const WelcomeMsgWrapper = styled.div``;

export default () => {
  const { user } = useSelector((s: RootState) => s.auth);

  const greetingText = () => {
    const now = moment();
    const currentHour = now.hour();
    if (currentHour < 12) {
      return 'Morning';
    }
    if (currentHour <= 17) {
      return 'Afternoon';
    }
    return 'Evening';
  };

  return (
    <WelcomeMsgWrapper className={'welcome-msg-wrapper'}>
      <h3>
        {greetingText()}, {ucFirst(user?.firstName || '')}
      </h3>
    </WelcomeMsgWrapper>
  );
};
