import { ButtonGroup } from '@atlaskit/button';
import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import DynamicInput from '../../input/DynamicInput';
import DynamicLabelPrinting from '../../labelPrinting/DynamicLabelPrinting';
import DynamicPhoto from '../../Photo/DynamicPhoto';
import { FormRowContainer } from '../../styleContainer';
import DynamicText from '../../text/DynamicText';
import { iManufactureRow } from '../shared/DynamicManufactureRows.types';
import MarkAsProcessed from './MarkAsProcessed';

const ManufactureRow = ({
  rowData,
  control,
  onChange,
  getValues,
  errors,
  jobId,
  onGoingJobShiftJobId,
  children,
}: {
  rowData: iManufactureRow;
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  getValues: (payload?: string | string[]) => any;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  jobId: string;
  onGoingJobShiftJobId: string;
  children: React.ReactNode;
}) => {
  const {
    sortOrder,
    length,
    nominalWeight,
    actualWeight,
    images,
    jobShiftJobId: jobShiftJobIdForCurrentRow,
    isProcessed,
  } = rowData;

  const isDiffShift = !!(jobShiftJobIdForCurrentRow && jobShiftJobIdForCurrentRow !== onGoingJobShiftJobId);
  // isProcessed or belongs to another shift, then not allowed to change any more
  const isInputDisabled = isProcessed || isDiffShift;

  const isAllFieldsFilled = () => {
    return length[0].value && nominalWeight[0].value && actualWeight[0].value;
  };
  return (
    <FormRowContainer data-testid={'manufacture-row'}>
      <div className="col-1 row-selector-cell">
        {children}
        <DynamicText title="" description={`Roll ${sortOrder + 1}`} />
      </div>
      <div className="col-1-8">
        <DynamicInput
          id={`${sortOrder}-length`}
          control={control}
          defaultValue={length}
          onChange={onChange}
          errors={errors}
          isDisabled={isInputDisabled}
          type={'number'}
        />
      </div>
      <div className="col-1-8">
        <DynamicInput
          id={`${sortOrder}-nominalWeight`}
          control={control}
          defaultValue={nominalWeight}
          onChange={onChange}
          errors={errors}
          isDisabled={isInputDisabled}
          type={'number'}
        />
      </div>
      <div className="col-1-8">
        <DynamicInput
          id={`${sortOrder}-actualWeight`}
          control={control}
          defaultValue={actualWeight}
          onChange={onChange}
          errors={errors}
          isDisabled={isInputDisabled}
          type={'number'}
        />
      </div>
      <div className="col-3-8 display-flex">
        <ButtonGroup>
          <MarkAsProcessed
            id={`${sortOrder}-isProcessed`}
            control={control}
            defaultValue={isProcessed}
            onChange={onChange}
            isDisabled={isDiffShift || !isAllFieldsFilled()}
          />
          <DynamicLabelPrinting
            jobId={jobId}
            title="Core"
            rowIndex={sortOrder + 1}
            getValues={getValues}
            params={[]}
            api={{
              serviceName: 'LabelPrintingService',
              functionName: 'printCoreLabel',
            }}
          />
          <DynamicLabelPrinting
            jobId={jobId}
            title="Roll"
            rowIndex={sortOrder + 1}
            getValues={getValues}
            params={[`${sortOrder}-length`, `${sortOrder}-nominalWeight`, `${sortOrder}-actualWeight`]}
            api={{
              serviceName: 'LabelPrintingService',
              functionName: 'printRollLabel',
            }}
          />
        </ButtonGroup>
      </div>
      <div className="col-1-8 asset-uploader">
        <DynamicPhoto
          id={`${sortOrder}-photos`}
          control={control}
          defaultValue={images}
          errors={errors}
          onChange={(name, imgs, config) => {
            onChange(`${sortOrder}-images`, imgs, config);
          }}
        />
      </div>
    </FormRowContainer>
  );
};

export default ManufactureRow;
