import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import DynamicTable from '@atlaskit/dynamic-table';
import iProduct from '../../../types/product/iProduct';
import JobService from '../../../services/JobService';
import ProductService from '../../../services/ProductService';
import CreateByAsyncProduct from '../../createByAsyncSearchProduct/CreateByAsyncProduct';
import TableHelper from '../../tableHelper/TableHelper';
import WasteTableUtil from './WasteTable.util';
import { apiErrorToast } from '../../toast/ToastHandler';
import {
  iWaste,
  iWasteTableRow,
  WASTE_TABLE_CAPTION,
  WASTE_TABLE_COLUMNS,
  WASTE_TABLE_NAME,
} from './ShiftSummary.constants';
import { WasteTableWrapper } from '../../styles/styles';

type iState = {
  isLoading: boolean;
  wasteTableItems: Array<iWasteTableRow>;
  allWasteProducts: Array<iProduct>;
};
const initialState: iState = {
  isLoading: true,
  allWasteProducts: [],
  wasteTableItems: [],
};
const Wastes = ({ wastes, jobId, jobShiftId }: { wastes: Array<iWaste>; jobId?: string; jobShiftId?: string }) => {
  const [state, setState] = useState(initialState);

  const postWastes = async (items: Array<iWasteTableRow>) => {
    try {
      if (!jobShiftId) {
        throw new Error(`Invalid JobShiftId(=${jobShiftId}).`);
      }
      await JobService.updateJobShift(jobShiftId, {
        jobId,
        wastes: items.map((item: iWasteTableRow) => ({
          productId: item.product?.id,
          qty: Number(item.qty),
        })),
      });
      setState({
        ...state,
        wasteTableItems: items,
      });
    } catch (error) {
      apiErrorToast(error);
    }
  };

  // delete a row
  const onDelete = async (id: string) => {
    const items = state.wasteTableItems.filter((item: iWasteTableRow) => item.id !== id);
    await postWastes(items);
  };

  // enter an amount
  const onUpdate = _.debounce(async (id: string, amount: string) => {
    const items = state.wasteTableItems.map((item: iWasteTableRow) =>
      item.id === id ? { ...item, qty: amount } : item,
    );
    await postWastes(items);
  }, 500);

  const onSelect = async (product: iProduct | null) => {
    if (!product) return;
    const newItem = {
      id: uuidv4(),
      product,
      qty: '',
    };
    await postWastes([...state.wasteTableItems, newItem]);
  };

  useEffect(() => {
    let isCancelled = false;
    const fetchWasteProducts = async () => {
      try {
        const allWasteProducts = await ProductService.getWasteProducts();
        if (isCancelled) return;
        const wasteTableItems = WasteTableUtil.convertWastes(wastes, allWasteProducts);
        setState(prevState => ({
          ...prevState,
          allWasteProducts,
          wasteTableItems,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };
    fetchWasteProducts();
    return () => {
      isCancelled = true;
    };
  }, [wastes]);

  return (
    <div data-testid={'waste-wrapper'}>
      <small>{WASTE_TABLE_CAPTION}</small>
      <WasteTableWrapper data-testid={'waste-tbl'}>
        <DynamicTable
          head={TableHelper.getHeads(WASTE_TABLE_COLUMNS, WASTE_TABLE_NAME)}
          rows={TableHelper.getRows({
            data: state.wasteTableItems,
            columns: WASTE_TABLE_COLUMNS,
            categories: [],
            renderSpecialContent: WasteTableUtil.wastelistRenderSpecialContent({ onDelete, onUpdate }),
          })}
          isLoading={state.isLoading}
        />
      </WasteTableWrapper>
      <CreateByAsyncProduct onSelect={onSelect} filter={'isForWaste:true'} />
    </div>
  );
};
export default Wastes;
