export const COLUMN_NAME_IN_STORAGE = 'tablet-job-list-columns';
export const FIXED_COLUMNS = ['Product Name', 'Product Code', 'Qty To Finish', 'Machine', 'Scheduled', 'Status'];
export const MUST_COLUMNS_BEFORE = ['Job'];
export const MUST_COLUMNS_AFTER = ['RollLabelPrint', 'Operation'];
export const MUST_COLUMNS_AFTER_FUTURE = ['RollLabelPrint'];
export const DEFAULT_COLUMNS = [
  ...MUST_COLUMNS_BEFORE,
  'Product Name',
  'Qty To Finish',
  'Machine',
  'Scheduled',
  'Status',
  ...MUST_COLUMNS_AFTER,
];
export const DEFAULT_COLUMNS_FUTURE = [
  ...MUST_COLUMNS_BEFORE,
  'Product Name',
  'Qty To Finish',
  'Machine',
  'Scheduled',
  'Status',
  ...MUST_COLUMNS_AFTER_FUTURE,
];

export const BOM_ATTRIBUTE_HOPPER = 'HOPPER';
