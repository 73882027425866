import React from 'react';
import iJob from '../../types/job/iJob';
import iJobAttribute from '../../types/job/iJobAttribute';
import iJobCategory from '../../types/job/iJobCategory';
import iJobStatus from '../../types/job/iJobStatus';
import { iConfigColumn } from '../UITypes/types';
import StatusLozenge from '../statusComponent/StatusLozenge';
import StartOrContinue from './startOrContinue/StartOrContinue';
import { JOB_LIST_FIXED_COLUMNS, JOB_LIST_INSERT_INDEX } from './JobList.constants';
import LinkBtnUrl from '../buttons/LinkBtnUrl';
import { JOB_URL } from '../UrlMap';
import { handleNullException } from '../../services/UtilsService';
import JobListPrintDropDown from './JobListPrintDropDown';

const getFlexColumnsByJobAttributes = (jobAttributes: Array<iJobAttribute>) => {
  return jobAttributes.map((item: iJobAttribute) => ({
    key: item.id,
    name: item.name,
    dataSource: ['jobAttributes', 'jobAttributeValues.value'],
    type: 'flexColumn',
    isSelected: true,
    group: '',
  }));
};

const insertFlexToColumns = (inserts: Array<iConfigColumn>, columns: Array<iConfigColumn>, insertPosition: number) => {
  const cloneColumns = [...columns];
  cloneColumns.splice(insertPosition, 0, ...inserts);
  return cloneColumns;
};

const getJobListColumns = ({
  jobAttributes,
  isCurrent,
}: {
  jobAttributes: Array<iJobAttribute>;
  isCurrent: boolean;
}) => {
  const columns = isCurrent
    ? JOB_LIST_FIXED_COLUMNS
    : JOB_LIST_FIXED_COLUMNS.slice(0, JOB_LIST_FIXED_COLUMNS.length - 1);
  return insertFlexToColumns(getFlexColumnsByJobAttributes(jobAttributes), columns, JOB_LIST_INSERT_INDEX);
};

//  eslint-disable-next-line
const getJobCategoryName = (status: iJobStatus, categories: Array<iJobCategory>) => {
  const category = categories.find((c: iJobCategory) => c.id === status.jobStatusCategoryId);
  return category ? category.name : '';
};

const joblistRenderSpecialContent =
  ({ jobCategoryList, isCurrent }: { jobCategoryList: Array<iJobCategory>; isCurrent: boolean }) =>
  (item: iJob, column: iConfigColumn, index: number) => {
    const { type, dataSource, key } = column;
    switch (type) {
      case 'jobNumberLink': {
        const { id, jobNumber } = item;
        return (
          <LinkBtnUrl
            url={`${JOB_URL}/${id}`}
            btnName={jobNumber}
            state={{ readOnly: true, isCurrent: index === 0 && isCurrent }}
          />
        );
      }
      case 'flexColumn': {
        const array = handleNullException(item, dataSource[0]);
        if (Array.isArray(array)) {
          //  eslint-disable-next-line
          const targetElement = (array as Array<any>).find((element: any) => element.id === key);
          return <div>{handleNullException(targetElement, dataSource[1])}</div>;
        }
        return <div />;
      }
      case 'jobStatus': {
        const { status } = item;
        return <StatusLozenge status={status} categoryCode={getJobCategoryName(status, jobCategoryList)} />;
      }
      case 'print': {
        const { id, jobAttributes, productId } = item;
        return <JobListPrintDropDown jobId={id} jobAttributes={jobAttributes} productId={productId} />;
      }
      case 'operation': {
        const { status } = item;
        return index === 0 ? (
          <StartOrContinue job={item} categoryName={getJobCategoryName(status, jobCategoryList)} />
        ) : (
          <div />
        );
      }
      default:
        return <div />;
    }
  };

export default {
  getFlexColumnsByJobAttributes,
  insertFlexToColumns,
  getJobListColumns,
  getJobCategoryName,
  joblistRenderSpecialContent,
};
