import React, { useState } from 'react';
import styled from 'styled-components';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Button from '@atlaskit/button';
import { FlexContainer } from '../styles/styles';

const CollapseWrapper = styled.div`
  .collapse-button {
    background-color: transparent;
  }
  .collapse-children {
    padding-left: 1rem;
  }
`;

const Collapse = ({
  defaultOpen = false,
  iconSize = 'medium',
  text,
  children,
  extraControl,
  className,
}: {
  defaultOpen?: boolean;
  iconSize?: 'small' | 'medium' | 'large' | 'xlarge' | undefined;
  text: string | React.ReactNode;
  children: React.ReactNode;
  extraControl?: React.ReactNode;
  className?: string;
}) => {
  const [state, setState] = useState(defaultOpen);
  const icon = state ? (
    <ChevronDownIcon label={'collapse'} size={iconSize} testId={'collapase-icon'} />
  ) : (
    <ChevronRightIcon label={'show'} size={iconSize} testId={'un-collapse-icon'} />
  );
  return (
    <CollapseWrapper data-testid={'collapse-wrapper'} className={className}>
      <FlexContainer>
        {extraControl}
        <Button
          iconAfter={icon}
          onClick={() => setState(!state)}
          className={'collapse-button'}
          testId={'collapse-button'}
        >
          {text}
        </Button>
      </FlexContainer>
      <div className={'collapse-children'}>{state && children}</div>
    </CollapseWrapper>
  );
};

export default Collapse;
