import React from 'react';
import { ButtonGroup } from '@atlaskit/button';
import iProduct from '../../../../types/product/iProduct';
import AsyncSearchFormOptionLabel from '../../../../shared/hookForms/asyncSearch/AsyncSearchFormOptionLabel';
import ProductService from '../../../../services/ProductService';
import { FlexContainer } from '../../../../shared/styles/styles';
import { getProductOptionLabel } from '../../../../shared/Utilities';
import { SearchWrapper } from '../../shared/StockTake.style';

const FoundItemOperation = ({
  onSelect,
  // onOpenCancelModal,
  children,
}: {
  onSelect: (selected: iProduct | null) => void;
  children: React.ReactNode;
}) => {
  return (
    <FlexContainer className={'space-between'} data-testid={'FlexContainer'}>
      <SearchWrapper data-testid={'SearchWrapper'}>
        <AsyncSearchFormOptionLabel
          onSelect={onSelect}
          promiseFn={(keyword: string) =>
            ProductService.getProductListAsyncSearch({
              like: `productCode:${keyword},name:${keyword}`,
            })
          }
          searchBarPlaceholder={'Search by productCode/name...'}
          formatOptionLabel={getProductOptionLabel}
        />
      </SearchWrapper>
      <ButtonGroup>{children}</ButtonGroup>
    </FlexContainer>
  );
};

export default FoundItemOperation;
