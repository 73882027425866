import _ from 'lodash';
import { iOption } from './types';

const getDefaultSelected = (values: Array<string>, optionsAttr: Array<iOption>) =>
  values
    .map(option => {
      const targetOptionArr = optionsAttr.filter(o => o.value === option);
      return targetOptionArr.length === 1 ? targetOptionArr[0] : [];
    })
    .filter((item: iOption | never[]) => !_.isEmpty(item));
export const parseDefault = (optionsAttr: Array<iOption>, defaultValue?: string | Array<string>, isMulti?: boolean) => {
  const parsedDefaultSelected =
    // eslint-disable-next-line no-nested-ternary
    !defaultValue
      ? []
      : // default value can be a string/an array
      // eslint-disable-next-line no-nested-ternary
      Array.isArray(defaultValue)
      ? getDefaultSelected(defaultValue, optionsAttr)
      : getDefaultSelected([defaultValue], optionsAttr);

  // eslint-disable-next-line no-nested-ternary
  return _.isEmpty(parsedDefaultSelected)
    ? []
    : // eslint-disable-next-line no-nested-ternary
    isMulti
    ? parsedDefaultSelected
    : [parsedDefaultSelected[0]];
};
