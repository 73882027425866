import React from 'react';
import styled from 'styled-components';

const CompanyLogoWrapper = styled.div`
  display: flex;
  margin: 0 auto 20px;
  align-items: center;
  justify-content: center;

  .image-wrapper {
    width: 100%;
    object-fit: contain;
    display: block;
  }
`;

const CompanyLogo = ({
  src = process.env.REACT_APP_COMPANY_LOGO,
  alt = process.env.REACT_APP_APP_NAME,
}: {
  src?: string;
  alt?: string;
}) => {
  return (
    <CompanyLogoWrapper>
      <img src={src} alt={alt} className={'image-wrapper'} />
    </CompanyLogoWrapper>
  );
};

export default CompanyLogo;
