import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import DynamicTable from '@atlaskit/dynamic-table';
import iStockTakeItem from '../../../types/stockTake/iStockTakeItem';
import TableHelper from '../../../shared/tableHelper/TableHelper';
import StockTakeUtil from '../shared/StockTake.util';
import { FINISHING_WARNING_TITLE, FINISH_PREVIEW_TABLE_COLUMNS } from '../shared/StockTake.constant';
import { SpaceVerticalWrapper } from '../../../shared/styles/styles';

const FinishModalBody = ({ stockTakeItems }: { stockTakeItems: Array<iStockTakeItem> }) => {
  return (
    <>
      <SectionMessage appearance="warning" testId={'warning-section-message'}>
        <p>{FINISHING_WARNING_TITLE}</p>
      </SectionMessage>
      <SpaceVerticalWrapper className={'small'} data-testid={'SpaceVerticalWrapper'} />
      <DynamicTable
        head={TableHelper.getHeads(FINISH_PREVIEW_TABLE_COLUMNS, 'finish-preview-table')}
        rows={TableHelper.getRows({
          data: stockTakeItems,
          columns: FINISH_PREVIEW_TABLE_COLUMNS,
          categories: [],
          renderSpecialContent: StockTakeUtil.finishTableRenderSpecialContent,
        })}
      />
    </>
  );
};

export default FinishModalBody;
