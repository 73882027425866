/**
 * make attention when trying to use QRReader in a modal
 * it may cause infinite execution of onResult and does not turn off the camera automatically
 */
import React from 'react';
import styled from 'styled-components';
import { QrReader } from '@blackbox-vision/react-qr-reader';

const QrReaderWrapper = styled.div`
  .qr-reader {
    padding: 1rem;
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
  }
`;

const QRReader = ({ onScanSuccess }: { onScanSuccess: (locationCode?: string) => void }) => {
  return (
    <QrReaderWrapper>
      <QrReader
        //  eslint-disable-next-line
        onResult={(result, error) => {
          if (result) {
            onScanSuccess(result.getText());
          }
        }}
        constraints={{ facingMode: 'environment' }}
        className={'qr-reader'}
      />
    </QrReaderWrapper>
  );
};
export default QRReader;
