import AppService from './AppService';
import { iParams } from '../shared/UITypes/types';

const getJobAttributes = (config?: iParams) => {
  return AppService.get('/jobAttribute', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

const createJobAttribute = (postContent: iParams) => {
  return AppService.post('/jobAttribute', postContent).then(({ data }) => data);
};

const updateJobAttribute = (id: string, updateContent: iParams) => {
  return AppService.put(`/jobAttribute/${id}`, updateContent).then(({ data }) => data);
};

const deleteJobAttribute = (id: string) => {
  return AppService.delete(`/jobAttribute/${id}`).then(({ data }) => data);
};

export default {
  getJobAttributes,
  createJobAttribute,
  updateJobAttribute,
  deleteJobAttribute,
};
