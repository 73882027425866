import DynamicTable from '@atlaskit/dynamic-table';
import React from 'react';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { DEFAULT_HEADS } from '../shared/DynamicManufactureRows.constants';
import { iManufactureRow } from '../shared/DynamicManufactureRows.types';
import DynamicMRsUtils from '../shared/DynamicManufactureRows.utils';
import { handleNullException } from '../../../../services/UtilsService';

// local length is longer then latest
const ConflictListing = ({ local, latest }: { local: Array<iManufactureRow>; latest: Array<iManufactureRow> }) => {
  const headCells = [
    { key: 'rollNumber' },
    ...DEFAULT_HEADS.map((field: string) => ({
      key: field,
      content: field,
    })),
  ];
  const getRows = () => {
    return latest.map((item: iManufactureRow, index: number) => {
      const { isConflict, detail: conflictFields } = DynamicMRsUtils.detectConflict({
        latest: item,
        local: local[index],
      });
      if (!isConflict) return { cells: [] };
      return {
        cells: [
          { content: `Roll ${index + 1}` },
          ...['length', 'nominalWeight', 'actualWeight'].map((field: string) => {
            if (!conflictFields.includes(field))
              return {
                content: <CheckIcon label={'check'} primaryColor={'green'} />,
              };
            const latestField = handleNullException(item, field);
            const localField = handleNullException(local[index], field);
            return {
              content: (
                <>
                  <div>Database version: {latestField[0].value}</div>
                  <div>Your input: {localField[0].value}</div>
                </>
              ),
            };
          }),
        ],
      };
    });
  };
  return <DynamicTable head={{ cells: headCells }} rows={getRows()} />;
};

export default ConflictListing;
