import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getStockTakeItemListByStockTakeId = (stockTakeId: string, config?: iParams) => {
  return AppService.get(`/stockTakeItem/stockTake/${stockTakeId}`, {
    include: 'createdBy,updatedBy,product.measurement,toWarehouseLocation',
    ...config,
  }).then(({ data }) => data);
};

const createStockTakeItem = (postData: iParams) => {
  return AppService.post('/stockTakeItem', postData).then(({ data }) => data);
};

const deactivateStockTakeItem = (id: string) => {
  return AppService.delete(`/stockTakeItem/${id}`).then(({ data }) => data);
};

const updateStockTakeItem = (id: string, updateContent: iParams) => {
  return AppService.put(`/stockTakeItem/${id}`, updateContent).then(({ data }) => data);
};

const getStockTakeItemList = (config?: iParams) => {
  return AppService.get('/stockTakeItem', {
    include: 'createdBy,updatedBy,product.measurement',
    ...config,
  }).then(({ data }) => data);
};

export default {
  getStockTakeItemListByStockTakeId,
  createStockTakeItem,
  deactivateStockTakeItem,
  updateStockTakeItem,
  getStockTakeItemList,
};
