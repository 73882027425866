import React from 'react';
import styled from 'styled-components';
import { Grid, GridColumn } from '@atlaskit/page';
import SectionMessage from '@atlaskit/section-message';
import { Label } from '@atlaskit/field-base';

import { LabelValue } from '../../../shared/styles/styles';
import { formatDate } from '../../../services/UtilsService';
import iPoReceiving from '../../../types/purchaseOrder/iPoReceiving';

const SectionMessageContainer = styled.div`
  margin: 2rem auto;
`;

const PoDetail = ({ poReceiving }: { poReceiving?: iPoReceiving }) => {
  return (
    <>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Supplier" />
          <LabelValue>{poReceiving?.purchaseOrder?.supplier?.name}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Email" />
          <LabelValue>{poReceiving?.purchaseOrder?.supplier?.email}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Phone" />
          <LabelValue>{poReceiving?.purchaseOrder?.supplier?.phone}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Reference" />
          <LabelValue>{poReceiving?.purchaseOrder?.supplierRef}</LabelValue>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Order Date" />
          <LabelValue>{formatDate(poReceiving?.purchaseOrder?.orderDate, 'DD MMM YYYY')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="ETA" />
          <LabelValue>{formatDate(poReceiving?.purchaseOrder?.eta, 'DD MMM YYYY')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Docket Number" />
          <LabelValue>{poReceiving?.reference}</LabelValue>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={5}>
          <SectionMessageContainer>
            {poReceiving?.purchaseOrder?.notes ? (
              <SectionMessage title="">
                <p>{poReceiving?.purchaseOrder?.notes}</p>
              </SectionMessage>
            ) : null}
          </SectionMessageContainer>
        </GridColumn>
      </Grid>
    </>
  );
};

export default PoDetail;
