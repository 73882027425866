import React from 'react';
import moment from 'moment';
import {
  AtlassianNavigation,
  CustomProductHome,
  PrimaryButton,
  PrimaryButtonProps,
  useOverflowStatus,
} from '@atlaskit/atlassian-navigation';
import { ButtonItem } from '@atlaskit/menu';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BACK_END_URL } from '../../shared/UrlMap';
import UserAvatarPopup from '../../shared/user/UserAvatarPopup';
import JobsPopup from '../../shared/job/JobsPopup';
import { RootState } from '../../redux/makeReduxStore';
import PurchasesPopup from '../../shared/topNavPopups/PurchasesPopup';
import StockPopup from '../../shared/topNavPopups/StockPopup';
import SalesPopup from '../../shared/topNavPopups/SalesPopup';
import RestrictedAccessDiv from '../../shared/UserAccess/RestrictedAccessDiv';
import {
  ACCESS_CODE_DISPATCH_NOTES,
  ACCESS_CODE_JOBS,
  ACCESS_CODE_PURCHASE_ORDERS,
} from '../../types/settings/UserAccess/iAccess';

const ResponsiveBtn = (props: PrimaryButtonProps) => {
  const overflowStatus = useOverflowStatus();
  return overflowStatus.isVisible ? (
    <PrimaryButton {...props} /> // eslint-disable-line
  ) : (
    <ButtonItem>{props.children}</ButtonItem> // eslint-disable-line
  );
};

const customIcon = '';
export default () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const history = useHistory();

  const navToHome = () => {
    history.push(BACK_END_URL);
  };

  const getCustomLogo = () => {
    if (!user || !user.company) {
      return '';
    }
    return user.company.logoUrl || '';
  };

  const logoBtn = () => (
    <CustomProductHome
      href={BACK_END_URL}
      onClick={navToHome}
      iconAlt="Home"
      iconUrl={customIcon}
      logoAlt="Logo"
      logoUrl={getCustomLogo()}
      logoMaxWidth={300}
    />
  );

  const UserProfile = () => {
    return <UserAvatarPopup />;
  };

  const Notifications = () => {
    return currentShift ? (
      <div style={{ marginRight: '10px' }}>
        Shift started @<strong>{moment(currentShift.startTime).format('MMM DD YYYY')}</strong>
      </div>
    ) : null;
  };
  return (
    <AtlassianNavigation
      testId={'top-menu'}
      label="site"
      primaryItems={[
        <ResponsiveBtn href={BACK_END_URL} onClick={navToHome}>
          Home
        </ResponsiveBtn>,
        <RestrictedAccessDiv hideWhenDenied expectedUserAccessCodes={[ACCESS_CODE_JOBS]} expectedCodeMatchedWithAny>
          <JobsPopup />
        </RestrictedAccessDiv>,
        <RestrictedAccessDiv
          hideWhenDenied
          expectedUserAccessCodes={[ACCESS_CODE_DISPATCH_NOTES]}
          expectedCodeMatchedWithAny
        >
          <SalesPopup />
        </RestrictedAccessDiv>,
        <RestrictedAccessDiv
          hideWhenDenied
          expectedUserAccessCodes={[ACCESS_CODE_PURCHASE_ORDERS]}
          expectedCodeMatchedWithAny
        >
          <PurchasesPopup />
        </RestrictedAccessDiv>,

        <StockPopup />,
      ]}
      renderProductHome={logoBtn}
      // renderAppSwitcher={SwitcherPopup}
      // renderCreate={DefaultCreate}
      // renderHelp={HelpPopup}
      renderNotifications={Notifications}
      renderProfile={UserProfile}
      // renderSearch={DefaultSearch}
      // renderSettings={DefaultSettings}
    />
  );
};
