import { COLUMN_NAME_IN_STORAGE } from '../shared/constants/JobListColumns';

const getTokenName = () => {
  return process.env.REACT_APP_LOCAL_USER_TOKEN_NAME || 'token';
};

const getToken = () => {
  return localStorage.getItem(getTokenName());
};

const setToken = (newToken: string) => {
  return localStorage.setItem(getTokenName(), newToken);
};

const removeToken = () => {
  return localStorage.removeItem(getTokenName());
};
const removeColumns = () => {
  return localStorage.removeItem(COLUMN_NAME_IN_STORAGE);
};

const getCompanyIdName = () => {
  return process.env.REACT_APP_LOCAL_COMPANY_ID_NAME || 'companyId';
};

const getCompanyId = () => {
  return localStorage.getItem(getCompanyIdName());
};

const setCompanyId = (newToken: string) => {
  return localStorage.setItem(getCompanyIdName(), newToken);
};

const removeCompanyId = () => {
  return localStorage.removeItem(getCompanyIdName());
};

const setItem = (key: string, data: string) => {
  return localStorage.setItem(key, data);
};

const getItem = (key: string) => {
  return localStorage.getItem(key);
};

const removeItem = (key: string) => {
  return localStorage.removeItem(key);
};

const clear = () => {
  return localStorage.clear();
};

export default {
  getToken,
  setToken,
  removeToken,
  removeColumns,
  getCompanyId,
  setCompanyId,
  removeCompanyId,
  setItem,
  getItem,
  removeItem,
  clear,
};
