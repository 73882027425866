import React from 'react';
import iStockTake from '../../../../types/stockTake/iStockTake';
import FoundItemsInBin from '../foundItems/FoundItemsInBin';
import SupposedItemsInBin from '../supposedItems/SupposedItemsInBin';
import { SpaceVerticalWrapper } from '../../../../shared/styles/styles';
import { handleNullException } from '../../../../services/UtilsService';
import { iCategoryClassify } from '../../shared/StockTake.type';

const NotFinished = ({
  stockTakeDetail,
  categoryClassify,
  children,
}: {
  stockTakeDetail: iStockTake;
  categoryClassify: iCategoryClassify;
  children: React.ReactNode;
}) => {
  return (
    <>
      <SupposedItemsInBin
        items={handleNullException(stockTakeDetail, 'warehouseLocation.warehouseItemLocations')}
        locationCode={handleNullException(stockTakeDetail, 'warehouseLocation.locationCode')}
      />
      <SpaceVerticalWrapper className={'small'} data-testid={'SpaceVerticalWrapper'} />
      <FoundItemsInBin
        stockTakeDetail={stockTakeDetail}
        locationCode={handleNullException(stockTakeDetail, 'warehouseLocation.locationCode')}
        categoryClassify={categoryClassify}
      >
        {children}
      </FoundItemsInBin>
    </>
  );
};

export default NotFinished;
