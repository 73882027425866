import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import AppPage from '../../layout/AppPage';
import CenteredDiv from '../../shared/CenteredDiv';
import LoginForm from '../../shared/auth/LoginForm';
import CompanyLogo from '../../shared/company/CompanyLogo';
import LoginScreenSizePopup from '../../shared/auth/LoginScreenSizePopup';
import ModulingService from '../../services/ModulingService';

const LoginPageWrapper = styled.div`
  height: 100%;
  .auth-div {
    height: 100%;
    .centered-div {
      width: calc(100% - 1rem);
      max-width: 400px;
    }
  }
`;

type iState = {
  companyId?: string;
  companyLogoUrl?: string;
  isLoading: boolean;
  isTabletSize: boolean;
};
const initialState: iState = {
  isLoading: true,
  isTabletSize: true,
};
export default () => {
  const [state, setState] = useState(initialState);

  const getContent = () => {
    if (state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <CompanyLogo src={state.companyLogoUrl} />
        <LoginForm />
      </>
    );
  };
  useEffect(() => {
    let isCancelled = false;
    const exchangeDomainWithCompanyInfo = async () => {
      try {
        const { width } = window.screen;
        const result = await ModulingService.domainExchangeCompanyInfo(window.location.hostname);
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          ...result,
          isTabletSize: width >= 768 && width <= 1024,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        // error is not necessary to be handled here
        // apiErrorToast(error);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };
    exchangeDomainWithCompanyInfo();
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <AppPage>
      {!state.isTabletSize && <LoginScreenSizePopup onConfirm={() => setState({ ...state, isTabletSize: true })} />}
      <LoginPageWrapper>
        <CenteredDiv className={'auth-div'}>{getContent()}</CenteredDiv>
      </LoginPageWrapper>
    </AppPage>
  );
};
