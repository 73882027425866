import React from 'react';
import styled from 'styled-components';
import CommentList from '../../../shared/comments/CommentList';
import CustomizeTab from '../../../shared/tab/CustomizeTab';

const ActivitySectionContainer = styled.div`
  margin-top: 5rem;
`;

const Activities = ({ dispatchNoteId }: { dispatchNoteId: string }) => {
  const tabs = [
    {
      label: 'Comments',
      content: <CommentList modelName={'DispatchNote'} modelId={dispatchNoteId} />,
    },
    // {
    //   label: 'History',
    //   content: <LogList modelName={'DispatchNote'} modelId={dispatchNoteId} />,
    // },
  ];

  return (
    <ActivitySectionContainer>
      <CustomizeTab components={tabs} />
    </ActivitySectionContainer>
  );
};

export default Activities;
