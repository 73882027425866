import React from 'react';
import styled from 'styled-components';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Flag from '@atlaskit/flag';

const Wrapper = styled.div`
  > div[data-testid='warning-flag'] {
    background-color: rgb(255, 196, 0);
    div[data-testid='warning-flag-description'],
    > div > span:last-child {
      color: rgb(37, 56, 88);
    }
  }
`;
const WarningFlag = ({ title }: { title: string }) => (
  <Wrapper>
    <Flag
      // appearance="warning"
      icon={
        <ErrorIcon
          label="Warning"
          primaryColor={'rgb(37, 56, 88)'}
          secondaryColor={'#FFC400'}
          testId={'warning-icon'}
        />
      }
      id="warning"
      key="warning"
      title={'You might need to note:'}
      description={title}
      testId={'warning-flag'}
    />
  </Wrapper>
);

export default WarningFlag;
