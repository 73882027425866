import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Thumbnail from './Thumbnail';
import { iImage } from './ImageTypes';
import UploadImage from './UploadImage';
import { ErrorMessageContainer } from '../styleContainer';

const SectionContainer = styled.div`
  flex-wrap: nowrap;
  overflow: auto;

  .thumbnail-icon-container {
    position: relative;
    min-height: 30px;
  }
`;
const ThumbnailsContainer = styled.div`
  margin-right: 30px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-wrap: nowrap;
  overflow: auto;
`;

const initialState: Array<iImage> = [];
const DynamicImageUploader = ({
  defaultValue,
  onUpdate,
  children,
}: {
  defaultValue?: string | Array<string>;
  //    eslint-disable-next-line
  onUpdate: (newValue: any) => void;
  children?: React.ReactNode;
}) => {
  const initImages = () =>
    defaultValue
      ? (Array.isArray(defaultValue) ? defaultValue : [defaultValue]).map(
          (img: string): iImage => ({
            id: uuidv4(),
            url: img,
            uploading: false,
          }),
        )
      : initialState;
  const [images, setImages] = useState(initImages());
  // remove an image;refresh page and call onchange to update
  const onRemove = (image: iImage) => {
    const newImages = images.filter((img: iImage) => img.id !== image.id);
    onUpdate(newImages);
    setImages(() => newImages);
  };
  // upload image:
  // 1:create url for local file and display
  // 2:after upload ,update 'uploading' to false
  // 3:access to prevState by function setState
  // 4.if upload fails, remove it
  const onUploadImage = (image: iImage) => {
    if (!image.rawSrc && !image.url) {
      onRemove(image);
      return;
    }
    setImages((prevState: Array<iImage>) => {
      const isContainImage = prevState.find((item: iImage) => item.id === image.id);
      const replacedImages = prevState.map((img: iImage) => (img.id === image.id ? { ...img, ...image } : img));
      const newImages = isContainImage ? replacedImages : [...prevState, image];
      onUpdate(newImages);
      return newImages;
    });
  };

  return (
    <SectionContainer data-testid={'dynamic-image'}>
      <div className={'thumbnail-icon-container'}>
        <ThumbnailsContainer>
          {images.map(image => (
            <Thumbnail key={image.id} image={image} onRemove={onRemove} />
          ))}
        </ThumbnailsContainer>
        <UploadImage onUploadImage={onUploadImage} />
      </div>
      <ErrorMessageContainer>{children}</ErrorMessageContainer>
    </SectionContainer>
  );
};

export default DynamicImageUploader;
