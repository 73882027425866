import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { iRowContent } from '../../shared/DispatchNote.type';
import iDispatchNoteItem from '../../../../types/dispatchNote/iDispatchNoteItem';
import iAddress from '../../../../types/contactCompany/iAddress';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import QtyByCCP from './QtyByCCP';
import TransparentIconBtn from '../../../../shared/buttons/TransparentIconBtn';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import WarningIconWithTooltip from '../createDNItem/shared/WarningIconWithTooltip';
import { handleNullException, hasKey } from '../../../../services/UtilsService';
import { JOB_URL } from '../../../../shared/UrlMap';

export const isValidDispatchNoteItem = (item: iDispatchNoteItem) => {
  const FIXED_BEGIN = 'The info of this row might not be accurate due to';
  if (!item.salesOrderItemId || !item.salesOrderItem) {
    return {
      result: false,
      message: `${FIXED_BEGIN} sales order item being deleted`,
    };
  }
  if (item.productId && !item.product) {
    return {
      result: false,
      message: `${FIXED_BEGIN} due to product being deleted`,
    };
  }
  return { result: true };
};
export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column) {
      case 'Operation':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'Warning':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-table-column-${column}`,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iDispatchNoteItem>,
  onOpenDeleteModal: ((id: string) => void) | undefined,
  //  eslint-disable-next-line
  onEdit: (targetId: string, data: any) => Promise<void>,
  columns: Array<string>,
  isDisabled: boolean,
  customerId?: string,
) => {
  const mapList = () =>
    data.map((dispatchNoteItem: iDispatchNoteItem) => {
      const row: iRowContent = {
        id: dispatchNoteItem.id,
        'Product Code/SKU': (
          <ComposeSecondaryText secondaryText={dispatchNoteItem.product.name}>
            {handleNullException(dispatchNoteItem, 'product.productCode')}
            {/* <LinkBtnUrl
              btnName={dispatchNoteItem.product.productCode}
              url={`${PRODUCTS_URL}/${dispatchNoteItem.productId}`}
            /> */}
          </ComposeSecondaryText>
        ),
        Description: handleNullException(dispatchNoteItem, 'product.name'),
        Quantity: (
          <>
            <QtyByCCP
              contactCompanyId={customerId}
              qty={dispatchNoteItem.quantity.toString()}
              productId={dispatchNoteItem.productId}
              productUnit={handleNullException(dispatchNoteItem, 'product.measurement.shortName')}
              onEdit={(value: string | number) => onEdit(dispatchNoteItem.id, { quantity: value })}
              isDisabled={isDisabled}
            />
          </>
        ),
        'Job No.': handleNullException(dispatchNoteItem, 'job.jobNumber') && (
          <ComposeSecondaryText
            secondaryText={`qtyTotal:${handleNullException(dispatchNoteItem, 'job.qtyTotal')} ${handleNullException(
              dispatchNoteItem,
              'product.measurement.shortName',
            )}`}
          >
            <LinkBtnUrl
              btnName={handleNullException(dispatchNoteItem, 'job.jobNumber')}
              url={`${JOB_URL}/${dispatchNoteItem.jobId}`}
              state={{ readOnly: true }}
            />
          </ComposeSecondaryText>
        ),

        'Sales Order No.': handleNullException(dispatchNoteItem, 'salesOrderItem.salesOrder.orderNumber'),
        'Customer Order No.':
          handleNullException(dispatchNoteItem, 'job.customerOrderRef') ||
          handleNullException(dispatchNoteItem, 'salesOrderItem.salesOrder.customerRef'),
        Warning: !isValidDispatchNoteItem(dispatchNoteItem).result && (
          <WarningIconWithTooltip tooltipContent={isValidDispatchNoteItem(dispatchNoteItem).message} />
        ),
        Operation: onOpenDeleteModal && (
          <TransparentIconBtn
            icon={<TrashIcon label="delete" />}
            onClick={() => onOpenDeleteModal(dispatchNoteItem.id)}
          />
        ),
      };
      return row;
    });

  return mapList().map((row: iRowContent) => {
    const cells = columns.map((column: string) => ({
      content: hasKey(row, column) ? row[column] : '',
    }));
    return { cells, testId: 'admin-dispatch-note-item-row' };
  });
};

export const compareAddress = (a?: iAddress, b?: iAddress) => {
  if (!a && !b) return true;
  const fields = ['street', 'suburb', 'state', 'country', 'postcode'];
  return !fields.find(
    (field: string) =>
      (handleNullException(a, field) as string).trim().replace(/\s+/g, '').toUpperCase() !==
      (handleNullException(b, field) as string).trim().replace(/\s+/g, '').toUpperCase(),
  );
};
