import React from 'react';
import { Label } from '@atlaskit/field-base';
import LabelValue from './styles/LabelValue';
import InlineBlock from './styles/InlineBlock';

const VerticalLabel = ({ label, value, isFirstChild }: { label: string; value: string; isFirstChild?: boolean }) => (
  <InlineBlock data-testid={'inline-block'}>
    <Label isFirstChild={isFirstChild} label={label} />
    <LabelValue>{value}</LabelValue>
  </InlineBlock>
);

export default VerticalLabel;
