import React from 'react';
import { assembleAddress, formatDate, handleMoney, handleNullException } from '../../../../services/UtilsService';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../../shared/createdOrUpdated/CreatedOrUpdated';

import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import { iConfigColumn } from '../../../../shared/UITypes/types';
import iReceivingItem from '../../../../types/purchaseOrder/iReceivingItem';

export const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

export const getRows = (data: Array<iReceivingItem>, columns: Array<iConfigColumn>) => {
  return data.map((item: iReceivingItem) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'date':
          return {
            key: handleNullException(item, key),
            content: formatDate(handleNullException(item, key), 'Do MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key),
          };
        case 'address':
          return {
            key: handleNullException(item, key),
            content: assembleAddress(handleNullException(item, key)),
          };
        case 'text-two-layer':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                <span style={{ fontWeight: 500 }}>{handleNullException(item, key)}</span>
              </ComposeSecondaryText>
            ),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'qty':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, dataSource[1])
              ? `${Number(item.qty) / Number(handleNullException(item, dataSource[2]))} ${handleNullException(
                  item,
                  dataSource[1],
                )} = ${item.qty} ${handleNullException(item, dataSource[3])}`
              : `${item.qty} ${handleNullException(item, dataSource[3])} = ${item.qty} ${handleNullException(
                  item,
                  dataSource[3],
                )}`,
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-purchaseOrder-row' };
  });
};
