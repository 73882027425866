import styled from 'styled-components';
import { LIST_TABLE_NAME } from './StockTake.constant';

export const TableWrapper = styled.div`
  th[data-testid='${LIST_TABLE_NAME}-column-operation--head--cell'] {
    min-width: 36px;
    max-width: 50px;
  }
  th[data-testid='${LIST_TABLE_NAME}-column-updatedAt--head--cell'],
  th[data-testid='${LIST_TABLE_NAME}-column-createdAt--head--cell'] {
    min-width: 160px;
  }
  th[data-testid='${LIST_TABLE_NAME}-column-stockTakeNumber--head--cell'] {
    min-width: 100px;
  }
`;

export const ItemsTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: RGB(23, 43, 77);
  margin-bottom: 1rem;
`;
export const SupposedWrapper = styled.div`
  padding-right: 1rem;
`;
export const FoundWrapper = styled.div`
  padding-left: 1rem;
`;

export const SearchWrapper = styled.div`
  min-width: 300px;
`;

export const SecondaryTextWrapper = styled.div`
  padding-left: 0.5rem;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgb(107, 119, 140);
  display: block;
`;
