import React, { Ref, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';
import { iKeyValuePairs } from '../../../../shared/UITypes/types';
import { iAddressContent } from '../../../../types/iAddressContent';
import iAddress from '../../../../types/contactCompany/iAddress';
import iContactCompanyAddress from '../../../../types/contactCompany/iContactCompanyAddress';
import AddressSelectWithController from '../../../../shared/hookForms/CompanyAddressSelectorWithController';
import ElementWithModalPage from './ElementWithModalPage';
import AddressService from '../../../../services/AddressService';
import { apiErrorToast } from '../../../../shared/toast/ToastHandler';
import CustomizedLabel from '../../../../shared/hookForms/CustomizedLabel';

const AddressPrefContainer = styled.div`
  margin-bottom: 1rem;
`;

type iOption<T> = {
  label: string;
  value: T;
};

const GetField = ({
  label,
  name,
  placeholder,
  errorMsg,
  defaultValue,
  type = 'text',
  register,
  errors,
  isRequired = true,
}: {
  label: string;
  name: string;
  placeholder: string;
  errorMsg: string;
  defaultValue: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (rules: iKeyValuePairs) => Ref<any> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: DeepMap<Record<string, any>, FieldError>;
  isRequired?: boolean;
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <fieldset>
      <CustomizedLabel label={label} htmlFor={name} isRequired={isRequired} />
      <Textfield
        isRequired
        type={type}
        value={value}
        onChange={onChange}
        id={name}
        name={name}
        css={'default'}
        placeholder={placeholder}
        ref={register({ required: isRequired })}
      />
      {name in errors ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
    </fieldset>
  );
};

export type iAddressWithSelectProps = {
  onClick?: (updateContent: iAddressContent) => void;
  address?: iAddress;
  heading?: string;
  isAddConfirming?: boolean;
  children: React.ReactNode;
  needAddressSelection?: boolean;
  options?: iOption<iContactCompanyAddress>[];
};
const AddressWithSelect = ({
  onClick,
  address,
  isAddConfirming,
  heading = 'Address',
  children,
  needAddressSelection = false,
  options = [],
}: iAddressWithSelectProps) => {
  const { register, errors, getValues, control } = useForm<iAddressContent>();

  const [localAddress, setLocalAddress] = useState<iAddress>();

  useEffect(() => {
    setLocalAddress(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(address)]);

  const onSelectPreferredAddress = async (name: string, value: string) => {
    if (!value) return;
    try {
      const data = await AddressService.getAddressDetail(value);
      setLocalAddress(data);
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const editAddress = () => {
    return (
      <form>
        <>
          {needAddressSelection && (
            <AddressPrefContainer>
              <AddressSelectWithController
                name={'shippingAddressId'}
                label={'Preferred Shipping Address'}
                control={control}
                defaultValue={localAddress?.id || undefined}
                options={options}
                onChange={onSelectPreferredAddress}
                errors={errors}
                isRequired={false}
                isClearable={false}
                triggerDefault={false}
              />
            </AddressPrefContainer>
          )}

          <GetField
            label={'Name'}
            name={'name'}
            placeholder={'name'}
            errorMsg={'Please provide name.'}
            defaultValue={localAddress?.name || ''}
            type={'text'}
            register={register}
            errors={errors}
            isRequired={false}
          />
          <GetField
            label={'Street'}
            name={'street'}
            placeholder={'street'}
            errorMsg={'Please provide street.'}
            defaultValue={localAddress?.street || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'Suburb'}
            name={'suburb'}
            placeholder={'suburb'}
            errorMsg={'Please provide suburb.'}
            defaultValue={localAddress?.suburb || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'State'}
            name={'state'}
            placeholder={'state'}
            errorMsg={'Please provide state.'}
            defaultValue={localAddress?.state || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'Postcode'}
            name={'postcode'}
            placeholder={'postcode'}
            errorMsg={'Please provide postcode.'}
            defaultValue={localAddress?.postcode || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'Country'}
            name={'country'}
            placeholder={'country'}
            errorMsg={'Please provide country.'}
            defaultValue={localAddress?.country || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
        </>
      </form>
    );
  };

  const handleConfirm = () => {
    const data = getValues();
    if (typeof onClick === 'function') {
      onClick(data);
    }
  };

  return (
    <ElementWithModalPage
      onClick={handleConfirm}
      popupContent={editAddress()}
      popupHeading={heading}
      isConfirming={isAddConfirming}
    >
      {children}
    </ElementWithModalPage>
  );
};
export default AddressWithSelect;
