import styled from 'styled-components';

export const TabWrapper = styled.div`
  margin: 1.5rem 0 0.5rem;
  .tab-items {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
  .tab-item {
    margin-right: 1rem;
    min-width: 5rem;
  }
`;
