import styled from 'styled-components';

export const PageDescriptionWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: RGB(23, 43, 77);
  margin-bottom: 1rem;
`;
export const StockTransferWrapper = styled.div`
  th[data-testid='stockTransfer-table-column-Delete--head--cell'] {
    min-width: 10rem;
    width: 10rem;
  }
`;
export const FlexEndWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
`;

export const AsyncSearchWrapper = styled.div`
  min-width: 20rem;
`;
export const AfterTransferWrapper = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  div {
    padding-bottom: 1rem;
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: RGB(23, 43, 77);
    padding-bottom: 1rem;
  }
  .breadcrumbs {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: RGB(23, 43, 77);
    padding-bottom: 0.5rem;
  }
`;
export const TableToggleWrapper = styled.div`
  margin-bottom: 2rem;
`;
export const FixHeightWrapper = styled.div`
  max-height: 150px;
`;

export const AddProdsWrapper = styled.div`
  .scan-button {
    width: 40px;
    height: 40px;
  }
`;

export const SearchAndButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  .scan-button {
    margin-top: 20px;
    width: 40px;
    height: 40px;
  }
`;
