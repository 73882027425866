import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import moment from 'moment';
import styled from 'styled-components';

import iPurchaseOrderDetail from '../../../types/purchaseOrder/iPurchaseOrderDetail';
import iPurchaseOrderItem from '../../../types/purchaseOrder/iPurchaseOrderItem';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import { getHeads, getQtyAfterConversion, getReceivedQtyBeforeConversion } from '../Purchase.utilities';
import { handleNullException } from '../../../services/UtilsService';

const TableWrapper = styled.div`
  th[data-testid='po-detail-poItems-table-column-Supplier Product Code--head--cell'] {
    width: 9rem;
    min-width: 9rem;
  }

  th[data-testid="po-detail-poItems-table-column-Recv'd Qty--head--cell"] {
    width: 14rem;
    min-width: 14rem;
  }
  th[data-testid='po-detail-poItems-table-column-Quantity--head--cell'] {
    width: 14rem;
    min-width: 14rem;
  }
`;

const COLUMNS = [
  'Product Code / SKU',
  'Supplier Product Code',
  // 'Description',
  'Quantity',
  "Recv'd Qty",
];

const PoItems = ({ po }: { po?: iPurchaseOrderDetail }) => {
  const getNameContent = (item: iPurchaseOrderItem) => (
    <ComposeSecondaryText secondaryText={item.product.name}>
      <span style={{ fontWeight: 500 }}>{item.product.productCode}</span>
    </ComposeSecondaryText>
  );

  const getRows = () => {
    return po?.purchaseOrderItems
      ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
      .map((item: iPurchaseOrderItem) => ({
        cells: [
          {
            key: item.product.productCode,
            content: getNameContent(item),
          },
          { content: handleNullException(item, 'supplierProductCode') },
          // { content: handleNullException(item, 'description') },
          { content: getQtyAfterConversion(item, true) },
          { content: getReceivedQtyBeforeConversion(item) },
        ],
      }));
  };

  return (
    <TableWrapper>
      <DynamicTable head={getHeads(COLUMNS, 'po-detail-poItems')} rows={getRows()} testId={'po-detail-poItems-table'} />
    </TableWrapper>
  );
};

export default PoItems;
