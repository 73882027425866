import iProductAttribute from '../../types/product/iProductAttribute';
import iProductAttributeValue from '../../types/product/iProductAttributeValue';

export const getProductAttributesArr = (
  attributeSetproductAttributes: iProductAttribute[],
  productAttributeValues: iProductAttributeValue[],
) => {
  return attributeSetproductAttributes
    .sort((pAttrA, pAttrB) => pAttrA.sortOrder - pAttrB.sortOrder)
    .map(pAttr => ({
      label: pAttr.name,
      value: productAttributeValues.some(pAttrVal => pAttrVal.attributeId === pAttr.id)
        ? productAttributeValues
            .filter(pAttrVal => pAttrVal.attributeId === pAttr.id)
            .map(pAttrVal => pAttrVal.value)
            .join(',')
        : '',
    }));
};
