import React from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import { iBtnAppearance } from '../UITypes/types';
import { ModalPageWrapper } from './ModalPage.style';

const ModalPage = ({
  heading,
  children,
  onConfirm,
  onCancel,
  isConfirming = false,
  confirmBtnName = 'Confirm',
  confirmBtnAppearance = 'primary',
  isDisabled = false,
  extraBottomHandler,
}: {
  heading: string;
  children: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  isConfirming?: boolean;
  confirmBtnName?: string;
  confirmBtnAppearance?: iBtnAppearance;
  isDisabled?: boolean;
  extraBottomHandler?: React.ReactNode;
}) => {
  const CloseButton = () => {
    return (
      <Button testId={'cross-button'} onClick={onCancel}>
        X
      </Button>
    );
  };

  return (
    <ModalPageWrapper data-testid={'modal-page-wrapper'}>
      <div className={'modal-header'}>
        <h3>{heading}</h3>
        <CloseButton />
      </div>
      <div className={'modal-body'}>{children}</div>
      <div className={'modal-footer'}>
        {extraBottomHandler || <div />}
        <ButtonGroup>
          <LoadingButton
            onClick={onConfirm}
            appearance={confirmBtnAppearance}
            isDisabled={isDisabled}
            testId={'confirm-button'}
            isLoading={isConfirming}
          >
            {confirmBtnName}
          </LoadingButton>
          <Button onClick={onCancel} testId={'cancel-button'}>
            Cancel
          </Button>
        </ButtonGroup>
      </div>
    </ModalPageWrapper>
  );
};

export default ModalPage;
