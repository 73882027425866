import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { Provider } from 'react-redux';
import store from './redux/makeReduxStore';
import 'react-notifications-component/dist/theme.css';
import './App.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import LoginPage from './pages/auth/LoginPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import {
  AUTH_URL_LOGIN,
  BACK_END_URL,
  BULK_STOCK_TAKE_URL,
  DISPATCH_NOTE_DETAIL_URL,
  DISPATCH_NOTES_URL,
  FINISHING_SHIFT_URL,
  ITEM_LOCATION_CURRENT_MACHINE_URL,
  ITEM_LOCATIONS_URL,
  JOB_DETAILS_URL,
  JOB_DISPATCH_URL,
  JOBS_CURRENT_URL,
  JOBS_FUTURE_URL,
  PURCHASE_ORDER_DETAIL_URL,
  PURCHASE_ORDER_RECEIVING_URL,
  PURCHASES_URL,
  STOCK_TAKE_DETAIL_URL,
  STOCK_TAKE_URL,
  STOCK_TRANSFER_URL,
  USER_PROFILE_URL,
} from './shared/UrlMap';
import ProtectedRoute from './shared/ProtectedRoute';
import UserProfilePage from './pages/user/UserProfilePage';
import JobDispatchPage from './pages/job/JobDispatchPage';
import '@atlaskit/css-reset';
import FinishingShiftPage from './pages/auth/FinishingShiftPage';
import PoReceiving from './pages/purchaseOrder/receiving/PoReceiving';
import PoList from './pages/purchaseOrder/list/PoList';
import PoDetail from './pages/purchaseOrder/detail/PoDetail';
import StockTakeList from './pages/stockTake/StockTakeList';
import StockTakeDetail from './pages/stockTake/StockTakeDetail';
import ItemLocations from './pages/itemLocation/ItemLocations';
import StockTransfer from './pages/stockTransfer/StockTransfer';
import BulkStockTake from './pages/stockTake/bulkStockTake/BulkStockTake';
import ItemLocationCurrentMachine from './pages/itemLocation/ItemLocationCurrentMachine';
import DispatchNoteList from './pages/dispatchNote/DispatchNoteList';
import DispatchNoteDetail from './pages/dispatchNote/DispatchNoteDetail';
import CurrentJobListPage from './pages/jobListPage/CurrentJobListPage';
import FutureJobListPage from './pages/jobListPage/FutureJobListPage';
import JobDetailPage from './pages/jobDetailPage/JobDetailPage';
import {
  ACCESS_CODE_DISPATCH_NOTES,
  ACCESS_CODE_JOBS,
  ACCESS_CODE_PURCHASE_ORDERS,
  ACCESS_CODE_STOCK_RECEIVING,
  ACCESS_CODE_STOCK_TAKES,
  ACCESS_CODE_STOCK_TRANSFER,
  ACCESS_CODE_WAREHOUSES,
  iAccessCode,
} from './types/settings/UserAccess/iAccess';
import RestrictedAccessDiv from './shared/UserAccess/RestrictedAccessDiv';

export default () => {
  const getRestrictedPage =
    (children: React.ReactElement, accessCodes: iAccessCode[], requiresAnyCodes = false) =>
    () => {
      return (
        <RestrictedAccessDiv expectedUserAccessCodes={accessCodes} expectedCodeMatchedWithAny={requiresAnyCodes}>
          {children}
        </RestrictedAccessDiv>
      );
    };

  return (
    <>
      <ReactNotifications />
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to={AUTH_URL_LOGIN} />
            </Route>
            <Route exact path={AUTH_URL_LOGIN} component={LoginPage} />
            <ProtectedRoute exact path={BACK_END_URL} component={DashboardPage} />
            <ProtectedRoute exact path={USER_PROFILE_URL} component={UserProfilePage} />

            {/* Job list page */}
            <ProtectedRoute exact path={JOBS_CURRENT_URL} component={CurrentJobListPage} />
            <ProtectedRoute exact path={JOBS_FUTURE_URL} component={FutureJobListPage} />
            <ProtectedRoute
              exact
              path={JOB_DETAILS_URL}
              component={getRestrictedPage(<JobDetailPage />, [ACCESS_CODE_JOBS])}
            />

            {/* Sales */}
            <ProtectedRoute
              exact
              path={JOB_DISPATCH_URL}
              component={getRestrictedPage(<JobDispatchPage />, [ACCESS_CODE_DISPATCH_NOTES])}
            />
            <ProtectedRoute
              exact
              path={DISPATCH_NOTES_URL}
              component={getRestrictedPage(<DispatchNoteList />, [ACCESS_CODE_DISPATCH_NOTES])}
            />
            <ProtectedRoute
              exact
              path={DISPATCH_NOTE_DETAIL_URL}
              component={getRestrictedPage(<DispatchNoteDetail />, [ACCESS_CODE_DISPATCH_NOTES])}
            />
            {/* Purchase */}
            <ProtectedRoute
              exact
              path={PURCHASE_ORDER_RECEIVING_URL}
              component={getRestrictedPage(<PoReceiving />, [ACCESS_CODE_STOCK_RECEIVING])}
            />
            <ProtectedRoute
              exact
              path={PURCHASES_URL}
              component={getRestrictedPage(<PoList />, [ACCESS_CODE_PURCHASE_ORDERS])}
            />
            <ProtectedRoute
              exact
              path={PURCHASE_ORDER_DETAIL_URL}
              component={getRestrictedPage(<PoDetail />, [ACCESS_CODE_PURCHASE_ORDERS])}
            />

            {/* Stock */}
            <ProtectedRoute
              exact
              path={ITEM_LOCATIONS_URL}
              component={getRestrictedPage(<ItemLocations />, [ACCESS_CODE_WAREHOUSES])}
            />
            <ProtectedRoute
              exact
              path={STOCK_TAKE_URL}
              component={getRestrictedPage(<StockTakeList />, [ACCESS_CODE_STOCK_TAKES])}
            />
            <ProtectedRoute
              exact
              path={STOCK_TAKE_DETAIL_URL}
              component={getRestrictedPage(<StockTakeDetail />, [ACCESS_CODE_STOCK_TAKES])}
            />
            <ProtectedRoute
              exact
              path={BULK_STOCK_TAKE_URL}
              component={getRestrictedPage(<BulkStockTake />, [ACCESS_CODE_STOCK_TAKES])}
            />
            <ProtectedRoute
              exact
              path={STOCK_TRANSFER_URL}
              component={getRestrictedPage(<StockTransfer />, [ACCESS_CODE_STOCK_TRANSFER])}
            />

            <ProtectedRoute exact path={ITEM_LOCATION_CURRENT_MACHINE_URL} component={ItemLocationCurrentMachine} />
            <ProtectedRoute exact path={FINISHING_SHIFT_URL} component={FinishingShiftPage} />
          </Switch>
        </Router>
      </Provider>
    </>
  );
};
