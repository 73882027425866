import React, { useState, useEffect, useCallback } from 'react';
import { Controller, Control } from 'react-hook-form';
import Select from '@atlaskit/select';
import type { ValueType } from '@atlaskit/select/types';
import { iOption } from './types';
import iFormFieldValueSet from '../../../types/form/iFormFieldValueSet';

const initialOptions: Array<iOption> = [];
const SelectWithOptions = ({
  id,
  placeholder,
  rawDefaultValue,
  defaultValue,
  control,
  onChange,
  selectOptions,
  isMulti,
  isCompulsory = false,
}: {
  id: string;
  placeholder?: string;
  rawDefaultValue?: Array<iFormFieldValueSet>;
  defaultValue?: string | Array<string>;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  selectOptions: Array<iOption>;
  isMulti?: boolean;
  isCompulsory?: boolean;
}) => {
  const [value, setValue] = useState<ValueType<iOption>>();
  const [options, setOptions] = useState(initialOptions);

  const getDefaultSelected = useCallback(
    (values: Array<string>) =>
      values.map(option => {
        const targetOptionArr = selectOptions.filter(o => o.value === option);
        return targetOptionArr.length === 1 ? targetOptionArr[0] : [];
      }),
    [selectOptions],
  );

  useEffect(() => {
    setOptions(selectOptions);
    // Set default value from one of the options
    // eslint-disable-next-line no-nested-ternary
    const parsedDefaultValue = !defaultValue
      ? []
      : // default value can be a string/an array
      Array.isArray(defaultValue)
      ? getDefaultSelected(defaultValue)
      : getDefaultSelected([defaultValue]);
    setValue(parsedDefaultValue as unknown as ValueType<iOption>);
  }, [defaultValue, selectOptions, getDefaultSelected]);

  // isMulti validation not done
  const handleValueChange = (selected: iOption | Array<iOption>) => {
    if (!Array.isArray(selected)) {
      onChange(id, [{ value: (selected as iOption).value }], {
        shouldValidate: true,
      });
    } else {
      const selectedItems = selected as Array<iOption>;
      onChange(
        id,
        selectedItems.map((selectedItem: iOption) => ({
          value: selectedItem.value,
        })),
        { shouldValidate: true },
      );
    }
  };

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={rawDefaultValue}
      rules={{
        validate: val =>
          isCompulsory ? (val && Array.isArray(val) && val.length > 0) || 'must select one item' : true,
      }}
      render={() => (
        <Select
          value={value}
          //  eslint-disable-next-line
          onChange={(selected: any) => handleValueChange(selected)}
          placeholder={placeholder}
          isMulti={isMulti}
          options={options}
        />
      )}
    />
  );
};

export default SelectWithOptions;
