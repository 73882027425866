import React from 'react';
import iAddress from '../../../../../types/contactCompany/iAddress';
import { assembleAddress } from '../../../../../services/UtilsService';
import { SpaceVerticalWrapper } from '../../../../../shared/styles/styles';

const DN_ADDRESS = 'Current shipping address:';
const SO_ADDRESS = "The product's shipping address:";

export type iCompareAddressInfoProps = {
  DNWording?: string;
  SOWording?: string;
  DNShippingAddress?: iAddress;
  SOShippingAddress?: iAddress;
};
/**
 * if the conditions become more complex, try to use composition modal to refactor
 * like getDNPart(),getSOPart()
 */
const CompareAddressInfo = ({
  DNWording = DN_ADDRESS,
  SOWording = SO_ADDRESS,
  DNShippingAddress,
  SOShippingAddress,
}: iCompareAddressInfoProps) => {
  return (
    <>
      <span data-testid={'dn-title'}>{DNWording}</span>
      <div data-testid={'dn-address'}>{assembleAddress(DNShippingAddress)}</div>
      <SpaceVerticalWrapper className={'x-small'} />
      <span data-testid={'so-title'}>{SOWording}</span>
      <div data-testid={'so-address'}>{assembleAddress(SOShippingAddress)}</div>
    </>
  );
};

export default CompareAddressInfo;
