import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getBomAttributeByName = (name: string) => {
  return AppService.get('/bomItemAttribute', { filter: `name:${name}` }).then(({ data }) => data);
};

const getBomAttributeDetail = (id: string) => {
  return AppService.get(`/bomItemAttribute/${id}`).then(({ data }) => data);
};

const getBomItemAttributes = (config?: iParams) => {
  return AppService.get('/bomItemAttribute', config).then(({ data }) => data);
};

const updateBomAttributeValue = (id: string, updateContent: { [key: string]: string | boolean | number }) => {
  return AppService.put(`/bomItemAttributeValue/${id}`, updateContent).then(({ data }) => data);
};

export default {
  getBomAttributeByName,
  updateBomAttributeValue,
  getBomItemAttributes,
  getBomAttributeDetail,
};
