import moment from 'moment';
import React from 'react';
import JobService from '../../services/JobService';
import { handleNullException, numberRound, numberToPercentage } from '../../services/UtilsService';
import iJob from '../../types/job/iJob';
import iJobAttribute from '../../types/job/iJobAttribute';
import iJobShift from '../../types/job/iJobShift';
import { apiErrorToast } from '../toast/ToastHandler';
import { LabelValuePair } from './jobDetail/types/LabelValuePair';
import { iJobShiftWithJobShiftJobId } from '../../redux/reduxers/shift.slice';
import FormService from '../../services/FormService';
import iBomItemAttribute from '../../types/product/iBomItemAttribute';
import iBomItem from '../../types/product/iBomItem';
import iBomItemAttributeValue from '../../types/product/iBomItemAttributeValue';
import { LabelValue } from '../styles/styles';
import { BOM_ATTRIBUTE_HOPPER } from '../constants/JobListColumns';
import {
  VALIDATION_ERROR_CODE_INVALID_JOBSHIFTJOB,
  VALIDATION_ERROR_STATUS_INVALID_JOBSHIFTJOB,
} from './jobProgress/constants';

export const roundUpJobAttribute = (jAttr: iJobAttribute) => {
  // jAttr.type null/undefined
  if (!jAttr.type) return jAttr.jobAttributeValues.value;
  // integer
  if (jAttr.type === 'integer') return numberRound(jAttr.jobAttributeValues.value, 0);
  // float(1)
  if (jAttr.type.includes('float')) {
    const regex = /^float\((\d)\)$/;
    const decimal = jAttr.type.match(regex);
    if (!decimal) return jAttr.jobAttributeValues.value;
    return numberRound(jAttr.jobAttributeValues.value, Number(decimal[1]));
  }
  return jAttr.jobAttributeValues.value;
};
export const mapJobAttributes = (job: iJob) => {
  const extraJobAttributes: LabelValuePair[] = job.jobAttributes
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((jAttr: iJobAttribute) => ({
      label: jAttr.name,
      value: roundUpJobAttribute(jAttr) || '',
      prefix: jAttr.jobAttributeValues.prefix,
      postfix: jAttr.jobAttributeValues.postfix,
    }));
  const jobAttributes = [
    { label: 'Product', value: job.product.productCode },
    {
      label: 'Qty to produce',
      value: numberRound(job.qtyToMake, 0) || '',
      postfix: job.product.measurement.name,
    },
    {
      label: 'Pallet System',
      value: job.palletSystem ? job.palletSystem.name : '',
    },
    {
      label: 'Wrapping Method',
      value: job.wrappingMethod ? job.wrappingMethod.name : '',
    },
    {
      label: 'Scheduled Date',
      value: job.scheduledAt ? moment(job.scheduledAt).format('Do MMM YYYY') : '',
    },
    ...extraJobAttributes,
  ];
  return jobAttributes;
};
export const getRollNumber = (jobAttributes: Array<LabelValuePair>) => {
  const rollsAttr = jobAttributes.find((item: LabelValuePair) => item.label.toUpperCase() === 'ROLL(S)');
  if (!rollsAttr) return 0;
  const convert = Number(rollsAttr.value.split(' ')[0]);
  return Number.isNaN(convert) ? 0 : convert;
};

const onExecuteJob = async (
  id: string,
  isStart: boolean,
  shift?: iJobShiftWithJobShiftJobId,
  actionAfter?: (newShift: iJobShiftWithJobShiftJobId, hasContinued: boolean) => void,
) => {
  try {
    if (isStart) {
      await JobService.startJob(id);
    }
    let newShift = shift;
    // no shift exist,need to start one
    if (!shift) {
      newShift = await JobService.startJobShift(id);
    }
    if (!newShift) {
      return;
    }
    const currentJobShiftJob = await JobService.createJobShiftJob(newShift.id, id);
    const hasContinued = await FormService.isJobShiftJobContinued(currentJobShiftJob.id);

    if (typeof actionAfter === 'function') {
      actionAfter(
        {
          ...newShift,
          currentJobShiftJobId: currentJobShiftJob.id,
        },
        hasContinued,
      );
    }
  } catch (error) {
    apiErrorToast(error);
    // addToastForAPIResponse('error');
  }
};

export const onContinueJob = async (
  jobId: string,
  dispatchShift: (newShift: iJobShift) => void,
  shift?: iJobShift,
  actionAfter?: () => void,
) => {
  try {
    // no shift exist,need to start one
    if (!shift) {
      const startShift = await JobService.startJobShift(jobId);
      dispatchShift(startShift);
    } else {
      await JobService.createJobShiftJob(shift.id, jobId);
    }
    if (typeof actionAfter === 'function') {
      actionAfter();
    }
  } catch (error) {
    apiErrorToast(error);
    // addToastForAPIResponse('error');
  }
};

export const getBomItemAttrHeads = (cluster: Array<iBomItemAttribute>) =>
  cluster
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((item: iBomItemAttribute) => ({
      key: item.name.toLowerCase(),
      content: item.name,
      isSortable: true,
    }));

export const getBomItemAttrContent = (bomItemAttr: iBomItemAttribute, bomItemAttrValue?: iBomItemAttributeValue) => {
  const isHopper = bomItemAttr.name.toUpperCase() === BOM_ATTRIBUTE_HOPPER;
  return isHopper ? (
    <LabelValue>{bomItemAttrValue?.value}</LabelValue>
  ) : (
    <LabelValue>{numberToPercentage(bomItemAttrValue?.value, 2)}</LabelValue>
  );
};

export const getBomItemAttrRows = (item: iBomItem, bomItemAttributes: iBomItemAttribute[]) =>
  bomItemAttributes.map((attr: iBomItemAttribute) => {
    const bomItemAttrValue = item.bomItemAttributes?.find(
      (b: iBomItemAttribute) => b.id === attr.id,
    )?.bomItemAttributeValues;
    return {
      key: handleNullException(bomItemAttrValue, 'value'),
      content: getBomItemAttrContent(attr, bomItemAttrValue),
    };
  });

export const getOrderType = (orderType: string | null) => {
  if (orderType === 'ASC' || orderType === 'DESC') {
    return orderType;
  }
  return undefined;
};

export const getSortKey = (sortKeyFromStorage: string | null) => {
  if (!sortKeyFromStorage) return '';
  return sortKeyFromStorage;
};

//  eslint-disable-next-line
export const isJobShiftJobError = (error: any) => {
  if (
    handleNullException(error, 'response.data.code').toString() === VALIDATION_ERROR_CODE_INVALID_JOBSHIFTJOB &&
    handleNullException(error, 'response.data.status').toString() === VALIDATION_ERROR_STATUS_INVALID_JOBSHIFTJOB
  ) {
    return true;
  }

  return false;
};
export const getBomItemsHead = (bomItemAttributes: Array<iBomItemAttribute>) => {
  return {
    cells: [
      { key: 'sku', content: 'SKU / Product Code', isSortable: true },
      ...getBomItemAttrHeads(bomItemAttributes),
      { key: 'percentage', content: 'Percentage', isSortable: true },
      {
        key: 'needAmount',
        content: 'Need Amount',
        isSortable: true,
        testId: 'bomItem-head-alignRight',
      },
      {
        key: 'totalAmount',
        content: 'Total Amount',
        isSortable: true,
        testId: 'bomItem-head-alignRight',
      },
    ],
    testId: 'bomItems-head',
  };
};

export default {
  roundUpJobAttribute,
  mapJobAttributes,
  getRollNumber,
  onExecuteJob,
  onContinueJob,
  getBomItemAttrHeads,
  getBomItemAttrRows,
  getOrderType,
  getSortKey,
  isJobShiftJobError,
  getBomItemsHead,
};
