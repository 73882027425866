import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { COLLAPSE_TITLE, TODO_TITLE } from './DynamicManufactureRows.constants';

const RowNumberWithTransition = ({ rowNumber, isCollapse }: { rowNumber: number; isCollapse: boolean }) => {
  return (
    <div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          classNames="fade"
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          key={rowNumber}
        >
          <span className={isCollapse ? 'collapse-rows' : 'show-rows'}>{rowNumber}</span>
        </CSSTransition>
      </SwitchTransition>
      {` ${isCollapse ? COLLAPSE_TITLE : TODO_TITLE}`}
    </div>
  );
};

export default RowNumberWithTransition;
