import React, { useState } from 'react';
import styled from 'styled-components';
import InlineEdit from '@atlaskit/inline-edit';
import { ErrorMessage } from '@atlaskit/form';
import { DatePicker } from '@atlaskit/datetime-picker';
import { formatDate } from '../../services/UtilsService';
import InlineEditReadView from './InlineEditReadView';

type iState = {
  value: string;
  focused: boolean;
};

const DivWrapper = styled.div``;
const InlineEditDatePicker = ({
  name,
  defaultValue,
  label,
  onConfirm,
  isRequired = false,
  testId = 'inline-edit-date-picker',
  isDisabled = false,
}: {
  defaultValue?: string;
  label: string;
  name: string;
  onConfirm?: (name: string, newValue: string) => void;
  isRequired?: boolean;
  testId?: string;
  isDisabled?: boolean;
}) => {
  const initialState: iState = {
    value: defaultValue || '',
    focused: false,
  };
  const [state, setState] = useState(initialState);

  // eslint-disable-next-line
  const changeValue = (value: any) => {
    // value is null or is default value
    // don't change it
    if (!value || value === defaultValue) {
      setState({
        ...state,
        focused: false,
      });
      return;
    }
    setState(preState => ({
      ...preState,
      value,
      focused: false,
    }));
    if (typeof onConfirm === 'function') {
      onConfirm(name, value);
    }
  };
  const validate = (value?: string) => {
    // isRequired
    if (isRequired && !value) {
      return 'is required';
    }
    return undefined;
  };

  return (
    <DivWrapper
      id={name}
      tabIndex={0}
      onFocus={() => setState(preState => ({ ...preState, focused: true }))}
      data-testid={`div-wrapper-${testId}`}
    >
      <InlineEdit
        label={label}
        onConfirm={changeValue}
        editView={({ errorMessage, ...fieldProps }) => (
          <>
            <DatePicker
              // eslint-disable-next-line
              {...fieldProps}
              dateFormat="Do MMM YYYY"
              testId={testId}
              placeholder={'select a date...'}
              isDisabled={isDisabled}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        defaultValue={state.value}
        readView={() => (
          <InlineEditReadView isDisabled={isDisabled} value={formatDate(state.value, 'Do MMM YYYY')} name={name} />
        )}
        isEditing={!isDisabled && state.focused}
        onEdit={() => setState({ ...state, focused: true })}
        onCancel={() => setState({ ...state, focused: false })}
        validate={validate}
      />
    </DivWrapper>
  );
};
export default InlineEditDatePicker;
