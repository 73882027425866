import styled from 'styled-components';

export const ModalPageWrapper = styled.div`
  box-sizing: border-box;
  padding: 1rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 510;
  background-color: white;
  opacity: 1;
  display: flex;
  flex-direction: column;
  text-align: -webkit-auto;
  .modal-header,
  .modal-footer {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .modal-header {
    padding: 0 2px 1rem 2px;
  }
  .modal-footer {
    padding: 1rem 2px 0 2px;
  }
  .modal-body {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
  }
`;
