// export const initialDetailState: iDetailState<T> = {
//   isLoading: false,
//   isConfirming: false,
// };
export interface iState<T> {
  data?: T;
  isLoading: boolean;
  isConfirming: boolean;
}

export enum ActionKind {
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Confirming = 'CONFIRMING',
  Confirmed = 'CONFIRMED',
  Updated = 'UPDATED',
}

export type iAction<T> = {
  type: ActionKind;
  payload: {
    //  eslint-disable-next-line
    updateContent?: any;
    // fieldName?: string;
    // //  eslint-disable-next-line
    // fieldValue?: any;
    initial?: T;
  };
};

export const reducer = <T extends { id: string }>(state: iState<T>, action: iAction<T>) => {
  switch (action.type) {
    case ActionKind.Loading:
      return { ...state, isLoading: true };
    case ActionKind.Confirming:
      return { ...state, isConfirming: true };
    case ActionKind.Confirmed:
      return { ...state, isConfirming: false };
    case ActionKind.Loaded:
      return {
        ...state,
        data: action.payload.initial,
        isLoading: false,
        isConfirming: false,
      };
    case ActionKind.Updated:
      return typeof action.payload.updateContent !== 'undefined' && typeof state.data !== 'undefined'
        ? {
            ...state,
            data: {
              ...state.data,
              ...action.payload.updateContent,
            },
            isLoading: false,
            isConfirming: false,
          }
        : state;
    default:
      return state;
  }
};
