import React from 'react';
import styled from 'styled-components';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { N0, R400 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';

const Wrapper = styled.div`
  > div[data-testid='error-flag'] {
    background-color: #de350b;
    div[data-testid='error-flag-description'],
    > div > span:last-child {
      color: #ffffff;
    }
  }
`;
const ErrorFlag = ({ title }: { title: string }) => (
  <Wrapper>
    <Flag
      // appearance="error"
      icon={<ErrorIcon label="error" primaryColor={N0} secondaryColor={R400} testId={'error-icon'} />}
      id="error"
      key="error"
      title={'Ops, something went wrong'}
      description={title}
      testId={'error-flag'}
    />
  </Wrapper>
);

export default ErrorFlag;
