import React from 'react';
import { Label } from '@atlaskit/field-base';
import LabelValue from './styles/LabelValue';
import InlineBlock from './styles/InlineBlock';

const HorizontalLabel = ({ label, value }: { label: string; value: string }) => (
  <InlineBlock data-testid={'out-inline-block'}>
    <InlineBlock data-testid={'inner-inline-block'}>
      <Label label={label} />
    </InlineBlock>
    <LabelValue>{value}</LabelValue>
  </InlineBlock>
);

export default HorizontalLabel;
