import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import CustomizedLabel from '../../hookForms/CustomizedLabel';
import InputWithController from '../../hookForms/InputWithController';
import { iKeyValuePairs } from '../../UITypes/types';
import { handleNullException } from '../../../services/UtilsService';
import { FlexContainer, SpaceHorizontalWrapper, SpaceVerticalWrapper } from '../../styles/styles';

const CoreLabelPrintBody = ({
  control,
  onChange,
  errors,
  watch,
  defaultValues,
}: {
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors: DeepMap<Record<string, any>, FieldError>;
  // eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  defaultValues?: iKeyValuePairs;
}) => {
  const rollNumber = handleNullException(defaultValues, 'rollNumber');
  return (
    <div>
      <SpaceVerticalWrapper className={'x-small'} />
      <CustomizedLabel label={'Roll Number Range:'} />
      <FlexContainer className={'flex-start'}>
        <InputWithController
          control={control}
          errors={errors}
          onChange={onChange}
          defaultValue={1}
          name={'start'}
          label={'Roll number from'}
          isRequired
          isNumeric
          type={'number'}
          range={{ min: 1, max: watch('end') || rollNumber }}
          testId={'start'}
        />
        <SpaceHorizontalWrapper className={'x-small'} />
        <InputWithController
          control={control}
          errors={errors}
          onChange={onChange}
          defaultValue={rollNumber}
          name={'end'}
          label={'Roll number to'}
          isRequired
          isNumeric
          type={'number'}
          range={{ min: watch('start') || 1, max: rollNumber }}
          testId={'end'}
        />
      </FlexContainer>
    </div>
  );
};

export default CoreLabelPrintBody;
