import React from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import { PageContainer, PageHeader, PageBody, PageFooter } from './style';

/*
box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.2);
*/
const JobProgressPage = ({
  submitHandler,
  closeHandler,
  extraHandler,
  heading,
  bottom,
  isMiddlePage,
  isDisabled = false,
  disabledReason = 'Invalid value detected',
  children,
  isConfirming = false,
}: {
  submitHandler: () => void;
  closeHandler: () => void;
  extraHandler?: React.ReactNode;
  heading: string;
  bottom: string;
  isMiddlePage?: boolean;
  children: React.ReactNode;
  isConfirming?: boolean;
  isDisabled?: boolean;
  disabledReason?: string;
}) => {
  const CloseButton = () => {
    return (
      <ButtonGroup>
        {isDisabled && <span className={'invalid-value-detected'}>{disabledReason}</span>}
        <LoadingButton onClick={submitHandler} appearance="primary" isDisabled={isDisabled} isLoading={isConfirming}>
          Save
        </LoadingButton>
        <Button onClick={closeHandler}>X</Button>
      </ButtonGroup>
    );
  };
  return (
    <PageContainer isMiddlePage={isMiddlePage} className={'job-progress-page-container'}>
      <div className={'job-progress-page-content'} data-testid={'job-progress-page-content'}>
        <PageHeader className={'job-progress-page-header'}>
          <h4>{heading}</h4>
          <CloseButton />
        </PageHeader>
        <PageBody className={'job-progress-page-body'}>{children}</PageBody>
        <PageFooter className={'job-progress-page-footer'}>
          <div>{bottom}</div>
          <ButtonGroup>
            {isDisabled && <span className={'invalid-value-detected'}>{disabledReason}</span>}
            <LoadingButton
              onClick={submitHandler}
              appearance="primary"
              isDisabled={isDisabled}
              isLoading={isConfirming}
            >
              Save
            </LoadingButton>

            {extraHandler}
            <Button onClick={closeHandler}>Cancel</Button>
          </ButtonGroup>
        </PageFooter>
      </div>
    </PageContainer>
  );
};

export default JobProgressPage;
