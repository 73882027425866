import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import BackendPage from '../../../layout/BackendPage';
import PurchaseOrderService from '../../../services/PurchaseOrderService';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/ToastHandler';
import iPurchaseOrderDetail from '../../../types/purchaseOrder/iPurchaseOrderDetail';
import { FlexContainer, FlexSpaceBetweenContainer, PageSubtitle, PageTitle } from '../../../shared/styles/styles';
import SearchBar from '../../../shared/search/SearchBar';
import PoListTable from './PoListTable';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import InputWithController from '../../../shared/hookForms/InputWithController';
import ModalPage from '../../../shared/modalPage/ModalPage';
import useStatusSelector from '../../../shared/hooks/useStatusSelector';
import EntityStatusCategoryService from '../../../services/EntityStatusCategoryService';
import PoReceivingService from '../../../services/PoReceivingService';
import useColumnHooksUpgrade from '../../../shared/hooks/useShowHideColumnHook/useColumnHooksUpgrade';
import { PO_COLUMNS, PO_STATUS_WIP_CATEGORY } from '../constants';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_RECEIVING } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';

const PoList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [localPOs, setLocalPOs] = useState<iPurchaseOrderDetail[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [sortStr, setSortStr] = useState('eta:ASC');
  const [keyword, setKeyword] = useState('');
  const [isReceivingModalOpen, setIsReceivingModalOpen] = useState(false);
  const [targetPoId, setTargetPoId] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);

  const { control, setValue, errors, handleSubmit } = useForm();
  const history = useHistory();
  const { user } = useSelector((s: RootState) => s.auth);
  const canReceiveStock = AccessService.hasAccess(ACCESS_CODE_STOCK_RECEIVING, ACCESS_CAN_UPDATE, user);

  const { columns, getShowHideColumns } = useColumnHooksUpgrade('purchaseOrder', PO_COLUMNS);

  const perPage = 10;

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const PoWithPagination = await PurchaseOrderService.getPoList({
          perPage,
          currentPage,
          like: `orderNumber:${keyword},supplier.name:${keyword}`,
          filter: `status.entityStatusCategory.code:${PO_STATUS_WIP_CATEGORY}`,
          sort: `${sortStr}`,
        });

        if (!mounted) return;
        setLocalPOs([...PoWithPagination.data]);
        setTotal(PoWithPagination.total);
        setCurrentPage(PoWithPagination.currentPage);
        setIsLoading(false);
      } catch (e) {
        if (!mounted) return;
        addToastForAPIResponse('error');
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [perPage, currentPage, sortStr, keyword]);

  const { categories } = useStatusSelector({
    type: 'PurchaseOrder',
    isMulti: true,
    getFn: EntityStatusCategoryService.getEntityCategoryList,
  });

  const getPageHeader = () => (
    <FlexSpaceBetweenContainer>
      <div>
        <PageTitle className={'median'}>Purchase Orders</PageTitle>
        <PageSubtitle>List of {total} open purchase order(s) waiting for receiving</PageSubtitle>
      </div>
      <div style={{ flexGrow: 0.1 }}>
        <SearchBar
          onSearch={(value: string) => setKeyword(value)}
          keyword={keyword}
          placeholder={'Search order number or supplier...'}
        />
      </div>
    </FlexSpaceBetweenContainer>
  );

  const onChangeCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const onSetSort = (sortString: string) => {
    setCurrentPage(1);
    setSortStr(sortString);
  };

  const onReceiving = (id: string) => {
    setTargetPoId(id);
    setIsReceivingModalOpen(true);
  };

  const onCreateReceivingPo = async (data: { [key: string]: string }) => {
    try {
      setIsConfirming(true);
      const created = await PoReceivingService.createPoReceiving({
        reference: data.docketNumber,
        purchaseOrderId: targetPoId,
      });
      setIsConfirming(false);
      history.push(`/b/purchases/receiving/${created.id}`);
    } catch (e) {
      setIsConfirming(false);
      apiErrorToast(e);
    }
  };

  const getReceivingModalBody = () => (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'docketNumber'}
            label={'Docket Number'}
            defaultValue={''}
            control={control}
            onChange={setValue}
            errors={errors}
            isRequired
            testId={'po-receiving-docketNumber'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );

  return isReceivingModalOpen ? (
    <ModalPage
      heading={'Receiving PO'}
      onConfirm={handleSubmit(onCreateReceivingPo)}
      onCancel={() => setIsReceivingModalOpen(false)}
      confirmBtnName="Create"
      confirmBtnAppearance="primary"
      isConfirming={isConfirming}
    >
      {getReceivingModalBody()}
    </ModalPage>
  ) : (
    <BackendPage pageHeader={getPageHeader()}>
      <FlexContainer className={'space-between'}>
        <div />
        {getShowHideColumns()}
      </FlexContainer>
      <PoListTable
        isLoading={isLoading}
        list={localPOs}
        testId={'purchase-order-list-table'}
        onSetSort={onSetSort}
        onReceiving={onReceiving}
        columns={columns.filter(col => {
          if (col.key === 'operation' && !canReceiveStock) {
            return false;
          }
          return true;
        })}
        categories={categories}
      />
      <CustomizePagination
        range={Math.ceil(total / perPage)}
        onChange={onChangeCurrentPage}
        currentPage={currentPage}
      />
    </BackendPage>
  );
};

export default PoList;
