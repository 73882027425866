import React from 'react';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { CursorPointerWrapper, ThumbnailWrapper } from '../styles/styles';

const LoadMoreThumbnail = ({ onClick, size = 'normal' }: { onClick: () => void; size?: string }) => {
  return (
    <ThumbnailWrapper className={`load-more ${size === 'small' ? 'small-size' : ''}`} data-testid={'thumbnail-wrapper'}>
      <CursorPointerWrapper onClick={onClick} className={'cursor-pointer'} data-testid={'cursor-point-wrapper'}>
        <MoreIcon label={'more'} testId={'load-more-icon'} />
      </CursorPointerWrapper>
    </ThumbnailWrapper>
  );
};

export default LoadMoreThumbnail;
