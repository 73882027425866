import styled from 'styled-components';

const LeftBtn = styled.div`
  color: #737373;
  position: absolute;
  bottom: 0;
  left: 0;
  background: white;
  opacity: 0.8;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
`;
export default LeftBtn;
