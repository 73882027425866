import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import { ButtonItem, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { iSubMenu } from '../UITypes/types';
import RestrictedAccessDiv from '../UserAccess/RestrictedAccessDiv';

type iState = {
  isOpen: boolean;
};
const initialState: iState = {
  isOpen: false,
};
const PopupWrapper = styled.div``;

const TopNavPopup = ({ name, subMenu }: { name: string; subMenu: iSubMenu[] }) => {
  const [state, setState] = useState(initialState);
  const history = useHistory();

  const showPopup = (isOpen: boolean) => {
    setState({
      ...state,
      isOpen,
    });
  };

  const getMenuItems = () => {
    return (
      <PopupWrapper data-testid={`nav-${name}`}>
        {subMenu.map((sub: iSubMenu, index: number) => (
          <RestrictedAccessDiv
            expectedUserAccessCodes={sub.accessCodes}
            expectedCodeMatchedWithAny
            hideWhenDenied
            key={`${name}-${sub.label}`}
          >
            <Section hasSeparator={index !== 0 && sub.hasSeparator}>
              <ButtonItem onClick={() => history.push(sub.value)} testId={`nav-${name}-${sub.label}`}>
                {sub.label}
              </ButtonItem>
            </Section>
          </RestrictedAccessDiv>
        ))}
      </PopupWrapper>
    );
  };

  return (
    <Popup
      isOpen={state.isOpen}
      placement={'bottom-start'}
      content={getMenuItems}
      onClose={() => showPopup(false)}
      trigger={(
        triggerProps: any, //eslint-disable-line
      ) => (
        <PrimaryDropdownButton
          {...triggerProps} //eslint-disable-line
          tooltip={''}
          onClick={() => showPopup(!state.isOpen)}
          testId={`nav-${name}-popup-trigger`}
        >
          {name}
        </PrimaryDropdownButton>
      )}
    />
  );
};
export default TopNavPopup;
