import { iJobSummaryTableRow, iJobSummaryTableHead } from './types';

const createRow = (row: iJobSummaryTableRow, heads: Array<iJobSummaryTableHead>, rowIndex: number) => {
  const rowCells = heads.map((head: iJobSummaryTableHead) => {
    let content = null;
    switch (head.name) {
      case 'jobNumber':
        content = row.jobNumber;
        break;
      case 'product':
        content = row.product;
        break;
      case 'produced':
        content = row.produced;
        break;
      case 'waste':
        content = row.waste;
        break;
      case 'status':
        content = row.status;
        break;
      default:
        break;
    }
    return { content };
  });
  return {
    key: `${rowIndex}`,
    cells: rowCells,
  };
};

export const jobSummaryRows = (rows: Array<iJobSummaryTableRow>, heads: Array<iJobSummaryTableHead>) => {
  return rows.map((row: iJobSummaryTableRow, rowIndex: number) => createRow(row, heads, rowIndex));
};
