import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import SelectWithOptions from './SelectWithOptions';
import SelectWithApi from './SelectWithApi';
import { iOption, iApiAttr } from './types';
import iFormFieldValueSet from '../../../types/form/iFormFieldValueSet';
import { ErrorMessageContainer, ErrorMsg } from '../styleContainer';

const SelectWrapper = styled.div`
  min-width: 200px;
`;
const DynamicSelect = ({
  id,
  placeholder,
  defaultValue,
  control,
  onChange,
  errors,
  selectOptions,
  api,
  isMulti,
  isCompulsory = false,
}: {
  id: string;
  placeholder?: string;
  defaultValue?: Array<iFormFieldValueSet>;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  selectOptions?: Array<iOption>;
  api?: iApiAttr;
  isMulti?: boolean;
  isCompulsory?: boolean;
}) => {
  const convertedDefaultValue = (formFieldValue?: Array<iFormFieldValueSet>) => {
    return (
      formFieldValue && formFieldValue.map((formFieldValueSet: iFormFieldValueSet) => formFieldValueSet.value as string)
    );
  };
  const commonProps = {
    id,
    control,
    onChange,
    rawDefaultValue: defaultValue,
    defaultValue: convertedDefaultValue(defaultValue),
    isMulti,
    placeholder,
    errors,
    isCompulsory,
  };
  return (
    <SelectWrapper
      data-testid={'dynamic-select'}
      className={`dynamic-select-container ${_.get(errors, id) && 'validation-error'}`}
    >
      {selectOptions ? (
        <SelectWithOptions
          // eslint-disable-next-line
          {...commonProps}
          selectOptions={selectOptions}
        />
      ) : (
        <SelectWithApi
          // eslint-disable-next-line
          {...commonProps}
          api={api}
        />
      )}
      {_.get(errors, id) && (
        <ErrorMessageContainer>
          <ErrorMsg data-testid={'error-msg'}>{_.get(errors, id).message}</ErrorMsg>
        </ErrorMessageContainer>
      )}
    </SelectWrapper>
  );
};

export default DynamicSelect;
