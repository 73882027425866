import React from 'react';
import { useTabPanel } from '@atlaskit/tabs';
import { CustomTabPanelWrapper } from './JobTabs.style';

const CustomTabPanel = ({ children }: { children: React.ReactNode }) => {
  const tabPanelAttributes = useTabPanel();

  return (
    //    eslint-disable-next-line
    <div {...tabPanelAttributes}>
      <CustomTabPanelWrapper data-testid={'CustomTabPanelWrapper'}> {children}</CustomTabPanelWrapper>
    </div>
  );
};
export default CustomTabPanel;
