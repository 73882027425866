import React, { useState } from 'react';
import styled from 'styled-components';
import { LoadingButton } from '@atlaskit/button';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { apiErrorToast } from '../../../../shared/toast/ToastHandler';

const BtnTransparentWrapper = styled.div`
  display: flex;
  .transparent-button {
    background-color: transparent;
  }
`;

const DeleteButton = ({
  icon = <TrashIcon label="delete" />,
  onDelete,
}: {
  icon?: React.ReactChild;
  onDelete?: () => Promise<void> | void;
}) => {
  const [state, setState] = useState(false);
  const handleOnClick = async () => {
    if (typeof onDelete !== 'function') {
      return;
    }
    setState(true);
    try {
      await onDelete();
      setState(false);
    } catch (error) {
      apiErrorToast(error);
      setState(false);
    }
  };
  return (
    <BtnTransparentWrapper>
      <LoadingButton
        iconBefore={icon}
        isLoading={state}
        onClick={handleOnClick}
        className={'transparent-button'}
        testId={'delete-button'}
      />
    </BtnTransparentWrapper>
  );
};

export default DeleteButton;
