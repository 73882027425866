import React from 'react';
import styled from 'styled-components';
import iJobAttribute from '../../types/job/iJobAttribute';
import CoreLabelPrint from '../print/coreLabelPrint/CoreLabelPrint';
import RollPrint from '../print/rollLabelPrint/RollPrint';
import { FlexContainer } from '../styles/styles';

type iJobListPrintDropDown = {
  jobId: string;
  jobAttributes: iJobAttribute[];
  productId: string;
};

const Wrapper = styled.div``;
const JobListPrintDropDown = ({ jobId, jobAttributes, productId }: iJobListPrintDropDown) => {
  return (
    <Wrapper>
      <FlexContainer className={'with-gap small'}>
        <CoreLabelPrint jobId={jobId} jobAttributes={jobAttributes} productId={productId} />
        <RollPrint jobId={jobId} jobAttributes={jobAttributes} productId={productId} />
      </FlexContainer>
    </Wrapper>
  );
};

export default JobListPrintDropDown;
