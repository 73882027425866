import React, { useState } from 'react';
import _ from 'lodash';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { iImage } from './ImageTypes';
import DynamicImageUploader from './DynamicImageUploader';
import iFormFieldValueSet from '../../../types/form/iFormFieldValueSet';
import { ErrorMsg } from '../styleContainer';

const DynamicPhoto = ({
  id,
  control,
  defaultValue,
  onChange,
  errors,
  isCompulsory = false,
}: {
  id: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: Array<iFormFieldValueSet>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  isCompulsory?: boolean;
}) => {
  const [isUploading, setIsUpLoading] = useState(false);
  const handleValueChange = (images: Array<iImage>) => {
    setIsUpLoading(!!images.find((i: iImage) => i.uploading));
    const newValue = images.map((image: iImage) => ({ value: image.url }));
    onChange(id, newValue, { shouldValidate: true });
  };
  const parseDefaultValue = () => {
    return defaultValue?.map((formFieldValueSet: iFormFieldValueSet) => formFieldValueSet.value as string);
  };
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || null}
      rules={{
        validate: {
          imageUploading: () => !isUploading || 'Please wait all images uploaded.',
          atLeastOne: value =>
            isCompulsory
              ? (value && Array.isArray(value) && value.length > 0) || 'At least one image is required'
              : true,
        },
      }}
      render={() => (
        <DynamicImageUploader
          defaultValue={parseDefaultValue()}
          onUpdate={(images: Array<iImage>) => {
            setTimeout(() => {
              if (images && images.length > 0) {
                handleValueChange(images);
              } else {
                onChange(id, []);
              }
            }, 0);
          }}
        >
          {_.get(errors, id) && <ErrorMsg data-testid={'error-msg'}>{_.get(errors, id).message}</ErrorMsg>}
        </DynamicImageUploader>
      )}
    />
  );
};

export default DynamicPhoto;
