import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import ModalPage from '../../../../modalPage/ModalPage';
import { CREATE_PO } from '../ServiceProvider.constants';

import { addToastForAPIResponse, apiErrorToast } from '../../../../toast/ToastHandler';
import PurchaseOrderService from '../../../../../services/PurchaseOrderService';
import PurchaseOrderItemService from '../../../../../services/PurchaseOrderItemService';
import { PURCHASES_URL } from '../../../../UrlMap';
import { CreateModalBodyWrapper } from '../ServiceProvider.style';
import CreatePOModalBody from './CreatePOModalBody';

export type iCreatePOServiceProviderProps = {
  jobId: string;
  ccp: iContactCompanyProduct;
  onCancel: () => void;
};
const CreatePOServiceProvider = ({ jobId, ccp, onCancel }: iCreatePOServiceProviderProps) => {
  const history = useHistory();
  const [isConfirming, setIsConfirming] = useState(false);
  //  eslint-disable-next-line
  const { control, setValue, errors, handleSubmit } = useForm({});
  //  eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setIsConfirming(true);
    try {
      const { id } = await PurchaseOrderService.createPurchaseOrder(data);
      await PurchaseOrderItemService.createPOItemConfig({
        orderId: id,
        productId: ccp.productId,
        qty: data.qty,
        jobId,
      });
      addToastForAPIResponse('success');
      setIsConfirming(false);
      history.push(`${PURCHASES_URL}/${id}`);
    } catch (e) {
      apiErrorToast(e);
      setIsConfirming(false);
    }
  };
  return (
    <ModalPage
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onCancel}
      confirmBtnName={'Create'}
      heading={CREATE_PO}
      isConfirming={isConfirming}
      isDisabled={Object.keys(errors).length > 0}
    >
      <CreateModalBodyWrapper>
        <CreatePOModalBody control={control} errors={errors} onChange={setValue} ccp={ccp} />
      </CreateModalBodyWrapper>
    </ModalPage>
  );
};

export default CreatePOServiceProvider;
