import AppService from './AppService';
import LocalStorageService from './LocalStorageService';

const getLocalAuthToken = () => {
  const token = LocalStorageService.getToken();
  return typeof token !== 'string' ? '' : token;
};

const removeLocalAuthToken = () => {
  LocalStorageService.removeToken();
};
const removeLocalColumns = () => {
  LocalStorageService.removeColumns();
};

const login = (username: string, password: string) => {
  return AppService.post('/auth', { username, password }).then(({ data }) => {
    LocalStorageService.setToken(data.token);
    LocalStorageService.setCompanyId(data.user.companyId);
    return { user: data.user, isAuthenticated: true };
  });
};

const logout = () => {
  // return AppService.delete('/logout');
  return AppService.delete('/auth').then(res => {
    removeLocalAuthToken();
    removeLocalColumns();
    LocalStorageService.removeCompanyId();
    return res;
  });
};

const getMe = () => {
  return AppService.get('/auth/getMe').then(({ data }) => {
    return {
      user: data.user,
    };
  });
};

export default {
  getLocalAuthToken,
  removeLocalAuthToken,
  login,
  logout,
  getMe,
};
