import React from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import { gridSize } from '@atlaskit/theme/constants';
import { jobSummaryHead } from './JobSummaryHead';
import { jobSummaryRows } from './JobSummaryRows';
import { iJobSummaryTableRow } from './types';

const TitleWrapper = styled.p`
  font-weight: bold;
  margin: ${gridSize()}px 0px ${gridSize() * 2}px 2px;
`;
const JobSummaryTable = ({ rows, isLoading }: { rows: Array<iJobSummaryTableRow>; isLoading: boolean }) => {
  return (
    <>
      <TitleWrapper>Job Summary for your shift:</TitleWrapper>
      <DynamicTable
        caption={''}
        head={{ cells: jobSummaryHead }}
        rows={jobSummaryRows(rows, jobSummaryHead)}
        isFixedSize
        isLoading={isLoading}
        loadingSpinnerSize={'large'}
      />
    </>
  );
};
export default JobSummaryTable;
