import React, { useState } from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import iBomItem from '../../../../types/product/iBomItem';
import iBomItemAttribute from '../../../../types/product/iBomItemAttribute';
import LocalStorageService from '../../../../services/LocalStorageService';
import ComposeSecondaryText from '../../../text/ComposeSecondaryText';
import { iSortOrderType } from '../../../UITypes/types';
import { getBomItemAttrRows, getBomItemsHead, getOrderType, getSortKey } from '../../Helper';
import { handleNullException, numberToPercentage } from '../../../../services/UtilsService';
import { LabelValue } from '../../../styles/styles';

const BomItemsContainer = styled.div`
  tr[data-testid='bomItems-table--row-undefined'] td:nth-last-child(2),
  tr[data-testid='bomItems-table--row-undefined'] td:nth-last-child(1),
  th[data-testid='bomItem-head-alignRight--head--cell'] {
    text-align: end;
  }
`;
const BomItems = ({
  bomDetails,
  bomItemAttributes,
  isLoading,
}: {
  bomDetails: iBomItem[];
  bomItemAttributes: iBomItemAttribute[];
  isLoading: boolean;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  const tableRows = bomDetails
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map(item => ({
      cells: [
        {
          key: handleNullException(item, 'material.productCode'),
          content: (
            <ComposeSecondaryText secondaryText={handleNullException(item, 'material.name')}>
              <span style={{ fontWeight: 500 }}>{handleNullException(item, 'material.productCode')}</span>
            </ComposeSecondaryText>
          ),
        },
        ...getBomItemAttrRows(item, bomItemAttributes),
        {
          key: numberToPercentage(item.materialQty, 2),
          content: <LabelValue>{numberToPercentage(item.materialQty, 2)}</LabelValue>,
        },
        {
          key: `${handleNullException(item, 'needAmt')} ${item.material.measurement.shortName}`,
          content: (
            <LabelValue>{`${handleNullException(item, 'needAmt')} ${item.material.measurement.shortName}`}</LabelValue>
          ),
          testId: 'align-right-cell',
        },
        {
          key: `${handleNullException(item, 'totalAmt')} ${item.material.measurement.shortName}`,
          content: (
            <LabelValue>{`${handleNullException(item, 'totalAmt')} ${item.material.measurement.shortName}`}</LabelValue>
          ),
        },
      ],
      testId: 'bomItems-row',
    }));

  return (
    <BomItemsContainer>
      <DynamicTable
        head={getBomItemsHead(bomItemAttributes)}
        rows={tableRows}
        testId="bomItems-table"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSort={(data: any) => {
          setSortKey(data.key);
          setSortOrder(data.sortOrder);
          LocalStorageService.setItem('sortKey', data.key);
          LocalStorageService.setItem('sortOrder', data.sortOrder);
        }}
        sortOrder={sortOrder}
        sortKey={sortKey}
        defaultSortOrder={getOrderType(LocalStorageService.getItem('sortOrder'))}
        defaultSortKey={getSortKey(LocalStorageService.getItem('sortKey'))}
        isLoading={isLoading}
      />
    </BomItemsContainer>
  );
};

export default BomItems;
