import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Button from '@atlaskit/button';

const MarkAsProcessed = ({
  id,
  control,
  defaultValue,
  onChange,
  isDisabled = false,
}: {
  id: string;
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: boolean;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  isDisabled?: boolean;
}) => {
  const onClick = () => onChange(id, !defaultValue);
  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={!!defaultValue}
        render={() => (
          <Button isSelected onClick={onClick} isDisabled={isDisabled}>
            {defaultValue ? 'Un-Do' : 'Done'}
          </Button>
        )}
      />
    </>
  );
};

export default MarkAsProcessed;
