import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { DatePicker, DateTimePicker } from '@atlaskit/datetime-picker';
import CustomizedLabel from './CustomizedLabel';
import { ErrorMsg } from '../styles/styles';
import { DATE_PLACEHOLDER, TIME_PLACEHOLDER } from '../constants/TimePlaceholderConstants';

type iState = {
  value: string | undefined;
};
const initialState: iState = {
  value: '',
};
/**
 * @returns
 * DateTimePick/DatePick 's value not allowed to be null.
 * pay attention to the blank string on UI
 * need to transform to null or other acceptable type for request
 */
const DateTimePickerWithController = ({
  label,
  name,
  control,
  defaultValue,
  onChange,
  errors,
  isRequired = false,
  testId = 'date-time-picker-react-hook-form',
  isDateOnly = false,
  isDefaultNow = false,
}: {
  label: string;
  name: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: string;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  isRequired?: boolean;
  testId?: string;
  isDateOnly?: boolean;
  isDefaultNow?: boolean;
}) => {
  const [state, setState] = useState(initialState);
  const handleValueChange = (newValue: string) => {
    setState({ ...state, value: newValue });
    onChange(name, newValue || null, { shouldValidate: true });
  };
  useEffect(() => {
    setState(preState => ({
      ...preState,
      value: defaultValue || (isDefaultNow ? moment().toISOString() : ''),
    }));
  }, [defaultValue, isDefaultNow]);

  const getErrorMessage = () => {
    if (isRequired) {
      return 'is required';
    }

    return undefined;
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || (isDefaultNow ? moment().toISOString() : null)}
      rules={{
        // validate: newValue => validateInput(newValue),
        required: isRequired,
      }}
      render={() => (
        <>
          <CustomizedLabel label={label} isRequired={isRequired} />
          {isDateOnly ? (
            <DatePicker
              onChange={handleValueChange}
              value={state.value}
              dateFormat="DD MMM YYYY"
              testId={testId}
              autoFocus={false}
              placeholder={DATE_PLACEHOLDER}
            />
          ) : (
            <DateTimePicker
              onChange={handleValueChange}
              value={state.value}
              timeFormat="HH:mm"
              dateFormat="DD MMM YYYY"
              testId={testId}
              autoFocus={false}
              datePickerProps={{ placeholder: DATE_PLACEHOLDER }}
              timePickerProps={{ placeholder: TIME_PLACEHOLDER }}
            />
          )}
          {_.get(errors, name) && <ErrorMsg data-testid={'error-msg'}>{getErrorMessage()}</ErrorMsg>}
        </>
      )}
    />
  );
};

export default DateTimePickerWithController;
