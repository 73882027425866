import React, { useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import BackendPage from '../../layout/BackendPage';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import AddProds from './addProducts/AddProds';
import AfterTransfer from './afterTransfer/AfterTransfer';
import MoveProdModalBody from './moveProducts/MoveProdModalBody';
import MoveButtonGroup from './moveProducts/MoveButtonGroup';
import ReviewTable from './moveProducts/ReviewTable';
import StockTransferHeader from './sharedComponents/StockTransferHeader';
import TransferProdsTable from './sharedComponents/TransferProdsTable';
import ModalPage from '../../shared/modalPage/ModalPage';
import iWarehouseLocation from '../../types/warehouse/iWarehouse';
import { FlexContainer } from '../../shared/styles/styles';
import { PageDescriptionWrapper } from './sharedComponents/StockTransfer.style';
import { iItemLocationTransfer } from './sharedComponents/StockTransfer.type';
import StockTransferService from '../../services/StockTransferService';
import { apiErrorToast } from '../../shared/toast/ToastHandler';
import {
  PAGE_DESCRIPTION,
  REMOVE_MODAL_HEADING,
  REVIEW_COLUMNS,
  STOCK_TRANSFER_LIST_COLUMNS,
  TRANSFER_TO_MODAL_HEADING,
} from './sharedComponents/StockTransfer.constants';
import StockTransferUtil from './sharedComponents/StockTransfer.util';

type iState = {
  data: Array<iItemLocationTransfer>;
  target?: iItemLocationTransfer;
  isModalOpen: boolean;
  isConfirming: boolean;
  transferDone: boolean;
  destination?: iWarehouseLocation;
};
const initialState: iState = {
  data: [],
  isModalOpen: false,
  isConfirming: false,
  transferDone: false,
};
const StockTransfer = () => {
  const [state, setState] = useState(initialState);
  //  eslint-disable-next-line
  const { control, setValue, errors, handleSubmit, getValues } = useForm();

  const onOpenDeleteModal = (id: string) =>
    setState({
      ...state,
      target: state.data.find((i: iItemLocationTransfer) => i.id === id),
      isModalOpen: true,
    });

  const onCloseModal = () =>
    setState({
      ...state,
      target: undefined,
      isModalOpen: false,
      transferDone: false,
    });

  const onDelete = () =>
    setState({
      ...state,
      data: state.data.filter((i: iItemLocationTransfer) => i.id !== state.target?.id),
      target: undefined,
      isModalOpen: false,
    });

  //  eslint-disable-next-line
  const onTransfer = async (data: any) => {
    setState({ ...state, isConfirming: true });
    try {
      const destinationWarehouseId = _.get(data, 'destination.id');
      const items = state.data.map((item: iItemLocationTransfer) => ({
        itemLocationId: item.id,
        qty: item.movingQty,
      }));
      await StockTransferService.transfer({ destinationWarehouseId, items });
      // console.log(transferResult);
      setState({
        ...state,
        destination: _.get(data, 'destination'),
        transferDone: true,
        isModalOpen: false,
        isConfirming: false,
        target: undefined,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  const updateRecords = (newRecords: Array<iItemLocationTransfer>) =>
    setState({ ...state, data: StockTransferUtil.mergeRecords(newRecords, state.data) });

  if (state.transferDone) {
    return (
      <BackendPage pageHeader={<StockTransferHeader />}>
        <AfterTransfer
          destination={state.destination}
          transferred={state.data}
          onRefresh={() => setState(initialState)}
        />
      </BackendPage>
    );
  }
  return (
    <BackendPage pageHeader={<StockTransferHeader />}>
      <PageDescriptionWrapper>{PAGE_DESCRIPTION}</PageDescriptionWrapper>
      <TransferProdsTable
        data={state.data}
        onOpenDeleteModal={onOpenDeleteModal}
        columns={STOCK_TRANSFER_LIST_COLUMNS}
      />
      <FlexContainer className={'space-between'}>
        <AddProds updateRecords={updateRecords} records={state.data} />
        <MoveButtonGroup
          length={state.data.length}
          onConfirm={() => setState({ ...state, isModalOpen: true })}
          onCancel={() => setState(initialState)}
        />
      </FlexContainer>
      <CustomizeModal
        isOpen={state.isModalOpen && !!state.target}
        isConfirming={state.isConfirming}
        onConfirm={onDelete}
        onCancel={onCloseModal}
        modalHeading={REMOVE_MODAL_HEADING}
      />
      {state.isModalOpen && !state.target && (
        <ModalPage
          onConfirm={handleSubmit(onTransfer)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          heading={TRANSFER_TO_MODAL_HEADING}
          confirmBtnName={'Transfer'}
        >
          <MoveProdModalBody control={control} onChange={setValue} errors={errors}>
            <ReviewTable data={state.data} columns={REVIEW_COLUMNS} />
          </MoveProdModalBody>
        </ModalPage>
      )}
    </BackendPage>
  );
};

export default StockTransfer;
