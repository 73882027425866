import { colors, gridSize } from '@atlaskit/theme';
import styled from 'styled-components';

export const CollapseInRowWrapper = styled.div`
  width: 100%;
  position: relative;
  .collapse-rows {
    color: #36b37e;
    display: inline-block;
    font-weight: bold;
  }
  .show-rows {
    color: rgb(222, 53, 11);
    display: inline-block;
    font-weight: bold;
  }
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 100ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .collapse-children {
    padding-left: 0 !important;
  }

  .finished-rows {
    input.dynamic-input-text-field {
      color: #014234;
      font-weight: bold !important;
      // #36b37e
    }
  }
`;

export const AddButtonWrapper = styled.div`
  border-bottom: 1px solid ${colors.N40};
  padding: ${gridSize()}px ${gridSize() - 2}px;
  box-sizing: border-box;
  width: 100%;
`;
