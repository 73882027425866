import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ActionMeta, ValueType, PopupSelect } from '@atlaskit/select';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import iEntityStatus from '../../types/status/iEntityStatus';
import iEntityCategory from '../../types/status/iEntityCategory';
import StatusLozenge from './StatusLozenge';
import EntityStatusService from '../../services/EntityStatusService';
import { apiErrorToast } from '../toast/ToastHandler';

const ButtonWrapper = styled.div`
  .status-menu-button {
    background-color: transparent;
    align-items: center;
    &:hover {
      background-color: transparent;
    }
  }
`;
type iOption = {
  label: React.ReactNode;
  value: string;
  status: iEntityStatus;
  categoryCode: string;
};
type iState = {
  options: Array<iOption>;
  value?: iOption;
  wholeOptions: Array<iOption>;
};
const initialState: iState = {
  options: [],
  wholeOptions: [],
};

type iStatusMenu = {
  defaultValue?: string;
  onSelect: (newValue: string) => void;
  categories: Array<iEntityCategory>;
  isDisabled?: boolean;
};

const StatusMenu = ({ defaultValue, onSelect, categories, isDisabled = false }: iStatusMenu) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const value = `${defaultValue || ''}`.trim();
    if (value === '') {
      return;
    }

    let isCanceled = false;
    const categoryMap: { [key: string]: iEntityCategory } = categories.reduce((map, category) => {
      return {
        ...map,
        [category.id]: category,
      };
    }, {});

    setIsLoading(true);
    EntityStatusService.getNextStatuses(value, { includeCurrent: '1' })
      .then(resp => {
        if (isCanceled) return;
        const wholeOptions = resp
          .sort((stat1: iEntityStatus, stat2: iEntityStatus) => (stat1.sortOrder > stat2.sortOrder ? 1 : -1))
          .map((status: iEntityStatus) => {
            const category = categoryMap[status.entityStatusCategoryId];
            if (!category) {
              return null;
            }
            return {
              label: <StatusLozenge status={status} categoryCode={category?.code} />,
              value: status.id,
              status,
              categoryCode: category?.code,
            };
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((status: any) => status !== null);
        setState(prevState => ({
          ...prevState,
          value: wholeOptions.find((option: iOption) => option.value === defaultValue),
          options: wholeOptions.filter((option: iOption) => option.value !== defaultValue),
        }));
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    // eslint-disable-next-line consistent-return
    return () => {
      isCanceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, JSON.stringify(categories)]);

  const onChange = async (
    value: ValueType<iOption>,
    //  eslint-disable-next-line
    action: ActionMeta<iOption>,
  ) => {
    //    value is null/undefined
    if (!value) return;
    const statusId = (value as iOption).value;
    onSelect(statusId);
  };

  return (
    <PopupSelect
      isLoading={isLoading === true}
      options={state.options}
      onChange={onChange}
      target={({ ref }) => (
        <ButtonWrapper>
          <Button
            testId="popup-trigger-btn"
            spacing={'none'}
            ref={ref}
            className={'status-menu-button'}
            isDisabled={isDisabled}
          >
            <StatusLozenge status={state.value?.status} categoryCode={state.value?.categoryCode}>
              <ChevronDownIcon label={'dropdown'} />
            </StatusLozenge>
          </Button>
        </ButtonWrapper>
      )}
      maxMenuWidth={'200'}
      minMenuWidth={'120'}
      className={'status-menu'}
      classNamePrefix={'status-menu-prefix'}
    />
  );
};

export default StatusMenu;
