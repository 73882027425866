import {
  STOCK_TRANSFER_URL,
  DISPATCH_NOTES_URL,
  PURCHASES_URL,
  STOCK_TAKE_URL,
  ITEM_LOCATIONS_URL,
  BULK_STOCK_TAKE_URL,
} from '../UrlMap';
import {
  ACCESS_CODE_DISPATCH_NOTES,
  ACCESS_CODE_STOCK_TAKES,
  ACCESS_CODE_STOCK_TRANSFER,
  ACCESS_CODE_WAREHOUSES,
} from '../../types/settings/UserAccess/iAccess';
import { iSubMenu } from '../UITypes/types';

export const purchasesSubMenu: iSubMenu[] = [
  {
    label: 'Purchase Orders',
    value: PURCHASES_URL,
  },
];
export const salesSubMenu: iSubMenu[] = [
  { label: 'Dispatch Notes', value: DISPATCH_NOTES_URL, accessCodes: [ACCESS_CODE_DISPATCH_NOTES] },
];

export const stockSubMenu: iSubMenu[] = [
  {
    label: 'Stock List',
    value: ITEM_LOCATIONS_URL,
    accessCodes: [ACCESS_CODE_WAREHOUSES],
  },
  {
    label: 'Stock Take',
    value: STOCK_TAKE_URL,
    hasSeparator: true,
    accessCodes: [ACCESS_CODE_STOCK_TAKES],
  },
  { label: 'Bulk Stock Take', value: BULK_STOCK_TAKE_URL, accessCodes: [ACCESS_CODE_STOCK_TAKES] },
  {
    label: 'Stock Transfer',
    value: STOCK_TRANSFER_URL,
    hasSeparator: true,
    accessCodes: [ACCESS_CODE_STOCK_TRANSFER],
  },
];
