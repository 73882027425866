import React, { useState } from 'react';
import Button from '@atlaskit/button';
import OperateBulkRows from '../head/OperateBulkRows';
import { iOperateBulkRow } from '../shared/DynamicManufactureRows.types';
import { AddButtonWrapper } from '../shared/DynamicManufactureRows.style';

type iState = {
  isOpen: boolean;
};
const initialState: iState = {
  isOpen: false,
};
const ManufactureBottom = ({ jobId, addRows }: { jobId: string; addRows: (filledValue: iOperateBulkRow) => void }) => {
  const [state, setState] = useState(initialState);

  const openAdd = () => setState({ ...state, isOpen: true });
  const closeAdd = () => setState({ ...state, isOpen: false });
  const addCallback = (filledValue: iOperateBulkRow) => {
    addRows(filledValue);
    closeAdd();
  };

  if (!state.isOpen)
    return (
      <AddButtonWrapper>
        <Button isSelected onClick={openAdd} testId={'add-row-button'}>
          Add
        </Button>
      </AddButtonWrapper>
    );
  return (
    <OperateBulkRows
      operationType={'add'}
      callback={addCallback}
      initialRowNumber={'1'}
      onCancel={closeAdd}
      jobId={jobId}
    />
  );
};

export default ManufactureBottom;
