import styled from 'styled-components';

const TopRightBtn = styled.div`
  color: #737373;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  opacity: 0.8;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  z-index: 9;
  cursor: pointer;
`;
export default TopRightBtn;
