import iBaseType from '../iBaseType';
import iContactCompanyRole from './iContactCompanyRole';

export const CONTACT_COMPANY_TYPE_CUSTOMER = 'Customer';
export const CONTACT_COMPANY_TYPE_SERVICE_PROVIDER = 'ServiceProvider';
export const CONTACT_COMPANY_TYPE_SUPPLIER = 'Supplier';

type iContactCompanyType = iBaseType & {
  name: string;
  roles?: Array<iContactCompanyRole>;
};

export default iContactCompanyType;
