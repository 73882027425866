import React from 'react';
import BackendPage from '../../layout/BackendPage';
import WelcomeMsg from '../../shared/user/WelcomeMsg';
import JobListPage from './JobListPage';
import { ACCESS_CODE_JOBS } from '../../types/settings/UserAccess/iAccess';
import RestrictedAccessDiv from '../../shared/UserAccess/RestrictedAccessDiv';

const CurrentJobListPage = () => (
  <BackendPage pageHeader={<WelcomeMsg />}>
    <RestrictedAccessDiv expectedUserAccessCodes={[ACCESS_CODE_JOBS]}>
      <JobListPage isCurrent />
    </RestrictedAccessDiv>
  </BackendPage>
);

export default CurrentJobListPage;
