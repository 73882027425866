import { iKeyValuePairs } from '../shared/UITypes/types';
import AppService from './AppService';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPoList = (config?: { [key: string]: any }) => {
  return AppService.get('/purchaseOrder', {
    include: 'supplier,createdBy,updatedBy,status.entityStatusCategory',
    ...config,
  }).then(({ data }) => data);
};

const getPurchaseOrderDetail = (id: string) => {
  return AppService.get(`/purchaseOrder/${id}`, {
    include:
      'supplier,taxBase,createdBy,updatedBy,purchaseOrderItems.measurement,purchaseOrderItems.product.measurement,status.entityStatusCategory',
  }).then(({ data }) => data);
};

const createPurchaseOrder = (postData: iKeyValuePairs) => {
  return AppService.post('/purchaseOrder', {
    ...postData,
  }).then(({ data }) => data);
};

export default {
  getPoList,
  getPurchaseOrderDetail,
  createPurchaseOrder,
};
