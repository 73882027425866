import React, { useState } from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';

import iPurchaseOrderDetail from '../../../types/purchaseOrder/iPurchaseOrderDetail';
import { getHeads } from '../../../services/UtilsService';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { getRows } from './TableHelper';
import { iConfigColumn, iSortOrderType } from '../../../shared/UITypes/types';

const TableWrapper = styled.div`
  margin-top: 1rem;

  th[data-testid='poList-column-orderNumber--head--cell'] {
    width: 8rem;
    min-width: 8rem;
  }

  th[data-testid='poList-column-status--head--cell'],
  th[data-testid='poList-column-supplierRef--head--cell'] {
    width: 7rem;
    min-width: 7rem;
  }

  th[data-testid='poList-column-supplier.name--head--cell'] {
    min-width: 10rem;
  }

  th[data-testid='poList-column-orderDate--head--cell'],
  th[data-testid='poList-column-eta--head--cell'] {
    width: 10rem;
    min-width: 6.6rem;
  }

  th[data-testid='poList-column-createdAt--head--cell'],
  th[data-testid='poList-column-updatedAt--head--cell'] {
    width: 10rem;
    min-width: 10rem;
  }
  th[data-testid='poList-column-operation--head--cell'] {
    width: 2rem;
    min-width: 2rem;
  }
`;

interface iProps {
  list: iPurchaseOrderDetail[];
  isLoading: boolean;
  testId?: string;
  onReceiving: (id: string) => void;
  onSetSort: (sortStr: string) => void;
  columns: Array<iConfigColumn>;
  categories: Array<iEntityCategory>;
}

const PurchasesListTable = ({
  list,
  isLoading,
  testId = 'purchase-list-table',
  onReceiving,
  onSetSort,
  columns,
  categories,
}: iProps) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();
  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(columns, 'poList')}
        rows={getRows(list, onReceiving, columns, categories)}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        onSort={
          //  eslint-disable-next-line
          (data: any) => {
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            onSetSort(`${data.key}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
        testId={testId}
      />
    </TableWrapper>
  );
};

export default PurchasesListTable;
