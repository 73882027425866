import React from 'react';
import BackendPage from '../../layout/BackendPage';
import WelcomeMsg from '../../shared/user/WelcomeMsg';
import JobListPage from './JobListPage';
import { ACCESS_CODE_JOBS } from '../../types/settings/UserAccess/iAccess';
import RestrictedAccessDiv from '../../shared/UserAccess/RestrictedAccessDiv';

const FutureJobListPage = () => (
  <BackendPage pageHeader={<WelcomeMsg />} className={'future-job-list-page'}>
    <RestrictedAccessDiv expectedUserAccessCodes={[ACCESS_CODE_JOBS]}>
      <JobListPage isCurrent={false} />
    </RestrictedAccessDiv>
  </BackendPage>
);

export default FutureJobListPage;
