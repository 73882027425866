import _ from 'lodash';
import moment from 'moment';
import { NUMBER_ROUND_DECIMAL } from '../shared/constants/AsyncConstants';
import {
  STATUS_CATEGORY_FINISHED,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_NEW,
} from '../shared/constants/StatusCategories';
import { iConfigColumn, iLabelValuePair } from '../shared/UITypes/types';
import iAddress from '../types/contactCompany/iAddress';
import iContactCompanyProduct from '../types/contactCompany/iContactCompanyProduct';
import iJobAttribute from '../types/job/iJobAttribute';
import iJobCategory from '../types/job/iJobCategory';
import iJobStatus from '../types/job/iJobStatus';
import iProduct from '../types/product/iProduct';
import iProductAttribute from '../types/product/iProductAttribute';
import iPurchaseOrderItem from '../types/purchaseOrder/iPurchaseOrderItem';
import iSalesOrderItem from '../types/sales/iSalesOrderItem';
import iEntityCategory from '../types/status/iEntityCategory';
import iEntityStatus from '../types/status/iEntityStatus';
import { mulExact } from '../shared/calculationHelper/calculationHelper';

export const ucFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};
//  2345 to 2,345
export const numberFormat = (str: string | number) => {
  const convert = Number(str);
  return Number.isNaN(convert) ? str.toString() : new Intl.NumberFormat().format(convert);
};

//  default round to integer
export const numberRound = (num: string | number | null, decimal?: number) => {
  if (num === null) return null;
  const convert = Number(num);
  //  eslint-disable-next-line no-nested-ternary
  const result = Number.isNaN(convert) ? num : decimal !== undefined ? _.round(convert, decimal) : _.round(convert, 0);
  return result.toLocaleString();
};

export const numberToPercentage = (num: string | number | undefined, float?: number) => {
  const convert = Number(num);
  const roundDecimal = float || 2;
  return Number.isNaN(convert) ? num?.toString() : `${parseFloat((convert * 100).toString()).toFixed(roundDecimal)}%`;
};

export const compareStr = (strA: string | null, strB: string | null) => {
  if (strA === null) {
    return 1;
  }
  if (strB === null) {
    return -1;
  }
  if (strA < strB) {
    return -1;
  }
  if (strA > strB) {
    return 1;
  }
  return 0;
};

export const formatDate = (date?: string | null, format?: string) => {
  if (!date) return '';
  const defaultFormat = format || 'Do MMM YYYY,h a';
  //  moment.locale('en-au');
  return moment(date).format(defaultFormat);
};

export const calculateDurationToNow = (date?: string) => {
  if (!date) return '';
  return moment.duration(moment(date).diff(moment.now())).asHours();
};

export const addsOnValue = (value: string | number, prefix?: string | null, postfix?: string | null) => {
  let viewValue = value;
  if (prefix) {
    viewValue = `${prefix} ${viewValue}`;
  }
  if (postfix) {
    viewValue = `${viewValue} ${postfix}`;
  }
  return viewValue.toString();
};

export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
  });
  return formatter.format(value);
};
export const mapToLabelValuePair = <T extends { id: string; name: string }>(list: Array<T>) => {
  if (list?.length === 0) return [];
  //    eslint-disable-next-line
  return list?.map((l: T) => ({ label: l.name, value: l.id }));
};

//  eslint-disable-next-line
export const hasKey = <O>(obj: O, key: keyof any): key is keyof O => {
  return key in obj;
};

export const assembleAddress = (address?: iAddress): string => {
  if (!address) return '';

  const street = !address.street ? '' : address.street;
  const suburb = !address.suburb ? '' : address.suburb;
  const state = !address.state ? '' : address.state;
  const postcode = !address.postcode ? '' : address.postcode;
  const country = !address.country ? '' : address.country;

  return street === '' && suburb === '' && state === '' && postcode === '' && country === ''
    ? ''
    : `${street}, ${suburb} ${state} ${postcode} ${country}`;
};

//  eslint-disable-next-line
export const handleNullException = (object: any, columnName: string) => {
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue)) return '';
  return getValue;
};

export const handleMoney = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
  decimal?: number,
  dollarSign?: boolean,
) => {
  //  by default, with dollar sign
  const sign = typeof dollarSign === 'undefined' ? true : dollarSign;
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue) || Number.isNaN(Number(getValue))) return '';

  const afterRound = decimal ? _.round(Number(getValue), decimal) : _.round(Number(getValue), 0);

  return sign ? `$${afterRound.toLocaleString()}` : afterRound.toLocaleString();
};

export const validateNumber = (value: string) => {
  const convertNumber = Number(value);
  if (Number.isNaN(convertNumber)) {
    return false;
  }
  return true;
};

export const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      case 'delete':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

export const mapJobAttributeForColumns = (attrs: Array<iJobAttribute>) => attrs.map((attr: iJobAttribute) => attr.name);

export const mapProdAttributeForColumns = (prodAttrs: Array<iProductAttribute>) => {
  const attrs = prodAttrs.map((attr: iProductAttribute) => attr.name);
  return _.uniq(attrs).sort();
};

export const getCategoryCode = (
  //  eslint-disable-next-line
  status: any,
  categories: Array<iEntityCategory>,
) => {
  if (!status) return '';
  const category = categories.find((c: iEntityCategory) => c.id === (status as iEntityStatus).entityStatusCategoryId);
  return category ? category.code : '';
};

export const classifyEntityCategoryByStatusId = (categories: Array<iEntityCategory>, statusId?: string) => {
  const result = { isNew: false, isWIP: false, isFinished: false };
  if (typeof statusId === 'undefined') return result;
  const category = categories.find((c: iEntityCategory) =>
    c.entityStatuses.find((s: iEntityStatus) => s.id === statusId),
  );
  if (typeof category === 'undefined') return result;
  switch (category.code) {
    case STATUS_CATEGORY_NEW:
      result.isNew = true;
      break;
    case STATUS_CATEGORY_IN_PROGRESS:
      result.isWIP = true;
      break;
    case STATUS_CATEGORY_FINISHED:
      result.isFinished = true;
      break;
    default:
      break;
  }
  return result;
};

export const getStatusByCategories = (categories: Array<iEntityCategory>, statusId?: string) => {
  const category = categories.find((c: iEntityCategory) =>
    c.entityStatuses.some((e: iEntityStatus) => e.id === statusId),
  );
  if (category) {
    return category.entityStatuses.find((e: iEntityStatus) => e.id === statusId);
  }
  return undefined;
};

export const AddZeroAhead = (str: string) => {
  if (str.startsWith('.')) return `0${str}`;
  return str;
};

export const mapOptionToValue = (options: iLabelValuePair[]) => {
  return options.map(o => o.value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapLabelValuePairIdPayload = (list: any[], attribute: string) => {
  return list.map(item => ({
    label: item[attribute],
    value: item.id,
  }));
};

// is str numeric
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isStrNumeric(str: any) {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapLabelValuePairObjPayload = (list: any[], attribute: string) => {
  return list.map(item => ({
    label: item[attribute],
    value: item,
  }));
};

export const handleNumber = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
): string => {
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue)) {
    return '';
  }
  const convertNumber = Number(getValue);
  if (Number.isNaN(convertNumber)) {
    return '';
  }
  return convertNumber.toLocaleString();
};
/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function intersperse(arr: any[], sep: string) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function (xs, x, i) {
      return xs.concat([sep, x]);
    },
    [arr[0]],
  );
}
/**
 * highest priority,lastest in columnName
 */
export const handleNullExceptionMultiFields = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
  defaultValue?: number | string | boolean,
) => {
  //  eslint-disable-next-line
  let result: any = typeof defaultValue === 'undefined' ? '' : defaultValue;
  const fields = columnName.split(',');
  fields.forEach((field: string) => {
    const getValue = _.get(object, field);
    result = _.isNull(getValue) || _.isUndefined(getValue) ? result : getValue;
  });
  return result;
};

export const prepareConversionEquation = ({
  value,
  conversion,
  contactCompanyUnit,
  productUnit,
}: {
  value: number;
  conversion: number;
  productUnit: string;
  contactCompanyUnit: string;
}) => {
  if (productUnit.trim().toUpperCase() === contactCompanyUnit.trim().toUpperCase() && conversion === 1) {
    return productUnit;
  }
  return `${contactCompanyUnit} = ${numberRound(mulExact(value, conversion), NUMBER_ROUND_DECIMAL)} ${productUnit}`;
};

/**
 * Getting the AfterConversion Qty as a formatted string and the suffix
 * @param inputValue
 * @param product
 * @param targetItem
 * @return {
 *   qtyString: the amount after conversion,
 *   suffix: bag = xxxx(qtyString) kg
 * }
 */
export const getUnitConversionSuffix = (
  inputValue: string | number,
  product?: iProduct,
  targetItem?: iPurchaseOrderItem | iSalesOrderItem | iContactCompanyProduct,
) => {
  let suffix = '';
  let productQty;
  const input = Number.isNaN(Number(inputValue)) ? 0 : Number(inputValue);
  const inputMeasure = targetItem?.measurement?.shortName || product?.measurement.shortName;
  const productMeasure = product?.measurement.shortName;
  // there is a target item
  if (targetItem?.id) {
    if (
      Number(targetItem.unitConversion) === 1 &&
      inputMeasure?.trim().toUpperCase() === productMeasure?.trim().toUpperCase()
    ) {
      suffix = `${productMeasure}`;
      productQty = inputValue ? Number(inputValue) : '';
    } else {
      suffix = `${inputMeasure} = ${
        // eslint-disable-next-line radix
        numberRound(mulExact(Number(targetItem.unitConversion), input), NUMBER_ROUND_DECIMAL)
      } ${productMeasure || ''}`;

      productQty = inputValue
        ? numberRound(mulExact(Number(targetItem.unitConversion), Number(inputValue)), NUMBER_ROUND_DECIMAL)
        : '';
    }
  }

  // there is not a target item
  if (!targetItem?.id) {
    suffix = `${productMeasure || ''}`;
    productQty = inputValue ? Number(inputValue) : '';
  }

  return { suffix, qtyString: `${productQty || ''}` };
};

export const getDaysMoreFromDay = (numberOfDays?: string, dateFormat = 'YYYY-MM-DD') => {
  return moment(new Date())
    .add(Number(numberOfDays || 0), 'day')
    .format(dateFormat);
};

export const scheduleAtFilterStr = () => {
  return `scheduledAt>=${moment(new Date()).format('YYYY-MM-DD')},scheduledAt<${getDaysMoreFromDay(
    `${process.env.REACT_APP_DISPLAY_FUTURE_JOBS_WITHIN_DAYS || 14}`,
  )}`;
};

/**
 *
 * @returns there isnot code on job category, use name instead
 */
export const getJobCategoryCode = (
  //  eslint-disable-next-line
  status: any,
  categories: Array<iJobCategory>,
) => {
  if (!status) return '';
  const category = categories.find((c: iJobCategory) => c.id === (status as iJobStatus).jobStatusCategoryId);
  return category ? category.name : '';
};
