import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import iStockTake from '../../../../types/stockTake/iStockTake';
import BackendPage from '../../../../layout/BackendPage';
import useStatusFilter from '../../../../shared/hooks/useStatusFilter/useStatusFilter';
import BulkStockTakeItem from '../bulkItem/BulkStockTakeItem';
import { apiErrorToast } from '../../../../shared/toast/ToastHandler';
import { iKeyValuePairs } from '../../../../shared/UITypes/types';
import EntityStatusCategoryService from '../../../../services/EntityStatusCategoryService';
import WarehouseService from '../../../../services/WarehouseService';
import { BULK_STOCK_TITLE } from '../shared/BulkStockTake.constants';
import { STATUS_CATEGORY_IN_PROGRESS, STATUS_CATEGORY_NEW } from '../../../../shared/constants/StatusCategories';
import { compareStr, handleNullException } from '../../../../services/UtilsService';
import { PageTitle, SpaceVerticalWrapper } from '../../../../shared/styles/styles';
import StockTakeService from '../../../../services/StockTakeService';

type iState = {
  isLoading: boolean;
  sorted: Array<iStockTake>;
  idNameMap: iKeyValuePairs;
};

export type iBulkStockTakeListProps = {
  stockTakeIdList: Array<string>;
  parentLocationId?: string;
  finishCallback: () => void;
};
const BulkStockTakeList = ({ stockTakeIdList, parentLocationId, finishCallback }: iBulkStockTakeListProps) => {
  const initialState: iState = {
    isLoading: true,
    sorted: [],
    idNameMap: {},
  };
  const [state, setState] = useState(initialState);
  const { categories, isStatusLoading } = useStatusFilter({
    type: 'StockTake',
    targetCategories: [STATUS_CATEGORY_NEW, STATUS_CATEGORY_IN_PROGRESS],
    getFn: EntityStatusCategoryService.getEntityCategoryList,
  });

  useEffect(() => {
    let isCancelled = false;
    //  eslint-disable-next-line
    const fetchStockTakes = async () => {
      try {
        if (stockTakeIdList.length === 0) {
          return;
        }
        setState(prevState => ({ ...prevState, isLoading: true }));
        //  eslint-disable-next-line
        const { data: stockTakeList } = await StockTakeService.getStockTakeListForBulk({
          filter: `id:${stockTakeIdList.join('|')}`,
        });
        const { idNameMap } = await WarehouseService.getWarehouseLocationPathNames(
          JSON.stringify(stockTakeList.map((item: iStockTake) => item.warehouseLocationId)),
        );
        if (isCancelled) return;
        const sorted = [...stockTakeList].sort((a: iStockTake, b: iStockTake) =>
          compareStr(
            handleNullException(idNameMap, a.warehouseLocationId),
            handleNullException(idNameMap, b.warehouseLocationId),
          ),
        );
        setState(prevState => ({
          ...prevState,
          sorted,
          idNameMap,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        setState(prevState => ({ ...prevState, isLoading: false }));
        apiErrorToast(error);
      }
    };
    fetchStockTakes();
    return () => {
      isCancelled = true;
    };
  }, [stockTakeIdList, parentLocationId]);

  const reloadFinishedStockTake = async (id: string) => {
    try {
      const finisedStockTake = await StockTakeService.getStockTakeDetail(id);
      setState({
        ...state,
        sorted: state.sorted.map((item: iStockTake) => (item.id === id ? finisedStockTake : item)),
      });
      finishCallback();
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const getPageHeader = () => (
    <PageTitle className={'median'}>{`${BULK_STOCK_TITLE} ${handleNullException(
      state.idNameMap,
      parentLocationId || '',
    )}`}</PageTitle>
  );

  if (isStatusLoading || state.isLoading) return <Spinner />;
  return (
    <BackendPage pageHeader={getPageHeader()}>
      {state.sorted.map((item: iStockTake) => (
        <div key={item.id}>
          <BulkStockTakeItem
            key={item.id}
            stockTakeDetail={item}
            categories={categories}
            path={handleNullException(state.idNameMap, item.warehouseLocationId)}
            finishCallback={reloadFinishedStockTake}
          />
          <SpaceVerticalWrapper className={'small'} />
        </div>
      ))}
    </BackendPage>
  );
};

export default BulkStockTakeList;
