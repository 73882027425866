import iAddress from '../../../../../types/contactCompany/iAddress';
import withModalControl from '../shared/withModalControl';
import CreateModalBody from './ModalBodyDataSO';
import TransparentIconBtn from '../../../../../shared/buttons/TransparentIconBtn';

export type iCreateAganistSOHOCProps = {
  DNId: string;
  customerId?: string;
  //  eslint-disable-next-line
  onCreate: (data: any) => Promise<void>;
  DNShippingAddress?: iAddress;
  DNItemLength?: number;
};

const CreateAganistSOHOC = ({
  DNId,
  customerId,
  onCreate,
  DNShippingAddress,
  DNItemLength,
}: iCreateAganistSOHOCProps) => {
  return withModalControl({
    WrappedModalBody: CreateModalBody,
    WrappedButton: TransparentIconBtn,
  })({
    wrappedModalBodyProps: {
      DNId,
      customerId,
      onCreate,
      DNShippingAddress,
      DNItemLength,
    },
    wrapperButtonProps: { btnName: 'SO' },
  });
};

export default CreateAganistSOHOC;
