import React from 'react';

import {
  PageLayout,
  Main,
  Content,
  // RightSidebar,
  LeftSidebar,
  // LeftSidebarWithoutResize,
  // RightPanel,
  // LeftPanel,
  Banner,
  TopNavigation,
} from '@atlaskit/page-layout';

export default ({
  children,
  header,
  nav,
  leftMenu,
  footer,
}: {
  children: React.ReactNode;
  header?: React.ReactNode;
  nav?: React.ReactNode;
  leftMenu?: React.ReactNode;
  footer?: React.ReactNode;
}) => {
  return (
    <PageLayout testId={'page-layout-wrapper'}>
      {header ? <Banner id={'page-header'}>{header}</Banner> : null}
      {nav ? (
        <TopNavigation id={'page-nav'} isFixed={false}>
          {nav}
        </TopNavigation>
      ) : null}
      <Content>
        {leftMenu ? (
          <LeftSidebar isFixed={false} width={125} id={'page-left-menu'}>
            {leftMenu}
          </LeftSidebar>
        ) : null}
        <Main>{children}</Main>
      </Content>

      {footer ? <div id={'page-footer'}>{footer}</div> : null}
    </PageLayout>
  );
};
