import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getStockTakeList = (config?: { [key: string]: string }) => {
  return AppService.get('/stockTake', {
    include: 'createdBy,updatedBy,warehouseLocation,status',
    ...config,
  }).then(({ data }) => data);
};
const getStockTakeDetail = (id: string) => {
  return AppService.get(`/stockTake/${id}`, {
    include: 'createdBy,updatedBy,lostAndFoundLocation,warehouseLocation.warehouseItemLocations.product.measurement',
  }).then(({ data }) => data);
};

const createStockTake = (postData: iParams) => {
  return AppService.post('/stockTake', postData).then(({ data }) => data);
};

const deactivateStockTake = (id: string) => {
  return AppService.delete(`/stockTake/${id}`).then(({ data }) => data);
};

const updateStockTake = (id: string, updateContent: iParams) => {
  return AppService.put(`/stockTake/${id}`, updateContent).then(({ data }) => data);
};

const startStockTake = (id: string) => {
  return AppService.post(`/stockTake/${id}/start`, {}).then(({ data }) => data);
};

const previewFinishStockTake = (id: string) => {
  return AppService.post(`/stockTake/${id}/preview`, {}).then(({ data }) => data);
};

const finishStockTake = (id: string) => {
  return AppService.post(`/stockTake/${id}/finish`, {}).then(({ data }) => data);
};

const createBulkStockTake = (postData: iParams) => {
  return AppService.post('/stockTake/bulk', postData).then(({ data }) => data);
};

const getStockTakeListForBulk = (config?: iParams) => {
  return AppService.get('/stockTake', {
    ...config,
    currentPage: '1',
    perPage: '100',
    include: 'createdBy,updatedBy,lostAndFoundLocation,warehouseLocation.warehouseItemLocations.product.measurement',
  }).then(({ data }) => data);
};

export default {
  getStockTakeList,
  getStockTakeDetail,
  getStockTakeListForBulk,
  createStockTake,
  createBulkStockTake,
  startStockTake,
  previewFinishStockTake,
  finishStockTake,
  updateStockTake,
  deactivateStockTake,
};
