import React from 'react';
import Modal, { ModalTransition, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import ConflictListing from './ConflictListing';
import {
  CONFLICT_HINT,
  CONFLICT_TITLE,
  KEEP_YOURS,
  KEEP_YOURS_HINT,
  USE_DATABASE,
  USE_DATABASE_HINT,
} from '../shared/DynamicManufactureRows.constants';
import { iManufactureRow } from '../shared/DynamicManufactureRows.types';
import ComposeSecondaryText from '../../../text/ComposeSecondaryText';

const ConflictModal = ({
  onClick,
  local,
  latest,
}: {
  onClick: (useOurVersion: boolean) => void;
  local: Array<iManufactureRow>;
  latest: Array<iManufactureRow>;
}) => {
  return (
    <ModalTransition>
      <Modal width={'large'}>
        <ModalHeader>
          <ModalTitle>{CONFLICT_HINT}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SectionMessage
            appearance={'warning'}
            title={CONFLICT_TITLE}
            actions={[
              <SectionMessageAction onClick={() => onClick(false)}>{USE_DATABASE}</SectionMessageAction>,
              <SectionMessageAction onClick={() => onClick(true)}>{KEEP_YOURS}</SectionMessageAction>,
            ]}
          >
            <ConflictListing local={local} latest={latest} />
          </SectionMessage>
        </ModalBody>
        <ModalFooter>
          <div>
            <ComposeSecondaryText secondaryText={USE_DATABASE_HINT}>
              <div />
            </ComposeSecondaryText>
            <ComposeSecondaryText secondaryText={KEEP_YOURS_HINT}>
              <div />
            </ComposeSecondaryText>
          </div>
          <div />
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};

export default ConflictModal;
