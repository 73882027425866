import React, { useState } from 'react';
import { TabWrapper } from './CustomizeTab.style';
import TabItems from './TabItems';

type iTab = {
  label: string;
  content: React.ReactNode;
};
const CustomizeTab = ({ components }: { components: Array<iTab> }) => {
  const defaultSelected = components.length > 0 ? components[0].label : undefined;
  const [state, setState] = useState(defaultSelected);

  const onSelect = (label: string) => setState(label);
  const getTabContent = () => {
    const selectContent = components.find((comp: iTab) => comp.label === state);
    return selectContent ? selectContent.content : null;
  };
  return (
    <TabWrapper data-testid={'customize-tab'}>
      <TabItems selected={state} items={components.map((comp: iTab) => comp.label)} onSelect={onSelect} />
      {getTabContent()}
    </TabWrapper>
  );
};

export default CustomizeTab;
