import React, { useCallback } from 'react';
import Spinner from '@atlaskit/spinner';
import iProduct from '../../../../types/product/iProduct';
import iStockTake from '../../../../types/stockTake/iStockTake';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import SubmitButton from './SubmitButton';
import BulkSTItemTable from './BulkSTItemTable';
import CreateByAsyncProduct from '../../../../shared/createByAsyncSearchProduct/CreateByAsyncProduct';
import { iParams } from '../../../../shared/UITypes/types';
import { FlexContainer } from '../../../../shared/styles/styles';
import StockTakeItemService from '../../../../services/StockTakeItemService';

const BulkSTItemCollapse = ({
  stockTakeDetail,
  path,
  finishCallback,
}: {
  stockTakeDetail: iStockTake;
  path: string;
  finishCallback: (id: string) => void;
}) => {
  const { id } = stockTakeDetail;
  const getFnCallback = useCallback(
    (config?: iParams) => StockTakeItemService.getStockTakeItemListByStockTakeId(id, config),
    [id],
  );
  const { state, onDelete, onCreate, onEdit } = useListCrudHook<iStockTakeItem>({
    getFn: getFnCallback,
    updateFn: StockTakeItemService.updateStockTakeItem,
    deleteFn: StockTakeItemService.deactivateStockTakeItem,
    createFn: StockTakeItemService.createStockTakeItem,
    notRenderWithoutFilter: true,
    paginationApplied: true,
    sort: 'createdAt:DESC',
    filter: 'isUserInput:true',
  });
  const onCreateSKItem = (selected: iProduct | null) => {
    if (!selected) return;
    onCreate({
      productId: selected.id,
      stockTakeId: stockTakeDetail.id,
      qtyFrom: 0,
      qty: 0,
      isUserInput: true,
    });
  };
  if (state.isLoading) return <Spinner />;
  return (
    <>
      <BulkSTItemTable
        stockTakeDetail={stockTakeDetail}
        stockTakeItems={state.data}
        createFn={onCreate}
        editFn={onEdit}
        deleteFn={onDelete}
      />
      <FlexContainer className={'space-between'}>
        <CreateByAsyncProduct onSelect={onCreateSKItem} />
        <SubmitButton stockTakeId={id} path={path} onFinishCallback={finishCallback} />
      </FlexContainer>
    </>
  );
};

export default BulkSTItemCollapse;
