import React from 'react';
import styled from 'styled-components';
import CommentList from '../comments/CommentList';
import CustomizeTab from '../tab/CustomizeTab';

const ActivitySectionContainer = styled.div`
  margin-top: 5rem;
`;

const Activities = ({ modalId, modalName }: { modalId: string; modalName: string }) => {
  const tabs = [
    {
      label: 'Comments',
      content: <CommentList modelName={modalName} modelId={modalId} />,
    },
  ];

  return (
    <ActivitySectionContainer>
      <h3>Activities:</h3>
      <CustomizeTab components={tabs} />
    </ActivitySectionContainer>
  );
};

export default Activities;
