/*  eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import DynamicTable from '@atlaskit/dynamic-table';
import iJob from '../../types/job/iJob';
import iJobCategory from '../../types/job/iJobCategory';
import { iConfigColumn } from '../UITypes/types';
import JobService from '../../services/JobService';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../hooks/useShowHideColumnHook/useColumnHooksUpgrade';
import JobListUtils from './JobList.utils';
import TableHelper from '../tableHelper/TableHelper';
import { RootState } from '../../redux/makeReduxStore';
import { FlexContainer } from '../styles/styles';
import { JobListTableWrapper } from './JobList.styles';
import { CURRENT_JOB_LIST, CURRENT_TITLE, FUTURE_JOB_LIST, FUTURE_TITLE } from './JobList.constants';

const JobList = ({
  isCurrent = true,
  fullColumns,
  jobCategoryList,
}: {
  isCurrent?: boolean;
  fullColumns: Array<iConfigColumn>;
  jobCategoryList: Array<iJobCategory>;
}) => {
  const pageName = isCurrent ? CURRENT_JOB_LIST : FUTURE_JOB_LIST;
  const { user } = useSelector((s: RootState) => s.auth);

  const getFn = useCallback(
    () => JobService.getJobs(user?.defaultOperatingMachine?.id || '', !!isCurrent),
    [isCurrent, user?.defaultOperatingMachine?.id],
  );

  const { state } = useListCrudHook<iJob>({
    getFn,
    notRenderWithoutFilter: false,
    paginationApplied: false,
  });
  const { columns, getShowHideColumns } = useColumnHooksUpgrade(pageName, fullColumns);

  return (
    <>
      <FlexContainer className={'space-between'} data-testid={'FlexContainer'}>
        <p>{isCurrent ? CURRENT_TITLE : FUTURE_TITLE}</p>
        {getShowHideColumns()}
      </FlexContainer>
      <JobListTableWrapper data-testid={'JobListTableWrapper'}>
        <DynamicTable
          head={TableHelper.getHeads(columns, pageName)}
          rows={TableHelper.getRows({
            data: state.data,
            columns,
            categories: [],
            renderSpecialContent: JobListUtils.joblistRenderSpecialContent({ jobCategoryList, isCurrent }),
          })}
          isLoading={state.isLoading}
          rowsPerPage={10}
          defaultPage={1}
          isFixedSize
          loadingSpinnerSize="large"
          testId={pageName}
        />
      </JobListTableWrapper>
    </>
  );
};

export default JobList;
