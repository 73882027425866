import React, { useState, useEffect } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iStockTake from '../../../types/stockTake/iStockTake';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { iConfigColumn, iParams, iSortOrderType } from '../../../shared/UITypes/types';
import WarehouseService from '../../../services/WarehouseService';
import TableHelper from '../../../shared/tableHelper/TableHelper';
import StockTakeUtil from '../shared/StockTake.util';
import { LIST_TABLE_NAME } from '../shared/StockTake.constant';
import { TableWrapper } from '../shared/StockTake.style';
import { apiErrorToast } from '../../../shared/toast/ToastHandler';

type iState = {
  isLoading: boolean;
  idNameMap?: iParams;
};
const initialState: iState = {
  isLoading: true,
};
export type iStockTakeTableProps = {
  data: Array<iStockTake>;
  isLoading: boolean;
  columns: Array<iConfigColumn>;
  onSetSort: (sortStr: string) => void;
  onOpenEditModal: (id: string) => void;
  categories: Array<iEntityCategory>;
};
const StockTakeTable = ({ data, onSetSort, isLoading, columns, onOpenEditModal, categories }: iStockTakeTableProps) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();
  const [state, setState] = useState(initialState);
  useEffect(
    () => {
      let isCancelled = false;
      const fetchLocationPathName = async () => {
        if (data.length === 0) {
          setState(prevState => ({ ...prevState, isLoading: false }));
          return;
        }
        try {
          const ids = data.map((item: iStockTake) => item.warehouseLocationId);
          const stringIds = JSON.stringify(ids);
          const { idNameMap } = await WarehouseService.getWarehouseLocationPathNames(stringIds);
          if (isCancelled) return;
          setState(prevState => ({
            ...prevState,
            idNameMap,
            isLoading: false,
          }));
        } catch (error) {
          if (isCancelled) return;
          apiErrorToast(error);
          setState(prevState => ({ ...prevState, isLoading: false }));
        }
      };
      fetchLocationPathName();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [JSON.stringify(data)],
  );
  return (
    <TableWrapper data-testid={'StockTake-TableWrapper'}>
      <DynamicTable
        head={TableHelper.getHeads(columns, LIST_TABLE_NAME)}
        rows={TableHelper.getRows({
          data,
          columns,
          categories,
          renderSpecialContent: StockTakeUtil.stockTakeTableRenderSpecialContent({
            onOpenEditModal,
            idNameMap: state.idNameMap,
          }),
        })}
        isLoading={isLoading || state.isLoading}
        onSort={
          //  eslint-disable-next-line
          (data: any) => {
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            onSetSort(`${data.key}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
    </TableWrapper>
  );
};

export default StockTakeTable;
