import React, { useEffect } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import DynamicTable from '@atlaskit/dynamic-table';
import SectionMessage from '@atlaskit/section-message';
import SearchBar from '../../../shared/search/SearchBar';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import iItemLocation from '../../../types/warehouse/iItemLocation';
import ItemLocationService from '../../../services/ItemLocationService';
import { FlexContainer } from '../../../shared/styles/styles';
import { ADD_PROD_COLUMNS } from '../sharedComponents/StockTransfer.constants';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import TableHelper from '../../../shared/tableHelper/TableHelper';
import StockTransferUtil from '../sharedComponents/StockTransfer.util';

export type iAddProdsTableProps = {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  warehouseLocationId: string;
  records: Array<iItemLocationTransfer>;
  //    eslint-disable-next-line
  onMovingQtyChange: (name: string, value: any) => void;
  onNoProduct: (isNoProduct: boolean) => void;
};
const AddProdsTable = ({
  control,
  onChange,
  errors,
  warehouseLocationId,
  records,
  onMovingQtyChange,
  onNoProduct,
}: iAddProdsTableProps) => {
  const {
    state,
    edit,
    onSetCurrentPage,
    onSearch,
    // onSetSort,
  } = useListCrudHook<iItemLocation>({
    getFn: ItemLocationService.getItemLocationList,
    filter: `warehouseLocationId:${warehouseLocationId},qty>0`,
    sort: 'createdAt:DESC',
    paginationApplied: true,
    keywordColumns: 'reference,product.name,product.productCode',
    perPage: 5,
  });
  // const [sortState, setSortState] = useState(initialState);
  //    eslint-disable-next-line
  const onChangeMiddleWare = (name: string, value: any, config?: any) => {
    // console.log(value);
    const itemLocation = state.data.find((item: iItemLocation) => item.id === name);
    if (itemLocation) {
      onMovingQtyChange(name, { ...itemLocation, movingQty: Number(value) });
    }
    onChange(name, value, config);
  };
  // can not directly call onNoProduct on the second if
  // can not call onNoProduct to change parent's state when current component is rendering
  useEffect(() => {
    onNoProduct(!state.isLoading && typeof state.total !== 'undefined' && state.total === 0);
  }, [state.total, state.isLoading, onNoProduct]);

  if (!state.isLoading && typeof state.total !== 'undefined' && state.total === 0) {
    return (
      <SectionMessage appearance={'warning'} testId={'no-product-section-message'}>
        No products stored here
      </SectionMessage>
    );
  }
  return (
    <>
      <FlexContainer className={'space-between'}>
        <div />
        <div style={{ flexGrow: 0, minWidth: '250px' }}>
          <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'search by product name/batch....'} />
        </div>
      </FlexContainer>
      <DynamicTable
        head={TableHelper.getHeads(ADD_PROD_COLUMNS, 'addProds')}
        rows={TableHelper.getRows({
          data: state.data,
          columns: ADD_PROD_COLUMNS,
          categories: [],
          renderSpecialContent: StockTransferUtil.addProductRenderSpecialContent({
            control,
            onChange: onChangeMiddleWare,
            errors,
            records,
          }),
        })}
        testId={'stock-transfer-table'}
        isLoading={state.isLoading}
      />
      {state.total && state.perPage && state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );
};

export default AddProdsTable;
