import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const printRollLabel = (jobId: string, params: iParams) => {
  return AppService.get(`/jobPrint/${jobId}/rollLabel`, { ...params }).then(({ data: { url } }) => url);
};

const printCoreLabel = (jobId: string, params: iParams) => {
  return AppService.get(`/jobPrint/${jobId}/coreLabel`, { ...params }).then(({ data: { url } }) => url);
};

const printPalletLabel = (jobId: string, params: iParams) => {
  return AppService.get(`/jobPrint/${jobId}/palletLabel`, { ...params }).then(({ data: { url } }) => url);
};

const printJobCard = (jobId: string) => {
  return AppService.get(`/jobPrint/${jobId}/jobCard`).then(({ data: { url } }) => url);
};

const printSalesOrder = (id: string) => {
  return AppService.post('/orderPrint/salesOrder', { id }).then(({ data: { url } }) => url);
};

const printPurchaseOrder = (id: string) => {
  return AppService.post('/orderPrint/purchaseOrder', { id }).then(({ data: { url } }) => url);
};

const printLocationLabel = (id: string) => {
  return AppService.get(`/warehouseLocation/label/${id}`).then(({ data: { url } }) => url);
};

const printDispatchNote = (id: string) => {
  return AppService.post(`/dispatchNotePrint/dispatchNote/${id}`, {}).then(({ data: { url } }) => url);
};

const printDispatchNoteManifest = (ids: string[]) => {
  return AppService.post('/dispatchNotePrint/manifest', {
    dispatchNoteIds: ids,
  }).then(({ data: { url } }) => url);
};

const printAcquisitionOrder = (id: string) => {
  return AppService.post('/orderPrint/acquisitionOrder', { id }).then(({ data: { url } }) => url);
};

export default {
  printRollLabel,
  printCoreLabel,
  printPalletLabel,
  printJobCard,
  printSalesOrder,
  printPurchaseOrder,
  printLocationLabel,
  printDispatchNote,
  printDispatchNoteManifest,
  printAcquisitionOrder,
};
