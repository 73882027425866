import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getPalletSystem = (config?: iParams) => {
  return AppService.get('/palletSystem', config).then(({ data }) => data);
};
const getPalletWrappingMethod = (config?: iParams) => {
  return AppService.get('/wrappingMethod', config).then(({ data }) => data);
};
export default {
  getPalletSystem,
  getPalletWrappingMethod,
};
