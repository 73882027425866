import AppService from './AppService';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';

const getDispatchNoteItemList = (dispatchNoteId: string, config?: iParams) => {
  return AppService.get(`/dispatchNoteItem/dispatchNote/${dispatchNoteId}`, {
    ...config,
    include: 'job,salesOrderItem.salesOrder.shippingAddress,product.measurement',
  }).then(({ data }) => data);
};

const deactivateDispatchNoteItem = (id: string) => {
  return AppService.delete(`/dispatchNoteItem/${id}`).then(({ data }) => data);
};

const createDispatchNoteItem = (postData: iKeyValuePairs) => {
  return AppService.post('/dispatchNoteItem', postData).then(({ data }) => data);
};

const updateDispatchNoteItem = (id: string, updateContent: iParams) => {
  return AppService.put(`/dispatchNoteItem/${id}`, updateContent).then(({ data }) => data);
};

const getDispatchNoteItems = (config?: iParams) => {
  return AppService.get('/dispatchNoteItem', {
    ...config,
    include: 'job,salesOrderItem.salesOrder,product.measurement,dispatchNote',
  }).then(({ data }) => data);
};

const getDNItemConfig = (config?: iParams) => {
  return AppService.get('/dispatchNoteItem', config).then(({ data }) => data);
};

export default {
  getDispatchNoteItemList,
  getDispatchNoteItems,
  getDNItemConfig,
  createDispatchNoteItem,
  updateDispatchNoteItem,
  deactivateDispatchNoteItem,
};
