import { Checkbox } from '@atlaskit/checkbox';
import React, { ChangeEvent } from 'react';
import iDispatchNote from '../../../types/dispatchNote/iDispatchNote';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { iConfigColumn } from '../shared/DispatchNote.type';
import GeneralPrintBtn from '../../../shared/buttons/GeneralPrintBtn';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import LabelPrintingService from '../../../services/PrintService';
import {
  assembleAddress,
  formatDate,
  getCategoryCode,
  handleMoney,
  handleNullException,
} from '../../../services/UtilsService';
import CompanyWithTypeName from '../../../shared/company/CompanyWithTypeName';

export const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iDispatchNote>,
  onCheck: (id: string, value: boolean) => void,
  columns: Array<iConfigColumn>,
  categories: Array<iEntityCategory>,
  testId?: string,
) => {
  return data.map((dispatchNote: iDispatchNote) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(dispatchNote, key),
            content: handleNullException(dispatchNote, key),
          };
        case 'companyWithTypeName':
          return {
            key: handleNullException(dispatchNote, key),
            content: <CompanyWithTypeName contactCompany={handleNullException(dispatchNote, key)} />,
          };
        case 'date':
          return {
            key: handleNullException(dispatchNote, key),
            content: formatDate(handleNullException(dispatchNote, key), 'Do MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(dispatchNote, key),
            content: handleMoney(dispatchNote, key, 2),
          };
        case 'address':
          return {
            key: handleNullException(dispatchNote, key),
            content: assembleAddress(handleNullException(dispatchNote, key)),
          };
        case 'link':
          return {
            key: handleNullException(dispatchNote, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(dispatchNote, key)}
                url={`${column.urlPrefix}/${handleNullException(dispatchNote, dataSource[0])}`}
              />
            ),
          };
        case 'status':
          return {
            key: handleNullException(dispatchNote, `${key}.id`),
            content: (
              <StatusLozenge
                status={handleNullException(dispatchNote, key)}
                categoryCode={getCategoryCode(handleNullException(dispatchNote, key), categories)}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(dispatchNote, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(dispatchNote, key)}
                operator={handleNullException(dispatchNote, dataSource[0])}
              />
            ),
          };
        case 'operation':
          return {
            content: (
              <MoreIconPopup>
                <GeneralPrintBtn serviceFunc={() => LabelPrintingService.printDispatchNote(dispatchNote.id)}>
                  Print
                </GeneralPrintBtn>
              </MoreIconPopup>
            ),
          };
        case 'rowCheckbox':
          return {
            content: (
              <Checkbox
                onChange={(event: ChangeEvent<HTMLInputElement>) => onCheck(dispatchNote.id, event.target.checked)}
                className="hideCheckboxForiOS"
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, ...(testId ? { testId } : {}) };
  });
};
