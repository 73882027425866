import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { StockTransferWrapper } from './StockTransfer.style';
import { iItemLocationTransfer } from './StockTransfer.type';
import { iConfigColumn } from '../../../shared/UITypes/types';
import TableHelper from '../../../shared/tableHelper/TableHelper';
import StockTransferUtil from './StockTransfer.util';

const TransferProdsTable = ({
  data,
  onOpenDeleteModal,
  columns,
}: {
  data: Array<iItemLocationTransfer>;
  onOpenDeleteModal: (id: string) => void;
  columns: Array<iConfigColumn>;
}) => {
  return (
    <StockTransferWrapper data-testid={'StockTransferWrapper'}>
      <DynamicTable
        head={TableHelper.getHeads(columns, 'stockTransfer')}
        rows={TableHelper.getRows({
          data,
          columns,
          categories: [],
          renderSpecialContent: StockTransferUtil.transferProdsRenderSpecialContent({ onOpenDeleteModal }),
        })}
        testId={'stock-transfer-table'}
      />
    </StockTransferWrapper>
  );
};

export default TransferProdsTable;
