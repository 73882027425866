import React from 'react';
import styled from 'styled-components';
import iProduct from '../../types/product/iProduct';
// import AsyncSearchFormOptionLabel from '../asyncSearch/AsyncSearchFormOptionLabel';
// import ComposeSecondaryText from '../text/ComposeSecondaryText';
import ProductService from '../../services/ProductService';
// import { handleNullException } from '../../services/UtilsService';
import { getProductOptionLabel } from '../Utilities';
import AsyncSearchFormOptionLabel from '../hookForms/asyncSearch/AsyncSearchFormOptionLabel';

const SearchWrapper = styled.div`
  min-width: 300px;
`;
const CreateByAsyncProduct = ({
  onSelect,
  filter = '',
}: {
  onSelect: (selected: iProduct | null) => void;
  filter?: string;
}) => {
  return (
    <SearchWrapper data-testid={'select-wrapper'}>
      <AsyncSearchFormOptionLabel
        onSelect={onSelect}
        promiseFn={(keyword: string) =>
          ProductService.getProductListAsyncSearch({
            like: `productCode:${keyword},name:${keyword}`,
            filter,
          })
        }
        searchBarPlaceholder={'Search by productCode/name...'}
        formatOptionLabel={getProductOptionLabel}
      />
    </SearchWrapper>
  );
};

export default CreateByAsyncProduct;
