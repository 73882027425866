import iFormField from '../../../types/form/iFormField';
import iFormFieldValue from '../../../types/form/iFormFieldValue';
import iFormOption from '../../../types/form/iFormOption';
import iFormRow from '../../../types/form/iFormRow';

const mapFieldOptions = (options: Array<iFormOption>) => {
  let rawOptions = {};
  let api = {};
  options.forEach((option: iFormOption) => {
    // api
    if (option.serviceName || option.functionName) {
      api = { ...api, ...option };
    }
    //  classNames
    else if (option.classNames) {
      rawOptions = {
        ...rawOptions,
        classNames: (option.classNames as Array<string>).join(' '),
      };
    } else {
      rawOptions = { ...rawOptions, ...option };
    }
  });
  if (api) {
    rawOptions = { ...rawOptions, api };
  }
  return rawOptions;
};

const mapFormFieldValues = (fieldValue?: iFormFieldValue) => (fieldValue ? { defaultValue: fieldValue.value } : {});

const mapFormField = (jobId: string, field: iFormField, rowIndex: number, values: Array<iFormFieldValue>) => {
  const fieldValue = values.find((item: iFormFieldValue) => item.formFieldId === field.id);
  const rowField = {
    jobId,
    id: field.id,
    type: field.type,
    name: field.name,
    description: field.description,
    isCompulsory: field.isCompulsory,
    ...mapFormFieldValues(fieldValue),
    ...mapFieldOptions(field.options),
  };
  //   DYNAMIC-INDEX is a flag for add index to description and name
  //   description for roll 1, roll 2....
  if (field.options.find((option: iFormOption) => option.suffix && option.suffix === 'DYNAMIC-INDEX')) {
    rowField.description = `${rowField.description} ${rowIndex}`;
  }
  return rowField;
};

const mapFormRows = (jobId: string, rawRows: Array<iFormRow>, rawFieldValues: Array<iFormFieldValue>) => {
  return rawRows
    .sort((a: iFormRow, b: iFormRow) => a.sortOrder - b.sortOrder)
    .map((rawRow: iFormRow, rowIndex: number) => {
      const rowInputs = rawRow.formFields
        .sort((a: iFormField, b: iFormField) => a.sortOrder - b.sortOrder)
        .map((rawField: iFormField) => mapFormField(jobId, rawField, rowIndex, rawFieldValues));
      return { inputs: rowInputs, id: rawRow.id };
    });
};

export default {
  mapFormRows,
  mapFormField,
  mapFormFieldValues,
  mapFieldOptions,
};
