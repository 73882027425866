import AppService from './AppService';
import { iParams } from '../shared/UITypes/types';

const getItemLocationList = (config?: iParams) => {
  return AppService.get('/itemLocation', {
    ...config,
    include: 'product.measurement,createdBy,updatedBy,warehouseLocation',
  }).then(({ data }) => data);
};

export default { getItemLocationList };
