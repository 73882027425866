import Button from '@atlaskit/button';
import React from 'react';
import { BACK_END_URL } from '../UrlMap';
import CompanyExceptionEmptyState from '../company/CompanyExceptionEmptyState';
import iUser from '../../types/user/iUser';

const RestrictedAccessEmptyState = ({
  expectedUserAccessCodes,
  description,
  user,
}: {
  expectedUserAccessCodes?: string[];
  user?: iUser;
  description?: string;
}) => {
  return (
    <CompanyExceptionEmptyState
      header={'Access Denied'}
      description={
        <>
          {description || 'It appears that you do NOT have access to this page.'}
          <br />
          <small style={{ fontSize: '7px' }}>{(expectedUserAccessCodes || []).join('|')}</small>
        </>
      }
      primaryAction={
        <Button appearance={'primary'} href={BACK_END_URL}>
          HOME
        </Button>
      }
      secondaryAction={
        `${user?.company.email || ''}`.trim() !== '' ? (
          <div>
            <Button appearance={'link'} href={`mailto:${user?.company.email}`}>
              Contact your admin
            </Button>
          </div>
        ) : undefined
      }
    />
  );
};

export default RestrictedAccessEmptyState;
