import React, { useState } from 'react';
import SectionMessage from '@atlaskit/section-message';
import BackendPage from '../../../../layout/BackendPage';
import StartButton from './StartButton';
// import AsyncSearchFormOption from '../../../../shared/asyncSearch/AsyncSearchFormOptionLabel';
import iWarehouseLocation from '../../../../types/warehouse/iWarehouse';
import WarehouseService from '../../../../services/WarehouseService';
// import { getWarehouseOptionLabel } from '../shared/BulkStockTake.util';
import {
  FlexContainer,
  PageTitle,
  SpaceHorizontalWrapper,
  SpaceVerticalWrapper,
} from '../../../../shared/styles/styles';
import { BULK_STOCK_PAGE_NAME, BULK_STOCK_WARNING_DESCRIPTION } from '../shared/BulkStockTake.constants';
import AsyncSearchFormOptionLabel from '../../../../shared/hookForms/asyncSearch/AsyncSearchFormOptionLabel';
import { getWareHouseLocationOptionLabel } from '../../../../shared/Utilities';
import { iBulkStockTakeLocalStorage } from '../shared/BulkStockTake.type';

const NewBulk = ({
  callback,
}: {
  callback: ({ stockTakeIds, parentLocationId, finishedStockTake }: iBulkStockTakeLocalStorage) => void;
}) => {
  const [state, setState] = useState<string>();
  const onSelect = (warehouse: iWarehouseLocation | null) => {
    setState(warehouse ? warehouse.id : undefined);
  };
  return (
    <BackendPage pageHeader={<PageTitle className={'median'}>{BULK_STOCK_PAGE_NAME}</PageTitle>}>
      <SpaceHorizontalWrapper className={'large'} />
      <SectionMessage appearance={'warning'} title={'Beware'}>
        <p>{BULK_STOCK_WARNING_DESCRIPTION}</p>
      </SectionMessage>
      <SpaceVerticalWrapper className={'median'} />
      <div style={{ minWidth: '280px' }}>
        <AsyncSearchFormOptionLabel
          onSelect={onSelect}
          promiseFn={(keyword: string) =>
            WarehouseService.getWarehouseListAsyncSearch({
              like: `name:${keyword},locationCode:${keyword}`,
            })
          }
          searchBarPlaceholder={'Search by locationCode/name...'}
          formatOptionLabel={getWareHouseLocationOptionLabel}
          shouldControlRenderValue
        />
        {/* <AsyncSearchFormOption
          onSelect={onSelect}
          promiseFn={(keyword: string) =>
            WarehouseService.getWarehouseListAsyncSearch({
              like: `name:${keyword},locationCode:${keyword}`,
            })
          }
          searchBarPlaceholder={'search by location code or name...'}
          shouldControlRenderValue
          clearAfterSelect={false}
          formatOptionLabel={getWarehouseOptionLabel}
        /> */}
      </div>
      {/* <Scanner onScanSuccess={onScanSuccess} /> */}
      <SpaceVerticalWrapper className={'median'} />
      <FlexContainer className={'space-between'}>
        <div />
        <StartButton locationId={state} callback={callback} />
      </FlexContainer>
      <SpaceHorizontalWrapper className={'large'} />
    </BackendPage>
  );
};

export default NewBulk;
