import React, { useState, useEffect } from 'react';
import iJob from '../../../../../types/job/iJob';
import iJobCategory from '../../../../../types/job/iJobCategory';
import iAddress from '../../../../../types/contactCompany/iAddress';
import { iModalBodyData } from '../shared/CreateDNItem.types';
import ModalBodyRender from '../shared/ModalBodyRender';
import useListCrudHook from '../../../../../shared/hooks/useListCrudHook/useListCrudHook';
import useStatusSelector from '../../../../../shared/hooks/useStatusSelector';
import EntityStatusCategoryService from '../../../../../services/EntityStatusCategoryService';
import { jobRenderSpecialContent } from '../shared/SharedTableHelper';
import { apiErrorToast } from '../../../../../shared/toast/ToastHandler';
import { JOB_CREATE_COLUMNS, SELECT_JOBS } from '../shared/CreateDNItem.constants';
import JobService from '../../../../../services/JobService';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import { AVOID_PAGINATION_PER_PAGE } from '../../../../../shared/constants/AsyncConstants';
import ContactCompanyProductService from '../../../../../services/ContactCompanyProductService';

type iState = {
  isLoading: boolean;
  jobCategories: Array<iJobCategory>;
  isServicePovideLoading: boolean;
  ccpList: Array<iContactCompanyProduct>;
};

export type iCreateModalBodyProps = {
  onCreateCallback?: () => void;
  customerId?: string;
  DNId: string;
  //  eslint-disable-next-line
  onCreate: (data: any) => Promise<void>;
  DNShippingAddress?: iAddress;
  DNItemLength?: number;
  isServiceProvider?: boolean;
  customerShippingAddress?: iAddress;
};

const CreateModalBody = ({
  onCreateCallback,
  customerId,
  DNId,
  onCreate,
  DNShippingAddress,
  DNItemLength,
  isServiceProvider,
  customerShippingAddress,
}: iCreateModalBodyProps) => {
  const initialState: iState = {
    isLoading: true,
    jobCategories: [],
    isServicePovideLoading: !!isServiceProvider,
    ccpList: [],
  };
  const [supState, setSupState] = useState(initialState);
  const { selected, categories, isStatusLoading } = useStatusSelector({
    type: 'SalesOrder',
    isMulti: true,
    getFn: EntityStatusCategoryService.getEntityCategoryList,
  });

  const { state, edit, onAdvancedFilter, onSetCurrentPage, onSearch } = useListCrudHook<iJob>({
    getFn: JobService.getJobListConfig,
    paginationApplied: true,
    sort: 'jobNumber:DESC',
    notRenderWithoutFilter: true,
    keywordColumns: 'jobNumber,salesOrder.orderNumber,product.productCode,product.name',
  });

  useEffect(
    () => {
      if (isStatusLoading || supState.isServicePovideLoading) return;
      const qtyCondition = 'qtyTotal>0,qtyTotal>{qtyToMake}';
      const ccpCondition = `productId:${supState.ccpList
        .map((item: iContactCompanyProduct) => item.productId)
        .join('|')}`;
      const customerCondition = `SalesOrder_salesOrderId.customerId:${customerId}`;
      const statusCondition = `SalesOrder_salesOrderId.statusId:${selected.join('|')}`;
      if (isServiceProvider && !supState.isServicePovideLoading) {
        onAdvancedFilter(`${qtyCondition},${ccpCondition},${statusCondition}`);
        return;
      }
      if (!isServiceProvider) {
        onAdvancedFilter(`${qtyCondition},${customerCondition},${statusCondition}`);
      }
    },
    //  eslint-disable-next-line
    [
      //  eslint-disable-next-line
      JSON.stringify(selected),
      customerId,
      isServiceProvider,
      //  eslint-disable-next-line
      JSON.stringify(supState.ccpList),
    ],
  );

  useEffect(() => {
    let isCancelled = false;
    const fetchCCPList = async () => {
      if (!isServiceProvider) return;
      setSupState(prevState => ({
        ...prevState,
        isServicePovideLoading: true,
      }));
      try {
        const { data } = await ContactCompanyProductService.getContactCompanyProducts({
          filter: `contactCompanyId:${customerId}`,
          currentPage: '1',
          perPage: AVOID_PAGINATION_PER_PAGE,
        });
        if (isCancelled) return;
        setSupState(prevState => ({
          ...prevState,
          isServicePovideLoading: false,
          ccpList: data,
        }));
      } catch (error) {
        if (isCancelled) return;
        setSupState(prevState => ({
          ...prevState,
          isServicePovideLoading: false,
        }));
        apiErrorToast(error);
      }
    };
    fetchCCPList();
    return () => {
      isCancelled = true;
    };
  }, [isServiceProvider, customerId]);

  useEffect(() => {
    let isCancelled = false;
    const loadSupData = async () => {
      setSupState(prevState => ({
        ...prevState,
        isLoading: true,
      }));
      try {
        const jobCategories: Array<iJobCategory> = await JobService.getJobCategories();
        if (isCancelled) return;
        setSupState(prevState => ({
          ...prevState,
          jobCategories,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        setSupState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
        apiErrorToast(error);
      }
    };
    loadSupData();
    return () => {
      isCancelled = true;
    };
  }, []);

  const exportData: iModalBodyData<iJob> = {
    isLoading: state.isLoading || isStatusLoading || supState.isLoading,
    state,
    keyword: edit.keyword,
    columns: JOB_CREATE_COLUMNS,
    pageName: SELECT_JOBS,
    categories,
    onSearch,
    onSetCurrentPage,
    renderSpecialContent: jobRenderSpecialContent({
      onCreate,
      onCreateCallback,
      DNId,
      jobCategories: supState.jobCategories,
      DNShippingAddress,
      DNItemLength,
      isServiceProvider,
      customerShippingAddress,
    }),
  };
  //  eslint-disable-next-line
  return <ModalBodyRender {...exportData} />;
};

export default CreateModalBody;
