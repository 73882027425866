import React, { createContext, useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/makeReduxStore';
import FormService from '../../../services/FormService';
import iFormTaggable from '../../../types/form/iFormTaggable';
import iJobStatus from '../../../types/job/iJobStatus';
import iJob from '../../../types/job/iJob';
import iJobCategory from '../../../types/job/iJobCategory';
import JobService from '../../../services/JobService';
import { apiErrorToast } from '../../toast/ToastHandler';
import { STATUS_CATEGORY_NEW } from '../../constants/JobCategoryConstants';
import { FORMTAGGABLE_CONTINUE_JOB, FORMTAGGABLE_NORMAL } from '../../constants/FormTaggableConstants';
import FormManagementUtils from '../formManagement/FormManagement.utils';

// formStatusId=>formTaggable.entityId=>formTaggable to select form
// jobStatusId=>job.statusId to control the progress of progress bar and progress select
// the comparsion between formStatusId and jobStatusId to decide whether forward job.status
type iState = {
  isLoading: boolean;
  formTaggableList: Array<iFormTaggable>;
  isFormOpen: boolean;
  formStatusId: string;
  jobStatusId: string;
  isWIPJob?: boolean;
  formTaggable?: iFormTaggable;
};

// eslint-disable-next-line
const StatusContext = createContext<any>(null);
const StatusContextProvider = ({
  jobDetail,
  isWIPJob,
  jobStatusCategories,
  children,
}: {
  jobDetail: iJob;
  isWIPJob?: boolean;
  jobStatusCategories: Array<iJobCategory>;
  children: React.ReactNode;
}) => {
  const { id, statusId } = jobDetail;
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const initialState: iState = {
    isLoading: true,
    formTaggableList: [],
    isFormOpen: false,
    formStatusId: statusId,
    jobStatusId: statusId,
    isWIPJob,
  };
  const [state, setState] = useState(initialState);

  const currentJobShiftJobId = currentShift?.currentJobShiftJobId || '';

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const formTaggableList = await JobService.getFormTaggableList(id);
        const hasContinued = await FormService.isJobShiftJobContinued(currentJobShiftJobId);
        const formTaggable = isWIPJob
          ? formTaggableList.find((item: iFormTaggable) => item.step === FORMTAGGABLE_CONTINUE_JOB)
          : undefined;

        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          formTaggableList,
          isFormOpen: !hasContinued && !!isWIPJob,
          formTaggable,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [id, isWIPJob, currentJobShiftJobId]);

  // map raw status to labelValuePair to adapt progress bar and progress select inside the value of provider
  // in order to avoid refactor progressBar and progressSelect
  const statusMenu = React.useMemo(
    () =>
      _.sortBy(
        jobStatusCategories.reduce(
          (acc: Array<iJobStatus>, item: iJobCategory) =>
            item.name.toUpperCase() === STATUS_CATEGORY_NEW ? acc : [...acc, ...item.jobStatuses],
          [],
        ),
        'sortOrder',
      ),
    [jobStatusCategories],
  );

  const openFormModal = (formStatusId: string) => {
    const formTaggable = state.formTaggableList.find(
      (item: iFormTaggable) => item.entityId === formStatusId && item.step === FORMTAGGABLE_NORMAL,
    );
    setState(prevState => ({ ...prevState, isFormOpen: true, formStatusId, formTaggable }));
  };

  // isWIPJob set to false to avoid affecting choose correct form taggable id
  const closeFormModal = () =>
    setState(prevState => ({
      ...prevState,
      isFormOpen: false,
      isWIPJob: false,
      formTaggable: undefined,
    }));

  const forwardJobStatus = async () => {
    // close form modal
    setState(prevState => ({
      ...prevState,
      isFormOpen: false,
      isWIPJob: false,
    }));
    // jobStatus is ahead of formStatus/ job Status category is fininshed, don't need to forward job status
    if (
      FormManagementUtils.compareTheIndexOfJobStatus(state.jobStatusId, state.formStatusId, statusMenu) ||
      FormManagementUtils.classifyJobCategoryByStatusId(jobStatusCategories, state.jobStatusId).isFinished
    ) {
      return;
    }
    try {
      const response = FormManagementUtils.isJobStatusTheEndOfInProgress(state.jobStatusId, jobStatusCategories)
        ? await JobService.finishJob(id)
        : await JobService.moveJobStatus(id);
      setState(prevState => ({
        ...prevState,
        jobStatusId: response.data.statusId,
      }));
    } catch (error) {
      apiErrorToast(error);
    }
  };
  return (
    <StatusContext.Provider
      value={{
        ...state,
        statusMenu: statusMenu.map((item: iJobStatus) => ({ label: item.name, value: item.id })),
        openFormModal,
        closeFormModal,
        forwardJobStatus,
        isJobStatusFinished: FormManagementUtils.classifyJobCategoryByStatusId(jobStatusCategories, state.jobStatusId)
          .isFinished,
        isFormStatusFinished: FormManagementUtils.classifyJobCategoryByStatusId(jobStatusCategories, state.formStatusId)
          .isFinished,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};
export const useStatusContext = () => useContext(StatusContext);
export default StatusContextProvider;
