import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { useHistory, useParams } from 'react-router-dom';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';
import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/ToastHandler';
import BackendPage from '../../../layout/BackendPage';
import { iRouteTypeId } from '../../../types/iRoute';
import PoDetail from './PoDetail';
import ReceivingItems from './ReceivingItems';
import Header from './Header';
import { LabelValue, SpinnerWrapper } from '../../../shared/styles/styles';
import ProductService from '../../../services/ProductService';
import iProduct from '../../../types/product/iProduct';
import SelectWithController from '../../../shared/hookForms/SelectWithController';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import InputWithController from '../../../shared/hookForms/InputWithController';
import ModalPage from '../../../shared/modalPage/ModalPage';
import iPoReceiving from '../../../types/purchaseOrder/iPoReceiving';
import iEntityCategory from '../../../types/status/iEntityCategory';
import PoReceivingService from '../../../services/PoReceivingService';
import EntityStatusCategoryService from '../../../services/EntityStatusCategoryService';
import WarehouseService from '../../../services/WarehouseService';
import PoItems from './PoItems';
import { createPoReceivingItem, updatePoReceivingItem } from '../../../services/PoReceivingItemService';
import iPurchaseOrderItem from '../../../types/purchaseOrder/iPurchaseOrderItem';
import iReceivingItem from '../../../types/purchaseOrder/iReceivingItem';
import { handleNullException } from '../../../services/UtilsService';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_RECEIVING } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';
import { PURCHASE_ORDER_RECEIVING_URL } from '../../../shared/UrlMap';
import { divExact, mulExact } from '../../../shared/calculationHelper/calculationHelper';

type iState = {
  version: number;
};

const initialState: iState = {
  version: 0,
};

const PoReceiving = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalState, setModalState] = useState(initialState);
  const [localPoReceiving, setLocalPoReceiving] = useState<iPoReceiving>();
  const [localWarehouseLocations, setLocalWarehouseLocations] = useState<iWarehouseLocation[]>([]);
  const [localCategories, setLocalCategories] = useState<Array<iEntityCategory>>([]);
  const [isReceivingModalOpen, setIsReceivingModalOpen] = useState(false);
  const [targetPoItem, setTargetPoItem] = useState<iPurchaseOrderItem>();
  const [targetReceivingItem, setTargetReceivingItem] = useState<iReceivingItem>();
  const [targetProduct, setTargetProduct] = useState<iProduct>();
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_STOCK_RECEIVING, ACCESS_CAN_UPDATE, user);

  const { control, setValue, errors, handleSubmit } = useForm();
  const history = useHistory();

  const { id } = useParams<iRouteTypeId>();

  useEffect(() => {
    let mounted = true;
    const fetchPO = async () => {
      setIsLoading(true);
      try {
        const poReceivingData = await PoReceivingService.getPoReceivingDetail(id);
        const warehouseLocations = await WarehouseService.getWarehouseList();
        const categories: Array<iEntityCategory> = await EntityStatusCategoryService.getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:PurchaseOrderReceiving',
        });

        if (!mounted) return;
        setLocalPoReceiving(poReceivingData);
        setLocalWarehouseLocations(warehouseLocations);
        setLocalCategories(categories);
      } catch (e) {
        addToastForAPIResponse('error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPO();
    return () => {
      mounted = false;
    };
  }, [id, modalState.version]);

  const onRefresh = () => {
    setModalState({ ...modalState, version: modalState.version + 1 });
  };

  const onSelectReceivingProduct = async (productId: string) => {
    try {
      const localProduct = await ProductService.getProductDetail(productId);
      const target = localPoReceiving?.purchaseOrder?.purchaseOrderItems.find(
        item => item.productId === localProduct.id,
      );
      setIsReceivingModalOpen(true);
      setTargetPoItem(target);
      setTargetProduct(localProduct);
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onCreateReceivingItem = async (data: { [key: string]: string }) => {
    try {
      if (!targetProduct) return;
      // eslint-disable-next-line no-unused-expressions
      targetReceivingItem
        ? await updatePoReceivingItem(targetReceivingItem.id, {
            ...data,
            productId: targetProduct.id,
            purchaseOrderReceivingId: id,
            purchaseOrderItemId: targetPoItem?.id,
            qty: targetPoItem ? mulExact(Number(data.qty), Number(targetPoItem.unitConversion)) : Number(data.qty),
          })
        : await createPoReceivingItem({
            ...data,
            productId: targetProduct.id,
            purchaseOrderReceivingId: id,
            purchaseOrderItemId: targetPoItem?.id,
            qty: targetPoItem ? mulExact(Number(data.qty), Number(targetPoItem.unitConversion)) : Number(data.qty),
          });
      setIsReceivingModalOpen(false);
      history.push(PURCHASE_ORDER_RECEIVING_URL.replace(':id', id));
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onOpenEditReceivingItemModalPage = (item: iReceivingItem) => {
    setIsReceivingModalOpen(true);
    setTargetProduct(item.product);
    setTargetPoItem(item.purchaseOrderItem);
    setTargetReceivingItem(item);
  };

  const getUnitConversionSuffix = (inputValue: string | number) => {
    let suffix = '';
    let productQty;
    const input = Number.isNaN(Number(inputValue)) ? 0 : Number(inputValue);

    const inputMeasure = targetPoItem?.measurement?.shortName || targetProduct?.measurement.shortName;
    const productMeasure = targetProduct?.measurement.shortName;
    // targeting poItem
    if (targetPoItem?.id) {
      suffix = `${inputMeasure} = ${
        // eslint-disable-next-line radix
        mulExact(Number(targetPoItem.unitConversion), input)
      } ${productMeasure}`;

      productQty = inputValue ? mulExact(Number(targetPoItem.unitConversion), Number(inputValue)) : '';
    }

    // not targeting poItem
    if (!targetPoItem?.id) {
      suffix = `${productMeasure} = ${input} ${productMeasure}`;
      productQty = inputValue ? Number(inputValue) : '';
    }

    const qtyString = productQty?.toString();

    return { suffix, qtyString };
  };

  const getReceivingModalBody = () => (
    <>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={4}>
          <Label label="Product SKU" />
          <LabelValue>{targetProduct?.productCode}</LabelValue>
        </GridColumn>
        <GridColumn medium={4}>
          <Label label="Product Name" />
          <LabelValue>{targetProduct?.name}</LabelValue>
        </GridColumn>
        <GridColumn medium={4}>
          <Label label="Supplier Product Code / SKU" />
          <LabelValue>
            {targetReceivingItem
              ? targetReceivingItem?.purchaseOrderItem?.supplierProductCode
              : targetPoItem?.supplierProductCode}
          </LabelValue>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={12}>
          <Label label="Product Description" />
          <LabelValue>{targetProduct?.shortDescription}</LabelValue>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={4}>
          <SelectWithController
            name={'toWarehouseLocationId'}
            label={'To Location'}
            defaultValue={
              targetReceivingItem
                ? targetReceivingItem.toWarehouseLocationId
                : handleNullException(user, 'defaultFacility.defaultInwardsLocationId')
            }
            control={control}
            onChange={setValue}
            options={localWarehouseLocations
              ?.filter(location => location.allowParts === true)
              .map((location: iWarehouseLocation) => ({
                label: location.name,
                value: location.id,
              }))}
            testId={'receiving-product-modal-toLocation'}
            errors={errors}
            isRequired
          />
        </GridColumn>
        <GridColumn medium={5}>
          <InputWithController
            name={'qty'}
            label={'Receiving Qty'}
            defaultValue={
              targetReceivingItem
                ? divExact(targetReceivingItem.qty, Number(targetReceivingItem.purchaseOrderItem?.unitConversion || 1))
                : undefined
            }
            control={control}
            onChange={setValue}
            errors={errors}
            isRequired
            isNumeric
            testId={'receiving-product-modal-receivingQty'}
            isUnitConversion
            getUnitConversionSuffix={getUnitConversionSuffix}
          />
        </GridColumn>
        <GridColumn medium={3}>
          <InputWithController
            name={'reference'}
            label={'Lot / Batch No.'}
            defaultValue={targetReceivingItem?.reference}
            control={control}
            onChange={setValue}
            errors={errors}
            isRequired
            testId={'receiving-product-modal-batchNumber'}
          />
        </GridColumn>
      </Grid>
    </>
  );

  const getPageHeader = () => <Header poReceiving={localPoReceiving} categories={localCategories} id={id} />;

  // eslint-disable-next-line no-nested-ternary
  return !isLoading ? (
    isReceivingModalOpen ? (
      <ModalPage
        heading={`Receiving Product: ${targetProduct?.productCode}`}
        onConfirm={handleSubmit(onCreateReceivingItem)}
        onCancel={() => setIsReceivingModalOpen(false)}
        confirmBtnName="Receive"
        confirmBtnAppearance="primary"
        isDisabled={Object.keys(errors).length > 0}
      >
        {getReceivingModalBody()}
      </ModalPage>
    ) : (
      <BackendPage pageHeader={getPageHeader()}>
        <Page>
          <PoDetail poReceiving={localPoReceiving} />
          <PoItems poReceiving={localPoReceiving} />
          <ReceivingItems
            id={id}
            onSelectReceiving={onSelectReceivingProduct}
            onRefresh={onRefresh}
            poReceiving={localPoReceiving}
            onOpenEdit={onOpenEditReceivingItemModalPage}
            canCreate={canUpdate}
            canUpdate={canUpdate}
            canDelete={canUpdate}
          />
        </Page>
      </BackendPage>
    )
  ) : (
    <SpinnerWrapper>
      <Spinner size="xlarge" />
    </SpinnerWrapper>
  );
};

export default PoReceiving;
