import iJobAttribute from '../../../types/job/iJobAttribute';
import { JOB_ATTRIBUTE_KG, JOB_ATTRIBUTE_METER, JOB_ATTRIBUTE_ROLL } from './RollPrint.constants';

const extractJobAttribute = (attributes: Array<iJobAttribute>) => {
  const results = [JOB_ATTRIBUTE_METER, JOB_ATTRIBUTE_KG, JOB_ATTRIBUTE_ROLL].map((attrName: string) => {
    let jobAttribute = attributes.find((item: iJobAttribute) => item.name.trim().toUpperCase() === attrName);
    let value = jobAttribute?.jobAttributeValues.value;
    if (!jobAttribute || !value) {
      return 0;
    }
    // Roll(s) value can be value+unit eg:40 roll
    if (attrName === JOB_ATTRIBUTE_ROLL) {
      //  eslint-disable-next-line
      value = value.split(' ')[0];
      jobAttribute = {
        ...jobAttribute,
        jobAttributeValues: { ...jobAttribute.jobAttributeValues, value },
      };
    }
    if (Number.isNaN(Number(value))) {
      return 0;
    }
    return Number(jobAttribute.jobAttributeValues.value);
  });
  return {
    length: results[0],
    nominalWeight: results[1],
    rollNumber: results[2],
  };
};

export default { extractJobAttribute };
