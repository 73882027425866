import { iConfigColumn } from '../UITypes/types';
import { JOB_URL } from '../UrlMap';

export const CURRENT_TITLE = `It looks like you've got a few jobs to take care of today,
let start your shift by starting one of the jobs below:`;
export const FUTURE_TITLE = "It looks like you've got a few jobs to take care of in future";
export const CURRENT_JOB_LIST = 'current-job-list';
export const FUTURE_JOB_LIST = 'future-job-list';
export const JOB_LIST_INSERT_INDEX = 6;
export const JOB_LIST_FIXED_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Job',
    key: 'id',
    dataSource: ['jobNumber'],
    urlPrefix: JOB_URL,
    type: 'jobNumberLink',
    isSelected: true,
    isCompulsory: true,
    group: '',
  },
  {
    name: 'Status',
    key: 'status',
    dataSource: ['status'],
    type: 'jobStatus',
    isSelected: true,
    group: '',
  },
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.name'],
    type: 'secondary-text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Qty To Finish',
    key: 'qtyToMake',
    dataSource: ['product.measurement.shortName'],
    type: 'text-postfix',
    isSelected: true,
    group: '',
  },
  {
    name: 'Machine',
    key: 'machine.name',
    dataSource: [],
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Scheduled',
    key: 'scheduledAt',
    dataSource: [],
    type: 'date',
    isSelected: true,
    group: '',
  },

  {
    name: '',
    key: 'print',
    dataSource: ['id', 'jobAttributes', 'productId'],
    type: 'print',
    isSelected: true,
    isCompulsory: true,
    group: '',
  },
  {
    name: '',
    key: 'operation',
    dataSource: ['status'],
    type: 'operation',
    isSelected: true,
    group: '',
    isCompulsory: true,
  },
];
export const MODAL_HEADING = 'Are you sure to [STARTORCONTINUE] it?';
export const MODAL_BODY = 'Please confirm you are about to [STARTORCONTINUE] it';
