import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@atlaskit/button';
import JobService from '../../services/JobService';
import { iJobSummaryTableRow } from '../../shared/job/jobSummaryTable/types';
import JobSummaryTable from '../../shared/job/jobSummaryTable/JobSummaryTable';
import ShiftSummary from '../../shared/dynamicForm/shiftSummary/ShiftSummary';
import { removedAuthentication } from '../../redux/reduxers/auth.slice';
import { AUTH_URL_LOGIN } from '../../shared/UrlMap';
import AuthService from '../../services/AuthService';
import { RootState } from '../../redux/makeReduxStore';
import iJobShiftJob from '../../types/job/iJobShiftJob';
import iJobCategory from '../../types/job/iJobCategory';
import { STATUS_CATEGORY_IN_PROGRESS } from '../../shared/constants/JobCategoryConstants';
import iJobStatus from '../../types/job/iJobStatus';
import iJob from '../../types/job/iJob';
import { addToastForAPIResponse } from '../../shared/toast/ToastHandler';
import iJobShiftWaste from '../../types/job/iJobShiftWaste';
import { clearShift } from '../../redux/reduxers/shift.slice';
import BackendPage from '../../layout/BackendPage';
import { PageHeader, PageBody, PageFooter } from '../../shared/job/jobProgress/style';
import { addExact } from '../../shared/calculationHelper/calculationHelper';

const FinishingShiftPage = () => {
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [jobSummaryList, setJobSummaryList] = useState<Array<iJobSummaryTableRow>>([]);
  const [openJobList, setOpenJobList] = useState<Array<iJob>>([]);
  //  eslint-disable-next-line
  const [isValid, setIsValid] = useState(true);

  const doLogout = () => {
    AuthService.logout().then(() => {
      dispatch(removedAuthentication());
      dispatch(clearShift());
      history.push(AUTH_URL_LOGIN);
    });
  };
  const doFinishShift = async () => {
    if (!currentShift) return;
    try {
      await JobService.finishJobShift(currentShift.id);
      doLogout();
    } catch (error) {
      addToastForAPIResponse('error');
      history.goBack();
    }
    // showFinishingShiftModal(false);
  };
  const close = () => {
    history.goBack();
    //  showFinishingShiftModal(false);
  };
  const formatHeading = () =>
    openJobList.length === 0
      ? 'Finishing your shift'
      : `Finishing your shift with open job# ${openJobList[0].jobNumber}`;

  useEffect(() => {
    if (!currentShift) return;
    let isCancelled = false;

    const isWIPJob = (job: iJob, jobCategories: Array<iJobCategory>) => {
      const inProgressCategory = jobCategories.find((item: iJobCategory) => item.name === STATUS_CATEGORY_IN_PROGRESS);
      return inProgressCategory &&
        inProgressCategory.jobStatuses.find((status: iJobStatus) => status.id === job.status.id)
        ? job
        : undefined;
    };

    const loadJobShiftJobs = async () => {
      setIsLoading(true);
      try {
        const jobShiftJobs = await JobService.getJobShiftJobs(currentShift.id);
        const jobCategories = await JobService.getJobStatus();
        const openJobs: Array<iJob> = [];
        const summaryList = jobShiftJobs.map((item: iJobShiftJob) => {
          const wipJob = isWIPJob(item.job, jobCategories);
          if (wipJob) openJobs.push(wipJob);
          return {
            id: item.id,
            waste: item.jobShiftWastes.reduce(
              (partialSum: number, a: iJobShiftWaste) => addExact(partialSum, Number(a.qty)),
              0,
            ),
            jobNumber: item.job.jobNumber,
            product: item.job.product.name,
            produced: item.qtyProduced,
            status: item.job.status.name,
          };
        });
        if (isCancelled) return;
        setJobSummaryList(summaryList);
        setOpenJobList(openJobs);
        setIsLoading(false);
      } catch (error) {
        if (isCancelled) return;
        addToastForAPIResponse('error');
        setIsLoading(false);
      }
    };

    loadJobShiftJobs();
    // eslint-disable-next-line
    return () => {
      isCancelled = true;
    };
  }, [currentShift]);
  const getLogoutBtn = () => {
    return (
      <Button appearance={'warning'} onClick={doLogout} testId={'logout-btn'}>
        Logout
      </Button>
    );
  };
  return (
    <BackendPage>
      <PageHeader>
        <h4>{formatHeading()}</h4>
        <Button onClick={close} testId={'close-btn'}>
          X
        </Button>
      </PageHeader>
      <PageBody>
        {openJobList.length !== 0 && (
          <ShiftSummary jobId={openJobList[0].id} measurementUnit={openJobList[0].product.measurement.shortName} />
        )}
        <JobSummaryTable rows={jobSummaryList} isLoading={isLoading} />
      </PageBody>
      <PageFooter>
        <div />
        <div>
          {!isValid && <span className={'invalid-value-detected'}>Invalid value detected</span>}
          <Button onClick={doFinishShift} appearance="primary" isDisabled={!isValid} testId={'confirm-btn'}>
            Confirm
          </Button>
          {getLogoutBtn()}
          <Button onClick={close} testId={'cancel-btn'}>
            Cancel
          </Button>
        </div>
      </PageFooter>
    </BackendPage>
  );
};
export default FinishingShiftPage;
