import SectionMessage from '@atlaskit/section-message';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../redux/makeReduxStore';
import { removedAuthentication } from '../../redux/reduxers/auth.slice';
import { updateCurrentShift } from '../../redux/reduxers/shift.slice';
import AuthService from '../../services/AuthService';
import JobService from '../../services/JobService';
import CustomizeModal from '../modal/CustomizeModal';
import { apiErrorToast } from '../toast/ToastHandler';
import { AUTH_URL_LOGIN } from '../UrlMap';

const getBodyMessage = () => (
  <SectionMessage appearance={'warning'}>
    Your shift is ended by someone,Would you mind to start a new shift? Otherwise, you will be forced logout
  </SectionMessage>
);
const RestartJobShiftJob = ({
  jobId,
  isOpen,
  closeValidate,
}: {
  jobId: string;
  isOpen: boolean;
  closeValidate: () => void;
}) => {
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const dispatch = useDispatch();
  const history = useHistory();

  const onCancel = async () => {
    try {
      await AuthService.logout();
      dispatch(removedAuthentication());
      history.push(AUTH_URL_LOGIN);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const onConfirm = async () => {
    try {
      let newShift = currentShift;
      // no shift exist,need to start one
      if (!currentShift) {
        newShift = await JobService.startJobShift(jobId);
      }
      if (!newShift) {
        return;
      }
      const currentJobShiftJob = await JobService.createJobShiftJob(newShift.id, jobId);
      dispatch(
        updateCurrentShift({
          currentShift: {
            ...newShift,
            currentJobShiftJobId: currentJobShiftJob.id,
          },
        }),
      );
      closeValidate();
    } catch (error) {
      apiErrorToast(error);
    }
  };

  return (
    <CustomizeModal
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      modalBody={getBodyMessage()}
      modalHeading={'Invalid shift'}
    />
  );
};

export default RestartJobShiftJob;
