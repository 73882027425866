import React, { useState } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';

const ModalContentWrapper = styled.div`
  min-height: 40px;
  .soft-delete-confirm-info {
    display: block;
    margin: 4px 0px 8px;
  }
`;
export type iDeleteModalContentProps = {
  answer: string;
  onTypeAnswer: (isRightAnswer: boolean) => void;
};

const DeleteModalContent = ({ answer, onTypeAnswer }: iDeleteModalContentProps) => {
  const [value, setValue] = useState('');
  //  eslint-disable-next-line
  const onChange = (event: any) => {
    setValue(event.target.value);
    onTypeAnswer(event.target.value.trim() === answer);
  };
  return (
    <ModalContentWrapper className={'modal-content-wrapper'}>
      <span className={'soft-delete-confirm-info'}>
        Before you delete it, Please type <strong data-testid={'answer'}>{answer}</strong> to confirm
      </span>
      <Textfield value={value} onChange={onChange} isCompact testId={'textfield-enter-answer'} />
    </ModalContentWrapper>
  );
};

export default DeleteModalContent;
