import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import HipchatChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up';
import Button from '@atlaskit/button';

import SectionMessage from '@atlaskit/section-message';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import LinkBtn from '../../../shared/buttons/LinkBtn';
import iReceivingItem from '../../../types/purchaseOrder/iReceivingItem';
import ProductService from '../../../services/ProductService';
import { getHeads, getQtyBeforeConversion } from '../Purchase.utilities';
import Activities from '../../../shared/activities/Activities';
import DeleteBtn from '../../../shared/buttons/DeleteBtn';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import {
  createPoReceivingItem,
  deletePoReceivingItem,
  getPoReceivingItems,
  updatePoReceivingItem,
} from '../../../services/PoReceivingItemService';
import { PO_RECEIVING_STATUS_CLOSED_CATEGORY } from '../constants';
import { handleNullException } from '../../../services/UtilsService';
import iPoReceiving from '../../../types/purchaseOrder/iPoReceiving';

import { FlexContainer, PageTitle } from '../../../shared/styles/styles';
import ApiModal from '../../../shared/modal/ApiModal';
import PoReceivingService from '../../../services/PoReceivingService';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';
import iProduct from '../../../types/product/iProduct';
import AsyncSearchFormOptionLabel from '../../../shared/hookForms/asyncSearch/AsyncSearchFormOptionLabel';
import { getProductOptionLabel } from '../../../shared/Utilities';
import DataTable from '../../../shared/tableHelper/DataTable';

const TableWrapper = styled.div`
  min-height: 2.5rem;
`;

const AsyncSearchWrapper = styled.div`
  width: 20rem;
  margin: 1rem 0 0 0;
`;

const AttachmentsWrapper = styled.div`
  display: inline-block;
  margin-top: 3rem;
  max-width: 100%;
`;

const ActivitiesWrapper = styled.div``;

const ChevronContainer = styled.div`
  display: inline-block;
`;

const TitleWithChevron = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const COLUMNS = ['Product', 'Supplier Product Code', 'Quantity', 'To Location', 'Batch / Lot Number', 'Operation'];

const ReceivingItems = ({
  id,
  onSelectReceiving,
  poReceiving,
  onRefresh,
  onOpenEdit,
  canCreate = true,
  canUpdate = true,
  canDelete = true,
}: {
  id: string;
  onSelectReceiving: (payload: string) => void;
  poReceiving?: iPoReceiving;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  onRefresh: () => void;
  onOpenEdit: (item: iReceivingItem) => void;
}) => {
  const [isShown, setIsShown] = useState(true);

  const { state, onDelete } = useListCrudHook<iReceivingItem>({
    getFn: getPoReceivingItems,
    createFn: createPoReceivingItem,
    updateFn: updatePoReceivingItem,
    deleteFn: deletePoReceivingItem,
    filter: `purchaseOrderReceivingId:${id}`,
    paginationApplied: true,
    perPage: 10,
  });

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const getNameContent = (item: iReceivingItem) => (
    <ComposeSecondaryText secondaryText={item.product.name}>
      <LinkBtn btnName={item.product.productCode} onClick={() => onOpenEdit(item)} />
    </ComposeSecondaryText>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSelect = async (payload: iProduct | null) => {
    if (!payload) return;
    onSelectReceiving(payload.id);
  };

  const onCloseSubmitModal = () => {
    setIsSubmitModalOpen(false);
    onRefresh();
  };

  const getRows = () => {
    return state.data
      ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
      .map((item: iReceivingItem) => ({
        cells: [
          {
            key: item.product.productCode,
            content:
              poReceiving?.status?.entityStatusCategory?.code === PO_RECEIVING_STATUS_CLOSED_CATEGORY || !canUpdate ? (
                <ComposeSecondaryText secondaryText={item.product.name}>
                  <span style={{ fontWeight: 500 }}>{item.product.productCode}</span>
                </ComposeSecondaryText>
              ) : (
                getNameContent(item)
              ),
          },
          {
            content: handleNullException(item, 'purchaseOrderItem.supplierProductCode'),
          },
          { content: getQtyBeforeConversion(item) },
          {
            content: (
              <ComposeSecondaryText secondaryText={item.toWarehouseLocation.locationCode}>
                <span style={{ fontWeight: 500 }}>{item.toWarehouseLocation.name}</span>
              </ComposeSecondaryText>
            ),
          },
          { content: `${item.reference}` },
          {
            content:
              poReceiving?.status?.entityStatusCategory?.code === PO_RECEIVING_STATUS_CLOSED_CATEGORY ||
              !canDelete ? null : (
                <DeleteBtn id={item.id} onClick={() => onDelete(item.id)} />
              ),
          },
        ],
      }));
  };

  return (
    <>
      <TitleWithChevron>
        <PageTitle className={'small space-right'}>Receiving Items</PageTitle>
        {isShown ? (
          <ChevronContainer onClick={() => setIsShown(prev => !prev)}>
            <HipchatChevronUpIcon label={'chervronUp'} />
          </ChevronContainer>
        ) : (
          <ChevronContainer onClick={() => setIsShown(prev => !prev)}>
            <HipchatChevronDownIcon label={'chervronDown'} />
          </ChevronContainer>
        )}
      </TitleWithChevron>
      {isShown && (
        <TableWrapper>
          <DataTable
            head={getHeads(COLUMNS, 'po-receiving-items')}
            rows={getRows()}
            testId={'po-receiving-items-table'}
          />
        </TableWrapper>
      )}

      {poReceiving?.status?.entityStatusCategory?.code !== PO_RECEIVING_STATUS_CLOSED_CATEGORY && (
        <FlexContainer className="space-between">
          <AsyncSearchWrapper>
            {canCreate && (
              <AsyncSearchFormOptionLabel
                onSelect={onSelect}
                promiseFn={(keyword: string) =>
                  ProductService.getProductListAsyncSearch({
                    like: `productCode:${keyword},name:${keyword}`,
                  })
                }
                searchBarPlaceholder={'Search by productCode/name...'}
                formatOptionLabel={getProductOptionLabel}
                isDisabled={!canCreate}
              />
            )}
          </AsyncSearchWrapper>
          {canUpdate && (
            <Button
              appearance="primary"
              onClick={() => setIsSubmitModalOpen(true)}
              isDisabled={state.data.length === 0 || !canUpdate}
            >
              Submit
            </Button>
          )}
        </FlexContainer>
      )}

      <AttachmentsWrapper>
        <GeneralAttachments
          entityId={id}
          entityName={'PurchaseOrderReceiving'}
          isTablet
          thumbnailSize={'small'}
          canDelete={canUpdate}
          canCreate={canUpdate}
        />
      </AttachmentsWrapper>

      <ActivitiesWrapper>
        <Activities modalId={id} modalName={'PurchaseOrderReceiving'} />
      </ActivitiesWrapper>
      {isSubmitModalOpen && (
        <ApiModal
          isOpen={isSubmitModalOpen}
          onConfirm={() => PoReceivingService.submitPoReceiving(id)}
          onCancel={onCloseSubmitModal}
          modalHeading={'Are you sure to submit this PO receiving?'}
          modalBody={
            <SectionMessage appearance="warning">
              <p>Please make sure you receive all items in the delivery docket.</p>
            </SectionMessage>
          }
          confirmBtnName={'Submit'}
          successFlagTitle={'Received!'}
        />
      )}
    </>
  );
};

export default ReceivingItems;
