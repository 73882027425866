import React from 'react';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import Collapse from '../../../shared/collapse/Collapse';
import AfterTransferDescription from './AfterTransferDescription';
import TransferProdsTable from '../sharedComponents/TransferProdsTable';
import { COLUMNS_EXCLUDE_DELETE } from '../sharedComponents/StockTransfer.constants';

export type iAfterTransferProps = {
  destination?: iWarehouseLocation;
  transferred: Array<iItemLocationTransfer>;
  onRefresh: () => void;
};
const AfterTransfer = ({ destination, transferred, onRefresh }: iAfterTransferProps) => {
  if (!destination) return null;
  return (
    <>
      <AfterTransferDescription destination={destination} data={transferred} onRefresh={onRefresh} />
      <Collapse defaultOpen text={'Moved Items'}>
        <TransferProdsTable
          data={transferred}
          //  eslint-disable-next-line
          onOpenDeleteModal={(id: string) => {}}
          columns={COLUMNS_EXCLUDE_DELETE}
        />
      </Collapse>
    </>
  );
};

export default AfterTransfer;
