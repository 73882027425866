import React from 'react';
import { LoadingButton } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import CustomIconPrinter from '../customIcon/CustomIconPrinter';

function PrintBtn({ title, onClick, isLoading = false }: { title: string; onClick: () => void; isLoading?: boolean }) {
  return (
    <LoadingButton
      iconBefore={<CustomIconPrinter size={'medium'} />}
      style={{ backgroundColor: `${colors.N600}` }}
      onClick={onClick}
      isLoading={isLoading}
      testId={'print-button'}
    >
      <span style={{ color: 'white', maxWidth: '100%' }}>{title}</span>
    </LoadingButton>
  );
}

export default PrintBtn;
