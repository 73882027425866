import { gridSize } from '@atlaskit/theme';
import styled from 'styled-components';
import iProduct from '../../../types/product/iProduct';
import { iConfigColumn } from '../../UITypes/types';

export const WASTE_TABLE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.name'],
    type: 'secondary-text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Amount',
    key: 'qty',
    dataSource: ['product.measurement.shortName'],
    type: 'text-edit-postfix',
    isSelected: true,
    group: '',
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isSelected: true,
    group: '',
    isCompulsory: true,
  },
];
export const WASTE_TABLE_CAPTION = 'If any waste is produced, please add it by searching productCode/name';
export const WASTE_TABLE_NAME = 'waste-table';

export const SHIFT_SUMMARY_TITLE = 'Please provide all the amounts for your shift:';
export const TOTAL_AMOUNT_TITLE = 'Total Produced Amount:';
// export const TOTAL_AMOUNT_ERROR = 'must be a number';
export const TOTAL_AMOUNT_HELPER =
  'This is the total amount produced so far for this job. Changing the value will overwrite the total produced amount.';

export type iWaste = {
  productId: string;
  qty: string;
};

export type iWasteTableRow = {
  id: string;
  product?: iProduct;
  qty: string;
};

export const SectionWrapper = styled.div`
  margin: 0px ${gridSize() * 2}px ${gridSize()}px ${gridSize() * 2}px;
`;
export const TitleWrapper = styled.p`
  margin-bottom: ${gridSize()}px;
`;
