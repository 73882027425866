import React from 'react';
import styled from 'styled-components';
import { ProgressTracker, Stages } from '@atlaskit/progress-tracker';
import { LabelValuePair } from '../types/LabelValuePair';

const ProgressBarContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;

  max-height: 70px;
  width: 100%;

  padding-bottom: 2em;
  background-color: #fff;
  opacity: 1;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.2);
`;
const ProgressBar = ({
  currentJobStatus,
  onOpenModal,
  allJobStatus,
}: {
  currentJobStatus: LabelValuePair;
  onOpenModal: (status: LabelValuePair) => void;
  allJobStatus: Array<LabelValuePair>;
}) => {
  const updateTracker = (allStatus: Array<LabelValuePair>, currentStatus: LabelValuePair): Stages => {
    const currentIndex = allJobStatus.findIndex((item: LabelValuePair) => item.value === currentStatus.value);
    return currentIndex === -1
      ? []
      : allStatus.map((jobStatus: LabelValuePair, index: number) => ({
          id: jobStatus.value,
          label: jobStatus.label,
          percentageComplete: index < currentIndex ? 100 : 0,
          status:
            // eslint-disable-next-line no-nested-ternary
            index > currentIndex + 1 ? 'unvisited' : 'visited',
          // index === currentIndex
          //   ? 'visited'
          //   : index < currentIndex || index === currentIndex + 1
          onClick: () => onOpenModal(jobStatus),
        }));
  };
  return (
    <ProgressBarContainer data-testid={'job-status-progress-bar'}>
      <ProgressTracker items={updateTracker(allJobStatus, currentJobStatus)} />
    </ProgressBarContainer>
  );
};
export default ProgressBar;
