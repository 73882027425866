import React, { useState } from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';

import { iBtnAppearance } from '../UITypes/types';
import { apiErrorToast } from '../toast/ToastHandler';

const defaultModalHeading = <span>Are you sure to start?</span>;
const defaultModalBody = <p>Please confirm you are about to start it.</p>;

export type iApiModalProps = {
  isOpen?: boolean;
  onConfirm: () => Promise<string>;
  onCancel: () => void;
  //   isConfirming?: boolean;
  successFlagTitle?: string;
  isDisabled?: boolean;
  confirmBtnName?: string;
  confirmBtnAppearance?: iBtnAppearance;
  modalHeading?: React.ReactNode;
  modalBody?: React.ReactNode;
  bottom?: React.ReactNode;
  testIdPrefix?: string;
  width?: 'small' | 'medium' | 'large' | 'x-large' | number | string;
  height?: number | string;
};
const CustomizeModal = ({
  isOpen,
  onConfirm,
  onCancel,
  //   isConfirming = false,
  successFlagTitle = 'Success',
  confirmBtnName = 'Confirm',
  confirmBtnAppearance = 'primary',
  modalHeading = defaultModalHeading,
  isDisabled = false,
  modalBody = defaultModalBody,
  bottom = null,
  testIdPrefix,
  width,
  height,
}: iApiModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [localModalBody, setLocalModalBody] = useState(modalBody);
  const [localModalHeading, setLocalModalHeading] = useState(modalHeading);
  const [isLoadingBtn, setIsLoadingBtn] = useState(true);

  const sendAjax = () => {
    setIsLoading(true);
    onConfirm()
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(res => {
        setIsLoading(false);
        setLocalModalBody('');
        setLocalModalHeading(
          <div style={{ display: 'flex', alignItems: 'center' }} data-testid={'after-succes-modal-heading'}>
            <div style={{ display: 'inline-block', marginRight: '10px' }}>
              <SuccessIcon primaryColor={G300} label="success info" />
            </div>
            <div style={{ display: 'inline-block' }}>{successFlagTitle}</div>
          </div>,
        );
        setIsLoadingBtn(false);
      })
      .catch(apiErrorToast)
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getModal = () => (
    <Modal
      testId={testIdPrefix ? `${testIdPrefix}-customize-modal` : 'customize-modal'}
      onClose={onCancel}
      width={width || 'medium'}
      height={height || 'auto'}
    >
      <ModalHeader>
        <ModalTitle>{localModalHeading}</ModalTitle>
      </ModalHeader>
      <ModalBody>{localModalBody}</ModalBody>
      <ModalFooter>
        <div>{bottom}</div>
        <ButtonGroup>
          {isLoadingBtn && (
            <LoadingButton
              onClick={sendAjax}
              appearance={confirmBtnAppearance}
              isDisabled={isDisabled}
              testId={testIdPrefix ? `${testIdPrefix}-confirm-button` : 'confirm-button'}
              isLoading={isLoading}
            >
              {confirmBtnName}
            </LoadingButton>
          )}
          <Button
            onClick={onCancel}
            appearance={isLoadingBtn ? 'default' : 'primary'}
            testId={testIdPrefix ? `${testIdPrefix}-cancel-button` : 'cancel-button'}
          >
            {isLoadingBtn ? 'Cancel' : 'OK'}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
  return isOpen ? <ModalTransition>{getModal()}</ModalTransition> : null;
};

export default CustomizeModal;
