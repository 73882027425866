import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { iSingleSelectOption } from '../../../../UITypes/types';
import iContactCompany from '../../../../../types/contactCompany/iContactCompany';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import CustomizedLabel from '../../../../hookForms/CustomizedLabel';
import ComposeSecondaryText from '../../../../text/ComposeSecondaryText';
import InputWithController from '../../../../hookForms/InputWithController';
import DateTimePickerWithController from '../../../../hookForms/DateTimePickerWithController';
import AsyncSearchWithController from '../../../../hookForms/AsyncSearchWithController';
import ContactCompanyService from '../../../../../services/ContactCompanyService';
import { SpaceVerticalWrapper } from '../../../../styles/styles';
import { getGeneralOptionLabel } from '../../../../Utilities';
import { getUnitConversionSuffix, handleNullException } from '../../../../../services/UtilsService';

export type iCreatePOModalBodyProps = {
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  // eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  // eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  ccp: iContactCompanyProduct;
};

const CreatePOModalBody = ({ control, errors, onChange, ccp }: iCreatePOModalBodyProps) => {
  return (
    <Page>
      <Grid testId={'full-grid'}>
        <GridColumn medium={12}>
          <AsyncSearchWithController
            testId={'supplierId'}
            label={'Service Provider'}
            name={'supplierId'}
            control={control}
            // eslint-disable-next-line
            onChange={(name: string, value: any, config?: Object) => onChange(name, value.id, config)}
            errors={errors}
            formatOptionLabel={(option: iSingleSelectOption<iContactCompany>) => getGeneralOptionLabel(option, 'name')}
            promiseFn={(keyword: string) =>
              ContactCompanyService.getServiceProviderListAsyncSearch({ like: `name:${keyword}` })
            }
            defaultValue={ccp.contactCompany}
            isRequired
            shouldControlRenderValue
            isDisabled
          />
          {/* <AsyncCreatableWithController
            testId={'supplierId'}
            label={'Service Provider'}
            name={'supplierId'}
            control={control}
            // eslint-disable-next-line
            onChange={(name: string, value: any, config?: Object) => onChange(name, value.id, config)}
            errors={errors}
            promiseFn={ContactCompanyService.getServiceProviderListAsyncSearch}
            optionLabel={'name'}
            isRequired
            defaultValue={ccp.contactCompany ? mapLabelValuePairObjPayload([ccp.contactCompany], 'name')[0] : undefined}
            isCreatable={false}
            isDisabled
          /> */}
        </GridColumn>
        <GridColumn medium={12}>
          <InputWithController
            testId={'supplierRef'}
            label={'Service Provider Reference'}
            name={'supplierRef'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={false}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <DateTimePickerWithController
            testId={'orderDate'}
            label={'Order Date'}
            name={'orderDate'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isDateOnly
            isDefaultNow
          />
        </GridColumn>
        <GridColumn medium={12}>
          <SpaceVerticalWrapper className={'small'} />
          <CustomizedLabel label={'Purchase Item'} />
          <ComposeSecondaryText secondaryText={handleNullException(ccp, 'product.name')}>
            {handleNullException(ccp, 'product.productCode')}
          </ComposeSecondaryText>
        </GridColumn>
        <GridColumn medium={12}>
          <InputWithController
            testId={'qty'}
            label={'Quantity'}
            name={'qty'}
            defaultValue={'0'}
            control={control}
            onChange={onChange}
            errors={errors}
            isUnitConversion
            getUnitConversionSuffix={value => getUnitConversionSuffix(value, ccp.product, ccp)}
            isNumeric
            type={'number'}
          />
        </GridColumn>
      </Grid>
      <Grid>
        <SpaceVerticalWrapper className={'large'} />
      </Grid>
    </Page>
  );
};

export default CreatePOModalBody;
