import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button/standard-button';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import Popup from '@atlaskit/popup';
import { LabelValuePair } from '../types/LabelValuePair';

const PopupContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  .progress-dropdown-trigger {
    background-color: #deebff !important;
    margin-left: 5px;
  }
`;
const ProgressPopup = ({
  currentJobStatus,
  onOpenModal,
  allJobStatus,
}: {
  currentJobStatus: LabelValuePair;
  onOpenModal: (value: LabelValuePair) => void;
  allJobStatus: Array<LabelValuePair>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const getPopupContent = () => {
    const currentIndex = allJobStatus.findIndex((item: LabelValuePair) => item.value === currentJobStatus.value);
    return currentIndex === -1
      ? []
      : allJobStatus.map((status: LabelValuePair, index: number) =>
          index > currentIndex + 1 ? (
            <Button key={status.value} isDisabled>
              {status.label}
            </Button>
          ) : (
            <Button
              key={status.value}
              onClick={() => {
                setIsOpen(false);
                onOpenModal(status);
              }}
            >
              {status.label}
            </Button>
          ),
        );
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-end"
      content={() => <PopupContentContainer data-testid={'pop-content'}>{getPopupContent()}</PopupContentContainer>}
      trigger={triggerProps => (
        <ButtonContainer>
          <Button
            //  eslint-disable-next-line
            {...triggerProps}
            onClick={() => setIsOpen(!isOpen)}
            iconBefore={<HipchatChevronDownIcon label="More" />}
            appearance={'link'}
            className={'progress-dropdown-trigger '}
            testId={'popup-trigger-button'}
          />
        </ButtonContainer>
      )}
    />
  );
};
export default ProgressPopup;
