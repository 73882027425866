import React, { useState } from 'react';
import styled from 'styled-components';
import Select from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { iOption } from '../select/types';
import { FlexAlignEndContainer } from '../styleContainer';
import AddsOn from '../addsOn/AddsOn';

const ExtruderSelectWrapper = styled.div`
  max-width: 120px;
  min-width: 70px;
`;

const AddInput = ({
  options,
  onAdd,
  prefix,
  suffix,
}: {
  options: Array<iOption>;
  onAdd: (newValue: string, inputId: string) => void;
  prefix?: string;
  suffix?: string;
}) => {
  const [state, setState] = useState(options.length > 0 ? options[0] : undefined);
  const [inputValue, setInputValue] = useState<string>('');

  // type is number
  const handleAdd = (value?: string) => {
    if (typeof state === 'undefined' || typeof value === 'undefined') return;
    onAdd(value, state.value);
    setInputValue('');
  };
  return (
    <>
      <ExtruderSelectWrapper data-testid={'extruder-select-wrapper'}>
        <Select
          placeholder={'Extruder'}
          options={options}
          value={state}
          onChange={option => setState(option as iOption)}
        />
      </ExtruderSelectWrapper>
      <FlexAlignEndContainer className={'dynamic-multiInput-textInput-container'}>
        <Textfield
          onKeyPress={evt => evt.key === 'Enter' && handleAdd(evt.currentTarget.value)}
          placeholder={'Input value'}
          value={inputValue}
          onChange={evt => setInputValue(evt.currentTarget.value)}
          type={'number'}
          elemAfterInput={<AddsOn value={suffix} />}
          elemBeforeInput={<AddsOn value={prefix} />}
        />
      </FlexAlignEndContainer>
    </>
  );
};
export default AddInput;
