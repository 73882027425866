import React from 'react';
import iJob from '../../types/job/iJob';
import JobService from '../../services/JobService';
import HorizontalLabel from '../job/jobDetail/HorizontalLabel';
import Title from '../job/jobDetail/styles/Title';
import PrintJobMasterCard from '../job/printJobMasterCard/PrintJobMasterCard';
import RollPrint from '../print/rollLabelPrint/RollPrint';
import { FlexContainer } from '../styles/styles';
import CoreLabelPrint from '../print/coreLabelPrint/CoreLabelPrint';

const JobDetailHeader = ({
  jobDetail,
  readOnly,
  children,
}: {
  jobDetail: iJob;
  readOnly?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <FlexContainer className={'space-between'} data-testid={'job-details-header'}>
      <div>
        <div>Job / Job# {jobDetail.jobNumber}</div>
        <Title>Job #: {jobDetail.jobNumber}</Title>
      </div>
      <HorizontalLabel label="Machine:" value={jobDetail.machine.name} />
      {readOnly && <HorizontalLabel label="Status:" value={jobDetail.status.name} />}
      <FlexContainer className={'with-gap small'}>
        <PrintJobMasterCard jobId={jobDetail.id} printFn={JobService.printJobMasterCard} btnName={'Job Card'} />
        <RollPrint jobId={jobDetail.id} jobAttributes={jobDetail.jobAttributes} productId={jobDetail.productId} />
        <CoreLabelPrint jobId={jobDetail.id} jobAttributes={jobDetail.jobAttributes} productId={jobDetail.productId} />
        <PrintJobMasterCard jobId={jobDetail.id} printFn={JobService.printEnvelopLabel} btnName={'Envelop'} />
      </FlexContainer>
      {children}
    </FlexContainer>
  );
};

export default JobDetailHeader;
