import React from 'react';
import styled from 'styled-components';
import { fontSizeSmall } from '@atlaskit/theme';

const ErrorMsgContainer = styled.span`
  display: block;
  font-size: ${fontSizeSmall()}px;
  color: #bf1650;
  min-height: 16px;
  &:before {
    content: '⚠ ';
  }
`;
const ErrorMsg = ({ message, isShow = true }: { message?: string; isShow?: boolean }) => {
  return isShow ? <ErrorMsgContainer>{message}</ErrorMsgContainer> : null;
};

export default ErrorMsg;
