import PackagingService from '../../../services/PackagingService';
import PrintService from '../../../services/PrintService';

export const mapFunctionByName = (serviceName: string, functionName: string) => {
  switch (serviceName) {
    case 'PackagingService':
      switch (functionName) {
        case 'getPalletSystem':
          return PackagingService.getPalletSystem;
        case 'getPalletWrappingMethod':
          return PackagingService.getPalletWrappingMethod;
        default:
          throw Error('not found');
      }
    case 'LabelPrintingService':
      switch (functionName) {
        case 'printRollLabel':
          return PrintService.printRollLabel;
        case 'printCoreLabel':
          return PrintService.printCoreLabel;
        default:
          throw Error('not found');
      }
    default:
      throw Error('not found');
  }
};
