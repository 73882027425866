import _ from 'lodash';
import { handleNullException } from '../../services/UtilsService';
import iContactCompanyProduct from '../../types/contactCompany/iContactCompanyProduct';
import iPurchaseOrderItem from '../../types/purchaseOrder/iPurchaseOrderItem';
import iReceivingItem from '../../types/purchaseOrder/iReceivingItem';
import { divExact, mulExact } from '../../shared/calculationHelper/calculationHelper';

export const getUnitPriceMeasure = (item: iContactCompanyProduct | iPurchaseOrderItem) => {
  return handleNullException(item, 'unitPriceMeasurementId')
    ? `${handleNullException(item, 'unitPriceMeasurement.shortName')}`
    : `${handleNullException(item, 'product.measurement.shortName')}`;
};

// qty in product measure
export const getQtyAfterConversion = (item: iPurchaseOrderItem, isQty: boolean) => {
  return isQty
    ? `${item.qty} ${item.measurement?.shortName || item.product.measurement.shortName} = ${mulExact(
        Number(item.unitConversion),
        item.qty,
      )} ${item.product?.measurement.shortName}`
    : `${item.receivedQty} ${item.measurement?.shortName || item.product.measurement.shortName} = ${mulExact(
        Number(item.unitConversion),
        item.receivedQty,
      )} ${item.product?.measurement.shortName}`;
};

// qty in po measure
export const getQtyBeforeConversion = (item: iReceivingItem) => {
  return `${
    item.purchaseOrderItem ? divExact(item.qty, Number(item.purchaseOrderItem.unitConversion)) : item.qty
  } ${_.get(item, 'purchaseOrderItem.measurement.shortName', item.product.measurement.shortName)} = ${item.qty} ${
    item.product.measurement.shortName
  }`;
};

// qty in po measure
export const getReceivedQtyBeforeConversion = (item: iPurchaseOrderItem) => {
  return `${divExact(item.receivedQty, Number(item.unitConversion))} ${_.get(
    item,
    'measurement.shortName',
    item.product.measurement.shortName,
  )} = ${item.receivedQty} ${item.product.measurement.shortName}`;
};

export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column.toUpperCase()) {
      case 'SKU':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'NAME':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'SALES ORDER NO.':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'PO NO.':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'OPERATION':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'ADD':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'DELETE':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-table-column-${column}`,
        };
    }
  });
  return { cells };
};
