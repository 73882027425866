import React from 'react';
import styled from 'styled-components';

const AddsOnContainer = styled.span`
  margin: 0px 8px;
  text-align: right;
`;
const NoFullWidthAddsOn = ({ value }: { value?: string }) => {
  return value ? (
    <AddsOnContainer className={'adds-on-container'} data-testid={'postfix'}>
      {value}
    </AddsOnContainer>
  ) : null;
};
export default NoFullWidthAddsOn;
