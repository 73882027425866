import React from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';

const LoginScreenSizePopup = ({ onConfirm }: { onConfirm: () => void }) => {
  return (
    <ModalTransition>
      <Modal onClose={onConfirm}>
        <ModalHeader>
          <ModalTitle>Screen Size Advice</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>This site is more suitable to run on a tablet</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onConfirm} appearance={'primary'}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};
export default LoginScreenSizePopup;
