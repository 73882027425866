import React, { useState } from 'react';
import Spinner from '@atlaskit/spinner';
import PrintBtn from '../../buttons/PrintBtn';
import { apiErrorToast } from '../../toast/ToastHandler';
import PDFPrintPopup from '../../PDFPrintPopup';

type iState = {
  isLoading: boolean;
  pdfFileUrl?: string;
};
const initialState: iState = {
  isLoading: false,
};

const PrintJobMasterCard = ({
  jobId,
  printFn,
  btnName,
}: {
  jobId: string;
  printFn: (id: string) => Promise<string>;
  btnName: string;
}) => {
  const [state, setState] = useState(initialState);
  const printJobCard = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      // const fileURL = await printJobMasterCard(jobId);
      const fileURL = await printFn(jobId);
      if (fileURL) {
        setState({
          ...state,
          pdfFileUrl: fileURL,
          isLoading: false,
        });
        // printJS({ printable: fileURL, type: 'pdf', showModal: true });
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      apiErrorToast(error);
    }
  };
  return state.isLoading === true ? (
    <Spinner />
  ) : (
    <>
      <PrintBtn title={btnName} onClick={printJobCard} />
      <PDFPrintPopup pdfFileUrl={state.pdfFileUrl} />
    </>
  );
};

export default PrintJobMasterCard;
