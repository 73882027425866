import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@atlaskit/button';
import iJob from '../../../types/job/iJob';
import CustomizeModal from '../../modal/CustomizeModal';
import FormService from '../../../services/FormService';
import JobService from '../../../services/JobService';
import { RootState } from '../../../redux/makeReduxStore';
import { iJobShiftWithJobShiftJobId, updateCurrentShift } from '../../../redux/reduxers/shift.slice';
import { STATUS_CATEGORY_NEW } from '../../constants/JobCategoryConstants';
import { apiErrorToast } from '../../toast/ToastHandler';
import { MODAL_BODY, MODAL_HEADING } from '../JobList.constants';
import { JOB_URL } from '../../UrlMap';

const StartOrContinue = ({ job, categoryName }: { job: iJob; categoryName: string }) => {
  const btnName = categoryName.toUpperCase() === STATUS_CATEGORY_NEW ? 'Start' : 'Continue';
  const modalHeading = MODAL_HEADING.replace('[STARTORCONTINUE]', btnName.toLowerCase());
  const modalBody = MODAL_BODY.replace('[STARTORCONTINUE]', btnName.toLowerCase());

  const { id: jobId } = job;

  const { currentShift } = useSelector((s: RootState) => s.shift);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const onConfirm = async () => {
    try {
      if (categoryName.toUpperCase() === STATUS_CATEGORY_NEW) {
        await JobService.startJob(jobId);
      }
      let newShift = currentShift;
      // no shift exist,need to start one
      if (!currentShift) {
        newShift = await JobService.startJobShift(jobId);
      }
      const currentJobShiftJob = await JobService.createJobShiftJob(newShift?.id || '', jobId);
      const hasContinued = await FormService.isJobShiftJobContinued(currentJobShiftJob.id);
      dispatch(
        updateCurrentShift({
          currentShift: { ...newShift, currentJobShiftJobId: currentJobShiftJob.id } as iJobShiftWithJobShiftJobId,
        }),
      );
      if (categoryName.toUpperCase() === STATUS_CATEGORY_NEW) {
        history.push(`${JOB_URL}/${jobId}`);
        return;
      }
      history.push(`${JOB_URL}/${jobId}`, { isWIPJob: !hasContinued });
    } catch (error) {
      apiErrorToast(error);
    }
  };
  return (
    <>
      <Button appearance={'primary'} onClick={openModal} testId={'soc-btn'}>
        {btnName}
      </Button>
      <CustomizeModal
        isOpen={isOpen}
        modalHeading={modalHeading}
        onCancel={closeModal}
        onConfirm={onConfirm}
        modalBody={modalBody}
      />
    </>
  );
};

export default StartOrContinue;
