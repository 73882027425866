import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Spinner from '@atlaskit/spinner';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import iJobShiftJob from '../../../types/job/iJobShiftJob';
import Wastes from './Wastes';
import Textfield from '../../form/Textfield';
import JobService from '../../../services/JobService';
import { RootState } from '../../../redux/makeReduxStore';
import { apiErrorToast } from '../../toast/ToastHandler';
import {
  iWaste,
  SectionWrapper,
  SHIFT_SUMMARY_TITLE,
  TitleWrapper,
  TOTAL_AMOUNT_HELPER,
  TOTAL_AMOUNT_TITLE,
} from './ShiftSummary.constants';

type iState = {
  isLoading: boolean;
  wastes: Array<iWaste>;
  qtyProduced?: number | null;
};
const initialState: iState = {
  isLoading: true,
  wastes: [],
};
/**
 * waste table,amount is governed by type=number; so it won't be invalid
 * the same as qtyProducted
 */
const ShiftSummary = ({ jobId, measurementUnit }: { jobId?: string; measurementUnit?: string }) => {
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const [state, setState] = useState(initialState);
  const { isLoading, wastes, qtyProduced } = state;

  //  eslint-disable-next-line
  const onQtyProduced = _.debounce(async (value: string) => {
    if (!currentShift || !jobId || value === '') {
      return;
    }
    try {
      await JobService.updateJobShift(currentShift.id, {
        jobId,
        qtyProduced: Number(value),
      });
    } catch (error) {
      apiErrorToast(error);
    }
  }, 500);

  useEffect(() => {
    let isCancelled = false;
    const loadJobShift = async () => {
      if (!currentShift || !jobId) {
        return;
      }
      try {
        const response = await JobService.getJobShiftJobs(currentShift?.id);
        if (isCancelled) return;
        const currentJobShiftJob = response.find((item: iJobShiftJob) => item.jobId === jobId);
        const detail = await JobService.getJobShiftJobDetail(currentJobShiftJob.id);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          wastes: detail.jobShiftWastes,
          qtyProduced: detail.qtyProduced,
        }));
      } catch (error) {
        apiErrorToast(error);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };
    loadJobShift();
    return () => {
      isCancelled = true;
    };
  }, [currentShift, jobId]);

  if (isLoading) return <Spinner />;
  return (
    <SectionWrapper>
      <Page>
        <TitleWrapper>{SHIFT_SUMMARY_TITLE}</TitleWrapper>
        <Grid layout={'fluid'} spacing={'cosy'}>
          <GridColumn medium={3}>
            <small>{TOTAL_AMOUNT_TITLE}</small>
          </GridColumn>
          <GridColumn medium={9}>
            <Textfield
              defaultValue={typeof qtyProduced === 'undefined' || qtyProduced === null ? '' : qtyProduced.toString()}
              onChange={onQtyProduced}
              name={'total produced amount'}
              suffix={measurementUnit}
              type={'number'}
              helper={TOTAL_AMOUNT_HELPER}
            />
          </GridColumn>
        </Grid>
        <Grid layout={'fluid'} spacing={'cosy'}>
          <GridColumn medium={12}>
            <Wastes jobShiftId={currentShift?.id} jobId={jobId} wastes={wastes} />
          </GridColumn>
        </Grid>
      </Page>
    </SectionWrapper>
  );
};

export default ShiftSummary;
