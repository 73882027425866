import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyState from '@atlaskit/empty-state';
import Button from '@atlaskit/button/standard-button';
import { JOBS_CURRENT_URL } from '../UrlMap';
import iJob from '../../types/job/iJob';
import { iJobShiftWithJobShiftJobId, updateCurrentShift } from '../../redux/reduxers/shift.slice';

import Helper from './Helper';

const WithStartedJobs = ({ WIPJob }: { WIPJob: iJob }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const actionAfterClick = (newShift: iJobShiftWithJobShiftJobId, hasContinued: boolean) => {
    dispatch(updateCurrentShift({ currentShift: newShift }));
    history.push(`/b/job/${WIPJob.id}`, { isWIPJob: !hasContinued });
  };

  const startShiftAction = (
    <Button
      appearance="primary"
      testId={'to-my-shift'}
      onClick={() => Helper.onExecuteJob(WIPJob.id, false, undefined, actionAfterClick)}
    >
      Yes, start my shift
    </Button>
  );
  const toListAction = (
    <Button onClick={() => history.push(JOBS_CURRENT_URL)} testId={'to-job-list'}>
      No, take me to the list
    </Button>
  );
  return (
    <EmptyState
      header={'Start your shift with previous WIP jobs?'}
      description={"you've started a job previously,do you want to start your shift by continuing the job?"}
      primaryAction={toListAction}
      secondaryAction={startShiftAction}
    />
  );
};
export default WithStartedJobs;
