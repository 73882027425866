import React from 'react';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import iStockTake from '../../../../types/stockTake/iStockTake';
import BulkSTItemCollapse from './BulkSTItemCollapse';
import Collapse from '../../../../shared/collapse/Collapse';
import FinishedTable from '../../detail/finished/FinishedTable';
import { CollapseTextWrapper } from '../shared/BulkStockTake.style';
import { classifyEntityCategoryByStatusId } from '../../../../services/UtilsService';

export type iBulkStockTakeItemProps = {
  stockTakeDetail: iStockTake;
  categories: Array<iEntityCategory>;
  path: string;
  finishCallback: (id: string) => void;
};
const BulkStockTakeItem = ({ stockTakeDetail, categories, path, finishCallback }: iBulkStockTakeItemProps) => {
  const { statusId } = stockTakeDetail;
  const { isFinished } = classifyEntityCategoryByStatusId(categories, statusId);

  return (
    <Collapse text={<CollapseTextWrapper className={isFinished ? 'finished' : 'wip'}>{path}</CollapseTextWrapper>}>
      {isFinished ? (
        <FinishedTable stockTakeDetail={stockTakeDetail} />
      ) : (
        <BulkSTItemCollapse stockTakeDetail={stockTakeDetail} path={path} finishCallback={finishCallback} />
      )}
    </Collapse>
  );
};

export default BulkStockTakeItem;
