import React from 'react';
import iJob from '../../../types/job/iJob';
import iJobCategory from '../../../types/job/iJobCategory';
import JobDetailBody from '../JobDetailBody';
import StartOrContinue from '../../jobList/startOrContinue/StartOrContinue';
import JobDetailHeader from '../JobDetailHeader';
import { getJobCategoryCode } from '../../../services/UtilsService';

// read only view does not need to care status management
const JobDetailReadOnly = ({
  jobDetail,
  jobStatusCategories,
  isCurrent,
}: {
  jobDetail: iJob;
  jobStatusCategories: Array<iJobCategory>;
  isCurrent?: boolean;
}) => {
  return (
    <>
      <JobDetailHeader readOnly jobDetail={jobDetail}>
        {isCurrent && (
          <StartOrContinue job={jobDetail} categoryName={getJobCategoryCode(jobDetail.status, jobStatusCategories)} />
        )}
      </JobDetailHeader>
      <JobDetailBody jobDetail={jobDetail} />
    </>
  );
};

export default JobDetailReadOnly;
