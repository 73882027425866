import React from 'react';
import Spinner from '@atlaskit/spinner';
import BackendPage from '../../layout/BackendPage';

const SpinWhenLoading = () => {
  return (
    <BackendPage>
      <Spinner testId={'spinner'} />
    </BackendPage>
  );
};

export default SpinWhenLoading;
