import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@atlaskit/button';
import { RootState } from '../../redux/makeReduxStore';
import CompanyExceptionEmptyState from '../company/CompanyExceptionEmptyState';
import { iAccessCode } from '../../types/settings/UserAccess/iAccess';
import { BACK_END_URL } from '../UrlMap';

type iRestrictedAccessDiv = {
  children: React.ReactChildren | React.ReactElement;
  expectedUserAccessCodes?: iAccessCode[];
  expectedCodeMatchedWithAny?: boolean;
  hideWhenDenied?: boolean; // whether to hide the children when access denied.
};
const RestrictedAccessDiv = ({
  children,
  expectedUserAccessCodes,
  hideWhenDenied = false,
  expectedCodeMatchedWithAny = false,
}: iRestrictedAccessDiv) => {
  const { user } = useSelector((s: RootState) => s.auth);
  if (!user) {
    return null;
  }
  if (!expectedUserAccessCodes) {
    return <>{children}</>;
  }
  const userAccessCodes = Object.keys(user.accessMap || {});
  const canAccess = expectedCodeMatchedWithAny
    ? expectedUserAccessCodes.some(code => (userAccessCodes || []).includes(code))
    : expectedUserAccessCodes.every(code => (userAccessCodes || []).includes(code));

  if (!canAccess) {
    if (hideWhenDenied === true) {
      return null;
    }
    return (
      <CompanyExceptionEmptyState
        header={'Access Denied'}
        description={
          <>
            It appears that you do NOT have access to this page.
            <br />
            <small style={{ fontSize: '7px' }}>{(expectedUserAccessCodes || []).join('|')}</small>
          </>
        }
        primaryAction={
          <Button appearance={'primary'} href={BACK_END_URL}>
            HOME
          </Button>
        }
        secondaryAction={
          <div>
            <Button appearance={'link'} href={`mailto:${user.company.email}`}>
              Contact your admin
            </Button>
          </div>
        }
      />
    );
  }
  return <>{children}</>;
};

export default RestrictedAccessDiv;
