import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { iConfigColumn } from '../../../shared/UITypes/types';
import TableHelper from '../../../shared/tableHelper/TableHelper';
import StockTransferUtil from '../sharedComponents/StockTransfer.util';

const ReviewTable = ({ data, columns }: { data: Array<iItemLocationTransfer>; columns: Array<iConfigColumn> }) => {
  return (
    <DynamicTable
      head={TableHelper.getHeads(columns, 'stockTransfer-review')}
      rows={TableHelper.getRows({
        data,
        columns,
        categories: [],
        renderSpecialContent: StockTransferUtil.reviewRenderSpecialContent,
      })}
      testId={'stock-transfer-review-table'}
    />
  );
};

export default ReviewTable;
