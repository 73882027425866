import AppService from './AppService';

const getPoReceivings = (config?: { [key: string]: string }) => {
  return AppService.get('/purchaseOrderReceiving', {
    include: 'purchaseOrder,status.entityStatusCategory,createdBy,updatedBy,purchaseOrderReceivingItems',
    ...config,
  }).then(({ data }) => data);
};

const getPoReceivingDetail = (id: string) => {
  return AppService.get(`/purchaseOrderReceiving/${id}`, {
    include:
      'purchaseOrder.purchaseOrderItems.product.measurement,purchaseOrder.purchaseOrderItems.measurement,purchaseOrder.supplier,purchaseOrderReceivingItems,status.entityStatusCategory',
  }).then(({ data }) => data);
};

const createPoReceiving = (postData: { [key: string]: string }) => {
  return AppService.post('/purchaseOrderReceiving', postData).then(({ data }) => data);
};

const updatePoReceiving = (
  id: string,
  updateContent: {
    [key: string]: string;
  },
) => {
  return AppService.put(`/purchaseOrderReceiving/${id}`, updateContent).then(({ data }) => data);
};

const deletePoReceiving = (id: string) => {
  return AppService.delete(`/purchaseOrderReceiving/${id}`).then(({ data }) => data);
};

const submitPoReceiving = (id: string) => {
  return AppService.put(`/purchaseOrderReceiving/receive/${id}`, {}).then(({ data }) => data);
};

export default {
  getPoReceivings,
  getPoReceivingDetail,
  createPoReceiving,
  updatePoReceiving,
  deletePoReceiving,
  submitPoReceiving,
};
