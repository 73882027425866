import React from 'react';
import styled from 'styled-components';

const MemoWrapper = styled.div`
  background-color: RGB(222, 235, 255);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  .space-around {
    padding-right: 0.3rem;
    display: flex;
    align-item: center;
  }
`;
const StockTakeMemo = ({ icon, children }: { icon: React.ReactChild; children: React.ReactNode }) => {
  return (
    <MemoWrapper data-testid={'MemoWrapper'}>
      <div className={'space-around'} data-testid={'icon'}>
        {icon}
      </div>
      {children}
    </MemoWrapper>
  );
};

export default StockTakeMemo;
