import styled from 'styled-components';
import { CURRENT_JOB_LIST, FUTURE_JOB_LIST } from './JobList.constants';

export const JobListTableWrapper = styled.div`
  th[data-testid='${CURRENT_JOB_LIST}-column-id--head--cell'],
  th[data-testid='${FUTURE_JOB_LIST}-column-id--head--cell'] {
    width: 120px;
    min-width: 120px;
  }
  th[data-testid='${CURRENT_JOB_LIST}-column-product.productCode--head--cell'],
  th[data-testid='${FUTURE_JOB_LIST}-column-product.productCode--head--cell'] {
    width: 200px;
    min-width: 200px;
  }
  th[data-testid='${CURRENT_JOB_LIST}-column-print--head--cell'],
  th[data-testid='${FUTURE_JOB_LIST}-column-print--head--cell'] {
    width: 180px;
    min-width: 180px;
  }
  th[data-testid='${CURRENT_JOB_LIST}-column-operation--head--cell'],
  th[data-testid='${FUTURE_JOB_LIST}-column-operation--head--cell'] {
    width: 100px;
    text-align: end;
  }

  tr[data-testid='${CURRENT_JOB_LIST}--row-undefined'] td:nth-last-child(2),
  tr[data-testid='${CURRENT_JOB_LIST}--row-undefined'] td:nth-last-child(1),
  tr[data-testid='${FUTURE_JOB_LIST}--row-undefined'] td:nth-last-child(1) {
    text-align: end;
  }
`;
