import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iItemLocation from '../../../../types/warehouse/iItemLocation';
import Collapse from '../../../../shared/collapse/Collapse';
import TableHelper from '../../../../shared/tableHelper/TableHelper';
import { SUPPOSED_ITEMS_TITLE, SUPPOSED_ITEM_TABLE_COLUMNS } from '../../shared/StockTake.constant';

const SupposedItemsInBin = ({ items, locationCode }: { items: Array<iItemLocation>; locationCode: string }) => {
  return (
    <Collapse text={`${SUPPOSED_ITEMS_TITLE}${locationCode}`}>
      <DynamicTable
        head={TableHelper.getHeads(SUPPOSED_ITEM_TABLE_COLUMNS, SUPPOSED_ITEMS_TITLE)}
        rows={TableHelper.getRows({ data: items, columns: SUPPOSED_ITEM_TABLE_COLUMNS, categories: [] })}
      />
    </Collapse>
  );
};

export default SupposedItemsInBin;
