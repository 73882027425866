import React from 'react';
import { iConfigColumn } from '../../../shared/DispatchNote.type';
import iEntityCategory from '../../../../../types/status/iEntityCategory';
import iJobCategory from '../../../../../types/job/iJobCategory';
import LinkBtnUrl from '../../../../../shared/buttons/LinkBtnUrl';
import iAddress from '../../../../../types/contactCompany/iAddress';
import ComposeSecondaryText from '../../../../../shared/text/ComposeSecondaryText';
import StatusLozenge from '../../../../../shared/statusComponent/StatusLozenge';
import ConversionText from '../../../../../shared/text/ConversionText';
import JobStatusLozenge from '../../../../job/jobStatus/JobStatusLozenge';
import CreateBtnPlusIcon from '../../../../../shared/buttons/CreateBtnPlusIcon';
import WarningIconWithTooltip from './WarningIconWithTooltip';
import CompareAddressInfo from './CompareAddressInfo';
import { compareAddress } from '../../dispatchNoteItems/TableHelper';
import {
  handleNullException,
  handleMoney,
  handleNullExceptionMultiFields,
  handleNumber,
  getCategoryCode,
  numberRound,
  getJobCategoryCode,
} from '../../../../../services/UtilsService';
import { DN_SHIPPING_WORDING, SERVICE_PRODIVDER_SHIPPING, SO_SHIPPING_WORDING } from './CreateDNItem.constants';
import { subExact } from '../../../../../shared/calculationHelper/calculationHelper';

export type iJobRenderSpecialContentProps = {
  //  eslint-disable-next-line
  onCreate: (data: any) => Promise<void>;
  onCreateCallback?: () => void;
  DNId: string;
  jobCategories: Array<iJobCategory>;
  DNShippingAddress?: iAddress;
  DNItemLength?: number;
  isServiceProvider?: boolean;
  customerShippingAddress?: iAddress;
};

export const jobRenderSpecialContent =
  <T extends { id: string }>({
    onCreate,
    onCreateCallback,
    DNId,
    jobCategories,
    DNShippingAddress,
    DNItemLength,
    isServiceProvider = false,
    customerShippingAddress,
  }: iJobRenderSpecialContentProps) =>
  (item: T, column: iConfigColumn) => {
    const { key, type, dataSource } = column;
    switch (type) {
      case 'operation': {
        const addressCompareTarget = isServiceProvider
          ? customerShippingAddress
          : handleNullException(item, dataSource[0]);
        const warningWording = isServiceProvider ? SERVICE_PRODIVDER_SHIPPING : SO_SHIPPING_WORDING;

        return DNItemLength === 0 || compareAddress(DNShippingAddress, addressCompareTarget) ? (
          <CreateBtnPlusIcon
            createFn={() =>
              onCreate({
                dispatchNoteId: DNId,
                jobId: handleNullException(item, 'id'),
              })
            }
            onCreateCallback={onCreateCallback}
          />
        ) : (
          <WarningIconWithTooltip
            tooltipContent={
              <CompareAddressInfo
                DNWording={DN_SHIPPING_WORDING}
                SOWording={warningWording}
                DNShippingAddress={DNShippingAddress}
                SOShippingAddress={addressCompareTarget}
              />
            }
          />
        );
      }
      case 'job-status':
        return (
          <JobStatusLozenge
            status={handleNullException(item, key)}
            categoryCode={getJobCategoryCode(handleNullException(item, key), jobCategories)}
          />
        );
      case 'link-secondary-slash': {
        const { urlPrefix } = column;
        return (
          <ComposeSecondaryText
            secondaryText={`${numberRound(
              subExact(
                Number(handleNullException(item, dataSource[2])),
                Number(handleNullException(item, dataSource[1])),
              ),
              3,
            )}/${handleNullException(item, dataSource[2])} ${handleNullException(item, dataSource[3])}`}
          >
            <LinkBtnUrl
              btnName={handleNullException(item, key)}
              url={`${urlPrefix}/${handleNullException(item, dataSource[0])}`}
            />
          </ComposeSecondaryText>
        );
      }
      default:
        return <div />;
    }
  };

export type iSoRenderSpecialContentProps = {
  //  eslint-disable-next-line
  onCreate: (data: any) => Promise<void>;
  onCreateCallback?: () => void;
  DNId: string;
  DNShippingAddress?: iAddress;
  DNItemLength?: number;
};

export const soRenderSpecialContent =
  <T extends { id: string }>({
    onCreate,
    onCreateCallback,
    DNId,
    DNShippingAddress,
    DNItemLength,
  }: iSoRenderSpecialContentProps) =>
  (item: T, column: iConfigColumn) => {
    const { type, dataSource } = column;
    switch (type) {
      case 'operation':
        return DNItemLength === 0 || compareAddress(DNShippingAddress, handleNullException(item, dataSource[0])) ? (
          <CreateBtnPlusIcon
            createFn={() =>
              onCreate({
                dispatchNoteId: DNId,
                salesOrderItemId: handleNullException(item, 'id'),
              })
            }
            onCreateCallback={onCreateCallback}
          />
        ) : (
          <WarningIconWithTooltip
            tooltipContent={
              <CompareAddressInfo
                DNWording={DN_SHIPPING_WORDING}
                SOWording={SO_SHIPPING_WORDING}
                DNShippingAddress={DNShippingAddress}
                SOShippingAddress={handleNullException(item, dataSource[0])}
              />
            }
          />
        );
      default:
        return <div />;
    }
  };

export const getRows = <T extends { id: string }>({
  data,
  columns,
  categories,
  renderSpecialContent,
}: {
  data: Array<T>;
  columns: Array<iConfigColumn>;
  categories: Array<iEntityCategory>;
  renderSpecialContent: (item: T, column: iConfigColumn) => JSX.Element;
}) => {
  return data.map((item: T) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key, 2),
          };
        case 'unit-price':
          return {
            key: handleNullException(item, key),
            content: `${handleMoney(item, key, 2)} / ${handleNullExceptionMultiFields(item, dataSource.join(','))}`,
          };
        case 'number':
          return {
            key: handleNullException(item, key),
            content: handleNumber(item, key),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
              />
            ),
          };
        case 'text-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                {handleNullException(item, key)}
              </ComposeSecondaryText>
            ),
          };
        case 'conversion-text':
          return {
            key: handleNullException(item, key),
            content: (
              <ConversionText
                value={handleNullException(item, key)}
                conversion={handleNullException(item, dataSource[0])}
                contactCompanyUnit={
                  handleNullException(item, dataSource[1]) || handleNullException(item, dataSource[2])
                }
                productUnit={handleNullException(item, dataSource[2])}
              />
            ),
          };
        case 'status':
          return {
            content: (
              <StatusLozenge
                status={handleNullException(item, key)}
                categoryCode={getCategoryCode(handleNullException(item, key), categories)}
              />
            ),
          };
        default:
          return { content: renderSpecialContent(item, column) };
      }
    });
    return { cells, testId: 'SO-items' };
  });
};
