import React, { useCallback, useState } from 'react';
import TextArea from '@atlaskit/textarea';
import styled from 'styled-components';
import { fontSize, gridSize, fontFamily } from '@atlaskit/theme/constants';
import _ from 'lodash';

export type textareaProps = {
  placeholder?: string;
  label?: React.ReactNode;
  helper?: React.ReactNode;
  name: string;
  onChange?: (name: string, newValue: string) => void;
  defaultValue?: string;
  appStyle?: 'default' | 'danger' | 'success' | 'warning';
  minimumRows?: number;
  appearance?: 'standard' | 'subtle' | 'none';
  isDisabled?: boolean;
  //  eslint-disable-next-line
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
  debouncePeriod?: number;
};

/*
  &.default {
    margin-top: 3rem;
    max-width: 70%;
  }
*/
const TextAreaWrapper = styled.div`
  &.danger {
    .helper {
      color: #de350b;
    }
  }
  .label {
    font-size: ${fontSize() / 17}rem;
    margin: ${gridSize() / 2}px 0;
    display: block;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #6b778c;
    font-weight: 600;
    font-family: ${fontFamily()};
  }
  .helper {
    font-size: ${fontSize() / 18}rem;
    padding: ${gridSize() / 2}px;
  }
`;
export default ({
  defaultValue,
  placeholder,
  label,
  helper,
  name,
  appStyle,
  onChange,
  minimumRows,
  appearance,
  isDisabled = false,
  //   eslint-disable-next-line
  textareaRef,
  debouncePeriod = 1000,
}: textareaProps) => {
  const [input, setInput] = useState(defaultValue);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [debouncedInput, setDebouncedInput] = useState(defaultValue);

  const debounce = useCallback(
    _.debounce((inputValue: string) => {
      //  setDebouncedInput(inputValue);
      if (!onChange) {
        return;
      }
      onChange(name, inputValue);
    }, debouncePeriod),
    [],
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    debounce(e.target.value);
  };

  return (
    <TextAreaWrapper className={appStyle || 'default'}>
      {label ? (
        <label className={'label'} htmlFor={name}>
          {label}
        </label>
      ) : null}
      <TextArea
        value={input}
        placeholder={placeholder}
        testId={name}
        onChange={handleOnChange}
        minimumRows={minimumRows}
        appearance={appearance}
        isDisabled={isDisabled}
        //  eslint-disable-next-line
        ref={textareaRef}
      />
      {helper ? <div className={'helper'}>{helper}</div> : null}
    </TextAreaWrapper>
  );
};
