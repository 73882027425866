import React, { useState, useEffect, useReducer } from 'react';
import { reducer, ActionKind, iState, Action } from './reducer';
import { apiErrorToast } from '../../toast/ToastHandler';

type iEditState<T> = {
  isModalOpen: boolean;
  version: number;
  target?: T;
  keyword?: string;
  perPage: number;
  currentPage: number;
  sort?: string;
  filter?: string;
  advancedFilter?: string;
  delTargetId?: string;
};
export type iUseListCrudHookProps = {
  //  eslint-disable-next-line
  createFn?: (content: any) => any;
  //  eslint-disable-next-line
  updateFn?: (id: string, content: any) => any;
  deleteFn?: (id: string) => void;
  //  eslint-disable-next-line
  getFn: (config?: { [key: string]: string }) => any;
  keywordColumns?: string;
  perPage?: number;
  loadMoreApplied?: boolean;
  paginationApplied?: boolean;
  sort?: string;
  filter?: string;
  advancedFilter?: string;
  notRenderWithoutFilter?: boolean;
  createCallBack?: (id: string) => void;
  editCallBack?: (id: string) => void;
};

const useListCrudHook = <T extends { id: string }>({
  createFn,
  deleteFn,
  updateFn,
  getFn,
  keywordColumns,
  perPage = 10,
  paginationApplied = false,
  loadMoreApplied = false,
  sort,
  filter,
  advancedFilter,
  notRenderWithoutFilter = false,
  createCallBack,
  editCallBack,
}: iUseListCrudHookProps) => {
  const initialState: iState<T> = {
    data: [],
    isLoading: false,
    isConfirming: false,
  };
  const initialEditState: iEditState<T> = {
    isModalOpen: false,
    version: 0,
    perPage,
    currentPage: 1,
    sort,
    filter,
    advancedFilter,
  };
  const [state, dispatch] = useReducer<React.Reducer<iState<T>, Action<T>>>(reducer, initialState);
  const [edit, setEdit] = useState(initialEditState);

  useEffect(() => {
    let isCancelled = false;
    //  interface for configs
    const getConfig = () => {
      let likeConfig;
      let paginationConfig;
      let sortConfig;
      let filterConfig;
      let advancedFilterConfig;
      if (edit.sort) {
        sortConfig = { sort: edit.sort };
      }
      if (paginationApplied || loadMoreApplied) {
        paginationConfig = {
          perPage: edit.perPage.toString(),
          currentPage: edit.currentPage.toString(),
        };
      }
      if (
        typeof edit.keyword !== 'undefined' &&
        edit.keyword !== '' &&
        typeof keywordColumns !== 'undefined' &&
        keywordColumns.trim() !== ''
      ) {
        const columns = keywordColumns.split(',');
        likeConfig = {
          like: columns?.map((column: string) => `${column}:${edit.keyword}`).join(','),
        };
      }
      if (edit.filter) {
        filterConfig = { filter: edit.filter };
      }
      if (edit.advancedFilter) {
        advancedFilterConfig = { advancedFilter: edit.advancedFilter };
        filterConfig = undefined;
      }
      return {
        ...paginationConfig,
        ...likeConfig,
        ...sortConfig,
        ...filterConfig,
        ...advancedFilterConfig,
      };
    };
    const fetchData = async () => {
      if (typeof edit.filter === 'undefined' && typeof edit.advancedFilter === 'undefined' && notRenderWithoutFilter) {
        return;
      }
      dispatch({ type: ActionKind.Loading, payload: {} });
      try {
        //  eslint-disable-next-line
        const fetchResult: any = await getFn(getConfig());
        if (isCancelled) return;
        // if overpaged, roll back to page 1
        if ((paginationApplied || loadMoreApplied) && fetchResult.data.length === 0 && fetchResult.total !== 0) {
          setEdit({ ...edit, currentPage: 1 });
        }

        //  load more is to append resolved result to original list
        //  general pagination is to replace original list by resolved result
        dispatch({
          type: loadMoreApplied ? ActionKind.LoadMore : ActionKind.Loaded,
          payload:
            paginationApplied || loadMoreApplied
              ? {
                  data: fetchResult.data,
                  from: fetchResult.from,
                  to: fetchResult.to,
                  total: fetchResult.total,
                  currentPage: fetchResult.currentPage,
                  perPage: fetchResult.perPage,
                }
              : { data: fetchResult },
        });
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        dispatch({
          type: ActionKind.Reset,
          payload: {},
        });
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    edit.version,
    edit.keyword,
    edit.perPage,
    edit.currentPage,
    edit.sort,
    edit.filter,
    edit.advancedFilter,
    paginationApplied,
    loadMoreApplied,
    getFn,
    keywordColumns,
    notRenderWithoutFilter,
  ]);

  const onRefresh = () => {
    setEdit({ ...edit, version: edit.version + 1 });
  };

  // eslint-disable-next-line
  const onSubmit = async (data: any) => {
    dispatch({ type: ActionKind.Confirming, payload: {} });
    try {
      if (typeof updateFn !== 'function') return;
      if (typeof createFn !== 'function') return;
      const result = edit.target ? await updateFn(edit.target.id, data) : await createFn(data);
      if (!edit.target && typeof createCallBack === 'function') {
        createCallBack(result.id);
        return;
      }
      // eslint-disable-next-line
      const eagerLoadResult: any = await getFn({ filter: `id:${result.id}` });
      dispatch({
        type: edit.target ? ActionKind.Update : ActionKind.Add,
        payload: {
          item: Array.isArray(eagerLoadResult) ? eagerLoadResult[0] : eagerLoadResult.data[0],
        },
      });
      setEdit({
        ...edit,
        target: undefined,
        isModalOpen: false,
      });
    } catch (error) {
      dispatch({ type: ActionKind.Confirmed, payload: {} });
      apiErrorToast(error);
    }
  };

  // eslint-disable-next-line
  const onCreate = async (data: any) => {
    dispatch({ type: ActionKind.Confirming, payload: {} });
    try {
      if (typeof createFn !== 'function') return;
      const result = await createFn(data);
      // eslint-disable-next-line
      const eagerLoadResult: any = await getFn({ filter: `id:${result.id}` });
      dispatch({
        type: ActionKind.Add,
        payload: {
          item: Array.isArray(eagerLoadResult) ? eagerLoadResult[0] : eagerLoadResult.data[0],
        },
      });
      setEdit({
        ...edit,
        target: undefined,
        isModalOpen: false,
      });
      if (typeof createCallBack === 'function') {
        createCallBack(result.id);
        return;
      }
    } catch (error) {
      dispatch({ type: ActionKind.Confirmed, payload: {} });
      apiErrorToast(error);
    }
  };
  // eslint-disable-next-line
  const onEdit = async (targetId: string, data: any) => {
    dispatch({ type: ActionKind.Confirming, payload: {} });
    try {
      if (typeof updateFn !== 'function') return;
      const result = await updateFn(targetId, data);
      // eslint-disable-next-line
      const eagerLoadResult: any = await getFn({ filter: `id:${result.id}` });
      dispatch({
        type: ActionKind.Update,
        payload: {
          item: Array.isArray(eagerLoadResult) ? eagerLoadResult[0] : eagerLoadResult.data[0],
        },
      });
      setEdit({
        ...edit,
        target: undefined,
        isModalOpen: false,
      });
      if (typeof editCallBack === 'function') {
        editCallBack(result.id);
        return;
      }
    } catch (error) {
      dispatch({ type: ActionKind.Confirmed, payload: {} });
      apiErrorToast(error);
    }
  };
  const onDelete = async (deleteTargetId: string) => {
    try {
      if (typeof deleteFn !== 'function') return;
      dispatch({ type: ActionKind.Confirming, payload: {} });
      await deleteFn(deleteTargetId);
      dispatch({
        type: ActionKind.Delete,
        payload: { targetId: deleteTargetId },
      });
      setEdit({
        ...edit,
        target: undefined,
        delTargetId: undefined,
        isModalOpen: false,
      });
    } catch (error) {
      apiErrorToast(error);
      dispatch({ type: ActionKind.Confirmed, payload: {} });
    }
  };

  const onOpenAddModal = () =>
    setEdit({
      ...edit,
      isModalOpen: true,
      target: undefined,
      delTargetId: undefined,
    });
  const onOpenEditModal = (targetId: string) => {
    const target = state.data.find((u: T) => u.id === targetId);
    setEdit(prevState => ({
      ...prevState,
      isModalOpen: true,
      target,
      delTargetId: undefined,
    }));
  };

  const onOpenDeleteModal = (targetId: string) => {
    const target = state.data.find((u: T) => u.id === targetId);
    setEdit({
      ...edit,
      isModalOpen: true,
      delTargetId: targetId,
      target,
    });
  };
  const onCloseModal = () =>
    setEdit({
      ...edit,
      isModalOpen: false,
      target: undefined,
      delTargetId: undefined,
    });

  const onSearch = (keyword: string) =>
    setEdit({
      ...edit,
      keyword,
      currentPage: 1,
    });
  const onFilter = (value: string) => {
    setEdit({
      ...edit,
      filter: value,
    });
  };
  const onAdvancedFilter = (value: string) => {
    setEdit({
      ...edit,
      advancedFilter: value,
    });
  };
  const onSetCurrentPage = (page: number) => setEdit({ ...edit, currentPage: page });
  const onSetSort = (sortStr: string) => setEdit({ ...edit, currentPage: 1, sort: sortStr });

  return {
    state,
    edit,
    onSubmit,
    onCloseModal,
    onDelete,
    onOpenEditModal,
    onOpenAddModal,
    onSearch,
    onSetCurrentPage,
    onSetSort,
    onFilter,
    onAdvancedFilter,
    onOpenDeleteModal,
    onRefresh,
    onEdit,
    onCreate,
  };
};

export default useListCrudHook;
