import { EXCLUDE_LOGO, INCLUDE_LOGO } from '../../dynamicForm/labelPrinting/LabelTypeConstants';
import { iLabelValuePair } from '../../UITypes/types';

export const ROLL_PRINT = 'Roll';

export const JOB_ATTRIBUTE_KG = 'KG(S) PER ROLL';
export const JOB_ATTRIBUTE_METER = 'METER(S) PER ROLL';
// ('Meter(s) Per Roll');
export const JOB_ATTRIBUTE_ROLL = 'ROLL(S)';

export const LABEL_TYPE = 'Label Type';

export const INCLUDE_LOGO_OPTION: Array<iLabelValuePair> = [
  { label: 'Shamrock', value: INCLUDE_LOGO },
  { label: 'Plain', value: EXCLUDE_LOGO },
];
