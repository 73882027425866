import React from 'react';
import iJob from '../../types/job/iJob';
import iJobAttribute from '../../types/job/iJobAttribute';
import GeneralAttachments from '../generalAttachments/GeneralAttachments';
import JobMemo from '../job/jobDetail/JobMemo';
import JobTabs from '../job/jobDetail/jobTabs/JobTabs';
import VerticalLabelGroup from '../job/jobDetail/VerticalLabelGroup';
import FormManagementUtils from './formManagement/FormManagement.utils';
import { AttachmentsWrapper } from '../styles/styles';

const JobDetailBody = ({ jobDetail }: { jobDetail: iJob }) => {
  return (
    <>
      <VerticalLabelGroup group={FormManagementUtils.getJobInfoGroup(jobDetail)} />
      <VerticalLabelGroup
        group={jobDetail.jobAttributes.map((item: iJobAttribute) => ({
          label: item.name,
          value: item.jobAttributeValues.value,
        }))}
      />
      <JobMemo jobMemo={jobDetail.memo} />
      <JobTabs job={jobDetail} />
      <AttachmentsWrapper>
        <GeneralAttachments entityId={jobDetail.id} entityName={'Job'} isTablet />
      </AttachmentsWrapper>
    </>
  );
};

export default JobDetailBody;
