import React from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { iBtnAppearance } from '../UITypes/types';

const defaultModalHeading = <span>Are you sure to start?</span>;
const defaultModalBody = <p>Please confirm you are about to start it.</p>;
export type iCustomizeModalProps = {
  isOpen?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isConfirming?: boolean;
  isDisabled?: boolean;
  confirmBtnName?: string;
  confirmBtnAppearance?: iBtnAppearance;
  modalHeading?: React.ReactNode;
  modalBody?: React.ReactNode;
  bottom?: React.ReactNode;
  testIdPrefix?: string;
  width?: 'small' | 'medium' | 'large' | 'x-large';
  height?: number | string;
};
const CustomizeModal = ({
  isOpen,
  onConfirm,
  onCancel,
  isConfirming = false,
  confirmBtnName = 'Confirm',
  confirmBtnAppearance = 'primary',
  modalHeading = defaultModalHeading,
  isDisabled = false,
  modalBody = defaultModalBody,
  bottom = null,
  testIdPrefix,
  width,
  height,
}: iCustomizeModalProps) => {
  const getModal = () => (
    <Modal
      testId={testIdPrefix ? `${testIdPrefix}-customize-modal` : 'customize-modal'}
      onClose={onCancel}
      width={width || 'medium'}
      height={height || 'auto'}
    >
      <ModalHeader>
        <ModalTitle>{modalHeading}</ModalTitle>
      </ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      <ModalFooter>
        <div>{bottom}</div>
        <ButtonGroup>
          <LoadingButton
            onClick={onConfirm}
            appearance={confirmBtnAppearance}
            isDisabled={isDisabled}
            testId={testIdPrefix ? `${testIdPrefix}-confirm-button` : 'confirm-button'}
            isLoading={isConfirming}
          >
            {confirmBtnName}
          </LoadingButton>
          <Button onClick={onCancel} testId={testIdPrefix ? `${testIdPrefix}-cancel-button` : 'cancel-button'}>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
  return isOpen ? <ModalTransition>{getModal()}</ModalTransition> : null;
};

export default CustomizeModal;
