import React, { useState, FormEvent } from 'react';
import _ from 'lodash';
import Textfield from '@atlaskit/textfield';
import NoFullWidthAddsOn from './NoFullWidthAddOn';
import { FlexContainer, SpaceHorizontalWrapper } from '../styles/styles';
import { ConCalInlineWrapper } from './ConCalculate.style';
import { NUMBER_ROUND_DECIMAL } from '../constants/AsyncConstants';
import { divExact, mulExact } from '../calculationHelper/calculationHelper';

type iState = {
  isProductEditing: boolean;
  isContactCompanyEditing: boolean;
};
const initialState: iState = {
  isProductEditing: false,
  isContactCompanyEditing: false,
};

export type iConCalReadEditViewProps = {
  label?: string;
  name: string;
  value?: string | number | readonly string[];
  onBlur?: () => void;
  //  eslint-disable-next-line
  onChange: (...event: any[]) => void;
  productUnit: string;
  contactCompanyUnit: string;
  conversion: number;
  isDisabled?: boolean;
};
/**
 * when not in modal/react-hook-form, use onBlur
 * @returns
 */
const ConCalReadEditView = ({
  label,
  name,
  value,
  onBlur,
  onChange,
  productUnit,
  contactCompanyUnit,
  conversion,
  isDisabled,
}: iConCalReadEditViewProps) => {
  const [state, setState] = useState(initialState);
  const onBlurEditing = () => {
    setState({
      ...state,
      isProductEditing: false,
      isContactCompanyEditing: false,
    });
    if (typeof onBlur === 'function') onBlur();
  };
  return (
    <ConCalInlineWrapper>
      <FlexContainer>
        {state.isContactCompanyEditing ? (
          <Textfield
            label={label}
            value={value ? _.round(Number(value), NUMBER_ROUND_DECIMAL) : ''}
            onChange={onChange}
            type={'number'}
            elemAfterInput={<NoFullWidthAddsOn value={contactCompanyUnit} />}
            className={'no-border'}
            isDisabled={isDisabled}
            onBlur={onBlurEditing}
            autoFocus={state.isContactCompanyEditing}
            testId={'before-conversion-value'}
          />
        ) : (
          <FlexContainer
            onClick={() => {
              if (!isDisabled) setState({ ...state, isContactCompanyEditing: true });
            }}
            data-testid={'before-conversion-edit-trigger'}
            id={name}
            className={'conversion-trigger'}
          >
            <div>{value && _.round(Number(value), NUMBER_ROUND_DECIMAL)}</div>
            <SpaceHorizontalWrapper className={'x-small'} />
            <div>{contactCompanyUnit}</div>
          </FlexContainer>
        )}

        {!(productUnit === contactCompanyUnit && conversion === 1) && (
          <>
            <div className={'equal-quote'}>=</div>
            {state.isProductEditing ? (
              <Textfield
                value={value && _.round(mulExact(Number(value), conversion), NUMBER_ROUND_DECIMAL)}
                onChange={(e: FormEvent<HTMLInputElement>) => {
                  if (!e.currentTarget.value) {
                    onChange('');
                    return;
                  }
                  onChange(divExact(Number(e.currentTarget.value), conversion));
                }}
                type={'number'}
                elemAfterInput={<NoFullWidthAddsOn value={productUnit} />}
                className={'no-border'}
                isDisabled={isDisabled}
                onBlur={onBlurEditing}
                autoFocus={state.isProductEditing}
                testId={'after-conversion-value'}
              />
            ) : (
              <FlexContainer
                onClick={() => setState({ ...state, isProductEditing: !isDisabled })}
                data-testid={'after-conversion-edit-trigger'}
                className={'conversion-trigger'}
              >
                <div>{value && _.round(mulExact(Number(value), conversion), NUMBER_ROUND_DECIMAL)}</div>
                <SpaceHorizontalWrapper className={'quarter'} />
                <div>{productUnit}</div>
              </FlexContainer>
            )}
          </>
        )}
      </FlexContainer>
    </ConCalInlineWrapper>
  );
};

export default ConCalReadEditView;
