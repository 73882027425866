import AppService from './AppService';
import { iParams } from '../shared/UITypes/types';

const getEntityStatuses = (config?: iParams) => {
  return AppService.get('/entityStatus', config).then(({ data }) => data);
};

const getNextStatuses = (id: string, config?: iParams) => {
  return AppService.get(`/entityStatus/${id}/next`, config).then(({ data }) => data);
};

export default {
  getEntityStatuses,
  getNextStatuses,
};
