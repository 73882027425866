import React from 'react';
import iJob from '../types/job/iJob';
import iProduct from '../types/product/iProduct';
import ComposeSecondaryText from './text/ComposeSecondaryText';
import { iSingleSelectOption } from './UITypes/types';
import { assembleAddress, handleNullException } from '../services/UtilsService';
import iContactCompanyAddress from '../types/contactCompany/iContactCompanyAddress';
import iWarehouseLocation from '../types/warehouse/iWarehouse';

export const getProductOptionLabel = (option: iSingleSelectOption<iProduct>) =>
  !option ? null : (
    <ComposeSecondaryText secondaryText={handleNullException(option.value, 'name')}>
      {handleNullException(option.value, 'productCode')}
    </ComposeSecondaryText>
  );

export const getWareHouseLocationOptionLabel = (option: iSingleSelectOption<iWarehouseLocation>) =>
  !option ? null : (
    <ComposeSecondaryText secondaryText={handleNullException(option.value, 'locationCode')}>
      {handleNullException(option.value, 'name')}
    </ComposeSecondaryText>
  );

export const getJobOptionLabel = (option: iSingleSelectOption<iJob>) =>
  !option ? null : (
    <ComposeSecondaryText secondaryText={handleNullException(option.value, 'product.productCode')}>
      {handleNullException(option.value, 'jobNumber')}
    </ComposeSecondaryText>
  );

export const getAddressOptionLabel = (option: iSingleSelectOption<iContactCompanyAddress>) => {
  if (!option) return null;
  return (
    <ComposeSecondaryText secondaryText={assembleAddress(option.value.address)}>
      {option.value.address?.name}
    </ComposeSecondaryText>
  );
};
export const getGeneralOptionLabel = <T extends { id: string }>(option: iSingleSelectOption<T>, field: string) =>
  !option ? null : handleNullException(option.value, field);

export const getTwoLayerOption = (label: string) => {
  return (
    <>
      <div style={{ fontWeight: 500 }}>{label.split(',')[0]}</div>
      <div style={{ fontSize: '0.7rem' }}>{label.split(',')[1]}</div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapLabelValuePairProduct = (list: any[], attributes: string[]) => {
  return list.map(item => {
    const label = `${item[attributes[0]]},${item[attributes[1]]}`;
    return {
      label: getTwoLayerOption(label),
      value: `${item.id}:${item[attributes[0]]}:${item[attributes[1]]}`,
    };
  });
};
