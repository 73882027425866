import React from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';

import { PO_RECEIVED_PRODUCTS_TABLE_NAME, PO_RECEIVED_PRODUCTS_TABLE_TEST_ID } from '../../constants';

import { getHeads, getRows } from './ReceivedProductTableHelper';
import iReceivingItem from '../../../../types/purchaseOrder/iReceivingItem';
import { iConfigColumn } from '../../../../shared/UITypes/types';

const TableWrapper = styled.div``;
const ReceivedProductTable = ({
  isLoading,
  data,
  columns,
}: {
  isLoading: boolean;
  data: iReceivingItem[];
  columns: Array<iConfigColumn>;
}) => {
  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(columns, PO_RECEIVED_PRODUCTS_TABLE_NAME)}
        rows={getRows(data, columns)}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        testId={PO_RECEIVED_PRODUCTS_TABLE_TEST_ID}
      />
    </TableWrapper>
  );
};

export default ReceivedProductTable;
