import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '@atlaskit/button';
import CheckIcon from '@atlaskit/icon/glyph/check';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { colors } from '@atlaskit/theme';
import { iIdValuePair } from '../../UITypes/types';

const FlexStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  text-align: center;
  font-size: ${fontSize()};

  padding: ${gridSize() - 2}px ${gridSize()}px;
  margin: 0px ${gridSize()}px;
  margin-left: auto;
  background-color: ${colors.N40};

  border: 2px solid transparent;
`;
const DeleteWrapper = styled.div`
  ${FlexStyle};
  background-color: transparent;
  border-color: ${colors.Y100};
`;
const TrashWrapper = styled.div`
  ${FlexStyle};
`;
const TextWrapper = styled.div`
  text-align: center;
  padding: 0;
  margin: 0;
`;

const DeleteInput = ({ inputValue, onDelete }: { inputValue: iIdValuePair; onDelete: (inputId: string) => void }) => {
  const [operating, setOperating] = useState(false);
  const handleDelete = () => {
    setOperating(false);
    onDelete(inputValue.id);
  };
  const confirmDelete = () => {
    return (
      <DeleteWrapper data-testid={'delete-wrapper'}>
        <Button
          iconAfter={<CheckIcon label={'confirm'} />}
          style={{ backgroundColor: 'transparent' }}
          onClick={handleDelete}
          testId={'delete-button'}
        />
        <TextWrapper>
          delete {inputValue.value}
          <small>{`-${inputValue.id}`}</small>?
        </TextWrapper>
        <Button
          iconAfter={<CrossIcon label={'cancel'} />}
          style={{ backgroundColor: 'transparent' }}
          onClick={() => setOperating(false)}
          testId={'cancel-button'}
        />
      </DeleteWrapper>
    );
  };
  const showInput = () => {
    return (
      <TrashWrapper data-testid={'trash-wrapper'}>
        <p>
          {inputValue.value}
          <small>{`-${inputValue.id}`}</small>
        </p>
        <Button
          iconAfter={<TrashIcon label={'trash'} />}
          style={{
            backgroundColor: 'transparent',
            marginLeft: `${gridSize()}px`,
          }}
          onClick={() => setOperating(true)}
          testId={'trash-icon-button'}
        />
      </TrashWrapper>
    );
  };
  return <>{operating ? confirmDelete() : showInput()}</>;
};
export default DeleteInput;
