import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import { LabelValuePair } from '../types/LabelValuePair';

const ButtonContainer = styled.div`
  .progress-button {
    min-width: 120px;
  }
`;
const ProgressBtn = ({
  status,
  onOpenModal,
}: {
  status: LabelValuePair;
  onOpenModal: (value: LabelValuePair) => void;
}) => {
  return (
    <ButtonContainer>
      <Button className={'progress-button'} onClick={() => onOpenModal(status)}>
        {status.label}
      </Button>
    </ButtonContainer>
  );
};

export default ProgressBtn;
