import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { Label } from '@atlaskit/field-base';
import SectionMessage from '@atlaskit/section-message';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PurchaseOrderService from '../../../services/PurchaseOrderService';
import { iRouteTypeId } from '../../../types/iRoute';
import iPurchaseOrderDetail from '../../../types/purchaseOrder/iPurchaseOrderDetail';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/ToastHandler';
import { LabelValue, SpinnerWrapper } from '../../../shared/styles/styles';
import BackendPage from '../../../layout/BackendPage';
import { formatDate } from '../../../services/UtilsService';
import DetailPageHeader from './DetailPageHeader';
import PoItems from './PoItems';
import POTabs from './POTabs';
import LogFooter from '../../../shared/logFooter/LogFooter';
import ModalPage from '../../../shared/modalPage/ModalPage';
import InputWithController from '../../../shared/hookForms/InputWithController';
import PoReceivingService from '../../../services/PoReceivingService';
import useStatusSelector from '../../../shared/hooks/useStatusSelector';
import EntityStatusCategoryService from '../../../services/EntityStatusCategoryService';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/UserAccess/AccessService';
import { ACCESS_CODE_PURCHASE_ORDERS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';

const SectionMessageContainer = styled.div`
  margin: 2rem auto;
`;

const AttachmentsWrapper = styled.div`
  display: inline-block;
  margin-top: 3rem;
`;

const PurchaseOrderDetail = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [localPO, setLocalPO] = useState<iPurchaseOrderDetail>();
  const [isReceivingModalOpen, setIsReceivingModalOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const { control, setValue, errors, handleSubmit } = useForm();
  const history = useHistory();
  const { id } = useParams<iRouteTypeId>();
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_UPDATE, user);

  const { categories } = useStatusSelector({
    type: 'PurchaseOrder',
    isMulti: true,
    getFn: EntityStatusCategoryService.getEntityCategoryList,
  });

  useEffect(() => {
    let mounted = true;
    const fetchPO = async () => {
      setIsLoading(true);
      try {
        const PO = await PurchaseOrderService.getPurchaseOrderDetail(id);

        if (mounted) {
          setLocalPO(PO);
        }
      } catch (e) {
        addToastForAPIResponse('error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPO();

    return () => {
      mounted = false;
    };
  }, [id]);

  const onReceiving = () => {
    setIsReceivingModalOpen(true);
  };

  const onCreateReceiving = async (data: { [key: string]: string }) => {
    try {
      setIsConfirming(true);
      const created = await PoReceivingService.createPoReceiving({
        reference: data.docketNumber,
        purchaseOrderId: id,
      });
      setIsConfirming(false);
      history.push(`/b/purchases/receiving/${created.id}`);
    } catch (e) {
      setIsConfirming(false);
      apiErrorToast(e);
    }
  };

  const getReceivingModalBody = () => (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'docketNumber'}
            label={'Docket Number'}
            defaultValue={''}
            control={control}
            onChange={setValue}
            errors={errors}
            isRequired
            testId={'po-receiving-docketNumber'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );

  const getPageHeader = () => <DetailPageHeader po={localPO} onReceiving={onReceiving} categories={categories} />;
  const getPageFooter = () => <LogFooter item={localPO} />;

  // eslint-disable-next-line no-nested-ternary
  return !isLoading ? (
    isReceivingModalOpen ? (
      <ModalPage
        heading={'Receiving PO'}
        onConfirm={handleSubmit(onCreateReceiving)}
        onCancel={() => setIsReceivingModalOpen(false)}
        confirmBtnName="Create"
        confirmBtnAppearance="primary"
        isConfirming={isConfirming}
      >
        {getReceivingModalBody()}
      </ModalPage>
    ) : (
      <BackendPage pageHeader={getPageHeader()} pageFooter={getPageFooter()}>
        <Page>
          <>
            <Grid layout={'fluid'} spacing={'compact'}>
              <GridColumn medium={3}>
                <Label label="Supplier" />
                <LabelValue>{localPO?.supplier?.name}</LabelValue>
              </GridColumn>
              <GridColumn medium={3}>
                <Label label="Email" />
                <LabelValue>{localPO?.supplier?.email}</LabelValue>
              </GridColumn>
              <GridColumn medium={3}>
                <Label label="Phone" />
                <LabelValue>{localPO?.supplier?.phone}</LabelValue>
              </GridColumn>
              <GridColumn medium={3}>
                <Label label="Reference" />
                <LabelValue>{localPO?.supplierRef}</LabelValue>
              </GridColumn>
            </Grid>
            <Grid layout={'fluid'} spacing={'compact'}>
              <GridColumn medium={3}>
                <Label label="Order Date" />
                <LabelValue>{formatDate(localPO?.orderDate, 'DD MMM YYYY')}</LabelValue>
              </GridColumn>
              <GridColumn medium={3}>
                <Label label="ETA" />
                <LabelValue>{formatDate(localPO?.eta, 'DD MMM YYYY')}</LabelValue>
              </GridColumn>
            </Grid>

            <Grid layout={'fluid'} spacing={'compact'}>
              <GridColumn medium={5}>
                <SectionMessageContainer>
                  {localPO?.notes ? (
                    <SectionMessage title="">
                      <p>{localPO.notes}</p>
                    </SectionMessage>
                  ) : null}
                </SectionMessageContainer>
              </GridColumn>
            </Grid>
          </>
          <PoItems po={localPO} />
          <AttachmentsWrapper>
            <GeneralAttachments
              entityId={id}
              entityName={'PurchaseOrder'}
              isTablet
              thumbnailSize={'small'}
              canCreate={canUpdate}
              canDelete={canUpdate}
            />
          </AttachmentsWrapper>
          <POTabs poId={id} />
        </Page>
      </BackendPage>
    )
  ) : (
    <SpinnerWrapper>
      <Spinner size="xlarge" />
    </SpinnerWrapper>
  );
};

export default PurchaseOrderDetail;
