import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iDispatchNote from '../../../types/dispatchNote/iDispatchNote';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { iSortOrderType } from '../../../shared/UITypes/types';
import { getHeads, getRows } from './TableHelper';
import { DispatchNoteTableWrapper } from '../shared/DispatchNote.style';
import { TABLE_TEST_ID, TABLE_NAME } from '../shared/DispatchNote.constant';
import { iConfigColumn } from '../shared/DispatchNote.type';

export type iDispatchNoteTableParams = {
  data: Array<iDispatchNote>;
  isLoading: boolean;
  columns: Array<iConfigColumn>;
  onSetSort: (sortStr: string) => void;
  onCheck: (id: string, value: boolean) => void;
  categories: Array<iEntityCategory>;
};

const DispatchNoteTable = ({ data, onSetSort, isLoading, columns, onCheck, categories }: iDispatchNoteTableParams) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <DispatchNoteTableWrapper>
      <DynamicTable
        head={getHeads(columns, TABLE_NAME)}
        rows={getRows(data, onCheck, columns, categories, `${TABLE_TEST_ID}-item`)}
        testId={TABLE_TEST_ID}
        isLoading={isLoading}
        onSort={({ key, sortOrder: sortOrd }: { key: string; sortOrder: iSortOrderType }) => {
          setSortKey(key);
          setSortOrder(sortOrd);
          onSetSort(`${key}:${sortOrd}`);
        }}
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
    </DispatchNoteTableWrapper>
  );
};

export default DispatchNoteTable;
