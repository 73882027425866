import React from 'react';
import iContactCompanyProduct from '../../../../types/contactCompany/iContactCompanyProduct';
import iJob from '../../../../types/job/iJob';
import ServiceProviderTable from './ServiceProviderTable';
import useListCrudHook from '../../../hooks/useListCrudHook/useListCrudHook';
import CustomizePagination from '../../../pagination/CustomizePagination';
import { SpaceVerticalWrapper } from '../../../styles/styles';
import { SERVICE_PROVIDER_COLUMNS } from './ServiceProvider.constants';
import ContactCompanyProductService from '../../../../services/ContactCompanyProductService';

const ServiceProvider = ({ detail }: { detail: iJob }) => {
  const { id, productId } = detail;
  const { state, onSetCurrentPage } = useListCrudHook<iContactCompanyProduct>({
    getFn: ContactCompanyProductService.getContactCompanyProducts,
    filter: `productId:${productId},contactCompany.type.name:ServiceProvider`,
    sort: 'updatedAt:DESC',
    notRenderWithoutFilter: true,
    paginationApplied: true,
  });
  // don't need third party service
  if (!detail.product.requireThirdPartyService) {
    return null;
  }
  return (
    <>
      <SpaceVerticalWrapper className={'small'} />
      <ServiceProviderTable
        data={state.data}
        isLoading={state.isLoading}
        columns={SERVICE_PROVIDER_COLUMNS}
        jobId={id}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );
};

export default ServiceProvider;
