import React, { useState, useEffect } from 'react';
import { Controller, Control } from 'react-hook-form';
import _ from 'lodash';
import Select from '@atlaskit/select';
import type { ValueType } from '@atlaskit/select/types';
import { mapFunctionByName } from '../helpers/MapFunctionByName';
import { iOption, iApiAttr } from './types';
import { parseDefault } from './HelperSelect';
import iFormFieldValueSet from '../../../types/form/iFormFieldValueSet';
import { apiErrorToast } from '../../toast/ToastHandler';

const initialOptions: Array<iOption> = [];
//  eslint-disable-next-line
const initialSelectedOptions: Array<any> = [];
const SelectWithApi = ({
  id,
  placeholder,
  rawDefaultValue,
  defaultValue,
  control,
  onChange,
  api,
  isMulti,
  isCompulsory = false,
}: {
  id: string;
  placeholder?: string;
  rawDefaultValue?: Array<iFormFieldValueSet>;
  defaultValue?: string | Array<string>;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  api?: iApiAttr;
  isMulti?: boolean;
  isCompulsory?: boolean;
}) => {
  const [options, setOptions] = useState(initialOptions);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<ValueType<iOption>>();

  const handleValueChange = (selected: iOption | Array<iOption>) => {
    if (!Array.isArray(selected)) {
      onChange(id, [{ value: (selected as iOption).value }], {
        shouldValidate: true,
      });
    } else {
      const selectedItems = selected as Array<iOption>;
      onChange(
        id,
        selectedItems.map((selectedItem: iOption) => ({
          value: selectedItem.value,
        })),
        { shouldValidate: true },
      );
    }
  };
  //    filter unnecessary info in machine
  //    type of options need to update when real api call is ready
  //  eslint-disable-next-line
  const extractOptions = (rawOptions: any) => {
    //  eslint-disable-next-line
    return _.sortBy(rawOptions, ['name']).map((item: any) => {
      return { label: item.name, value: item.id };
    });
  };

  useEffect(() => {
    let isCancelled = false;
    const loadOptions = async () => {
      if (api) {
        try {
          const res = await (
            mapFunctionByName(api?.serviceName, api?.functionName) as () => Promise<{ id: string; name: string }[]>
          )();

          if (isCancelled) return;
          const parsedOptions = extractOptions(res);
          setOptions(parsedOptions);
          setValue(parseDefault(parsedOptions, defaultValue, isMulti) as unknown as ValueType<iOption>);
          setLoading(false);
        } catch (error) {
          if (isCancelled) return;
          setLoading(false);
          apiErrorToast(error);
        }
      }
    };
    loadOptions();
    return () => {
      isCancelled = true;
    };
  }, [api, defaultValue, isMulti]);

  return loading ? (
    <small>Loading...</small>
  ) : (
    <Controller
      name={id}
      control={control}
      defaultValue={rawDefaultValue}
      rules={{
        validate: val =>
          isCompulsory ? (val && Array.isArray(val) && val.length > 0) || 'must select one item' : true,
      }}
      render={() => (
        <Select
          value={value}
          //  eslint-disable-next-line
          onChange={(selected: any) => handleValueChange(selected)}
          placeholder={placeholder}
          isMulti={isMulti}
          options={options}
        />
      )}
    />
  );
};

export default SelectWithApi;
