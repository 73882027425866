import React from 'react';
import ProgressBtn from './ProgressBtn';
import ProgressPopup from './ProgressPopup';
import { LabelValuePair } from '../types/LabelValuePair';
import { FlexContainer } from '../../../styles/styles';

export const emptyLabelValuePair: LabelValuePair = {
  label: '',
  value: '',
};
const ProgressSelectTest = ({
  currentJobStatus,
  onOpenModal,
  allJobStatus,
}: {
  currentJobStatus: LabelValuePair;
  onOpenModal: (value: LabelValuePair) => void;
  allJobStatus: Array<LabelValuePair>;
}) => {
  const findNextStatus = () => {
    const currentIndex = allJobStatus.findIndex((item: LabelValuePair) => item.value === currentJobStatus.value);
    //  eslint-disable-next-line
    return currentIndex === -1
      ? emptyLabelValuePair
      : currentIndex === allJobStatus.length - 1
      ? allJobStatus[currentIndex]
      : allJobStatus[currentIndex + 1];
  };
  return (
    <FlexContainer>
      <ProgressBtn onOpenModal={onOpenModal} status={findNextStatus()} />
      <ProgressPopup currentJobStatus={currentJobStatus} onOpenModal={onOpenModal} allJobStatus={allJobStatus} />
    </FlexContainer>
  );
};
export default ProgressSelectTest;
