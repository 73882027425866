import styled from 'styled-components';

export const CommentWrapper = styled.div`
  margin: 1.5rem 0;
  &.comments-wrapper > div {
    padding: 0.5rem 0;
    color: rgb(9, 30, 66);
  }
  .comment-item-view,
  .comment-create-view {
    width: 100%;
    > div {
      margin-bottom: 0.5rem;
    }
    .comment-item-header {
      .comment-item-username {
        color: rgb(66, 82, 110);
        font-weight: 500;
      }
      .comment-item-date {
        color: rgb(66, 82, 110);
      }
      span {
        margin-right: 8px;
      }
    }
  }
  .delete-button,
  .edit-button {
    padding-left: 0;
  }
  .item-avatar {
    margin-right: 1rem;
    display: block;
    margin-bottom: auto;
  }
  .comment-item-view > p {
    white-space: pre-line;
    margin-bottom: 0.5rem;
  }
`;
