import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@atlaskit/button';

const LinkBtnUrl = ({ btnName, url, state }: { btnName: string; url: string; state?: unknown }) => {
  const history = useHistory();
  return (
    <Button testId={'link-button'} appearance={'link'} spacing={'none'} onClick={() => history.push(url, state)}>
      {btnName}
    </Button>
  );
};

export default LinkBtnUrl;
