import React from 'react';
import iEntityCategory from '../../types/status/iEntityCategory';
import { iConfigColumn } from '../UITypes/types';
import LinkBtnUrl from '../buttons/LinkBtnUrl';
import StatusLozenge from '../statusComponent/StatusLozenge';
import ComposeSecondaryText from '../text/ComposeSecondaryText';
import ConversionText from '../text/ConversionText';
import {
  handleNullException,
  handleMoney,
  handleNullExceptionMultiFields,
  handleNumber,
  getCategoryCode,
  formatDate,
  assembleAddress,
} from '../../services/UtilsService';
import CreatedOrUpdated from '../createdOrUpdated/CreatedOrUpdated';

const NO_CONTENT_HEAD_TYPES = ['operation', 'print', 'delete'];

const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    if (NO_CONTENT_HEAD_TYPES.includes(column.type)) {
      return {
        key: column.key,
        testId: `${tableName}-column-${column.key}`,
      };
    }
    return {
      key: column.key,
      content: column.name,
      testId: `${tableName}-column-${column.key}`,
      isSortable: !!column.isSortable,
    };
  });
  return { cells };
};

const getRows = <T extends { id: string }>({
  data,
  columns,
  categories,
  renderSpecialContent,
}: {
  data: Array<T>;
  columns: Array<iConfigColumn>;
  categories: Array<iEntityCategory>;
  renderSpecialContent?: (item: T, column: iConfigColumn, index: number) => JSX.Element;
}) => {
  return data.map((item: T, index: number) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key, 2),
          };
        case 'unit-price':
          return {
            key: handleNullException(item, key),
            content: `${handleMoney(item, key, 2)} / ${handleNullExceptionMultiFields(item, dataSource.join(','))}`,
          };
        case 'number':
          return {
            key: handleNullException(item, key),
            content: handleNumber(item, key),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
              />
            ),
          };
        case 'secondary-text':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[0])}>
                {handleNullException(item, key)}
              </ComposeSecondaryText>
            ),
          };
        case 'text-postfix':
          return {
            key: handleNullException(item, key),
            content: `${handleNullException(item, key)} ${handleNullException(item, dataSource[0])}`,
          };
        case 'conversion-text':
          return {
            key: handleNullException(item, key),
            content: (
              <ConversionText
                value={handleNullException(item, key)}
                conversion={handleNullException(item, dataSource[0])}
                contactCompanyUnit={
                  handleNullException(item, dataSource[1]) || handleNullException(item, dataSource[2])
                }
                productUnit={handleNullException(item, dataSource[2])}
              />
            ),
          };
        case 'status':
          return {
            content: (
              <StatusLozenge
                status={handleNullException(item, key)}
                categoryCode={getCategoryCode(handleNullException(item, key), categories)}
              />
            ),
          };
        case 'date':
          return {
            key: handleNullException(item, key),
            content: formatDate(handleNullException(item, key), 'Do MMM YYYY'),
          };
        case 'datetime':
          return {
            key: handleNullException(item, key),
            content: formatDate(handleNullException(item, key), 'Do MMMM  YYYY, h:mm a'),
          };
        case 'address':
          return {
            key: handleNullException(item, key),
            content: assembleAddress(handleNullException(item, key)),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        default:
          return {
            content: typeof renderSpecialContent === 'function' ? renderSpecialContent(item, column, index) : <div />,
          };
      }
    });
    return { cells };
  });
};
export default { getHeads, getRows, NO_CONTENT_HEAD_TYPES };
