import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import BackendPage from '../../layout/BackendPage';
import WelcomeMsg from '../../shared/user/WelcomeMsg';
import { RootState } from '../../redux/makeReduxStore';
import InlineEdit from '../../shared/form/InlineEdit';
import InlineEditSelectFetchOptions from '../../shared/form/InlineEditSelectFetchOptions';
import PasswordReset from '../../shared/user/PasswordReset';
import UserAvatarUpload from '../../shared/user/UserAvatarUpload';
import UserProfileService from '../../services/UserProfileService';
import { getMachines } from '../../services/MachineService';
import { userAuthenticated } from '../../redux/reduxers/auth.slice';
import { addToastForAPIResponse, apiErrorToast } from '../../shared/toast/ToastHandler';

export default () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();
  // update profile
  const onConfirm = async (name: string, newValue: string | number | boolean | null) => {
    try {
      const res = await UserProfileService.updateUserProfile(name, newValue);
      dispatch(userAuthenticated(res));
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  return (
    <BackendPage pageHeader={<WelcomeMsg />}>
      <Page>
        <Grid spacing="cosy">
          <GridColumn medium={4}>
            <UserAvatarUpload name={'userAvatarUrl'} defaultValue={user?.userAvatarUrl} onConfirm={onConfirm} />
          </GridColumn>
          <GridColumn medium={8}>
            <Grid layout={'fluid'}>
              <GridColumn medium={4}>
                <InlineEdit
                  name={'firstName'}
                  label={'First name'}
                  defaultValue={user?.firstName}
                  onConfirm={onConfirm}
                  testId={'firstName'}
                />
              </GridColumn>
              <GridColumn medium={4}>
                <InlineEdit
                  name={'lastName'}
                  label={'Last name'}
                  defaultValue={user?.lastName}
                  onConfirm={onConfirm}
                  testId={'lastName'}
                />
              </GridColumn>
              <GridColumn medium={4}>
                <InlineEdit
                  name={'username'}
                  label={'Username'}
                  defaultValue={user?.username}
                  onConfirm={onConfirm}
                  testId={'username'}
                />
              </GridColumn>
              <GridColumn medium={4}>
                <InlineEdit
                  name={'email'}
                  label={'Email'}
                  defaultValue={user?.email}
                  onConfirm={onConfirm}
                  testId={'email'}
                />
              </GridColumn>
            </Grid>
            <hr />
            <Grid layout={'fluid'}>
              <GridColumn medium={4}>
                <InlineEditSelectFetchOptions
                  name={'defaultOperatingMachineId'}
                  label={'Default operating machine'}
                  defaultValue={user?.defaultOperatingMachineId}
                  promiseFn={getMachines}
                  onSelect={onConfirm}
                />
              </GridColumn>
            </Grid>
            <hr />
            <PasswordReset name={'password'} onConfirm={onConfirm} />
          </GridColumn>
        </Grid>
      </Page>
    </BackendPage>
  );
};
