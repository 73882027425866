import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { colors, gridSize } from '@atlaskit/theme';
import Button, { ButtonGroup } from '@atlaskit/button';
import DynamicText from '../../text/DynamicText';
import Textfield from '../../../form/Textfield';
import { iOperateBulkRow } from '../shared/DynamicManufactureRows.types';
import { ErrorMsg } from '../../styleContainer';
import { ucFirst } from '../../../../services/UtilsService';
import JobAttributeService from '../../../../services/JobAttributeService';
import JobAttributeValueService from '../../../../services/JobAttributeValueService';
import { apiErrorToast } from '../../../toast/ToastHandler';
import iJobAttribute from '../../../../types/job/iJobAttribute';
import iJobAttributeValue from '../../../../types/job/iJobAttributeValue';
import RollPrintUtils from '../../../print/rollLabelPrint/RollPrint.utils';

const BulkOperationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.N40};
  padding: ${gridSize()}px ${gridSize() - 2}px;
  box-sizing: border-box;
  background-color: ${colors.G50};
`;
const initialErrors = {
  length: '',
  actualWeight: '',
  nominalWeight: '',
  rowNumber: '',
};
const OperateBulkRows = ({
  operationType,
  callback,
  initialRowNumber,
  onCancel,
  jobId,
}: {
  operationType: string;
  callback: (value: iOperateBulkRow) => void;
  initialRowNumber?: string;
  onCancel: () => void;
  jobId: string;
}) => {
  const initialState = {
    length: '',
    nominalWeight: '',
    actualWeight: '',
    rowNumber: initialRowNumber || '1',
  };

  const [state, setState] = useState<iOperateBulkRow>(initialState);
  const [errors, setErrors] = useState<iOperateBulkRow>(initialErrors);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //  eslint-disable-next-line
  function hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj;
  }
  const validateInputs = () => {
    let isValid = true;
    Object.keys(initialState).forEach((key: string) => {
      if (!hasKey(errors, key) || !hasKey(state, key)) {
        return;
      }
      const convertNumber = Number(state[key]);
      if (
        key === 'rowNumber' &&
        operationType.toUpperCase() === 'UPDATE' &&
        initialRowNumber &&
        convertNumber > Number(initialRowNumber)
      ) {
        setErrors(pre => ({
          ...pre,
          [key]: `at most ${initialRowNumber} roll(s) is valid to update`,
        }));
        isValid = false;
      }
      if (Number.isNaN(convertNumber) || convertNumber === 0) {
        setErrors(pre => ({ ...pre, [key]: 'must enter a number' }));
        isValid = false;
      }
    });
    return isValid;
  };
  const hideErrors = () => {
    setErrors(initialErrors);
    setIsDisabled(false);
  };
  const onClick = () => {
    if (!validateInputs()) {
      setIsDisabled(true);
      return;
    }
    callback(state);
    // restore default value when add
    // if (operationType.toUpperCase() === 'ADD') {
    //   document.getElementsByName('bulkLength')[0].focus();
    //   setState(initialState);
    // }
  };

  //  .3 to 0.3
  const handleEnterValue = (name: string, newValue: string) => {
    let formattedValue = newValue;
    if (newValue.startsWith('.')) {
      formattedValue = `0${newValue}`;
    }
    hideErrors();
    setState({ ...state, [name]: formattedValue });
  };
  useEffect(() => {
    let isCancelled = false;
    const fetchJobAttribute = async () => {
      setIsLoading(true);
      try {
        const attributes = await JobAttributeService.getJobAttributes();
        const attributeValues = await JobAttributeValueService.getJobAttributeValues({
          filter: `jobId:${jobId}`,
        });
        if (isCancelled) return;

        const { length, nominalWeight } = RollPrintUtils.extractJobAttribute(
          attributes.map((attr: iJobAttribute) => {
            const matchValue = attributeValues.find((value: iJobAttributeValue) => value.jobAttributeId === attr.id);
            return matchValue ? { ...attr, jobAttributeValues: matchValue } : attr;
          }),
        );

        setState(prevState => ({
          ...prevState,
          length: length !== 0 ? length.toString() : '',
          nominalWeight: nominalWeight !== 0 ? nominalWeight.toString() : '',
        }));
        setIsLoading(false);
      } catch (error) {
        if (isCancelled) return;
        setIsLoading(false);
        apiErrorToast(error);
      }
    };
    fetchJobAttribute();
    return () => {
      isCancelled = true;
    };
  }, [jobId]);

  const getTitle = () => (
    <div className="col-1">
      <DynamicText title="" description={ucFirst(operationType)} />
    </div>
  );
  const getButtonGroup = (bulkUpdateNoRoll?: boolean) => (
    <ButtonGroup>
      <Button appearance="primary" onClick={onClick} isDisabled={bulkUpdateNoRoll || isDisabled} testId={'action-bulk'}>
        {ucFirst(operationType)}
      </Button>
      <Button onClick={onCancel} testId={'cancel-bulk'}>
        Cancel
      </Button>
    </ButtonGroup>
  );
  if (operationType.toUpperCase() === 'UPDATE' && state.rowNumber === '0') {
    return (
      <BulkOperationContainer className={'bulk-update-no-roll'}>
        {getTitle()}
        <div className="col-7-4">
          <ErrorMsg>
            All of rows below are completed and not allowed to update.You can add more rows by bulkAdd
          </ErrorMsg>
        </div>
        <div className="col-1-8">{getButtonGroup(true)}</div>
        <div className="col-1-8" />
      </BulkOperationContainer>
    );
  }
  if (isLoading) return <Spinner />;
  return (
    <BulkOperationContainer className={'bulk-update-container'}>
      {getTitle()}
      <div className="col-1-8">
        <Textfield
          defaultValue={state.length}
          name={'bulkLength'}
          onChange={(newValue: string) => handleEnterValue('length', newValue)}
          validateErrorMessage={errors.length}
          type={'number'}
        />
      </div>
      <div className="col-1-8">
        <Textfield
          defaultValue={state.nominalWeight}
          name={'bulkNominalWeight'}
          onChange={(newValue: string) => handleEnterValue('nominalWeight', newValue)}
          validateErrorMessage={errors.nominalWeight}
          type={'number'}
        />
      </div>
      <div className="col-1-8">
        <Textfield
          defaultValue={state.actualWeight}
          name={'bulkActualWeight'}
          onChange={(newValue: string) => handleEnterValue('actualWeight', newValue)}
          validateErrorMessage={errors.actualWeight}
          type={'number'}
        />
      </div>
      {operationType.toUpperCase() === 'UPDATE' && (
        <>
          <div className="col-2">
            <Textfield
              defaultValue={state.rowNumber}
              name={'bulkAddRowNumber'}
              onChange={(newValue: string) => handleEnterValue('rowNumber', newValue)}
              suffix={'Roll(s)'}
              validateErrorMessage={errors.rowNumber}
              type={'number'}
            />
          </div>
          <div className="col-1-8">{getButtonGroup()}</div>
          <div className="col-1-8" />
        </>
      )}
      {operationType.toUpperCase() === 'ADD' && (
        <>
          <div className="col-2 display-flex">{getButtonGroup()}</div>
          <div className="col-1-8" />
          <div className="col-1-8" />
        </>
      )}
    </BulkOperationContainer>
  );
};

export default OperateBulkRows;
