import React, { useEffect, useState } from 'react';
import SectionMessage from '@atlaskit/section-message';
import { LoadingButton } from '@atlaskit/button';
import iDispatchNote from '../../../types/dispatchNote/iDispatchNote';
import { STATUS_CATEGORY_FINISHED } from '../../../shared/constants/StatusCategories';
import EntityStatusService from '../../../services/EntityStatusService';
import iEntityStatus from '../../../types/status/iEntityStatus';
import ModalPage from '../../../shared/modalPage/ModalPage';
import DispatchNoteService from '../../../services/DispatchNoteService';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/ToastHandler';

export const STATUS_SEND_CODE = 'SENT';

type DNSentBtnProps = { dispatchNote: iDispatchNote; onUpdated?: (dn: iDispatchNote) => void };
const DNSentBtn = ({ dispatchNote, onUpdated }: DNSentBtnProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showingPopup, setShowingPopup] = useState(false);
  const [sentStatus, setSentStatus] = useState<iEntityStatus | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (
      !dispatchNote.status ||
      !dispatchNote.status?.entityStatusCategory ||
      dispatchNote.status?.entityStatusCategory?.code === STATUS_CATEGORY_FINISHED
    ) {
      return;
    }

    let isCancelled = false;
    setIsLoading(true);
    EntityStatusService.getEntityStatuses({
      advancedFilter: `code:${STATUS_SEND_CODE}`,
      include: 'entityStatusType',
    })
      .then((statuses: iEntityStatus[]) => {
        if (isCancelled) {
          return;
        }
        const stats = statuses.filter(
          status => status.entityStatusType && status.entityStatusType.entityName === 'DispatchNote',
        );
        setSentStatus(stats.length > 0 ? stats[0] : null);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        setIsLoading(false);
      });

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true;
    };
  }, [dispatchNote]);

  const markAsSent = (): void => {
    if (!sentStatus) {
      return;
    }
    setIsSaving(true);
    DispatchNoteService.updateDispatchNote(dispatchNote.id, { statusId: sentStatus.id })
      .then(res => {
        addToastForAPIResponse('success', `${dispatchNote.dispatchNoteNumber} sent successfully.`);
        setIsSaving(false);
        if (onUpdated) {
          onUpdated(res);
        }
      })
      .catch(error => {
        apiErrorToast(error);
        setIsSaving(false);
      });
  };

  const getPopup = () => {
    if (showingPopup !== true) {
      return null;
    }
    return (
      <ModalPage
        onConfirm={markAsSent}
        onCancel={() => setShowingPopup(false)}
        isConfirming={isSaving === true}
        isDisabled={isSaving === true}
        heading={`Marking ${dispatchNote.dispatchNoteNumber} to be as SENT`}
        confirmBtnName={'Confirm'}
      >
        <SectionMessage appearance="warning" testId={'warning-section-message'}>
          <p>
            Once you confirm this action, you will <b>NOT</b> be able to view this Dispatch Note anymore.
          </p>
        </SectionMessage>
        <p>Are you sure to continue?</p>
      </ModalPage>
    );
  };

  if (sentStatus === null) {
    return null;
  }

  return (
    <>
      <LoadingButton
        isLoading={isLoading}
        onClick={() => setShowingPopup(true)}
        appearance={'primary'}
        testId={'dn-sent-btn'}
      >
        MARK AS SENT
      </LoadingButton>
      {getPopup()}
    </>
  );
};

export default DNSentBtn;
