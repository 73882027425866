import React from 'react';
import { colors } from '@atlaskit/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button, { ButtonGroup } from '@atlaskit/button';
import { useSelector } from 'react-redux';
import iPurchaseOrderDetail from '../../../types/purchaseOrder/iPurchaseOrderDetail';
import { FlexContainer, FlexSpaceBetweenContainer, PageTitle } from '../../../shared/styles/styles';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import { getCategoryCode, handleNullException } from '../../../services/UtilsService';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { PO_STATUS_WIP_CATEGORY } from '../constants';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_RECEIVING } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';

const Wrapper = styled.div`
  & .Breadcrumb {
    font-size: 0.9rem;
    color: ${colors.N100};
    font-weight: 500;
  }
`;

const Breadcrumb = ({ children }: { children: React.ReactNode }) => <div className="Breadcrumb">{children}</div>;

const Header = ({
  po,
  onReceiving,
  categories,
}: {
  po?: iPurchaseOrderDetail;
  onReceiving: () => void;
  categories: Array<iEntityCategory>;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReceiveStock = AccessService.hasAccess(ACCESS_CODE_STOCK_RECEIVING, ACCESS_CAN_UPDATE, user);
  return (
    <>
      <FlexSpaceBetweenContainer>
        <Wrapper>
          <Breadcrumb>
            <Link to="/b/purchases" className="Breadcrumb__Link">
              {'Purchase Orders '}
            </Link>
            / PO #: {po?.orderNumber}
          </Breadcrumb>

          <FlexContainer>
            <PageTitle className={'small space-right'}>PO #: {po?.orderNumber}</PageTitle>

            <StatusLozenge
              status={handleNullException(po, 'status')}
              categoryCode={getCategoryCode(handleNullException(po, 'status'), categories)}
            />
          </FlexContainer>
        </Wrapper>

        <ButtonGroup>
          {po?.status.entityStatusCategory?.code === PO_STATUS_WIP_CATEGORY && canReceiveStock && (
            <Button appearance="primary" onClick={() => onReceiving()}>
              Receive
            </Button>
          )}
        </ButtonGroup>
      </FlexSpaceBetweenContainer>
    </>
  );
};

export default Header;
