import React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';
import iContactCompany from '../../types/contactCompany/iContactCompany';
import { LabelValue } from '../styles/styles';
import { assembleAddress } from '../../services/UtilsService';

const ContCompanyKeyInfo = ({ contComp, isCustomer }: { contComp: iContactCompany; isCustomer: boolean }) => {
  const getAddressGridColumn = (isBillingAddress: boolean) => {
    const gridSize = isCustomer ? 6 : 12;
    const address = isBillingAddress ? contComp.billingAddress : contComp.shippingAddress;
    //    eslint-disable-next-line
    const labelAndHeading = isBillingAddress ? 'Billing Address' : 'Shipping Address';
    return (
      <GridColumn medium={gridSize}>
        <Label label={labelAndHeading} isFirstChild />
        <LabelValue>{assembleAddress(address)}</LabelValue>
      </GridColumn>
    );
  };

  return (
    <>
      <Grid spacing={'compact'}>
        <GridColumn medium={6}>
          <Label label={'Email'} isFirstChild />
          <LabelValue>{contComp.email}</LabelValue>
        </GridColumn>
        <GridColumn medium={6}>
          <Label label={'Phone'} isFirstChild />
          <LabelValue>{contComp.phone}</LabelValue>
        </GridColumn>
      </Grid>
      <Grid spacing={'compact'}>
        {isCustomer && getAddressGridColumn(false)}
        {getAddressGridColumn(true)}
      </Grid>
    </>
  );
};

export default ContCompanyKeyInfo;
