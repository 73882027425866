import React from 'react';
import _ from 'lodash';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { Checkbox } from '@atlaskit/checkbox';
import iFormFieldValueSet from '../../../types/form/iFormFieldValueSet';
import { ErrorMsg, FlexAlignEndContainer } from '../styleContainer';

const DynamicCheckbox = ({
  id,
  control,
  defaultValue,
  onChange,
  errors,
  isCompulsory = false,
}: {
  id: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: Array<iFormFieldValueSet>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors: DeepMap<Record<string, any>, FieldError>;
  isCompulsory?: boolean;
}) => {
  const handleValueChange = (value: boolean) => {
    onChange(id, [{ value }], { shouldValidate: true });
  };
  const parseDefaultValue = () =>
    defaultValue && defaultValue.length === 1 ? (defaultValue[0].value as boolean) : false;

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || false}
      rules={{
        validate: value => (isCompulsory ? value && value.length === 1 && value[0].value : undefined),
      }}
      render={() => (
        <FlexAlignEndContainer
          data-testid={'dynamic-checkbox'}
          className={`dynamic-checkbox-container ${_.get(errors, id) && 'validation-error'}`}
        >
          <Checkbox
            className="hideCheckboxForiOS"
            name={id}
            defaultChecked={parseDefaultValue()}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const val = event.currentTarget.checked;
              handleValueChange(val);
            }}
          />
          {_.get(errors, id) && <ErrorMsg data-testid={'error-msg'}>must be checked</ErrorMsg>}
        </FlexAlignEndContainer>
      )}
    />
  );
};

export default DynamicCheckbox;
