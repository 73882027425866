import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import { ucFirst } from '../../services/UtilsService';
import { FlexContainer, PageTitle } from '../styles/styles';

const AddOneBtnWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  color: #42526e;
  &.space-left {
    margin-left: 1rem;
    background-color: ${colors.backgroundActive};
  }
`;

const PageTitleWithAddOne = ({
  title,
  onClickAddBtn,
  className,
  classNameTitle,
  isCustomizedTitle = false,
  isDisabled = false,
}: {
  title: string;
  onClickAddBtn: () => void;
  className?: string;
  classNameTitle?: string;
  isCustomizedTitle?: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <FlexContainer className={className} data-testid={'flex-container'}>
      <PageTitle className={classNameTitle} data-testid={'page-title'}>
        {isCustomizedTitle ? title : ucFirst(title)}
      </PageTitle>
      {isDisabled ? null : (
        <AddOneBtnWrapper className={'space-left'} data-testid={'add-one-btn-wrapper'}>
          <Button
            iconBefore={<AddIcon label="add" size={'small'} testId={'add-icon'} />}
            shouldFitContainer
            onClick={onClickAddBtn}
            testId={'add-one-after-page-title'}
          />
        </AddOneBtnWrapper>
      )}
    </FlexContainer>
  );
};

export default PageTitleWithAddOne;
