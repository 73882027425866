import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { handleNullException } from '../../../services/UtilsService';
import iProduct from '../../../types/product/iProduct';
import DeleteBtn from '../../buttons/DeleteBtn';
import InlineEdit from '../../form/InlineEdit';
import { iConfigColumn } from '../../UITypes/types';
import { iWaste } from './ShiftSummary.constants';

const wastelistRenderSpecialContent =
  <T extends { id: string }>({
    onDelete,
    onUpdate,
  }: {
    onDelete: (id: string) => void;
    onUpdate: (id: string, value: string) => void;
  }) =>
  (item: T, column: iConfigColumn) => {
    const { type, dataSource, key } = column;
    const { id } = item;
    switch (type) {
      case 'operation':
        return <DeleteBtn id={id} onClick={onDelete} />;
      case 'text-edit-postfix':
        return (
          <InlineEdit
            key={id}
            name={id}
            postfix={handleNullException(item, dataSource[0])}
            onConfirm={onUpdate}
            defaultValue={handleNullException(item, key)}
            type={'number'}
            hideActionButtons
            isNumeric
          />
        );
      default:
        return <div />;
    }
  };

const convertWastes = (wastes: Array<iWaste>, allWasteProducts: Array<iProduct>) => {
  return wastes.map((item: iWaste) => ({
    id: uuidv4(),
    product: allWasteProducts.find((product: iProduct) => product.id === item.productId),
    qty: item.qty,
  }));
};

export default { convertWastes, wastelistRenderSpecialContent };
