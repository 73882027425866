import React, { useState } from 'react';
import styled from 'styled-components';
import Select, { ValueType } from '@atlaskit/select';
import InlineEdit from '@atlaskit/inline-edit';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import { iLabelValuePair } from '../UITypes/types';
import InlineEditReadView from './InlineEditReadView';

const DivWrapper = styled.div``;

type iState = {
  focused: boolean;
  value: ValueType<iLabelValuePair>;
};

export default ({
  name,
  defaultValue,
  label,
  selectOptions,
  onConfirm,
  testId = 'inline-edit-select',
  isClearable = false,
  hideActionButtons = false,
  isDisabled = false,
}: {
  name: string;
  defaultValue?: iLabelValuePair | null;
  label?: string;
  selectOptions: Array<iLabelValuePair>;
  //  eslint-disable-next-line
  onConfirm?: (name: string, newValue: string | null) => any;
  testId?: string;
  isClearable?: boolean;
  hideActionButtons?: boolean;
  isDisabled?: boolean;
}) => {
  const initialState: iState = {
    focused: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value: defaultValue || undefined,
  };
  const [state, setState] = useState(initialState);

  const changeValue = (selected: ValueType<iLabelValuePair>) => {
    const value = selected ? (selected as iLabelValuePair).value : null;
    // not change
    if (value === defaultValue?.value || (value === null && typeof defaultValue === 'undefined')) {
      setState({
        ...state,
        focused: false,
      });
      return;
    }
    setState({
      ...state,
      value: selected,
      focused: false,
    });
    if (typeof onConfirm === 'function') {
      onConfirm(name, value);
    }
  };
  return (
    <DivWrapper
      id={name}
      tabIndex={0}
      onFocus={() => setState(preState => ({ ...preState, focused: true }))}
      data-testid={`div-wrapper-${testId}`}
    >
      <InlineEdit<ValueType<iLabelValuePair>>
        defaultValue={defaultValue}
        onConfirm={changeValue}
        hideActionButtons={hideActionButtons}
        label={label}
        editView={fieldProps => (
          <Select
            // eslint-disable-next-line
            {...fieldProps}
            options={selectOptions}
            autoFocus
            defaultMenuIsOpen
            classNamePrefix={`${testId}-prefix`}
            className={testId}
            isClearable={isClearable}
            isDisabled={isDisabled}
          />
        )}
        readView={() => (
          <InlineEditReadView
            value={state.value ? (state.value as iLabelValuePair).label : ''}
            postfix={<HipchatChevronDownIcon label={'ChevronDropdown'} size="small" />}
            name={name}
            isDisabled={isDisabled}
          />
        )}
        isEditing={!isDisabled && state.focused}
        onEdit={() => setState({ ...state, focused: true })}
        onCancel={() => setState({ ...state, focused: false })}
      />
    </DivWrapper>
  );
};
