import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { handleNullException } from '../../../services/UtilsService';
import InputWithController from '../../../shared/hookForms/InputWithController';
import iItemLocation from '../../../types/warehouse/iItemLocation';
import { iConfigColumn } from '../../dispatchNote/shared/DispatchNote.type';
import { FlexEndWrapper } from './StockTransfer.style';
import { iItemLocationTransfer } from './StockTransfer.type';
import TransparentIconBtn from '../../../shared/buttons/TransparentIconBtn';

const mergeRecords = (newRecords: Array<iItemLocationTransfer>, oldRecords: Array<iItemLocationTransfer>) => {
  const tempRecords = [...oldRecords];
  newRecords.forEach((newR: iItemLocationTransfer) => {
    const oldRecordIndex = tempRecords.findIndex((r: iItemLocationTransfer) => r.id === newR.id);
    if (oldRecordIndex > -1) {
      tempRecords.splice(oldRecordIndex, 1, newR);
    } else {
      tempRecords.push(newR);
    }
  });
  return tempRecords;
};

const addProductRenderSpecialContent =
  ({
    control,
    onChange,
    errors,
    records,
  }: {
    //  eslint-disable-next-line
    control: Control<Record<string, any>>;
    //  eslint-disable-next-line
    onChange: (name: string, value: any, config?: Object) => void;
    //  eslint-disable-next-line
    errors?: DeepMap<Record<string, any>, FieldError>;
    records: Array<iItemLocationTransfer>;
  }) =>
  (item: iItemLocation, column: iConfigColumn, index: number) => {
    const { type } = column;
    const { id } = item;
    switch (type) {
      case 'input-with-controller': {
        return (
          <div style={{ marginTop: '-8px' }}>
            <InputWithController
              label={''}
              key={`${id}-qty`}
              isNumeric
              type={'number'}
              isRequired={false}
              name={id}
              defaultValue={records.find((record: iItemLocationTransfer) => record.id === id)?.movingQty.toString()}
              onChange={onChange}
              control={control}
              errors={errors}
              autoFocus={index === 0}
              isDisabled={Number(handleNullException(item, 'qty')) <= 0}
              range={{
                min: 0,
                max: Number(handleNullException(item, 'qty')),
              }}
            />
          </div>
        );
      }
      default:
        return <div />;
    }
  };
const getLeftQty = (item: iItemLocationTransfer, sum: string, minus: string) => {
  const qty = handleNullException(item, sum);
  const movingQty = handleNullException(item, minus);
  if (!qty || !movingQty) return '';
  return (qty - movingQty).toString();
};
// eslint-disable-next-line
const reviewRenderSpecialContent = (item: iItemLocationTransfer, column: iConfigColumn, index: number) => {
  const { type, key, dataSource } = column;
  switch (type) {
    case 'calculate-minus':
      return <div>{getLeftQty(item, key, dataSource[0])}</div>;
    default:
      return <div />;
  }
};

const transferProdsRenderSpecialContent =
  ({ onOpenDeleteModal }: { onOpenDeleteModal: (id: string) => void }) =>
  // eslint-disable-next-line
  (item: iItemLocationTransfer, column: iConfigColumn, index: number) => {
    const { type, key } = column;
    switch (type) {
      case 'delete':
        return (
          <FlexEndWrapper>
            <TransparentIconBtn
              icon={<TrashIcon label={'remove'} />}
              onClick={() => onOpenDeleteModal(handleNullException(item, key))}
            />
          </FlexEndWrapper>
        );

      default:
        return <div />;
    }
  };
export default {
  getLeftQty,
  mergeRecords,
  addProductRenderSpecialContent,
  reviewRenderSpecialContent,
  transferProdsRenderSpecialContent,
};
