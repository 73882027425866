import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { formatDate } from '../../services/UtilsService';

const FooterContainer = styled.div`
  width: 30%;
  border-top: 1px solid ${colors.placeholderText};
  display: flex;
  flex-direction: column;
  .footer-item {
    margin-top: 5px;
  }
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LogFooter = ({ item }: { item?: any }) => {
  return (
    <FooterContainer>
      <small className={'footer-item'}>
        Created by {item?.createdBy?.firstName} @ {formatDate(item?.createdAt)}
      </small>
      <small className={'footer-item'}>
        Updated by {item?.updatedBy?.firstName} @ {formatDate(item?.updatedAt)}
      </small>
    </FooterContainer>
  );
};

export default LogFooter;
