/* eslint-disable no-nested-ternary */
import React from 'react';
import { Store } from 'react-notifications-component';
import { handleNullException } from '../../services/UtilsService';
import ErrorFlag from './ErrorFlag';
import SuccessFlag from './SuccessFlag';
import WarningFlag from './WarningFlag';

const defaultErrorMessage = 'Unfortunately, it fails pls try again later';
const defaultSuccessMessage = 'Success';
const defaultWarningMessage = 'Warning';

export const addToastForAPIResponse = (type: 'success' | 'error' | 'warning' | undefined, message?: string) => {
  Store.addNotification({
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: type?.toLowerCase() !== 'success' ? 8000 : 3000,
    },
    content:
      type?.toLowerCase() === 'error' ? (
        <ErrorFlag title={message || defaultErrorMessage} />
      ) : type?.toLowerCase() === 'warning' ? (
        <WarningFlag title={message || defaultWarningMessage} />
      ) : (
        <SuccessFlag title={message || defaultSuccessMessage} />
      ),
  });
};
//  eslint-disable-next-line
export const apiErrorToast = (error: any) => {
  const errorMessage =
    handleNullException(error, 'response.data.message') || handleNullException(error, 'response.data.error');
  return errorMessage ? addToastForAPIResponse('error', errorMessage.toString()) : addToastForAPIResponse('error');
};

export default {
  apiErrorToast,
  addToastForAPIResponse,
};
