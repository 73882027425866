import React from 'react';
import { iConfigColumn } from '../../../UITypes/types';
import iContactCompanyProduct from '../../../../types/contactCompany/iContactCompanyProduct';
import LinkBtnUrl from '../../../buttons/LinkBtnUrl';
import DotOperation from './dotOperation/DotOperation';
import FetchRelatedElements from '../../../fetchRelatedElements/FetchRelatedElements';
import { handleNullException } from '../../../../services/UtilsService';
import PurchaseOrderItemService from '../../../../services/PurchaseOrderItemService';
import DispatchNoteItemService from '../../../../services/DispatchNoteItemService';

export type iGetRowsProps = {
  data: Array<iContactCompanyProduct>;
  columns: Array<iConfigColumn>;
  jobId: string;
};

export const getRows = ({ data, columns, jobId }: iGetRowsProps) => {
  return data.map((item: iContactCompanyProduct) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };

        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
              />
            ),
          };
        case 'sum-POItem-qty':
          return {
            content: (
              <FetchRelatedElements
                fetchFn={() =>
                  PurchaseOrderItemService.getPOItemConfig({
                    // 'SalesOrder_salesOrderId.ContactCompany_customerId.id',
                    advancedFilter: `jobId:${jobId},PurchaseOrder_orderId.supplierId:${item.contactCompanyId}`,
                    currentPage: '1',
                    perPage: '100',
                  })
                }
                renderType={'number-conversion'}
                connectElementType={'sum'}
                dataSource={dataSource}
              />
            ),
          };
        case 'sum-POItem-receivedQty':
          return {
            content: (
              <FetchRelatedElements
                fetchFn={() =>
                  PurchaseOrderItemService.getPOItemConfig({
                    advancedFilter: `jobId:${jobId},PurchaseOrder_orderId.supplierId:${item.contactCompanyId}`,
                    currentPage: '1',
                    perPage: '100',
                  })
                }
                renderType={'number'}
                connectElementType={'sum'}
                dataSource={dataSource}
              />
            ),
          };
        case 'sum-DNItem-qty':
          return {
            content: (
              <FetchRelatedElements
                fetchFn={() =>
                  DispatchNoteItemService.getDNItemConfig({
                    advancedFilter: `jobId:${jobId},DispatchNote_dispatchNoteId.customerId:${item.contactCompanyId}`,
                    currentPage: '1',
                    perPage: '100',
                  })
                }
                renderType={'number'}
                connectElementType={'sum'}
                dataSource={dataSource}
              />
            ),
          };
        case 'operation':
          return {
            content: <DotOperation jobId={jobId} ccp={item} />,
          };

        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-purchaseOrder-row' };
  });
};
