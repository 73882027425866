import styled from 'styled-components';

export const ReadViewWrapper = styled.div``;

export const ConCalControllerWrapper = styled.div`
  border-color: #dfe1e6;
  cursor: text;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  .no-border {
    border-width: 0px;
  }
  .equal-quote {
    margin: 0px 8px;
  }
  .adds-on-container {
    width: auto !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ConCalInlineWrapper = styled.div`
  border-color: #dfe1e6;
  cursor: text;
  box-sizing: border-box;
  .no-border {
    border-width: 0px;
    &:focus-within {
      border-width: 2px;
      border-color: #4c9aff;
    }
  }
  .equal-quote {
    margin: 0px 8px;
  }
  .adds-on-container {
    width: auto !important;
  }
  .conversion-trigger {
    cursor: pointer;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
