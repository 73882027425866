import Button from '@atlaskit/button';
import React from 'react';
import {
  assembleAddress,
  formatDate,
  getCategoryCode,
  handleMoney,
  handleNullException,
} from '../../../services/UtilsService';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import { iConfigColumn } from '../../../shared/UITypes/types';
import iPurchaseOrderDetail from '../../../types/purchaseOrder/iPurchaseOrderDetail';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { PO_STATUS_WIP_CATEGORY } from '../constants';

export const getRows = (
  data: Array<iPurchaseOrderDetail>,
  onReceiving: (id: string) => void,
  columns: Array<iConfigColumn>,
  categories: Array<iEntityCategory>,
) => {
  return data.map((po: iPurchaseOrderDetail) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(po, key),
            content: handleNullException(po, key),
          };
        case 'date':
          return {
            key: handleNullException(po, key) || 'ZZZ',
            content: formatDate(handleNullException(po, key), 'DD MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(po, key),
            content: handleMoney(po, key),
          };
        case 'address':
          return {
            key: handleNullException(po, key),
            content: assembleAddress(handleNullException(po, key)),
          };
        case 'link':
          return {
            key: handleNullException(po, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(po, key)}
                url={`${column.urlPrefix}/${handleNullException(po, dataSource[0])}`}
              />
            ),
          };
        case 'status':
          return {
            key: handleNullException(po, `${key}.id`),
            content: (
              <StatusLozenge
                status={handleNullException(po, key)}
                categoryCode={getCategoryCode(handleNullException(po, key), categories)}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(po, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(po, key)}
                operator={handleNullException(po, dataSource[0])}
              />
            ),
          };
        case 'operation':
          return {
            content:
              po.status?.entityStatusCategory?.code === PO_STATUS_WIP_CATEGORY ? (
                <Button appearance="primary" onClick={() => onReceiving(po.id)}>
                  Receive
                </Button>
              ) : null,
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-purchaseOrder-row' };
  });
};
