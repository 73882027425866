import React, { useState } from 'react';
import ElementWithPopup from '../popup/ElementWithPopup';

import DeleteModalContent from './DeleteModalContent';

export type iSelfManagedDeleteWithReTypeProps = {
  answer: string;
  onConfirm: () => void;
  children: React.ReactNode;
};

const SelfManagedDeleteWithReType = ({ answer, onConfirm, children }: iSelfManagedDeleteWithReTypeProps) => {
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);

  return (
    <ElementWithPopup
      onClick={onConfirm}
      popupContent={<DeleteModalContent answer={answer} onTypeAnswer={setIsCorrectAnswer} />}
      popupHeading={'Are you absolutely sure'}
      isDisabled={!isCorrectAnswer}
      confirmBtnAppearance={'danger'}
      confirmBtnName={'Delete'}
    >
      {children}
    </ElementWithPopup>
  );
};

export default SelfManagedDeleteWithReType;
