import { useState, useEffect } from 'react';
import iEntityStatus from '../../../types/status/iEntityStatus';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { iParams } from '../../UITypes/types';
import { apiErrorToast } from '../../toast/ToastHandler';
import { ALL } from '../../constants/StatusCategories';

type iState = {
  statusCluster: Array<string>;
  isLoading: boolean;
  categories: Array<iEntityCategory>;
};
const initialState: iState = {
  statusCluster: [],
  isLoading: true,
  categories: [],
};

export type iUseStatusFilterProps = {
  type: string;
  //    eslint-disable-next-line
  getFn?: (config?: iParams) => Promise<any>;
  targetCategories: Array<string>;
  isAll?: boolean;
};
const useStatusFilter = ({ type, targetCategories, getFn, isAll = false }: iUseStatusFilterProps) => {
  const [state, setState] = useState(initialState);

  useEffect(
    () => {
      let isCancelled = false;
      const getStatus = (categoryList: Array<iEntityCategory>) =>
        categoryList.reduce(
          (acc: Array<string>, cur: iEntityCategory) =>
            targetCategories.includes(cur.code) ? [...acc, ...cur.entityStatuses.map((s: iEntityStatus) => s.id)] : acc,
          [],
        );
      const fetchDesStatus = async () => {
        if (!getFn) return;
        setState(prevState => ({ ...prevState, isLoading: true }));
        try {
          const categories: Array<iEntityCategory> = await getFn({
            filter: `entityStatuses.entityStatusType.entityName:${type}`,
          });
          if (isCancelled) return;
          //  eslint-disable-next-line
          const statusCluster = isAll ? [ALL] : getStatus(categories);
          setState(prevState => ({
            ...prevState,
            isLoading: false,
            statusCluster,
            categories,
          }));
        } catch (error) {
          if (isCancelled) return;
          setState(prevState => ({ ...prevState, isLoading: false }));
          apiErrorToast(error);
        }
      };
      fetchDesStatus();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [type, getFn, isAll, JSON.stringify(targetCategories)],
  );
  return {
    statusCluster: state.statusCluster,
    categories: state.categories,
    isStatusLoading: state.isLoading,
  };
};
export default useStatusFilter;
