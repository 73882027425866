/* eslint-disable consistent-return */
/* eslint-disable radix */
import React, { useState } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';
import { ErrorMessage } from '@atlaskit/form';
import InlineEditReadView from './InlineEditReadView';
import { emailPattern } from '../constants/RegexConstants';

type iState = {
  value: string;
  focused: boolean;
};

const DivWrapper = styled.div``;
export default ({
  name,
  defaultValue,
  label,
  onConfirm,
  prefix,
  postfix,
  needFormatValue = false,
  hideActionButtons = false,
  isRequired = false,
  isNumeric = false,
  isEmail = false,
  isDisabled = false,
  testId = 'inline-edit',
  type = 'text',
}: {
  defaultValue?: string;
  label?: string;
  name: string;
  onConfirm?: (name: string, newValue: string) => void;
  prefix?: string | React.ReactChild;
  postfix?: string;
  needFormatValue?: boolean;
  hideActionButtons?: boolean;
  isRequired?: boolean;
  isNumeric?: boolean;
  isEmail?: boolean;
  isDisabled?: boolean;
  testId?: string;
  type?: string;
}) => {
  const initialState: iState = {
    value: defaultValue || '',
    focused: false,
  };
  const [state, setState] = useState(initialState);

  // eslint-disable-next-line
  const changeValue = (value: any) => {
    // value is null or is default value
    // don't change it
    if ((!value && !defaultValue) || value === defaultValue) {
      setState({
        ...state,
        focused: false,
      });
      return;
    }

    setState(preState => ({
      ...preState,
      value,
      focused: false,
    }));

    if (typeof onConfirm === 'function') {
      onConfirm(name, value);
    }
  };
  const validate = (value?: string) => {
    // escape , for number
    const escapeComma = value?.toString().replace(',', '');
    // isRequired
    if (isRequired && value === '') {
      return 'is required';
    }
    // is a number
    if (isNumeric && Number.isNaN(Number(escapeComma))) {
      return 'must be a number';
    }

    if (isEmail && !value?.match(emailPattern)) {
      return 'invalid email format';
    }

    return undefined;
  };

  return (
    <DivWrapper
      id={name}
      tabIndex={0}
      onFocus={() => setState(preState => ({ ...preState, focused: true }))}
      data-testid={`div-wrapper-${testId}`}
    >
      <InlineEdit
        label={label}
        onConfirm={changeValue}
        editView={({ errorMessage, ...fieldProps }) => (
          <>
            <Textfield
              //  eslint-disable-next-line
              {...fieldProps}
              autoFocus
              css={{
                fontSize: 'inherit',
                fontWeight: 'inherit',
                lineHeight: 'inherit',
                '& > [data-ds--text-field--input]': {
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  lineHeight: 'inherit',
                },
              }}
              isInvalid={fieldProps.isInvalid}
              testId={testId}
              type={type}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        defaultValue={state.value}
        hideActionButtons={hideActionButtons}
        readView={() => (
          <InlineEditReadView
            value={state.value}
            prefix={prefix}
            postfix={postfix}
            needFormatValue={needFormatValue}
            isDisabled={isDisabled}
            name={name}
          />
        )}
        isEditing={!isDisabled && state.focused}
        onEdit={() => setState({ ...state, focused: true })}
        onCancel={() => setState({ ...state, focused: false })}
        validate={validate}
      />
    </DivWrapper>
  );
};
