import { iConfigColumn } from '../../../../shared/UITypes/types';

export const BULK_STOCK_PAGE_NAME = 'Bulk stocktake';
export const BULK_STOCK_WARNING_DESCRIPTION =
  'Bulk stocktake will allow you to stocktake multiple warehouse locations at the same time, which will lock those locations for any stock movement until you finish thei process. Please ensure no stock movements are needed before you start';
export const BULK_STOCK_START_LABEL = 'Select a parent warehouse location, which may contain multiple sublocations';

export const BULK_STOCK_TITLE = 'Bulk stock take all locations under:';

export const BULK_STOCK_ITEM_TABLE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.name'],
    isCompulsory: true,
    type: 'both-secondary-text',
  },
  {
    name: 'System Qty',
    key: 'itemLocation.qty',
    dataSource: ['product.measurement.shortName'],
    type: 'itemLocation-unit-text',
  },

  {
    name: 'Batch/Lot No.',
    key: 'reference',
    dataSource: ['reference'],
    type: 'both-inlineEdit-reference',
  },
  {
    name: 'Quantity',
    key: 'stockTakeItem.qty',
    dataSource: ['product.measurement.shortName'],
    type: 'stockTakeItem-inlineEdit-qty',
  },
  {
    name: '',
    key: 'delete',
    type: 'stockTakeItem-delete',
    dataSource: ['id'],
  },
];
