import _ from 'lodash';
import { formatDate, handleNullException } from '../../../services/UtilsService';
import iJob from '../../../types/job/iJob';
import iJobCategory from '../../../types/job/iJobCategory';
import iJobStatus from '../../../types/job/iJobStatus';
import { STATUS_CATEGORY_FINISHED, STATUS_CATEGORY_NEW } from '../../constants/JobCategoryConstants';
import { STATUS_CATEGORY_IN_PROGRESS } from '../../constants/StatusCategories';

//  is the status the last one of in progress category's statuses
const isJobStatusTheEndOfInProgress = (jobStatusId: string, jobStatusCategories: Array<iJobCategory>) => {
  const categoryInProgress = jobStatusCategories.find(
    (item: iJobCategory) => item.name === STATUS_CATEGORY_IN_PROGRESS,
  ) as iJobCategory;
  return (
    jobStatusId === _.sortBy(categoryInProgress.jobStatuses, 'sortOrder')[categoryInProgress.jobStatuses.length - 1].id
  );
};

const compareTheIndexOfJobStatus = (jobStatusIdOne: string, jobStatusIdTwo: string, statusMenu: Array<iJobStatus>) => {
  return (
    statusMenu.findIndex((item: iJobStatus) => item.id === jobStatusIdOne) -
      statusMenu.findIndex((item: iJobStatus) => item.id === jobStatusIdTwo) >=
    0
  );
};

const classifyJobCategoryByStatusId = (categories: Array<iJobCategory>, statusId: string) => {
  const result = { isNew: false, isWIP: false, isFinished: false };
  const category = categories.find((c: iJobCategory) => c.jobStatuses.find((s: iJobStatus) => s.id === statusId));
  if (typeof category === 'undefined') return result;
  switch (category.name.toUpperCase()) {
    case STATUS_CATEGORY_NEW:
      result.isNew = true;
      break;
    case STATUS_CATEGORY_IN_PROGRESS:
      result.isWIP = true;
      break;
    case STATUS_CATEGORY_FINISHED:
      result.isFinished = true;
      break;
    default:
      break;
  }
  return result;
};

const getJobInfoGroup = (jobDetail: iJob) => [
  { label: 'Product', value: handleNullException(jobDetail, 'product.productCode') },
  {
    label: 'Qty to produce',
    value: jobDetail.qtyToMake.toString(),
    postfix: handleNullException(jobDetail, 'product.measurement.name'),
  },
  {
    label: 'Pallet System',
    value: handleNullException(jobDetail, 'palletSystem.name'),
  },
  {
    label: 'Wrapping Method',
    value: handleNullException(jobDetail, 'wrappingMethod.name'),
  },
  {
    label: 'Scheduled Date',
    value: formatDate(handleNullException(jobDetail, 'scheduledAt'), 'Do MMM YYYY'),
  },
];

export default {
  isJobStatusTheEndOfInProgress,
  compareTheIndexOfJobStatus,
  classifyJobCategoryByStatusId,
  getJobInfoGroup,
};
