import React from 'react';
import Button from '@atlaskit/button';

const TabItems = ({
  selected,
  items,
  onSelect,
}: {
  selected?: string;
  items: Array<string>;
  onSelect: (label: string) => void;
}) => {
  return (
    <div className={'tab-items'} data-testid={'tab-items'}>
      {items.map((item: string) => (
        <Button
          key={item}
          onClick={() => onSelect(item)}
          isSelected={item === selected}
          className={'tab-item'}
          testId={`tab-item-${item}`}
        >
          {item}
        </Button>
      ))}
    </div>
  );
};

export default TabItems;
