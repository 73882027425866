import iBaseType from '../../iBaseType';

export const ACCESS_CODE_SALES_ORDERS = 'SALES_ORDERS';
export const ACCESS_CODE_QUOTES = 'QUOTES';
export const ACCESS_CODE_INVOICES = 'INVOICES';
export const ACCESS_CODE_DISPATCH_NOTES = 'DISPATCH_NOTES';

export const ACCESS_CODE_PURCHASE_ORDERS = 'PURCHASE_ORDERS';
export const ACCESS_CODE_ACQUISITION_ORDERS = 'ACQUISITION_ORDERS';
export const ACCESS_CODE_STOCK_RECEIVING = 'STOCK_RECEIVING';

export const ACCESS_CODE_JOBS = 'JOBS';
export const ACCESS_CODE_JOB_ATTRIBUTES = 'JOB_ATTRIBUTES';
export const ACCESS_CODE_JOB_SCHEDULER = 'JOB_SCHEDULER';

export const ACCESS_CODE_PRODUCTS = 'PRODUCTS';
export const ACCESS_CODE_PRODUCT_ATTRIBUTES = 'PRODUCT_ATTRIBUTES';

export const ACCESS_CODE_WAREHOUSES = 'WAREHOUSES';
export const ACCESS_CODE_STOCK_TAKES = 'STOCK_TAKES';
export const ACCESS_CODE_STOCK_TRANSFER = 'STOCK_TRANSFER';

export const ACCESS_CODE_WARRANTY = 'WARRANTY';
export const ACCESS_CODE_REPORTS = 'REPORTS';

export const ACCESS_CODE_CUSTOMERS = 'CUSTOMERS';
export const ACCESS_CODE_SUPPLIERS = 'SUPPLIERS';
export const ACCESS_CODE_SERVICE_PROVIDERS = 'SERVICE_PROVIDERS';

export const ACCESS_CODE_SYSTEM_SETTINGS = 'SYSTEM_SETTINGS';

export const AccessCodes = [
  ACCESS_CODE_SALES_ORDERS,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_DISPATCH_NOTES,

  ACCESS_CODE_PURCHASE_ORDERS,
  ACCESS_CODE_ACQUISITION_ORDERS,
  ACCESS_CODE_STOCK_RECEIVING,

  ACCESS_CODE_JOBS,
  ACCESS_CODE_JOB_ATTRIBUTES,
  ACCESS_CODE_JOB_SCHEDULER,

  ACCESS_CODE_PRODUCTS,
  ACCESS_CODE_PRODUCT_ATTRIBUTES,

  ACCESS_CODE_WAREHOUSES,
  ACCESS_CODE_STOCK_TAKES,
  ACCESS_CODE_STOCK_TRANSFER,

  ACCESS_CODE_WARRANTY,
  ACCESS_CODE_REPORTS,

  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_SUPPLIERS,
  ACCESS_CODE_SERVICE_PROVIDERS,

  ACCESS_CODE_SYSTEM_SETTINGS,
] as const;
export type iAccessCode = typeof AccessCodes[number];

type iAccess = iBaseType & {
  code: iAccessCode;
  name: string;
  description: string | null;
};

export default iAccess;
