import TransparentIconBtn from '../../../../../shared/buttons/TransparentIconBtn';
import iAddress from '../../../../../types/contactCompany/iAddress';
import withModalControl from '../shared/withModalControl';
import CreateModalBody from './ModalBodyDataJob';

export type iCreateAganistJobProps = {
  DNId: string;
  customerId?: string;
  //  eslint-disable-next-line
  onCreate: (data: any) => Promise<void>;
  DNShippingAddress?: iAddress;
  DNItemLength?: number;
  isServiceProvider?: boolean;
  customerShippingAddress?: iAddress;
};

const CreateAganistJob = ({
  DNId,
  customerId,
  onCreate,
  DNShippingAddress,
  DNItemLength,
  isServiceProvider = false,
  customerShippingAddress,
}: iCreateAganistJobProps) => {
  return withModalControl({
    WrappedModalBody: CreateModalBody,
    WrappedButton: TransparentIconBtn,
  })({
    wrappedModalBodyProps: {
      DNId,
      customerId,
      onCreate,
      DNShippingAddress,
      DNItemLength,
      isServiceProvider,
      customerShippingAddress,
    },
    wrapperButtonProps: { btnName: 'Job' },
  });
};

export default CreateAganistJob;
