import React from 'react';
import Checkbox from '@atlaskit/checkbox';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import ManufactureRow from './ManufactureRow';
import Collapse from '../../../collapse/Collapse';
import RowNumberWithTransition from '../shared/RowNumberWithTransition';
import { iManufactureRow } from '../shared/DynamicManufactureRows.types';
import { SpaceHorizontalWrapper, SpaceVerticalWrapper } from '../../../styles/styles';
import { CollapseInRowWrapper } from '../shared/DynamicManufactureRows.style';

const ManufactureRows = ({
  data,
  control,
  onChange,
  getValues,
  errors,
  jobId,
  onGoingJobShiftJobId,
  handleCheckboxAction,
  printTargets,
  selectAllDones,
}: {
  data: Array<iManufactureRow>;
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  getValues: (payload?: string | string[]) => any;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  jobId: string;
  onGoingJobShiftJobId: string;
  handleCheckboxAction: (rowId: string, isAdd: boolean) => void;
  printTargets: Array<string>;
  selectAllDones: React.ReactNode;
}) => {
  const groupRow = () => {
    return data.reduce(
      (acc: { collapse: Array<iManufactureRow>; show: Array<iManufactureRow> }, cur: iManufactureRow) =>
        cur.isProcessed ? { ...acc, collapse: [...acc.collapse, cur] } : { ...acc, show: [...acc.show, cur] },
      { collapse: [], show: [] },
    );
  };
  const renderRow = (rows: Array<iManufactureRow>) => {
    return rows.map((item: iManufactureRow) => (
      <ManufactureRow
        key={item.id}
        rowData={item}
        control={control}
        errors={errors}
        onChange={onChange}
        getValues={getValues}
        onGoingJobShiftJobId={onGoingJobShiftJobId}
        jobId={jobId}
      >
        <Checkbox
          className="hideCheckboxForiOS row-selector"
          isChecked={printTargets.includes(item.id)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleCheckboxAction(item.id, event.currentTarget.checked)
          }
        />
      </ManufactureRow>
    ));
  };
  return (
    <>
      <CollapseInRowWrapper>
        <Collapse
          className={'dones'}
          extraControl={selectAllDones}
          text={<RowNumberWithTransition rowNumber={groupRow().collapse.length} isCollapse />}
        >
          <div className={'finished-rows'}>
            {renderRow(groupRow().collapse)}
            <SpaceVerticalWrapper className={'median'} />
          </div>
        </Collapse>
        <Collapse
          defaultOpen
          // not in use jut for lineup
          extraControl={!!selectAllDones && <SpaceHorizontalWrapper className={'median'} />}
          text={<RowNumberWithTransition rowNumber={groupRow().show.length} isCollapse={false} />}
          className={'todos'}
        >
          {renderRow(groupRow().show)}
        </Collapse>
      </CollapseInRowWrapper>
    </>
  );
};

export default ManufactureRows;
