/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_URL_LOGIN } from './UrlMap';
import AuthService from '../services/AuthService';
import JobService from '../services/JobService';
import PageInit from './PageInit';
import { RootState } from '../redux/makeReduxStore';
import { userAuthenticated, removedAuthentication } from '../redux/reduxers/auth.slice';
import { updateCurrentShift, clearShift } from '../redux/reduxers/shift.slice';
import iJobShift from '../types/job/iJobShift';
import { compareStr } from '../services/UtilsService';
// import ModuleEnabledWrapper from './modulize/ModuleEnabledWrapper';
// import ModuleException from './modulize/ModuleException';
// import { ModuleCode } from '../types/iModuleCode';

type iState = {
  getMeloading?: boolean;
  openShiftLoading: boolean;
};
const initialState: iState = {
  getMeloading: true,
  openShiftLoading: true,
};
export default (props: RouteProps) => {
  const [state, setState] = useState(initialState);
  const { isAuthenticated, user } = useSelector((s: RootState) => s.auth);
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const dispatch = useDispatch();

  const findNearestNotEndJobShift = (list: Array<iJobShift>) => {
    const notEndShift = list.sort((a: iJobShift, b: iJobShift) => compareStr(b.startTime, a.startTime));
    if (notEndShift.length > 0) {
      return notEndShift[0];
    }
    return undefined;
  };

  useEffect(() => {
    if (user !== undefined) {
      setState(s => ({ ...s, getMeloading: false }));
      return;
    }
    let isCancelled = false;
    const getCurrentUser = () => {
      setState(s => ({ ...s, getMeloading: true }));
      AuthService.getMe()
        .then(res => {
          if (isCancelled === true) return;
          dispatch(userAuthenticated({ user: res.user }));
          setState(s => ({ ...s, getMeloading: false }));
        })
        .catch(() => {
          if (isCancelled === true) return;
          setState(s => ({ ...s, getMeloading: false }));
          AuthService.removeLocalAuthToken();
          dispatch(removedAuthentication());
        });
    };
    getCurrentUser();
    // eslint-disable-next-line
    return () => {
      isCancelled = true;
    };
  }, [dispatch, user]);

  useEffect(() => {
    if (currentShift !== undefined) {
      setState(s => ({ ...s, openShiftLoading: false }));
      return;
    }
    let isCancelled = false;
    const getCurrentShift = () => {
      setState(s => ({ ...s, openShiftLoading: true }));
      JobService.getOpenJobShift()
        .then(res => {
          if (isCancelled) return;
          setState(s => ({ ...s, openShiftLoading: false }));
          const shift = findNearestNotEndJobShift(res);
          dispatch(updateCurrentShift({ currentShift: shift }));
        })
        .catch(() => {
          if (isCancelled) return;
          setState(s => ({ ...s, openShiftLoading: false }));
          dispatch(clearShift());
        });
    };
    getCurrentShift();
    // eslint-disable-next-line
    return () => {
      isCancelled = true;
    };
  }, [dispatch, currentShift]);

  if (state.openShiftLoading || state.getMeloading) {
    return <PageInit />;
  }

  if (isAuthenticated !== true) {
    const renderComponent = () => <Redirect to={{ pathname: AUTH_URL_LOGIN }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  }
  // return <Route {...props} />;
  // module related
  return (
    // <ModuleEnabledWrapper checkedModule={ModuleCode.TABLET_PORTAL} exception={<ModuleException />}>
    <Route {...props} />
    // </ModuleEnabledWrapper>
  );
};
