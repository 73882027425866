import React from 'react';
import styled from 'styled-components';
import AppPage from './AppPage';
import TopNav from './TopNav/TopNav';

const ContentWrapper = styled.div`
  padding: 1.5rem;

  .page-header-wrapper {
    padding-bottom: 1.25rem;
  }

  .page-children-wrapper {
    td {
      height: 2rem;
    }
  }

  .page-footer-wrapper {
    padding: 1rem 0rem 0rem;
  }
`;
export type iBackendPageProps = {
  children: React.ReactNode;
  pageHeader?: React.ReactNode;
  // pageHeaderProps?: typeof PageHeader;
  pageFooter?: React.ReactNode;
  className?: string;
};
export default ({
  children,
  pageHeader,
  // pageHeaderProps,
  pageFooter,
  className,
}: iBackendPageProps) => {
  return (
    <AppPage nav={<TopNav />}>
      <ContentWrapper className={className}>
        {pageHeader && (
          <div className={'page-header-wrapper'} data-testid={'page-header-wrapper'}>
            {pageHeader}
          </div>
        )}
        <div className={'page-children-wrapper'}>{children}</div>
        {pageFooter && (
          <div className={'page-footer-wrapper'} data-testid={'page-footer-wrapper'}>
            {pageFooter}
          </div>
        )}
      </ContentWrapper>
    </AppPage>
  );
};
