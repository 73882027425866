import React from 'react';
import styled from 'styled-components';
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import Spinner from '@atlaskit/spinner';
import {
  getAttachmentList,
  uploadAttachment,
  updateAttachment,
  deactivateAttachment,
} from '../../../services/AssetService';
import iAttachment from '../../../types/attachment/iAttachment';
import Thumbnail from '../../generalAttachments/Thumbnail';
import Uploader from '../../generalAttachments/Uploader';
import useListCrudHook from '../useListCrudHook/useListCrudHook';
import CustomizeModal from '../../modal/CustomizeModal';
import LoadMoreThumbnail from '../../generalAttachments/LoadMoreInThumbnail';

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-wrap: nowrap;
  overflow: auto;

  width: 100%;
  box-sizing: border-box;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  .spinner-wrapper {
    min-height: 40px;
  }
  .swiper-slides {
    margin-left: 0px;
    padding-bottom: 10px;
    .slide-thumbnail-wrapper {
      width: fit-content !important;
    }
  }
`;
// install Swiper modules
SwiperCore.use([Pagination, Navigation]);
export const THUMBNAILS_ONE_TIME = 5;
export type iUseAttachmentHookParams = {
  entityId: string;
  entityName: string;
  childrenForUploader?: React.ReactNode;
  isTablet?: boolean;
  thumbnailSize?: string;
  canDelete?: boolean;
};

const useAttachmentHook = ({
  entityId,
  entityName,
  childrenForUploader = <CameraFilledIcon label={'camera'} testId={'upload-trigger'} />,
  isTablet = false,
  thumbnailSize = 'normal',
  canDelete = true,
}: iUseAttachmentHookParams) => {
  const { state, edit, onCloseModal, onDelete, onSubmit, onOpenDeleteModal, onSetCurrentPage } =
    useListCrudHook<iAttachment>({
      getFn: getAttachmentList,
      createFn: uploadAttachment,
      updateFn: updateAttachment,
      deleteFn: deactivateAttachment,
      filter: `entityId:${entityId},entityName:${entityName}`,
      sort: 'updatedAt:DESC',
      loadMoreApplied: true,
      perPage: THUMBNAILS_ONE_TIME,
    });

  const loadMore = () => {
    if (state.total && state.data.length < state.total) {
      onSetCurrentPage((state.currentPage || 0) + 1);
    }
  };

  const onInit = (swiper: SwiperCore) => {
    if (state.currentPage && state.total && state.currentPage > 1) {
      swiper.slideTo(state.data.length - THUMBNAILS_ONE_TIME - 1);
    }
  };
  const getUploader = () => {
    return (
      <Uploader onUploadFile={onSubmit} entityId={entityId} entityName={entityName}>
        {childrenForUploader}
      </Uploader>
    );
  };

  const getThumbnail = () => {
    return (
      <>
        <ThumbnailsContainer>
          {state.isLoading || state.isConfirming ? (
            <div className={'spinner-wrapper'}>
              <Spinner size={'medium'} />
            </div>
          ) : (
            <Swiper
              slidesPerView={THUMBNAILS_ONE_TIME}
              spaceBetween={THUMBNAILS_ONE_TIME}
              // slidesPerGroup={3}
              loop={false}
              // loopFillGroupWithBlank
              pagination
              // navigation
              className={'swiper-slides'}
              onInit={onInit}
            >
              {state.data.map((attachment: iAttachment) => (
                <SwiperSlide key={attachment.id} className={'slide-thumbnail-wrapper'}>
                  <Thumbnail
                    attachment={attachment}
                    onRemove={onOpenDeleteModal}
                    isTablet={isTablet}
                    size={thumbnailSize}
                    canDelete={canDelete}
                  />
                </SwiperSlide>
              ))}
              {!!state.total && !!state.to && state.to < state.total && (
                <SwiperSlide key={'load-more'} className={'slide-thumbnail-wrapper'}>
                  <LoadMoreThumbnail onClick={loadMore} size={thumbnailSize} />
                </SwiperSlide>
              )}
            </Swiper>
          )}
        </ThumbnailsContainer>
        {edit.delTargetId && (
          <CustomizeModal
            isOpen={edit.isModalOpen}
            onCancel={onCloseModal}
            onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
            isConfirming={state.isConfirming}
            modalHeading={'Are you sure to delete it?'}
            modalBody={<span>Please make sure you are about to delete it</span>}
            confirmBtnAppearance={'danger'}
          />
        )}
      </>
    );
  };
  return { getUploader, getThumbnail, files: state.data, total: state.total || 0 };
};

export default useAttachmentHook;
