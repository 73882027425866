import React from 'react';
import styled from 'styled-components';

const LogoutBtnWrapper = styled.div``;
export default ({
  children,
  showLogoutModal,
}: {
  children: React.ReactNode;
  showLogoutModal: (isLogoutModalOpen: boolean) => void;
}) => {
  const handleClicked = () => {
    showLogoutModal(true);
  };
  return (
    <LogoutBtnWrapper onClick={handleClicked} data-testid={'logout-btn'}>
      {children}
    </LogoutBtnWrapper>
  );
};
