import AppService from './AppService';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';

const getCompanyAddressList = (config?: iParams) => {
  return AppService.get('/companyAddress', {
    include: 'createdBy,updatedBy,address',
    ...config,
  }).then(({ data }) => data);
};

const deactivateCompanyAddress = (id: string) => {
  return AppService.delete(`/companyAddress/${id}`).then(({ data }) => data);
};

const createCompanyAddress = (postData: iKeyValuePairs) => {
  return AppService.post('/companyAddress', postData).then(({ data }) => data);
};

const updateCompanyAddress = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/companyAddress/${id}`, updateContent).then(({ data }) => data);
};

const getCompanyAddress = (id: string, config?: iParams) => {
  return AppService.get(`/companyAddress/${id}`, {
    include: 'createdBy,updatedBy,address',
    ...config,
  }).then(({ data }) => data);
};

export default {
  getCompanyAddressList,
  deactivateCompanyAddress,
  createCompanyAddress,
  updateCompanyAddress,
  getCompanyAddress,
};
