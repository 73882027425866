import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import JobDetailRouteType from '../../shared/job/jobDetail/types/JobDetailRouteType';
import JobDetailLocationType from '../../shared/job/jobDetail/types/JobDetailLocationType';
import iJob from '../../types/job/iJob';
import JobService from '../../services/JobService';
import BackendPage from '../../layout/BackendPage';
import StatusContextProvider from '../../shared/jobDetail/statusContext/StatusContext';
import JobDetailReadOnly from '../../shared/jobDetail/readOnly/JobDetailReadOnly';
import JobDetailFormManagement from '../../shared/jobDetail/formManagement/JobDetailFormManagement';
import iJobCategory from '../../types/job/iJobCategory';
import ProtectedJobShiftJob from '../../shared/jobDetail/ProtectedJobShiftJob';
import { apiErrorToast } from '../../shared/toast/ToastHandler';

type iJobState = {
  isLoading: boolean;
  jobDetail?: iJob;
  jobStatusCategories: Array<iJobCategory>;
};
const jobInitialState: iJobState = {
  isLoading: true,
  jobStatusCategories: [],
};

/**
 *
 * 1 click on start, jobStatus: new -> planned; history.push=>state is undefined
 * 2 click on continue, jobStatus:inProgress->inProgress; readOnly is false, isWIPJob depends on continue-form done/or not
 * 3 click on jobNumber,jobStatus:unChanged, readOnly is true, if the job is startable, isCurrent=true;otherwise isCurrent=false
 */
const JobDetailPage = () => {
  const { id: jobId } = useParams<JobDetailRouteType>();
  const { state } = useLocation<JobDetailLocationType>();
  const { readOnly, isCurrent, isWIPJob } = state || { readOnly: undefined, isCurrent: undefined, isWIPJob: undefined };
  const [jobState, setJobState] = useState(jobInitialState);

  useEffect(() => {
    let isCancelled = false;
    const fetchJobDetail = async () => {
      try {
        setJobState(prevState => ({ ...prevState, isLoading: true }));
        const jobStatusCategories: Array<iJobCategory> = await JobService.getJobCategories();
        const jobDetail = await JobService.getJob(jobId);
        if (isCancelled) return;
        setJobState(prevState => ({ ...prevState, isLoading: false, jobDetail, jobStatusCategories }));
      } catch (error) {
        apiErrorToast(error);
        setJobState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchJobDetail();
    return () => {
      isCancelled = true;
    };
  }, [jobId]);

  if (jobState.isLoading)
    return (
      <BackendPage>
        <Spinner />
      </BackendPage>
    );

  return (
    <BackendPage>
      {jobState.jobDetail &&
        (readOnly ? (
          <JobDetailReadOnly
            jobDetail={jobState.jobDetail}
            jobStatusCategories={jobState.jobStatusCategories}
            isCurrent={isCurrent}
          />
        ) : (
          <ProtectedJobShiftJob jobId={jobState.jobDetail.id}>
            <StatusContextProvider
              jobDetail={jobState.jobDetail}
              isWIPJob={isWIPJob}
              jobStatusCategories={jobState.jobStatusCategories}
            >
              <JobDetailFormManagement jobDetail={jobState.jobDetail as iJob} />
            </StatusContextProvider>
          </ProtectedJobShiftJob>
        ))}
    </BackendPage>
  );
};

export default JobDetailPage;
