/*  eslint-disable */
import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import DynamicTable from '@atlaskit/dynamic-table';
import iProduct from '../../../../types/product/iProduct';
import iStockTake from '../../../../types/stockTake/iStockTake';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import { iConfigColumn, iParams } from '../../../../shared/UITypes/types';
import { iCategoryClassify } from '../../shared/StockTake.type';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import FoundItemOperation from './FoundItemOperation';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import FoundItemModalBody from './FoundItemModalBody';
import ModalPage from '../../../../shared/modalPage/ModalPage';
import Collapse from '../../../../shared/collapse/Collapse';
import { FOUND_ITEMS_TITLE, FOUND_ITEM_TABLE_COLUMNS } from '../../shared/StockTake.constant';
import StockTakeItemService from '../../../../services/StockTakeItemService';
import TableHelper from '../../../../shared/tableHelper/TableHelper';
import StockTakeUtil from '../../shared/StockTake.util';
import DataTable from '../../../../shared/tableHelper/DataTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_TAKES } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../../types/settings/UserAccess/iRoleAccess';

type iState = {
  selectedProduct?: iProduct;
};
const initialSupState: iState = {};
export type iFoundItemsInBinProps = {
  stockTakeDetail: iStockTake;
  locationCode: string;
  categoryClassify: iCategoryClassify;
  children: React.ReactNode;
};
const FoundItemsInBin = ({ stockTakeDetail, locationCode, categoryClassify, children }: iFoundItemsInBinProps) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_UPDATE, user);
  const { isWIP } = categoryClassify;
  const { id: stockTakeId } = stockTakeDetail;
  const [supState, setSupState] = useState(initialSupState);
  const getFnCallback = useCallback(
    (config?: iParams) => StockTakeItemService.getStockTakeItemListByStockTakeId(stockTakeId, config),
    [stockTakeId],
  );
  const { state, edit, onCloseModal, onDelete, onOpenAddModal, onOpenEditModal, onOpenDeleteModal, onSubmit } =
    useListCrudHook<iStockTakeItem>({
      getFn: getFnCallback,
      updateFn: StockTakeItemService.updateStockTakeItem,
      deleteFn: StockTakeItemService.deactivateStockTakeItem,
      createFn: StockTakeItemService.createStockTakeItem,
      paginationApplied: true,
      sort: 'createdAt:DESC',
      filter: 'isUserInput:true',
    });
  const { control, setValue, errors, handleSubmit } = useForm();

  const onSelect = (selectedProduct: iProduct | null) => {
    if (!selectedProduct) {
      setSupState({ ...supState, selectedProduct: undefined });
      return;
    }
    setSupState({ ...supState, selectedProduct });
    onOpenAddModal();
  };
  //  eslint-disable-next-line
  const onCreate = (data: any) => {
    if (!supState.selectedProduct) return;
    onSubmit({
      ...data,
      productId: supState.selectedProduct.id,
      stockTakeId,
      qtyFrom: 0,
      isUserInput: true,
    });
  };
  const onCloseModalMiddle = () => {
    setSupState({ ...supState, selectedProduct: undefined });
    onCloseModal();
  };
  const getHeading = () => {
    if (edit.target) {
      return `Update for ${edit.target.product?.productCode}/${edit.target.product?.name}`;
    }
    if (supState.selectedProduct) {
      return `Find ${supState.selectedProduct.productCode}/${supState.selectedProduct.name} here`;
    }
    return 'Error';
  };

  const getFilteredColumns = (cols: iConfigColumn[]) => {
    return cols
      .filter(col => {
        if (col.key === 'delete' && !canUpdate) {
          return false;
        }
        return true;
      })
      .map(col => {
        if (col.key === 'product.name' && !canUpdate) {
          return {
            ...col,
            type: 'secondary-text',
          };
        }
        return col;
      });
  };
  return (
    <>
      <Collapse text={`${FOUND_ITEMS_TITLE}${locationCode}`} defaultOpen>
        <DataTable
          testId={'found-items'}
          head={TableHelper.getHeads(getFilteredColumns(FOUND_ITEM_TABLE_COLUMNS), FOUND_ITEMS_TITLE)}
          rows={TableHelper.getRows({
            data: state.data,
            columns: getFilteredColumns(FOUND_ITEM_TABLE_COLUMNS),
            categories: [],
            renderSpecialContent: StockTakeUtil.foundItemsTableRenderSpecialContent({
              onOpenEditModal,
              onOpenDeleteModal,
              isWIP,
            }),
          })}
        />
        {isWIP && canUpdate && <FoundItemOperation onSelect={onSelect}>{children}</FoundItemOperation>}
      </Collapse>
      {edit.isModalOpen && !edit.delTargetId && (
        <ModalPage
          heading={getHeading()}
          onConfirm={!edit.target ? handleSubmit(onCreate) : handleSubmit(onSubmit)}
          onCancel={onCloseModalMiddle}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
        >
          <FoundItemModalBody control={control} onChange={setValue} errors={errors} targetStockTakeItem={edit.target} />
        </ModalPage>
      )}

      {/* delete a stock take item */}
      {edit.delTargetId && (
        <CustomizeModal
          isOpen={edit.isModalOpen}
          onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
          onCancel={onCloseModalMiddle}
          isConfirming={state.isConfirming}
          modalHeading={'Are you sure to remove it?'}
          modalBody={'Please confirm you are about to remove it'}
        />
      )}
    </>
  );
};

export default FoundItemsInBin;
