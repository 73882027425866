import styled from 'styled-components';

const LabelValue = styled.span`
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3333333333333333;
  padding: 10px 0px 4px 5px;
  display: inline-block;
  min-height: 12px;
`;

export default LabelValue;
