import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import ModalPage from '../../../../modalPage/ModalPage';

import { CREATE_DN } from '../ServiceProvider.constants';
import { addToastForAPIResponse, apiErrorToast } from '../../../../toast/ToastHandler';
import { DISPATCH_NOTES_URL } from '../../../../UrlMap';
import DispatchNoteService from '../../../../../services/DispatchNoteService';
import DispatchNoteItemService from '../../../../../services/DispatchNoteItemService';
import { CreateModalBodyWrapper } from '../ServiceProvider.style';
import CreateDNModalBody from './CreateDNModalBody';
import { mulExact } from '../../../../calculationHelper/calculationHelper';

export type iCreateDNServiceProviderProps = {
  jobId: string;
  ccp: iContactCompanyProduct;
  onCancel: () => void;
};

const CreateDNServiceProvider = ({ jobId, ccp, onCancel }: iCreateDNServiceProviderProps) => {
  const history = useHistory();
  const [isConfirming, setIsConfirming] = useState(false);
  const { control, setValue, errors, handleSubmit } = useForm({});
  //  eslint-disable-next-line
  const onSubmit = async (data: any) => {
    // dispatch note item needs to consider unit conversion ; like form 1 bag to 15 kg
    const conversion = Number(ccp.unitConversion) || 1;
    setIsConfirming(true);
    try {
      const { id } = await DispatchNoteService.createDispatchNote(data);
      await DispatchNoteItemService.createDispatchNoteItem({
        dispatchNoteId: id,
        quantity: mulExact(Number(data.qty), conversion),
        jobId,
      });
      addToastForAPIResponse('success');
      setIsConfirming(false);
      history.push(`${DISPATCH_NOTES_URL}/${id}`);
    } catch (e) {
      apiErrorToast(e);
      setIsConfirming(false);
    }
  };
  return (
    <ModalPage
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onCancel}
      confirmBtnName={'Create'}
      heading={CREATE_DN}
      isConfirming={isConfirming}
      isDisabled={Object.keys(errors).length > 0}
    >
      <CreateModalBodyWrapper>
        <CreateDNModalBody control={control} errors={errors} onChange={setValue} ccp={ccp} />
      </CreateModalBodyWrapper>
    </ModalPage>
  );
};

export default CreateDNServiceProvider;
