import React, { useState } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { LoadingButton } from '@atlaskit/button';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import FinishModalBody from '../../detail/FinishModalBody';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import { FlexContainer } from '../../../../shared/styles/styles';
import { apiErrorToast } from '../../../../shared/toast/ToastHandler';
import StockTakeService from '../../../../services/StockTakeService';
import { SecondaryTextWrapper } from '../../shared/StockTake.style';
import { FINISHING_WARNING_BOTTOM } from '../../shared/StockTake.constant';

type iState = {
  isLoading: boolean;
  isConfirming: boolean;
  isPreviewing: boolean;
  previewItems: Array<iStockTakeItem>;
};
const initialState: iState = {
  isLoading: false,
  isConfirming: false,
  isPreviewing: false,
  previewItems: [],
};
const SubmitButton = ({
  stockTakeId,
  path,
  onFinishCallback,
}: {
  stockTakeId: string;
  path?: string;
  onFinishCallback: (id: string) => void;
}) => {
  const [state, setState] = useState(initialState);

  const onOpenPreview = async () => {
    setState({ ...state, isLoading: true });
    try {
      const previewItems: Array<iStockTakeItem> = await StockTakeService.previewFinishStockTake(stockTakeId);
      setState({
        ...state,
        isLoading: false,
        isPreviewing: true,
        previewItems,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false });
    }
  };

  const onFinish = async () => {
    setState({ ...state, isConfirming: true });
    try {
      await StockTakeService.finishStockTake(stockTakeId);
      setState({ ...state, isConfirming: false, isPreviewing: false });
      if (typeof onFinishCallback === 'function') {
        onFinishCallback(stockTakeId);
      }
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  return (
    <>
      <LoadingButton
        appearance={'primary'}
        onClick={onOpenPreview}
        isLoading={state.isLoading}
        testId={'submit-button'}
      >
        Submit
      </LoadingButton>
      {state.isPreviewing && (
        <CustomizeModal
          isOpen={state.isPreviewing}
          onConfirm={onFinish}
          onCancel={() => setState({ ...state, isPreviewing: false })}
          isConfirming={state.isConfirming}
          confirmBtnName={'Finish'}
          modalBody={<FinishModalBody stockTakeItems={state.previewItems} />}
          modalHeading={`Finishing stocktake for: ${path}`}
          bottom={
            <FlexContainer>
              <WarningIcon label={'warning'} primaryColor={'#FF8B00'} />
              <SecondaryTextWrapper>{FINISHING_WARNING_BOTTOM}</SecondaryTextWrapper>
            </FlexContainer>
          }
          width={'large'}
        />
      )}
    </>
  );
};

export default SubmitButton;
