import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';
import Spinner from '@atlaskit/spinner';
import iContactCompany from '../../types/contactCompany/iContactCompany';
import Collapse from '../collapse/Collapse';
import ContCompanyKeyInfo from './ContCompanyKeyInfo';
import ContactCompanyService from '../../services/ContactCompanyService';
import CustomizedLabel from '../hookForms/CustomizedLabel';
import AsyncSearchWithController from '../hookForms/AsyncSearchWithController';
import { getGeneralOptionLabel } from '../Utilities';
import { iSingleSelectOption } from '../UITypes/types';
import iContactCompanyType, {
  CONTACT_COMPANY_TYPE_CUSTOMER,
  CONTACT_COMPANY_TYPE_SERVICE_PROVIDER,
  CONTACT_COMPANY_TYPE_SUPPLIER,
} from '../../types/contactCompany/iContactCompanyType';
import { ucFirst } from '../../services/UtilsService';
import { apiErrorToast } from '../toast/ToastHandler';
import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../constants/AsyncConstants';

const Wrapper = styled.div`
  .collapse-button {
    padding-left: 0px;
  }
`;
export type iContactCompanyTypeName =
  | typeof CONTACT_COMPANY_TYPE_CUSTOMER
  | typeof CONTACT_COMPANY_TYPE_SUPPLIER
  | typeof CONTACT_COMPANY_TYPE_SERVICE_PROVIDER;

export type iAsyncCreatableContactCompanyProps = {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  defaultValue?: iContactCompany;
  contactCompanyTypeName: iContactCompanyTypeName;
};

/**
 * the component is not creatable any more, only async search;
 * if want to recover creatable, a possible solution is to make a new component like AsyncSearchFormOptionLabel
 * the diff is from AsyncSelect to AsyncCreatableSelect and create function
 */
const AsyncCreatableContactCompany = ({
  control,
  errors,
  onChange,
  defaultValue,
  contactCompanyTypeName,
}: iAsyncCreatableContactCompanyProps) => {
  const [contactCompany, setContactCompany] = useState<iContactCompany | undefined>(defaultValue);
  const [contactCompanyTypes, setContactCompanyTypes] = useState<iContactCompanyType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    setContactCompany(() => defaultValue);
    ContactCompanyService.getContactCompanyTypeList()
      .then(resp => {
        if (isCanceled) return;
        setContactCompanyTypes(resp);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isCanceled = true;
    };
  }, [defaultValue]);

  // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/no-explicit-any
  const onChangeMiddleWare = (name: string, value: any, config?: Object) => {
    setContactCompany(value || undefined);
    onChange(name, value?.id || null, config);
  };

  const searchPromiseFn = (config = {}) =>
    ContactCompanyService.getContactCompanies({
      ...config,
      filter: `typeId:${contactCompanyTypes
        .filter(type => type.name === contactCompanyTypeName)
        .map(type => type.id)
        .join('|')}`,
      include: 'shippingAddress,billingAddress',
      currentPage: ASYNC_SEARCH_CURRENT_PAGE,
      perPage: ASYNC_SEARCH_PER_PAGE,
    });

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <>
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <AsyncSearchWithController
            label={ucFirst(contactCompanyTypeName)}
            name={contactCompanyTypeName !== CONTACT_COMPANY_TYPE_SUPPLIER ? 'customerId' : 'supplierId'}
            control={control}
            onChange={onChangeMiddleWare}
            errors={errors}
            formatOptionLabel={(option: iSingleSelectOption<iContactCompany>) => getGeneralOptionLabel(option, 'name')}
            promiseFn={(keyword: string) => searchPromiseFn({ like: `name:${keyword}` })}
            defaultValue={defaultValue}
            isRequired
            shouldControlRenderValue
          />
        </GridColumn>
      </Grid>

      {contactCompany && (
        <Grid spacing={'compact'}>
          <GridColumn medium={12}>
            <Wrapper>
              <Collapse
                text={<CustomizedLabel label={`${ucFirst(contactCompanyTypeName)} Details`} />}
                defaultOpen={false}
                iconSize={'small'}
              >
                <ContCompanyKeyInfo
                  contComp={contactCompany}
                  isCustomer={contactCompanyTypeName !== CONTACT_COMPANY_TYPE_SUPPLIER}
                />
              </Collapse>
            </Wrapper>
          </GridColumn>
        </Grid>
      )}
    </>
  );
};

export default AsyncCreatableContactCompany;
