/* eslint-disable @typescript-eslint/no-explicit-any */
import { iKeyValuePairs } from '../shared/UITypes/types';
import AppService from './AppService';

const getComments = (config?: iKeyValuePairs) => {
  return AppService.get('/comment', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

const createComment = (postData: { [key: string]: any }) => {
  return AppService.post('/comment', postData).then(({ data }) => data);
};

const updateComment = (id: string, updateContent: { [key: string]: any }) => {
  return AppService.put(`/comment/${id}`, updateContent).then(({ data }) => data);
};

const deleteComment = (id: string) => {
  return AppService.delete(`/comment/${id}`).then(({ data }) => data);
};

export default {
  getComments,
  createComment,
  updateComment,
  deleteComment,
};
