import React from 'react';
import { Label } from '@atlaskit/field-base';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import iStockTake from '../../../types/stockTake/iStockTake';
import { LabelValue, SpaceVerticalWrapper } from '../../../shared/styles/styles';
import { iParams } from '../../../shared/UITypes/types';
import { formatDate, handleNullException } from '../../../services/UtilsService';
import StockTakeMemo from './StockTakeMemo';

const DetailInfo = ({ detail, idNameMap }: { detail?: iStockTake; idNameMap?: iParams }) => {
  const getLocation = (locationObjName: string) => {
    const code = handleNullException(detail, `${locationObjName}.locationCode`);
    const path = handleNullException(idNameMap, handleNullException(detail, `${locationObjName}Id`));
    return `${code} - ${path}`;
  };
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="For Location" />
          <LabelValue>{getLocation('warehouseLocation')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Lost&Found Location" />
          <LabelValue>{getLocation('lostAndFoundLocation')}</LabelValue>
        </GridColumn>
        <GridColumn medium={2}>
          <Label label="Scheduled At" />
          <LabelValue>{formatDate(handleNullException(detail, 'scheduledAt'), 'Do MMMM YYYY h:mm a')}</LabelValue>
        </GridColumn>
        <GridColumn medium={2}>
          <Label label="Started At" />
          <LabelValue>{formatDate(handleNullException(detail, 'startedAt'), 'Do MMMM YYYY h:mm a')}</LabelValue>
        </GridColumn>
        <GridColumn medium={2}>
          <Label label="Finished At" />
          <LabelValue>{formatDate(handleNullException(detail, 'finishedAt'), 'Do MMMM YYYY h:mm a')}</LabelValue>
        </GridColumn>
      </Grid>
      <SpaceVerticalWrapper className={'small'} />
      <Grid layout={'fluid'} spacing={'compact'}>
        {handleNullException(detail, 'memo') && (
          <GridColumn medium={6}>
            <StockTakeMemo icon={<WarningIcon label={'warning'} primaryColor={'RGB(7, 71, 166)'} />}>
              {handleNullException(detail, 'memo')}
            </StockTakeMemo>
          </GridColumn>
        )}
      </Grid>
    </Page>
  );
};

export default DetailInfo;
