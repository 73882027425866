import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import NewBulk from './newBulk/NewBulk';
import BulkStockTakeList from './bulkList/BulkStockTakeList';
import useLocalStorageHook from '../../../shared/hooks/useLocalStorageHook/useLocalStorageHook';
import { iBulkStockTakeLocalStorage } from './shared/BulkStockTake.type';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_TAKES } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';
import RestrictedAccessEmptyState from '../../../shared/UserAccess/RestrictedAccessEmptyState';

const BulkStockTake = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_UPDATE, user);
  const [state, setState, clear] = useLocalStorageHook<iBulkStockTakeLocalStorage>({
    key: 'bulk-stockTake',
    defaultValue: { stockTakeIds: [], finishedStockTake: 0 },
  });
  // const [finishedTimes, setFinishedTimes] = useState(0);

  useEffect(
    () => {
      if (state.finishedStockTake === state.stockTakeIds.length) {
        clear();
      }
    },
    //  eslint-disable-next-line
    [state.finishedStockTake, state.stockTakeIds.length],
  );

  if (!canUpdate) {
    return <RestrictedAccessEmptyState expectedUserAccessCodes={[ACCESS_CODE_STOCK_TAKES]} />;
  }

  if (state.stockTakeIds.length === 0 || !state.parentLocationId) {
    return <NewBulk callback={setState} />;
  }
  // useMemo to improve performance: state.finishStockTake should not cause re-render of BulkStockTakeList
  return (
    <BulkStockTakeList
      stockTakeIdList={state.stockTakeIds}
      parentLocationId={state.parentLocationId}
      finishCallback={() => setState({ ...state, finishedStockTake: state.finishedStockTake + 1 })}
    />
  );
};

export default BulkStockTake;
