import React from 'react';
import styled from 'styled-components';
import { NoGrowShrinkContainer } from '../styleContainer';

const TitleWrapper = styled.strong`
  display: block;
  &.isCompulsory:after {
    color: #bf1650;
    content: ' *';
    vertical-align: middle;
  }
`;
const DynamicText = ({
  title,
  description,
  isCompulsory = false,
}: {
  title?: string;
  description?: string;
  isCompulsory?: boolean;
}) => {
  return (
    <NoGrowShrinkContainer data-testid={'dynamic-text'}>
      {title && (
        <TitleWrapper
          data-testid={'title-wrapper'}
          className={isCompulsory && !title.toUpperCase().includes('NOTES') ? 'isCompulsory' : ''}
        >
          {title}
        </TitleWrapper>
      )}
      {description && <p data-testid={'description'}>{description}</p>}
    </NoGrowShrinkContainer>
  );
};

export default React.memo(DynamicText);
