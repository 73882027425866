import { DISPATCH_NOTES_URL } from '../../../shared/UrlMap';
import { iConfigColumn } from './DispatchNote.type';

export const ALL = 'All';
export const PAGE_NAME = 'Dispatch Notes';
export const PAGE_TYPE = 'dispatchNotes';
export const DISPATCH_NOTE_NUMBER_ALLIE = 'Disp. Note No.';

export const DETAIL_SUB_NAME_PREFIX = 'Dispatch Note';

export const EMPTY_OPTION = { label: 'remove', value: 'null' };

export const ITEM_COLUMNS = [
  'Product Code/SKU',
  'Description',
  'Quantity',
  'Job No.',
  'Sales Order No.',
  'Customer Order No.',
  'Warning',
  'Operation',

  // 'Unit Price',
  // 'Total',
];
export const TABLE_NAME = 'dispatchNote-table';
export const TABLE_TEST_ID = 'dispatchNote-table';

export const DISPATCH_COLUMNS: Array<iConfigColumn> = [
  {
    name: '',
    key: 'rowCheckbox',
    dataSource: [],
    type: 'rowCheckbox',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
  {
    name: 'Disp. Note No.',
    key: 'dispatchNoteNumber',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'link',
    urlPrefix: DISPATCH_NOTES_URL,
    isSelected: true,
    group: '',
  },
  {
    name: 'Status',
    key: 'status',
    dataSource: ['status'],
    type: 'status',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Customer',
    key: 'customer',
    dataSource: [],
    type: 'companyWithTypeName',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  // {
  //   name: 'Total Amt.',
  //   key: 'totalAmt',
  //   dataSource: ['totalAmt'],
  //   type: 'money',
  //   isSelected: true,
  //   group: '',
  //   isSortable: false,
  // },
  {
    name: 'Sent Date',
    key: 'dispatchDate',
    dataSource: ['dispatchDate'],
    type: 'date',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Courier',
    key: 'courier.name',
    dataSource: ['courier.name'],
    type: 'text',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Reference',
    key: 'courierReference',
    dataSource: ['courierReference'],
    type: 'text',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Shipping Addr.',
    key: 'shippingAddress',
    dataSource: ['shippingAddress'],
    type: 'address',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];

export const DN_ITEM_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product Code/SKU',
    key: 'product.productCode',
    dataSource: ['product.name'],
    isCompulsory: true,
    isSortable: true,
    type: 'secondary-text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Quantity',
    key: 'quantity',
    dataSource: ['job.qtyTotal', 'product.measurement.shortName'],
    type: 'inline-edit-number',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Job No.',
    key: 'job.jobNumber',
    dataSource: [],
    type: 'link',
    isSelected: true,
    group: '',
  },
  {
    name: 'Sales Order No.',
    key: 'salesOrderItem.salesOrder.orderNumber',
    dataSource: [],
    type: 'text',
    group: '',
    isSelected: true,
  },
  {
    name: 'Customer Order No.',
    key: 'salesOrderItem.salesOrder.customerRef',
    dataSource: ['job.customerOrderRef'],
    type: 'multi-source-text',
    group: '',
    isSelected: true,
  },
  {
    name: '',
    key: 'Operation',
    dataSource: ['courier.name'],
    type: 'operation',
    isSelected: true,
    group: '',
    isCompulsory: true,
  },
];
