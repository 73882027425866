import React from 'react';
import EmptyState from '@atlaskit/empty-state';

const EmptyRollBody = () => {
  return (
    <EmptyState
      header={'Roll(s) not set properly'}
      description={'It looks like roll number for this job is not set properly, please have a look and try again alter'}
      imageUrl={'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'}
    />
  );
};

export default EmptyRollBody;
