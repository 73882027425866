import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import PdfDocument48Icon from '@atlaskit/icon-file-type/glyph/pdf-document/48';
import iAttachment from '../../types/attachment/iAttachment';
import TopRightBtn from './TopRightBtn';
import { formatDate } from '../../services/UtilsService';
import { CursorPointerWrapper, ThumbnailWrapper } from '../styles/styles';

const Thumbnail = ({
  attachment,
  onRemove,
  isTablet = false,
  canDelete = true,
  size = 'normal',
}: {
  attachment: iAttachment;
  onRemove: (id: string) => void;
  isTablet?: boolean;
  canDelete?: boolean;
  size?: string;
}) => {
  // preview image
  // eslint-disable-next-line
  const preview = (evt: any) => {
    evt.preventDefault();
    window.open(attachment.asset.url);
    return false;
  };
  const fileDisplay = () => {
    if (attachment.asset.fileType.includes('image')) {
      return 'image';
    }
    return 'pdf';
  };
  return (
    <ThumbnailWrapper className={size === 'small' ? 'small-size' : ''}>
      <div className={'image-wrapper'}>
        <CursorPointerWrapper onClick={preview}>
          {fileDisplay() === 'image' && <img src={attachment.asset.url} alt={'error'} />}
          {fileDisplay() === 'pdf' && <PdfDocument48Icon label={'pdf'} />}
        </CursorPointerWrapper>
        {canDelete && (
          <TopRightBtn
            onClick={() => onRemove(attachment.id)}
            className={`${isTablet ? 'delete-button-tablet' : 'delete-button-admin'}`}
          >
            <EditorRemoveIcon label="remove" size={'medium'} testId={'remove-trigger'} />
          </TopRightBtn>
        )}
      </div>
      <Tooltip content={attachment.asset.fileName}>
        <div className="file-name">{attachment.asset.fileName}</div>
      </Tooltip>
      <div className="upload-time">{formatDate(attachment.createdAt, 'DD MMM YYYY, hh mm A')}</div>
    </ThumbnailWrapper>
  );
};

export default Thumbnail;
