import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import DynamicFormRow from './DynamicFormRow';
import { iJobProgressRow } from './types';
import './dynamicForm.css';
import iFormTaggable from '../../../types/form/iFormTaggable';

const JobProgress = ({
  rows,
  control,
  getValues,
  setValue,
  errors,
  children,
  jobShiftJobId,
  setError,
  clearErrors,
  formTaggable,
}: {
  rows: Array<iJobProgressRow>;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  getValues: (payload?: string | string[]) => any;
  //    eslint-disable-next-line
  setValue: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors: DeepMap<Record<string, any>, FieldError>;
  children?: React.ReactNode;
  jobShiftJobId: string;

  setError: (
    name: string,
    error: {
      type?: string;
      message?: string;
    },
  ) => void;
  clearErrors: (name?: string | string[]) => void;
  formTaggable?: iFormTaggable;
}) => {
  return (
    <>
      {rows.map((row: iJobProgressRow, rowIndex: number) => (
        <DynamicFormRow
          key={row.id}
          row={row}
          control={control}
          setValue={setValue}
          getValues={getValues}
          rowIndex={rowIndex}
          jobShiftJobId={jobShiftJobId}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          formTaggable={formTaggable}
        >
          {children}
        </DynamicFormRow>
      ))}
    </>
  );
};

export default JobProgress;
