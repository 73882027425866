import React from 'react';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import useAttachmentHook from '../hooks/useAttachmentHook/useAttachmentHook';
import { FlexContainer } from '../styles/styles';

export type iGeneralAttachmentsProps = {
  entityId: string;
  entityName: string;
  thumbnailSize?: string;
  isTablet?: boolean;
  canCreate?: boolean;
  canDelete?: boolean;
};
const GeneralAttachments = ({
  entityId,
  entityName,
  thumbnailSize = 'normal',
  isTablet = false,
  canCreate = true,
  canDelete = true,
}: iGeneralAttachmentsProps) => {
  const { files, getUploader, getThumbnail, total } = useAttachmentHook({
    entityId,
    entityName,
    childrenForUploader: canCreate ? <CameraFilledIcon label={'camera'} testId={'upload-trigger'} /> : null,
    thumbnailSize,
    isTablet,
    canDelete,
  });
  return (
    <>
      <FlexContainer data-testid={'gAttachmentList'}>
        <span data-testid={'title'}>Attachments({total > 0 ? `${files.length}/${total}` : 0})</span>
        {getUploader()}
      </FlexContainer>
      {getThumbnail()}
    </>
  );
};

export default GeneralAttachments;
