import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Spinner from '@atlaskit/spinner';
import LinkBtnUrl from '../buttons/LinkBtnUrl';
import { handleNullException, handleNumber, intersperse } from '../../services/UtilsService';
import { apiErrorToast } from '../toast/ToastHandler';
import { mulExact } from '../calculationHelper/calculationHelper';

type iState<T> = {
  isLoading: boolean;
  data: Array<T>;
};

export type iFetchRelatedElementsProps = {
  //  eslint-disable-next-line
  fetchFn: () => Promise<any>;
  renderType: string;
  dataSource: Array<string>;
  urlPrefix?: string;
  connectElementType?: string;
  suffix?: string;
};

const FetchRelatedElements = <T extends { id: string }>({
  fetchFn,
  renderType,
  dataSource,
  urlPrefix,
  connectElementType = 'concat',
  suffix = 'Kg',
}: iFetchRelatedElementsProps) => {
  const initialState: iState<T> = {
    isLoading: true,
    data: [],
  };
  const [state, setState] = useState(initialState);

  useEffect(
    () => {
      const fetchData = async () => {
        setState(prevState => ({ ...prevState, isLoading: true }));
        try {
          const { data } = await fetchFn();
          setState(prevState => ({ ...prevState, isLoading: false, data }));
        } catch (error) {
          apiErrorToast(error);
          setState(prevState => ({ ...prevState, isLoading: false }));
        }
      };
      fetchData();
    },
    //  eslint-disable-next-line
    [],
  );

  const getRenderComponents = () => {
    const parts = state.data.map((item: T) => {
      switch (renderType) {
        case 'text':
          return handleNullException(item, dataSource[0]);
        case 'number':
          return Number(handleNumber(item, dataSource[0]).replace(',', ''));
        case 'number-conversion': {
          const number = Number(handleNumber(item, dataSource[0]).replace(',', ''));
          const unitConversion = Number(handleNumber(item, dataSource[1]).replace(',', '')) || 1;
          return mulExact(number, unitConversion);
        }
        case 'link':
          return (
            <LinkBtnUrl
              key={handleNullException(item, dataSource[0])}
              btnName={handleNullException(item, dataSource[1])}
              url={`${urlPrefix}/${handleNullException(item, dataSource[0])}`}
            />
          );
        default:
          return '';
      }
    });

    switch (connectElementType) {
      case 'concat':
        return intersperse(
          //  eslint-disable-next-line
          parts.filter((item: any) => item !== ''),
          ', ',
        );
      case 'sum':
        return _.sum(parts) === 0 ? '' : `${_.sum(parts).toLocaleString()} ${suffix}`;
      default:
        return '';
    }
  };
  if (state.isLoading) return <Spinner size={'small'} />;
  return <div data-testid={'related-element-wrapper'}>{getRenderComponents()}</div>;
};

export default FetchRelatedElements;
