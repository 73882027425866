import React from 'react';
// import { useSelector } from 'react-redux';
import Tabs, { Tab, TabList } from '@atlaskit/tabs';
import iJob from '../../../../types/job/iJob';
import { BOM_DETAIL, PRODUCT_ATTRIBUTE, SERVICE_PROVIDER } from './JobTabs.constants';
import ServiceProvider from '../serviceProvider/ServiceProvider';
import ProductAttributesData from '../productAttrs/ProductAttributesData';
import BomDetail from '../bomDetail/BomDetail';
import CustomTabPanel from './CustomTabPanel';
import { SpaceVerticalWrapper } from '../../../styles/styles';
// import ModuleEnabledWrapper from '../../../modulize/ModuleEnabledWrapper';
// import { ModuleCode } from '../../../../types/iModuleCode';
// import ModulingService from '../../../../services/ModulingService';
// import { RootState } from '../../../../redux/makeReduxStore';

const JobTabs = ({ job }: { job: iJob }) => {
  // const { user } = useSelector((s: RootState) => s.auth);
  const tabs =
    // ModulingService.filterListByModule({
    //   list: [PRODUCT_ATTRIBUTE, BOM_DETAIL, SERVICE_PROVIDER],
    //   checkedModule: ModuleCode.SERVICE_PROVIDER,
    //   companyModules: user?.companyModules || [],
    //   filterFn: (item: string) => item !== SERVICE_PROVIDER,
    // })
    [PRODUCT_ATTRIBUTE, BOM_DETAIL, SERVICE_PROVIDER].filter(
      (item: string) => job.product.requireThirdPartyService || item !== SERVICE_PROVIDER,
    );

  return (
    <>
      <SpaceVerticalWrapper className={'small'} data-testid={'space-vertical'} />
      <Tabs id={'job-detail-tabs'} testId={'job-detail-tabs'}>
        <TabList>
          {tabs.map((item: string) => (
            <Tab key={item} testId={item}>
              {item}
            </Tab>
          ))}
        </TabList>
        <CustomTabPanel>
          <ProductAttributesData productAttributeSetId={job.product.attributeSetId} productId={job.productId} />
        </CustomTabPanel>
        <CustomTabPanel>
          <BomDetail job={job} />
        </CustomTabPanel>
        {job.product.requireThirdPartyService && (
          // <ModuleEnabledWrapper checkedModule={ModuleCode.SERVICE_PROVIDER}>
          <CustomTabPanel>
            <ServiceProvider detail={job} />
          </CustomTabPanel>
          // </ModuleEnabledWrapper>
        )}
      </Tabs>
      <SpaceVerticalWrapper className={'small'} />
    </>
  );
};

export default JobTabs;
