import React from 'react';
import _ from 'lodash';
import { AsyncSelect } from '@atlaskit/select';
import { iOption, iSingleSelectOption } from '../../UITypes/types';

type iProps<T> = {
  onSelect: (payload: T | null) => void;
  //  eslint-disable-next-line
  promiseFn: (keyword: string) => Promise<any>;
  searchBarPlaceholder?: string;
  shouldControlRenderValue?: boolean;
  formatOptionLabel: (option: iSingleSelectOption<T>) => React.ReactNode;
  debounceTime?: number;
  isClearable?: boolean;
  isDisabled?: boolean;
  className?: string;
  defaultValue?: T;
};

const AsyncSearchFormOptionLabel = <T extends { id: string }>({
  onSelect,
  promiseFn,
  searchBarPlaceholder,
  shouldControlRenderValue = false,
  formatOptionLabel,
  defaultValue,
  debounceTime = 700,
  isClearable = true,
  isDisabled = false,
  className,
}: iProps<T>) => {
  const promiseOptions = (inputValue: string, callback: (options: iOption<T>[]) => void) => {
    promiseFn(inputValue).then(({ data: items }) => {
      callback(
        items.map((item: T) => ({
          label: item.id,
          value: item,
        })),
      );
    });
  };

  const debouncedOptions = _.debounce(promiseOptions, debounceTime);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (newSelectInput: any) => {
    if (newSelectInput === null || typeof newSelectInput === 'undefined') {
      onSelect(null);
      return;
    }
    onSelect(_.get(newSelectInput, 'value'));
  };

  return (
    <AsyncSelect
      isClearable={isClearable}
      loadOptions={debouncedOptions}
      placeholder={searchBarPlaceholder || 'Search...'}
      onChange={onChange}
      controlShouldRenderValue={shouldControlRenderValue}
      autoFocus
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      className={className}
      defaultValue={defaultValue ? { label: defaultValue.id, value: defaultValue } : null}
    />
  );
};

export default AsyncSearchFormOptionLabel;
