import React from 'react';
import { handleNullException } from '../../services/UtilsService';
import CreatedOrUpdated from '../../shared/createdOrUpdated/CreatedOrUpdated';
import ComposeSecondaryText from '../../shared/text/ComposeSecondaryText';
import { iConfigColumn } from '../../shared/UITypes/types';
import iItemLocation from '../../types/warehouse/iItemLocation';

export const getRows = (data: Array<iItemLocation>, columns: Array<iConfigColumn>) => {
  return data.map((item: iItemLocation) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'text-two-layer':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                <span style={{ fontWeight: 500 }}>{handleNullException(item, key)}</span>
              </ComposeSecondaryText>
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        default:
          return { content: handleNullException(item, key) };
      }
    });
    return { cells, testId: 'item-location' };
  });
};
