import AppService from './AppService';
import { iParams } from '../shared/UITypes/types';
import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';

const getContactCompanies = (config?: iParams) => {
  return AppService.get('/company', config).then(({ data }) => data);
};

const getCustomerList = (config?: iParams) => {
  return AppService.get('/company/customer', {
    ...config,
    include:
      'shippingAddress,billingAddress,type.roles,createdBy,updatedBy,contactCompanyContacts.contact,contactCompanyContacts.role',
  }).then(({ data }) => data);
};

const getSupplierList = (config?: iParams) => {
  return AppService.get('/company/supplier', {
    ...config,
    include:
      'shippingAddress,billingAddress,type.roles,createdBy,updatedBy,contactCompanyContacts.contact,contactCompanyContacts.role',
  }).then(({ data }) => data);
};

const removeContactCompany = (id: string) => {
  return AppService.delete(`/company/${id}`).then(({ data }) => data);
};

const createContactCompany = (name: string, typeId: string) => {
  return AppService.post('/company', { name, typeId }).then(({ data }) => data);
};

const updateContactCompany = (id: string, updateContent: { [key: string]: string }) => {
  return AppService.put(`/company/${id}`, updateContent).then(({ data }) => data);
};

const getContactCompany = (id: string) => {
  return AppService.get(`/company/${id}`, {
    include:
      'shippingAddress,billingAddress,type.roles,createdBy,updatedBy,contactCompanyContacts.contact,contactCompanyContacts.role',
  }).then(({ data }) => data);
};

const getContactCompanyTypeList = (config?: { [key: string]: string }) => {
  return AppService.get('/companyType', {
    ...config,
  }).then(({ data }) => data);
};

const createAndUpdateContactCompanyContact = (roleId: string, contactId: string | null, companyId: string) => {
  return AppService.put(`/company/assignRole/${companyId}`, {
    roleId,
    contactId,
  }).then(({ data }) => data);
};

const getCustomerListAsyncSearch = (config?: iParams) => {
  return AppService.get('/company/customer', {
    ...config,
    include: 'shippingAddress,billingAddress',
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

const getSupplierListAsyncSearch = (config?: iParams) => {
  return AppService.get('/company/supplier', {
    ...config,
    include: 'shippingAddress,billingAddress',
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

const getServiceProviderListAsyncSearch = (config?: iParams) => {
  return AppService.get('/company/serviceProvider', {
    ...config,
    include: 'shippingAddress,billingAddress',
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export default {
  getContactCompanies,
  getCustomerList,
  getSupplierList,
  getContactCompany,
  getContactCompanyTypeList,
  getSupplierListAsyncSearch,
  getCustomerListAsyncSearch,
  getServiceProviderListAsyncSearch,
  createContactCompany,
  createAndUpdateContactCompanyContact,
  updateContactCompany,
  removeContactCompany,
};
