import AppService from './AppService';

const updateUserProfile = (name: string, newValue: string | number | boolean | null) => {
  return AppService.put('/auth/profile', { [name]: newValue }).then(({ data }) => {
    return data;
  });
};

const precheckPasswordComplexity = (password: string) => {
  return AppService.get('/user/validatePassword', { password }).then(({ data }) => data);
};

const checkDuplicateUser = (targetValue: string, excludeId = '') => {
  return AppService.get('/user/isIdDuplicate', {
    targetValue,
    excludeId,
  }).then(({ data }) => data);
};

export default {
  updateUserProfile,
  precheckPasswordComplexity,
  checkDuplicateUser,
};
