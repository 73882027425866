import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { removedAuthentication } from '../../../redux/reduxers/auth.slice';
import { RootState } from '../../../redux/makeReduxStore';
import { AUTH_URL_LOGIN } from '../../UrlMap';
import AuthService from '../../../services/AuthService';

const LogoutModal = ({
  showLogoutModal,
  onNavToFinishingShift,
}: {
  showLogoutModal: (isOpen: boolean) => void;
  onNavToFinishingShift: () => void;
}) => {
  const { currentShift } = useSelector((s: RootState) => s.shift);
  const dispatch = useDispatch();
  const history = useHistory();
  const doLogout = () => {
    AuthService.logout().then(() => {
      dispatch(removedAuthentication());
      history.push(AUTH_URL_LOGIN);
    });
  };
  const handleClick = () => {
    if (currentShift) {
      onNavToFinishingShift();
    } else {
      doLogout();
    }
  };
  const close = () => {
    showLogoutModal(false);
  };
  return (
    <ModalTransition>
      <Modal testId={'logout-popup'}>
        <ModalHeader>
          <ModalTitle>Are you sure to logout?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Please confirm you are about to logout.</p>
        </ModalBody>
        <ModalFooter>
          <Button appearance={'subtle'} testId={'cancel-btn'} onClick={close}>
            Cancel
          </Button>
          <Button appearance={'primary'} testId={'confirm-btn'} onClick={handleClick}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};
export default LogoutModal;
