import React, { useRef } from 'react';
import styled from 'styled-components';
import { apiErrorToast } from '../toast/ToastHandler';

const UploadTriggerWrapper = styled.div`
  display: flex;
  .title {
    font-weight: 400;
    font-size: 16px;
    color: RGB(23, 43, 77);
    margin-right: 0.5rem;
  }
  .invisible-input {
    display: none;
  }
`;

const acceptTypes = '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf';
const Uploader = ({
  entityId,
  entityName,
  onUploadFile,
  children,
}: {
  entityId: string;
  entityName: string;
  //    eslint-disable-next-line
  onUploadFile: (data: any) => void;
  children: React.ReactNode;
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const openFileBrowser = () => {
    return fileRef.current?.click();
  };
  const uploadSingleFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('attachedFile', file);
      formData.append('entityId', entityId);
      formData.append('entityName', entityName);
      onUploadFile(formData);
    } catch (error) {
      apiErrorToast(error);
    }
  };
  const onUploadFiles = () => {
    if (!fileRef || !fileRef.current || !fileRef.current.files || fileRef.current.files.length === 0) {
      return;
    }
    Array.from(fileRef.current.files).forEach((file: File) => uploadSingleFile(file));
  };
  return (
    <UploadTriggerWrapper onClick={openFileBrowser}>
      {/* <span className={'title'}>Attachments({length})</span> */}
      {children}
      <input
        className={'invisible-input'}
        type="file"
        multiple
        accept={acceptTypes}
        ref={fileRef}
        onChange={() => onUploadFiles()}
        data-testid={'upload-file'}
      />
    </UploadTriggerWrapper>
  );
};

export default Uploader;
