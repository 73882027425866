import React from 'react';
import BackendPage from '../../layout/BackendPage';
import WelcomeMsg from '../../shared/user/WelcomeMsg';
import JobListPage from '../jobListPage/JobListPage';
import RestrictedAccessDiv from '../../shared/UserAccess/RestrictedAccessDiv';
import { ACCESS_CODE_JOBS } from '../../types/settings/UserAccess/iAccess';

export default () => {
  return (
    <BackendPage pageHeader={<WelcomeMsg />}>
      <RestrictedAccessDiv expectedUserAccessCodes={[ACCESS_CODE_JOBS]}>
        <JobListPage isCurrent />
      </RestrictedAccessDiv>
    </BackendPage>
  );
};
