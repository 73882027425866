import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import iJob from '../../../../types/job/iJob';
import iBomItemAttribute from '../../../../types/product/iBomItemAttribute';
import BomItems from './BomItems';
import BomAttributeService from '../../../../services/BomAttributeService';
import { apiErrorToast } from '../../../toast/ToastHandler';

type iState = {
  isLoading: boolean;
  bomItemAttributes: Array<iBomItemAttribute>;
};
const initialState: iState = {
  isLoading: true,
  bomItemAttributes: [],
};
const BomDetail = ({ job }: { job: iJob }) => {
  const { bomDetails } = job;
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({
        ...prevState,
        isLoading: true,
      }));
      try {
        const bomItemAttributes = await BomAttributeService.getBomItemAttributes();
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          bomItemAttributes,
          isLoading: false,
        }));
      } catch (error) {
        apiErrorToast(error);
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);
  if (state.isLoading) return <Spinner />;
  return <BomItems bomDetails={bomDetails} bomItemAttributes={state.bomItemAttributes} isLoading={state.isLoading} />;
};

export default BomDetail;
