import React from 'react';
import { prepareConversionEquation } from '../../services/UtilsService';

const ConversionText = ({
  value,
  contactCompanyUnit,
  conversion,
  productUnit,
  decimal = 2,
}: {
  value: number;
  contactCompanyUnit: string;
  conversion: number;
  productUnit: string;
  decimal?: number;
}) => {
  return (
    <div data-testid={'conversion-text'}>
      {value.toLocaleString('en', { maximumFractionDigits: decimal })}{' '}
      {prepareConversionEquation({
        value,
        conversion,
        productUnit,
        contactCompanyUnit,
      })}
    </div>
  );
};

export default ConversionText;
