import { iConfigColumn, iKeyValuePairs } from '../../../shared/UITypes/types';

export const PAGE_NAME = 'Stock Transfer';
export const PAGE_DESCRIPTION = 'List of products to be transferred:';
export const REMOVE_MODAL_HEADING = 'Are you sure to remove this item?';
export const TRANSFER_TO_MODAL_HEADING = 'Transfer selected items to:';
export const stockTransferTextLabelValuePairs: Array<iKeyValuePairs> = [
  {
    label: 'Product Code/SKU',
    value: 'product.productCode',
    isSortable: true,
  },
  {
    label: 'Product Name',
    value: 'product.name',
  },
  {
    label: 'Moving Qty',
    value: 'movingQty',
  },
  {
    label: 'Unit',
    value: 'product.measurement.shortName',
  },
  { label: 'Batch/Lot No.', value: 'reference' },
  { label: 'From Location', value: 'warehouseLocation.name' },
  { label: 'Location Code', value: 'warehouseLocation.locationCode' },
];
const stockTransferTextColumns = stockTransferTextLabelValuePairs.map((item: iKeyValuePairs) => ({
  name: item.label as string,
  key: item.value as string,
  dataSource: [],
  type: 'text',
  isSelected: true,
  isSortable: item.isSortable ? (item.isSortable as boolean) : false,
}));
export const STOCK_TRANSFER_LIST_COLUMNS: Array<iConfigColumn> = [
  ...stockTransferTextColumns,
  { name: '', key: 'id', dataSource: [], type: 'delete', isCompulsory: true },
];
export const COLUMNS_EXCLUDE_DELETE = stockTransferTextColumns;

const previewTextLabelValuePairs = [
  {
    label: 'Product',
    value: 'product.productCode',
    isSortable: true,
  },
  {
    label: 'Moving Qty',
    value: 'movingQty',
  },
  {
    label: 'Unit',
    value: 'product.measurement.shortName',
  },
  { label: 'Batch/Lot No.', value: 'reference' },
];
export const REVIEW_COLUMNS: Array<iConfigColumn> = [
  ...previewTextLabelValuePairs.map((item: iKeyValuePairs) => ({
    name: item.label as string,
    key: item.value as string,
    dataSource: [],
    type: 'text',
    isSelected: true,
    isSortable: item.isSortable ? (item.isSortable as boolean) : false,
  })),
  {
    name: 'Left Qty',
    key: 'qty',
    dataSource: ['movingQty'],
    type: 'calculate-minus',
    isSelected: true,
  },
];

const addProdLabelValuePairs = [
  {
    label: 'Product',
    value: 'product.productCode',
    isSortable: true,
  },
  { label: 'Qty', value: 'qty' },
  {
    label: 'Unit',
    value: 'product.measurement.shortName',
  },
  { label: 'Batch/Lot No.', value: 'reference' },
];
export const ADD_PROD_COLUMNS: Array<iConfigColumn> = [
  ...addProdLabelValuePairs.map((item: iKeyValuePairs) => ({
    name: item.label as string,
    key: item.value as string,
    dataSource: [],
    type: 'text',
    isSelected: true,
    isSortable: item.isSortable ? (item.isSortable as boolean) : false,
  })),
  {
    name: 'Moving Qty',
    key: '',
    dataSource: [''],
    type: 'input-with-controller',
    isSelected: true,
  },
];
