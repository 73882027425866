import { handleNullException } from '../../../../services/UtilsService';
import { iManufactureRow } from './DynamicManufactureRows.types';

const detectConflict = ({
  latest,
  local,
  fields = ['length', 'nominalWeight', 'actualWeight'],
}: {
  latest: iManufactureRow;
  local: iManufactureRow;
  fields?: Array<string>;
}) => {
  const isDoneByLatest = latest.jobShiftJobId && latest.jobShiftJobId !== local.jobShiftJobId;
  let isConflict = false;
  const conflictFields = [];
  for (let i = 0; i < fields.length; i++) {
    const latestField = handleNullException(latest, fields[i]);
    const localField = handleNullException(local, fields[i]);
    if (
      Array.isArray(latestField) &&
      Array.isArray(localField) &&
      localField.length === 1 &&
      latestField.length === 1 &&
      latestField[0].value &&
      localField[0].value &&
      latestField[0].value !== localField[0].value
    ) {
      isConflict = true;
      conflictFields.push(fields[i]);
    }
  }
  return { isConflict: isDoneByLatest && isConflict, detail: conflictFields };
};

// not conflicts then merge
const mergeBranches = ({ local, latest }: { local: Array<iManufactureRow>; latest: Array<iManufactureRow> }) => {
  const newLocal = local.map((item: iManufactureRow, index: number) => {
    if (index >= latest.length) {
      return item;
    }
    // some one done a row or undone a row with the same value
    if (latest[index].jobShiftJobId || (local[index].jobShiftJobId && !latest[index].jobShiftJobId)) {
      return latest[index];
    }
    return item;
  });
  return newLocal;
};

const mergeConflicts = ({
  local,
  latest,
  isUseDatabaseVersion,
}: {
  local: Array<iManufactureRow>;
  latest: Array<iManufactureRow>;
  isUseDatabaseVersion: boolean;
}) => {
  let useOurVersions: iManufactureRow[] = [];
  const newLocal = local.map((item: iManufactureRow, index: number) => {
    if (index >= latest.length) {
      return item;
    }
    if (detectConflict({ latest: latest[index], local: item }).isConflict && isUseDatabaseVersion) {
      const ourVersion = {
        ...item,
        sortOrder: local.length + useOurVersions.length,
      };
      useOurVersions = [...useOurVersions, ourVersion];
    }
    // some one done a row or undone a row with the same value
    if (latest[index].jobShiftJobId || (local[index].jobShiftJobId && !latest[index].jobShiftJobId)) {
      return latest[index];
    }
    return item;
  });
  return [...newLocal, ...useOurVersions];
};
export default { detectConflict, mergeBranches, mergeConflicts };
