import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';
import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getProdAttributes = (config?: { [key: string]: string }) => {
  return AppService.get('/productAttribute', {
    ...config,
    include: 'createdBy,updatedBy',
  }).then(({ data }) => data);
};

const createProdAttribute = (postData: iParams) => {
  return AppService.post('/productAttribute', postData).then(({ data }) => data);
};

const updateProdAttribute = (id: string, updateContent: iParams) => {
  return AppService.put(`/productAttribute/${id}`, updateContent).then(({ data }) => data);
};

const deleteProdAttribute = (id: string) => {
  return AppService.delete(`/productAttribute/${id}`).then(({ data }) => data);
};

const getProductAttributesAsyncSearch = (config?: iParams) => {
  return AppService.get('/productAttribute', {
    ...config,
    perPage: ASYNC_SEARCH_PER_PAGE,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
  }).then(({ data }) => data);
};

export default {
  getProdAttributes,
  createProdAttribute,
  updateProdAttribute,
  deleteProdAttribute,
  getProductAttributesAsyncSearch,
};
