import { iConfigColumn } from '../../../shared/UITypes/types';

export const LIST_TABLE_NAME = 'stock-take-table';
export const LIST_PAGE_NAME = 'Stocktake';
export const LIST_CREATE_HINT = "Any warehouse location with stocktake WIP won't allow any movement";
export const STOCK_TAKE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Stocktake No.',
    key: 'stocktakeNum',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'link',
    urlPrefix: '/b/stockTake',
    isSelected: true,
    group: '',
  },
  {
    name: 'Status',
    key: 'status',
    dataSource: ['status'],
    type: 'status',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Starting Date',
    key: 'scheduledAt',
    dataSource: ['scheduledAt'],
    type: 'datetime',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'For Location',
    key: 'warehouseLocation.locationCode',
    dataSource: ['warehouseLocation.id'],
    type: 'text',
    urlPrefix: '/b/warehouse',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Location Path',
    key: 'warehouseLocationId',
    dataSource: ['warehouseLocationId'],
    type: 'text-idNameMap',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];

export const DETAIL_PREFIX = 'Stocktake';
export const DETAIL_PAGE_NAME = 'Stocktakes';
export const SUPPOSED_ITEMS_TITLE = 'Current Items in ';
export const SUPPOSED_ITEM_TABLE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.name',
    dataSource: ['product.id', 'product.productCode'],
    isCompulsory: true,
    type: 'secondary-text',
  },
  {
    name: 'Batch/Lot No.',
    key: 'reference',
    dataSource: ['reference'],
    type: 'text',
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: ['qty'],
    type: 'text',
  },
  {
    name: 'Unit',
    key: 'product.measurement.shortName',
    dataSource: ['product.measurement.shortName'],
    type: 'text',
  },
];
export const FOUND_ITEMS_TITLE = 'Found Items in ';
export const FOUND_ITEM_TABLE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.name',
    dataSource: ['product.productCode'],
    type: 'edit-trigger',
  },
  {
    name: 'Batch/Lot No.',
    key: 'reference',
    dataSource: ['reference'],
    type: 'text',
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: ['qty'],
    type: 'text',
  },
  {
    name: 'Unit',
    key: 'product.measurement.shortName',
    dataSource: ['product.measurement.shortName'],
    type: 'text',
  },
  {
    name: '',
    key: 'delete',
    type: 'delete',
    dataSource: ['id'],
  },
];

export const FINISHING_WARNING_TITLE =
  'This process can NOT be reversed, please confirm all the items below before you continue';
export const FINISHING_WARNING_BOTTOM = 'This process can NOT be reversed';

export const FINISH_PREVIEW_TABLE_COLUMNS = [
  {
    name: 'Product',
    key: 'product.name',
    dataSource: ['product.productCode'],
    type: 'secondary-text',
  },
  {
    name: 'Batch/Lot No.',
    key: 'reference',
    dataSource: ['reference'],
    type: 'text',
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: ['qtyFrom'],
    type: 'text-arrow-text',
  },
  {
    name: 'Unit',
    key: 'product.measurement.shortName',
    dataSource: ['product.measurement.shortName'],
    type: 'text',
  },
  {
    name: 'New Location',
    key: 'toWarehouseLocation.locationCode',
    type: 'secondary-text',
    dataSource: ['toWarehouseLocation.name'],
  },
];

export const FINISHED_STOCK_TAKE_ITEM_TABLE = 'finished-stockTakeItem-table';
