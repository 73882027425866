import React, { useState } from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import { iBtnAppearance } from '../UITypes/types';

const BtnWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
const initialState = {
  showPopup: false,
  confirming: false,
};

const defaultHeading = 'Are you sure to start?';
const defaultPopupContent = <p>Please confirm you are about to start it.</p>;
export type iElementwithPopupProps = {
  onClick?: () => void;
  popupHeading?: string;
  popupContent?: React.ReactNode;
  isConfirming?: boolean;
  isDisabled?: boolean;
  confirmBtnAppearance?: iBtnAppearance;
  confirmBtnName?: string;
  bottom?: React.ReactNode;
  bottomHandler?: () => void;
  extraHandler?: React.ReactNode;
  children: React.ReactNode;
};

const ElementWithPopup = ({
  onClick,
  popupHeading,
  popupContent,
  isConfirming,
  isDisabled = false,
  confirmBtnAppearance,
  confirmBtnName,
  bottom,
  bottomHandler,
  extraHandler,
  children,
}: iElementwithPopupProps) => {
  const [state, setState] = useState(initialState);

  const handleClicked = () => {
    setState({
      ...state,
      showPopup: true,
    });
  };

  const close = () => {
    setState({
      ...state,
      showPopup: false,
    });
  };
  const doBottomHandler = async () => {
    if (typeof bottomHandler === 'function') {
      await bottomHandler();
      close();
    }
  };
  const doConfirm = () => {
    setState({
      ...state,
      showPopup: false,
      confirming: true,
    });
    if (typeof onClick === 'function') {
      onClick();
    }
    setState({
      ...state,
      showPopup: false,
      confirming: false,
    });
  };
  const getModalContent = () => {
    if (state.showPopup !== true) return null;
    return (
      <Modal testId="start-modal" onClose={close}>
        <ModalHeader>
          <ModalTitle>{popupHeading || defaultHeading}</ModalTitle>
        </ModalHeader>
        <ModalBody>{popupContent || defaultPopupContent}</ModalBody>
        <ModalFooter>
          <BtnWrapper onClick={doBottomHandler}>{bottom}</BtnWrapper>
          <ButtonGroup>
            <Button
              onClick={() => doConfirm()}
              appearance={confirmBtnAppearance || 'primary'}
              isDisabled={isDisabled}
              testId={'confirm-button'}
            >
              {confirmBtnName || 'Confirm'}
            </Button>
            {extraHandler}
            <Button onClick={close} testId={'cancel-button'}>
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  };

  const getBtn = () => {
    if (state.confirming || isConfirming) return <Spinner testId="start-spinner" />;
    return (
      <BtnWrapper onClick={handleClicked} data-testid={'popup-trigger'}>
        {children}
      </BtnWrapper>
    );
  };

  return (
    <>
      {getBtn()}
      <ModalTransition>{getModalContent()}</ModalTransition>
    </>
  );
};
export default ElementWithPopup;
