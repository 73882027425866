import React from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import ElementWithPopup from '../../../shared/popup/ElementWithPopup';

const MoveButtonGroup = ({
  length,
  onConfirm,
  onCancel,
}: {
  length: number;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <ButtonGroup data-testid={'button-group'}>
      <ElementWithPopup
        onClick={onCancel}
        popupHeading={'Are you sure to clear the list ?'}
        popupContent={'Please make sure you want to clear the products selected?'}
        confirmBtnAppearance={'danger'}
        confirmBtnName={'Clear'}
      >
        <Button testId={'cancel-button'}>Cancel</Button>
      </ElementWithPopup>
      <LoadingButton
        onClick={onConfirm}
        appearance={'primary'}
        testId={'move-button'}
        isDisabled={length === 0}
        // isLoading={isConfirming}
      >
        Move above {length} items
      </LoadingButton>
    </ButtonGroup>
  );
};

export default MoveButtonGroup;
