import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import BreadcrumbsTwoLayers from '../../shared/heading/BreadcrumbsTwoLayers';
import useDetailHook from '../../shared/hooks/useDetailHook/useDetailHook';
import DetailInfo from './detail/DetailInfo';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import iEntityCategory from '../../types/status/iEntityCategory';
import iStockTake from '../../types/stockTake/iStockTake';
import SpinWhenLoading from '../../shared/spin/SpinWhenLoading';
import NotFinished from './detail/notFinished/NotFinished';
import Finished from './detail/finished/Finished';
import { STOCK_TAKE_URL } from '../../shared/UrlMap';
import { DETAIL_PAGE_NAME, DETAIL_PREFIX, FINISHING_WARNING_BOTTOM } from './shared/StockTake.constant';
import EntityStatusCategoryService from '../../services/EntityStatusCategoryService';
import {
  classifyEntityCategoryByStatusId,
  getStatusByCategories,
  handleNullException,
} from '../../services/UtilsService';
import { iParams, iRouteTypeId } from '../../shared/UITypes/types';
import { FlexContainer, PageTitle, SpaceVerticalWrapper } from '../../shared/styles/styles';
import StockTakeService from '../../services/StockTakeService';
import WarehouseService from '../../services/WarehouseService';
import iStockTakeItem from '../../types/stockTake/iStockTakeItem';
import FinishModalBody from './detail/FinishModalBody';
import { SecondaryTextWrapper } from './shared/StockTake.style';
import StatusLozenge from '../../shared/statusComponent/StatusLozenge';
import {
  STATUS_CATEGORY_FINISHED,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_NEW,
} from '../../shared/constants/StatusCategories';
import { addToastForAPIResponse, apiErrorToast } from '../../shared/toast/ToastHandler';
import AttachmentsAndActivity from './detail/AttachmentsAndActivity';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_TAKES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';

type iSupportState = {
  isLoadingPreviewItems: boolean;
  isConfirming: boolean;
  isStartModalOpen: boolean;
  isFinishModalOpen: boolean;
  categories: Array<iEntityCategory>;
  idNameMap?: iParams;
  previewItems: Array<iStockTakeItem>;
};
const initialSupportState: iSupportState = {
  isLoadingPreviewItems: false,
  isConfirming: false,
  isStartModalOpen: false,
  isFinishModalOpen: false,
  categories: [],
  previewItems: [],
};
const StockTakeDetail = () => {
  const { id } = useParams<iRouteTypeId>();
  const history = useHistory();
  const { state, onUpdateEagerLoadObject } = useDetailHook<iStockTake>({
    id,
    getFn: StockTakeService.getStockTakeDetail,
  });
  const [supportState, setSupportState] = useState(initialSupportState);
  const { isNew, isWIP, isFinished } = classifyEntityCategoryByStatusId(supportState.categories, state.data?.statusId);
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_UPDATE, user);

  useEffect(() => {
    let isCancelled = false;
    const fetchDetail = async () => {
      try {
        const categories: Array<iEntityCategory> = await EntityStatusCategoryService.getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:StockTake',
        });
        if (isCancelled) return;
        setSupportState(prevState => ({ ...prevState, categories }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchDetail();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(
    () => {
      let isCancelled = false;
      const fetchIdNameMap = async () => {
        if (typeof state.data === 'undefined') return;
        try {
          const stringIds = JSON.stringify([state.data.lostAndFoundLocationId, state.data.warehouseLocationId]);
          const { idNameMap } = await WarehouseService.getWarehouseLocationPathNames(stringIds);
          if (isCancelled) return;
          setSupportState(prevState => ({ ...prevState, idNameMap }));
        } catch (error) {
          if (isCancelled) return;
          apiErrorToast(error);
        }
      };
      fetchIdNameMap();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [
      //  eslint-disable-next-line
      JSON.stringify([state.data?.lostAndFoundLocationId, state.data?.warehouseLocationId]),
    ],
  );

  const onOpenStartModal = () => setSupportState({ ...supportState, isStartModalOpen: true });

  const onCloseStartModal = () =>
    setSupportState({
      ...supportState,
      isStartModalOpen: false,
      isConfirming: false,
    });

  const onOpenFinishModal = async () => {
    setSupportState({ ...supportState, isLoadingPreviewItems: true });
    try {
      const previewItems: Array<iStockTakeItem> = await StockTakeService.previewFinishStockTake(id);
      setSupportState({
        ...supportState,
        isFinishModalOpen: true,
        previewItems,
        isLoadingPreviewItems: false,
      });
    } catch (error) {
      apiErrorToast(error);
      setSupportState({ ...supportState, isLoadingPreviewItems: false });
    }
  };
  const onCloseFinishModal = () =>
    setSupportState({
      ...supportState,
      isFinishModalOpen: false,
      previewItems: [],
      isConfirming: false,
    });

  /**
   * start will affect started at and status
   * started at directly from after start
   * status need to by the help of supportState.categories
   */
  const onStart = async () => {
    setSupportState({ ...supportState, isConfirming: true });
    try {
      const afterStart: iStockTake = await StockTakeService.startStockTake(id);
      const status = getStatusByCategories(supportState.categories, afterStart.statusId);
      if (typeof status === 'undefined') return;
      onUpdateEagerLoadObject({
        status,
        statusId: status.id,
        startedAt: afterStart.startedAt,
      });
      onCloseStartModal();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
      setSupportState({ ...supportState, isConfirming: false });
    }
  };
  /**
   * finish will affect finished at and status
   * started at directly from after start
   * status need to by the help of supportState.categories
   */
  const onFinish = async () => {
    setSupportState({ ...supportState, isConfirming: true });
    try {
      const afterFinish: iStockTake = await StockTakeService.finishStockTake(id);
      const status = getStatusByCategories(supportState.categories, afterFinish.statusId);
      if (typeof status === 'undefined') return;
      onUpdateEagerLoadObject({
        status,
        statusId: status.id,
        finishedAt: afterFinish.finishedAt,
      });
      onCloseFinishModal();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
      setSupportState({ ...supportState, isConfirming: false });
    }
  };

  const breadCrumbsSubName = `${DETAIL_PREFIX}: ${handleNullException(state.data, 'stocktakeNum')}`;
  const getPageHeader = () => {
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers
            name={DETAIL_PAGE_NAME}
            onClick={() => history.push(STOCK_TAKE_URL)}
            subName={breadCrumbsSubName}
          />
          <FlexContainer>
            <PageTitle className={'small space-right'}>{breadCrumbsSubName}</PageTitle>
            <StatusLozenge
              status={getStatusByCategories(supportState.categories, state.data?.statusId)}
              categoryCode={
                //  eslint-disable-next-line
                isNew
                  ? STATUS_CATEGORY_NEW
                  : //  eslint-disable-next-line
                  isWIP
                  ? STATUS_CATEGORY_IN_PROGRESS
                  : STATUS_CATEGORY_FINISHED
              }
            />
          </FlexContainer>
        </div>
        <ButtonGroup>
          {isNew && canUpdate && (
            <Button appearance={'primary'} onClick={onOpenStartModal} testId={'start-button'}>
              Start
            </Button>
          )}
        </ButtonGroup>
      </FlexContainer>
    );
  };

  const getContent = () => {
    if (!state.data) {
      return null;
    }

    if (isFinished) {
      return <Finished stockTakeDetail={state.data} />;
    }

    return (
      <NotFinished stockTakeDetail={state.data} categoryClassify={{ isNew, isWIP, isFinished }}>
        <LoadingButton
          appearance={'primary'}
          onClick={onOpenFinishModal}
          isLoading={supportState.isLoadingPreviewItems}
          testId={'finish-button'}
          isDisabled={!canUpdate}
        >
          Finish
        </LoadingButton>
      </NotFinished>
    );
  };

  if (state.isLoading) return <SpinWhenLoading />;
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <DetailInfo detail={state.data} idNameMap={supportState.idNameMap} />
      <SpaceVerticalWrapper className={'small'} />
      {getContent()}
      <SpaceVerticalWrapper className={'small'} />
      <AttachmentsAndActivity entityId={handleNullException(state.data, 'id')} entityName={'StockTake'} />
      <CustomizeModal
        isOpen={supportState.isStartModalOpen}
        onConfirm={onStart}
        onCancel={onCloseStartModal}
        isConfirming={supportState.isConfirming}
      />
      <CustomizeModal
        isOpen={supportState.isFinishModalOpen}
        onConfirm={onFinish}
        onCancel={onCloseFinishModal}
        isConfirming={supportState.isConfirming}
        modalBody={<FinishModalBody stockTakeItems={supportState.previewItems} />}
        modalHeading={`Finishing stocktake: ${state.data?.stocktakeNum}`}
        bottom={
          <FlexContainer>
            <WarningIcon label={'warning'} primaryColor={'#FF8B00'} />
            <SecondaryTextWrapper>{FINISHING_WARNING_BOTTOM}</SecondaryTextWrapper>
          </FlexContainer>
        }
        width={'large'}
      />
    </BackendPage>
  );
};

export default StockTakeDetail;
