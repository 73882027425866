import React from 'react';
import { colors } from '@atlaskit/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonGroup } from '@atlaskit/button';
import { FlexContainer, FlexSpaceBetweenContainer, PageTitle } from '../../../shared/styles/styles';
import iPoReceiving from '../../../types/purchaseOrder/iPoReceiving';
import iEntityCategory from '../../../types/status/iEntityCategory';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import { getCategoryCode, handleNullException } from '../../../services/UtilsService';

const Wrapper = styled.div`
  & .Breadcrumb {
    font-size: 0.9rem;
    color: ${colors.N100};
    font-weight: 500;
  }
`;

const Breadcrumb = ({ children }: { children: React.ReactNode }) => <div className="Breadcrumb">{children}</div>;

const Header = ({
  poReceiving,
  categories,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  id,
}: {
  poReceiving?: iPoReceiving;
  categories: Array<iEntityCategory>;
  id: string;
}) => {
  return (
    <>
      <FlexSpaceBetweenContainer>
        <Wrapper>
          <Breadcrumb>
            <Link to="/b/purchases" className="Breadcrumb__Link">
              {'Purchase Orders '}
            </Link>
            /{' '}
            <Link to={`/b/purchases/${poReceiving?.purchaseOrder?.id}`} className="Breadcrumb__Link">
              {`PO: ${poReceiving?.purchaseOrder?.orderNumber}`}
            </Link>{' '}
            / Receiving: {poReceiving?.reference}
          </Breadcrumb>

          <FlexContainer>
            <PageTitle className={'small space-right'}>
              Receiving PO: {poReceiving?.purchaseOrder?.orderNumber}
            </PageTitle>
            <StatusLozenge
              status={handleNullException(poReceiving, 'status')}
              categoryCode={getCategoryCode(handleNullException(poReceiving, 'status'), categories)}
            />
          </FlexContainer>
        </Wrapper>

        <ButtonGroup />
      </FlexSpaceBetweenContainer>
    </>
  );
};

export default Header;
