import { LoadingButton } from '@atlaskit/button';
import React, { ReactNode, useState } from 'react';
import PDFPrintPopup from '../PDFPrintPopup';
import { apiErrorToast } from '../toast/ToastHandler';

type iState = {
  isPrinting: boolean;
  PDFUrl?: string;
};
const initialState: iState = {
  isPrinting: false,
};
const GeneralPrintBtn = ({
  serviceFunc,
  children,
  shouldWarningModalOpen = false,
  onWarning,
}: {
  serviceFunc: () => Promise<string>;
  children: ReactNode;
  shouldWarningModalOpen?: boolean;
  onWarning?: () => void;
}) => {
  const [state, setState] = useState(initialState);
  const printOrder = () => {
    setState({ ...state, isPrinting: true });
    serviceFunc()
      .then(pdfUrl => setState({ ...state, isPrinting: false, PDFUrl: pdfUrl }))
      .catch(error => {
        setState({ ...state, isPrinting: false });
        apiErrorToast(error);
      });
  };
  return (
    <>
      <LoadingButton
        className={'popup-item'}
        onClick={shouldWarningModalOpen ? onWarning : () => printOrder()}
        isLoading={state.isPrinting}
        testId="print-order"
        isSelected
      >
        {children}
      </LoadingButton>
      <PDFPrintPopup pdfFileUrl={state.PDFUrl} />
    </>
  );
};

export default GeneralPrintBtn;
