import React from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import SectionMessage from '@atlaskit/section-message';

const INVALID_HEADING = 'invalid action';
const INVALID_TEXT = "Due to some unknown reasons, we can't take your action";
const WarningModal = ({
  isOpen,
  onCancel,
  modalHeading = INVALID_HEADING,
  modalBody = INVALID_TEXT,
  bottom = null,
  closeBtnName = 'Close',
  width,
  height,
}: {
  isOpen?: boolean;
  onCancel: () => void;
  modalHeading?: React.ReactNode;
  modalBody?: React.ReactNode;
  bottom?: React.ReactNode;
  closeBtnName?: string;
  width?: 'small' | 'medium' | 'large' | 'x-large' | number | string;
  height?: number | string;
}) => {
  const getModal = () => (
    <Modal testId={'warning-modal'} onClose={onCancel} width={width || 'medium'} height={height || 'auto'}>
      <ModalHeader testId={'modal-header'}>
        <ModalTitle>{modalHeading}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <SectionMessage appearance="warning" testId={'modal-body'}>
          {modalBody}
        </SectionMessage>
      </ModalBody>
      <ModalFooter>
        <div>{bottom}</div>
        <ButtonGroup>
          <Button onClick={onCancel} testId={'warning-modal-close-button'}>
            {closeBtnName}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
  return isOpen ? <ModalTransition>{getModal()}</ModalTransition> : null;
};

export default WarningModal;
