import React from 'react';
import iComment from '../../types/comment/iComment';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';

import CommentItem from './CommentItem';
import CreateComment from './CreateComment';
import { CommentWrapper } from './Comment.style';
import CommentService from '../../services/CommentService';
import LoadMore from '../pagination/loadMore/LoadMore';
import CustomizeModal from '../modal/CustomizeModal';

interface iProps {
  modelName?: string;
  modelId?: string;
}

const CommentList = ({ modelName, modelId }: iProps) => {
  const commentFilter = `modelName:${modelName},modelId:${modelId}`;
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onOpenDeleteModal,
    onSubmit,
    onSetCurrentPage,
  } = useListCrudHook<iComment>({
    getFn: CommentService.getComments,
    createFn: CommentService.createComment,
    updateFn: CommentService.updateComment,
    deleteFn: CommentService.deleteComment,
    loadMoreApplied: true,
    filter: commentFilter,
    sort: 'updatedAt:desc',
  });
  const onLoadMore = () => {
    const currentPage = state.currentPage ? state.currentPage : 1;
    onSetCurrentPage(currentPage + 1);
  };
  const isNotEnd = () => {
    if (typeof state.to === 'undefined' || typeof state.total === 'undefined') {
      return true;
    }
    return state.total > state.to;
  };
  const onSubmitComment = (value?: string) => {
    onSubmit({ content: value, modelName, modelId });
  };
  const onDelConfirm = () => {
    if (edit.delTargetId) {
      onDelete(edit.delTargetId);
    }
  };
  return (
    <CommentWrapper className={'comments-wrapper'}>
      <CreateComment
        isConfirming={state.isConfirming}
        isReadView={!(edit.isModalOpen && edit.target === undefined && edit.delTargetId === undefined)}
        onCreate={onSubmitComment}
        onOffReadView={onOpenAddModal}
        onCancel={onCloseModal}
      />
      {state.data.map((comm: iComment) => (
        <CommentItem
          key={comm.id}
          comment={comm}
          onOpenDelete={onOpenDeleteModal}
          onEdit={onSubmitComment}
          isReadView={!edit.isModalOpen}
          onOffReadView={onOpenEditModal}
          editTarget={edit.target}
          onCancel={onCloseModal}
        />
      ))}
      {isNotEnd() && <LoadMore isLoading={state.isLoading} onClick={onLoadMore} />}
      <CustomizeModal
        isOpen={edit.isModalOpen && !!edit.delTargetId}
        isConfirming={state.isConfirming}
        onConfirm={onDelConfirm}
        onCancel={onCloseModal}
        confirmBtnName={'Delete'}
        confirmBtnAppearance={'danger'}
        modalHeading={<span>Are you sure?</span>}
        modalBody={<p>Please confirm you are about to delete it.</p>}
        testIdPrefix={'comment-delete'}
      />
    </CommentWrapper>
  );
};

export default CommentList;
