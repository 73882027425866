import styled from 'styled-components';
import { SERVICE_PROVIDER_TABLE_NAME } from './ServiceProvider.constants';

export const CreateModalBodyWrapper = styled.div`
  div[data-testid='full-grid'] {
    max-width: 100%;
  }
`;

export const ServiceProviderTableWrapper = styled.div`
  overflow-x: auto;
  th[data-testid='${SERVICE_PROVIDER_TABLE_NAME}-column-requested--head--cell'],
  th[data-testid='${SERVICE_PROVIDER_TABLE_NAME}-column-received--head--cell'] {
    min-width: 80px;
    max-width: 200px;
  }
  th[data-testid='${SERVICE_PROVIDER_TABLE_NAME}-column-sent--head--cell'] {
    min-width: 64px;
    max-width: 200px;
  }
  th {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const QtyInputContainer = styled.div`
  margin-top: -2rem;
`;
