import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';
import LoadingButton from '@atlaskit/button/loading-button';
import AuthService from '../../services/AuthService';
import { BACK_END_URL } from '../UrlMap';
import { addToastForAPIResponse } from '../toast/ToastHandler';

type Inputs = {
  username: string;
  password: string;
};

const initialState = {
  logging: false,
};

const LoginFormWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
export default () => {
  const history = useHistory();
  const [state, setState] = useState(initialState);
  const { register, handleSubmit, errors } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    setState({
      ...state,
      logging: true,
    });
    AuthService.login(data.username, data.password)
      .then(() => {
        history.push(BACK_END_URL);
      })
      .catch(() => {
        addToastForAPIResponse('error', 'Incorrect Credential');
        setState({
          ...state,
          logging: false,
        });
      });
  };

  const getField = (label: string, name: string, placeholder: string, errorMsg: string, type = 'text') => {
    return (
      <fieldset>
        {label}
        <Textfield
          type={type}
          id={name}
          name={name}
          css={'default'}
          placeholder={placeholder}
          ref={register({ required: true })}
        />
        {name in errors ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
      </fieldset>
    );
  };

  return (
    <LoginFormWrapper className={'login-form-wrapper'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {getField('Username', 'username', 'Username or your email', 'Please provide your username or email.')}
        {getField('Password', 'password', 'Password', 'Password is required.', 'password')}
        <fieldset>
          <LoadingButton
            css={'primary'}
            appearance={'primary'}
            type={'submit'}
            isLoading={state.logging === true}
            shouldFitContainer
          >
            Login
          </LoadingButton>
        </fieldset>
      </form>
    </LoginFormWrapper>
  );
};
