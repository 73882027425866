import React from 'react';
import Spinner from '@atlaskit/spinner';
import iJob from '../../../types/job/iJob';
import { iLabelValuePair } from '../../UITypes/types';
import ProgressBar from '../../job/jobDetail/progressBar/ProgressBar';
import JobDetailContainer from '../../job/jobDetail/styles/JobDetailContainer';
import JobProgressInPage from '../../job/jobProgress/JobProgressInPage';
import JobDetailBody from '../JobDetailBody';
import ProgressSelect from '../../job/jobDetail/progressSelect/ProgressSelect';
import JobDetailHeader from '../JobDetailHeader';
import { useStatusContext } from '../statusContext/StatusContext';
import { NO_FORM_STATUS } from './FormManagement.constants';

const JobDetailFormManagement = ({ jobDetail }: { jobDetail: iJob }) => {
  const {
    isLoading,
    isFormOpen,
    isWIPJob,
    statusMenu,
    formTaggable,
    formStatusId,
    jobStatusId,
    openFormModal,
    closeFormModal,
    forwardJobStatus,
    isJobStatusFinished,
    isFormStatusFinished,
  } = useStatusContext();

  const onOpenModal = (pair: iLabelValuePair) => {
    if (NO_FORM_STATUS.includes(pair.label.toLowerCase())) return;
    openFormModal(pair.value);
  };

  if (isLoading) return <Spinner />;

  if (isFormOpen)
    return (
      <JobProgressInPage
        jobDetail={jobDetail}
        isWIPJob={isWIPJob}
        forwardStatus={forwardJobStatus}
        closeModal={() => closeFormModal(formStatusId)}
        formTaggable={formTaggable}
        isModalFinished={isFormStatusFinished}
        isJobFinished={isJobStatusFinished}
      />
    );
  return (
    <JobDetailContainer>
      <JobDetailHeader readOnly={false} jobDetail={jobDetail}>
        <ProgressSelect
          allJobStatus={statusMenu}
          currentJobStatus={statusMenu.find((item: iLabelValuePair) => item.value === jobStatusId)}
          onOpenModal={onOpenModal}
        />
      </JobDetailHeader>
      <JobDetailBody jobDetail={jobDetail} />
      <ProgressBar
        allJobStatus={statusMenu}
        currentJobStatus={statusMenu.find((item: iLabelValuePair) => item.value === jobStatusId)}
        onOpenModal={onOpenModal}
      />
    </JobDetailContainer>
  );
};

export default JobDetailFormManagement;
