import { iConfigColumn } from '../shared/UITypes/types';
// import { SERVICE_PROVIDER_URL } from '../shared/UrlMap';
import { ModuleCode } from '../types/iModuleCode';
import AppService from './AppService';
import LocalStorageService from './LocalStorageService';
// import { handleNullException } from './UtilsService';

const checkIsModuleEnabled = ({
  checkedModule,
  companyModules,
}: {
  checkedModule: ModuleCode;
  companyModules: Array<ModuleCode>;
}) => {
  return companyModules.includes(checkedModule);
};

const filterListByModule = ({
  list,
  // targetIndexs,
  checkedModule,
  companyModules,
  filterFn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: Array<any>;
  // targetIndexs: Array<number>;
  checkedModule: ModuleCode;
  companyModules: Array<ModuleCode>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterFn: (item: any, index: number) => boolean;
}) => {
  if (checkIsModuleEnabled({ checkedModule, companyModules })) {
    return list;
  }

  return list.filter(filterFn);
  // !targetIndexs.includes(index));
};

const filterConfigColumnsByModule = ({
  list,
  companyModules,
}: {
  list: Array<iConfigColumn>;
  companyModules: Array<ModuleCode>;
}) => {
  return list.filter(
    (item: iConfigColumn) =>
      !(item.moduleCode && !checkIsModuleEnabled({ checkedModule: item.moduleCode, companyModules })),
  );
};
// eslint-disable-next-line
const domainExchangeCompanyInfo = (hostname: string) => {
  return AppService.get('/auth/domainExchange', { hostname }).then(({ data }) => {
    if (data && data.companyId) {
      LocalStorageService.setCompanyId(data.companyId);
    }
    return data;
  });
};

export default {
  checkIsModuleEnabled,
  filterListByModule,
  filterConfigColumnsByModule,
  domainExchangeCompanyInfo,
};
