import React, { useState } from 'react';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import iAddress from '../../../../../types/contactCompany/iAddress';
import ModalPageNoConfirm from '../../../../../shared/modalPage/ModalPageNoConfirm';

type iState = {
  isOpen: boolean;
};
const initialState: iState = {
  isOpen: false,
};

const withModalControl =
  ({
    WrappedModalBody,
    WrappedButton,
  }: {
    //  eslint-disable-next-line
    WrappedModalBody: React.ComponentType<any>;
    //  eslint-disable-next-line
    WrappedButton: React.ComponentType<any>;
  }) =>
  ({
    wrappedModalBodyProps,
    wrapperButtonProps,
  }: {
    wrappedModalBodyProps: {
      customerId?: string;
      DNId: string;
      //  eslint-disable-next-line
      onCreate: (data: any) => Promise<void>;
      DNShippingAddress?: iAddress;
      DNItemLength?: number;
      isServiceProvider?: boolean;
      customerShippingAddress?: iAddress;
    };
    wrapperButtonProps: {
      iconAfter?: React.ReactChild;
      btnName?: string;
      testId?: string;
    };
  }) => {
    const [state, setState] = useState(initialState);
    const onCloseModal = () => setState(initialState);

    return (
      <>
        <WrappedButton
          //  eslint-disable-next-line
          {...wrapperButtonProps}
          icon={<EditorAddIcon size={'small'} label={'add'} testId={'create-poItem-ao'} />}
          onClick={() => setState({ ...state, isOpen: true })}
        />
        {state.isOpen && (
          <ModalPageNoConfirm onCancel={onCloseModal} heading={'add an item for this Dispatch note:'}>
            <WrappedModalBody
              //  eslint-disable-next-line
              {...wrappedModalBodyProps}
              onCreateCallback={onCloseModal}
            />
          </ModalPageNoConfirm>
        )}
      </>
    );
  };

export default withModalControl;
