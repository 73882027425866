import iBaseType from '../../iBaseType';
import iAccess from './iAccess';
// import iRole from './iRole';

export const ACCESS_CAN_READ = 'canRead';
export const ACCESS_CAN_CREATE = 'canCreate';
export const ACCESS_CAN_UPDATE = 'canUpdate';
export const ACCESS_CAN_DELETE = 'canDelete';
export const AccessCanFields = [ACCESS_CAN_READ, ACCESS_CAN_CREATE, ACCESS_CAN_UPDATE, ACCESS_CAN_DELETE] as const;

export type iAccessCanFields = typeof AccessCanFields[number];
type iRoleAccess = iBaseType & {
  roleId: string;
  accessId: string;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canCreate: boolean;
  companyId: string;
  access?: iAccess;
  // role?: iRole;
};

export default iRoleAccess;
