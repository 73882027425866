import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Spinner from '@atlaskit/spinner';
import TypeFilter from '../filter/TypeFilter';
import iEntityCategory from '../../types/status/iEntityCategory';
import iEntityStatus from '../../types/status/iEntityStatus';
import { mapToLabelValuePair } from '../../services/UtilsService';
import { ALL, STATUS_CATEGORY_FINISHED } from '../constants/StatusCategories';
import { iParams } from '../UITypes/types';
import { apiErrorToast } from '../toast/ToastHandler';

type iState = {
  whole: Array<iEntityStatus>;
  defaultSelected: Array<string>;
  selected: Array<string>;
  isLoading: boolean;
  categories: Array<iEntityCategory>;
};
const initialState: iState = {
  whole: [],
  defaultSelected: [],
  selected: [],
  isLoading: true,
  categories: [],
};
type iWholeAndDefaultStatus = {
  whole: Array<iEntityStatus>;
  defaultSelected: Array<string>;
};
const extraOptions = [{ label: ALL, value: ALL }];

export type iUseTypesSelectorProps = {
  type: string;
  isMulti?: boolean;
  //    eslint-disable-next-line
  getFn?: (config?: iParams) => Promise<any>;
};
const useTypesSelector = ({ type, isMulti = true, getFn }: iUseTypesSelectorProps) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;

    const getStatus = (categoryList: Array<iEntityCategory>) =>
      _.orderBy(categoryList, ['code'], 'desc').reduce(
        (acc: iWholeAndDefaultStatus, cur: iEntityCategory) => {
          return {
            ...acc,
            whole: [
              ...acc.whole,
              ...cur.entityStatuses.sort((a: iEntityStatus, b: iEntityStatus) => a.sortOrder - b.sortOrder),
            ],
            defaultSelected:
              cur.code === STATUS_CATEGORY_FINISHED
                ? acc.defaultSelected
                : [...acc.defaultSelected, ...cur.entityStatuses.map((s: iEntityStatus) => s.id)],
          };
        },
        { whole: [], defaultSelected: [] },
      );

    const fetchDesStatus = async () => {
      if (!getFn) return;
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const categoryList: Array<iEntityCategory> = await getFn({
          filter: `entityStatuses.entityStatusType.entityName:${type}`,
        });
        if (isCancelled) return;
        //  eslint-disable-next-line
        const { whole, defaultSelected } = getStatus(categoryList);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          whole,
          defaultSelected,
          selected: defaultSelected,
          categories: categoryList,
        }));
      } catch (error) {
        if (isCancelled) return;
        setState(prevState => ({ ...prevState, isLoading: false }));
        apiErrorToast(error);
      }
    };
    fetchDesStatus();
    return () => {
      isCancelled = true;
    };
  }, [type, getFn]);

  const onToggleType = (toggled: string) => {
    // have to and only have a type selected
    if (!isMulti) {
      setState({ ...state, selected: [toggled] });
      return;
    }
    // multi types can be selected
    if (state.selected.includes(toggled) && state.selected.length > 1) {
      setState({
        ...state,
        selected: state.selected.filter((s: string) => s !== toggled),
      });
    }
    // nothing is selected, reset to initial value
    else if (state.selected.includes(toggled) && state.selected.length === 1) {
      setState({ ...state, selected: state.defaultSelected });
    } else {
      setState({ ...state, selected: [...state.selected, toggled] });
    }
  };

  const getTypeSelectorComponent = () =>
    state.isLoading ? (
      <Spinner />
    ) : (
      <TypeFilter
        options={[...extraOptions, ...mapToLabelValuePair(state.whole)]}
        selectedTypes={isMulti ? state.selected : state.selected.slice(0, 1)}
        onSelect={onToggleType}
      />
    );
  return {
    selected: isMulti ? state.selected : state.selected.slice(0, 1),
    getTypeSelectorComponent,
    categories: state.categories,
    isStatusLoading: state.isLoading,
  };
};
export default useTypesSelector;
