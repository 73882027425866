import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import DeleteInput from './DeleteInput';
import AddInput from './AddInput';
import iFormFieldValueSet from '../../../types/form/iFormFieldValueSet';
import iFormOption from '../../../types/form/iFormOption';
import { ErrorMsg, FlexAlignEndContainer } from '../styleContainer';
import { iIdValuePair, iLabelValuePair } from '../../UITypes/types';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-wrap: nowrap;
  .dynamic-multiInput-enterArea {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
const DeletesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-wrap: nowrap;
  overflow-x: auto;
`;
const initialValues: Array<iIdValuePair> = [];
export const INITIAL_OPTIONS: Array<iLabelValuePair> = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
];

const DynamicMultiInput = ({
  id,
  control,
  defaultValue,
  onChange,
  errors,
  prefix,
  suffix,
  isCompulsory = false,
}: {
  id: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: Array<iFormFieldValueSet>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  prefix?: string;
  suffix?: string;
  isCompulsory?: boolean;
}) => {
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      const defaultInputs: Array<iIdValuePair> = [];
      defaultValue.forEach((formFieldValue: iFormFieldValueSet) => {
        // multi input must include id in options
        if (formFieldValue.options) {
          const idIndex = formFieldValue.options.findIndex((option: iFormOption) => option.id);
          if (idIndex > -1) {
            defaultInputs.push({
              value: formFieldValue.value as string,
              id: formFieldValue.options[idIndex].id as string,
            });
          }
        }
      });
      setValues(defaultInputs);
    } else {
      setValues(initialValues);
    }
  }, [defaultValue]);

  const mapValuesForCollection = (newValues: Array<iIdValuePair>) => {
    return newValues.map((newValue: iIdValuePair) => ({
      value: newValue.value,
      options: [{ id: newValue.id }],
    }));
  };
  //  add new
  const onAdd = (value: string, inputId: string) => {
    const newValues = [...values, { id: inputId, value }];
    onChange(id, mapValuesForCollection(newValues), { shouldValidate: true });
  };
  //  delete a value
  const onDelete = (inputId: string) => {
    const newValues = values.filter((item: iIdValuePair) => item.id !== inputId);
    onChange(id, mapValuesForCollection(newValues), { shouldValidate: true });
  };
  //  update option according to values
  const updateAddOptions = () =>
    INITIAL_OPTIONS.filter(option => !values.find(value => value.id === option.value)).sort((a, b) =>
      a.value > b.value ? 1 : -1,
    );

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || []}
      rules={{
        validate: value => (isCompulsory ? value.length > 0 || 'at least set one value' : true),
      }}
      render={() => (
        <FlexAlignEndContainer data-testid={'dynamic-multiInput'}>
          <SectionWrapper>
            <DeletesContainer>
              {values.map((item: iIdValuePair) => (
                <DeleteInput key={`delete${item.id}`} inputValue={item} onDelete={onDelete} />
              ))}
            </DeletesContainer>
            <div className={`dynamic-multiInput-enterArea ${_.get(errors, id) && 'validation-error'}`}>
              {values.length < INITIAL_OPTIONS.length && (
                <AddInput
                  key={`add-input-${updateAddOptions().length}`}
                  options={updateAddOptions()}
                  onAdd={onAdd}
                  prefix={prefix}
                  suffix={suffix}
                />
              )}
            </div>
          </SectionWrapper>
          {_.get(errors, id) && <ErrorMsg data-testid={'error-msg'}>{_.get(errors, id).message}</ErrorMsg>}
        </FlexAlignEndContainer>
      )}
    />
  );
};

export default DynamicMultiInput;
