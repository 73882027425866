import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getPurchaseOrderItems = (config?: iParams) => {
  return AppService.get('/purchaseOrderItem', {
    include:
      'product.measurement,purchaseOrder.createdBy,purchaseOrder.updatedBy,purchaseOrder.status,purchaseOrder.supplier',
    ...config,
  }).then(({ data }) => data);
};

const updatePurchaseOrderItem = (id: string, updatedContent: iKeyValuePairs) => {
  return AppService.put(`/purchaseOrderItem/${id}`, updatedContent).then(({ data }) => data);
};

const createPurchaseOrderItem = (orderId: string, productId: string) => {
  return AppService.post('/purchaseOrderItem', {
    orderId,
    productId,
  }).then(({ data }) => data);
};

const deletePurchaseOrderItem = (id: string) => {
  return AppService.delete(`/purchaseOrderItem/${id}`).then(({ data }) => data);
};

const getPOItemConfig = (config?: iParams) => {
  return AppService.get('/purchaseOrderItem', config).then(({ data }) => data);
};

const createPOItemConfig = (postData: iKeyValuePairs) => {
  return AppService.post('/purchaseOrderItem', postData).then(({ data }) => data);
};

export default {
  getPurchaseOrderItems,
  updatePurchaseOrderItem,
  createPurchaseOrderItem,
  deletePurchaseOrderItem,
  getPOItemConfig,
  createPOItemConfig,
};
