import React from 'react';
import { useSelector } from 'react-redux';
import { stockSubMenu } from './subMenus';
import { RootState } from '../../redux/makeReduxStore';
import TopNavPopup from './TopNavPopup';
import { handleNullException } from '../../services/UtilsService';
import { ITEM_LOCATION_CURRENT_MACHINE_URL } from '../UrlMap';

const StockPopup = () => {
  const { user } = useSelector((s: RootState) => s.auth);

  return (
    <TopNavPopup
      name={'Stock'}
      subMenu={[
        {
          label: `${handleNullException(user, 'defaultOperatingMachine.name')} Stock`,
          value: ITEM_LOCATION_CURRENT_MACHINE_URL,
        },
        ...stockSubMenu,
      ]}
    />
  );
};

export default StockPopup;
