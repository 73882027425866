export const COLLAPSE_TITLE = 'Roll(s) done';
export const TODO_TITLE = 'Roll(s) todo';
export const IS_PROGRESS_COMPLETED = 'isProgressCompleted';
export const IS_OVER_PRODUCED = 'isOverProduced';
export const IS_NO_ROW_DONE = 'isNoRollDone';
export const ACTUAL_WEIGHT = 'actualWeight';
export const IS_PROCESSED = 'isProcessed';
export const CONFLICT_HINT = 'Database has been changed since you start Manufacturing';
export const CONFLICT_TITLE = 'Somebody has made change to database';
export const USE_DATABASE = 'Use database version';
export const USE_DATABASE_HINT = `${USE_DATABASE}: might lose your input`;
export const KEEP_YOURS = 'Keep your input';
export const KEEP_YOURS_HINT = `${KEEP_YOURS}: more rolls added to keep your input`;
export const DEFAULT_HEADS = ['Length(m)', 'Nominal Weight(Kg)', 'Actual Weight(Kg)'];
