/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GridColumn } from '@atlaskit/page';

import { iLabelValuePair, iParams } from '../../UITypes/types';
import { mapOptionToValue } from '../../../services/UtilsService';
import MultiSelect from '../../hookForms/MultiSelect';
import ResetBtn from '../../buttons/ResetBtn';
import CreatableMultiSelect from '../CreatableMultiSelect';
import DatePickerWithoutReadview from '../DatePickerWithoutReadview';

const ControllerWrapper = styled.div`
  padding: 5px 0;
`;

export type iFilterConfig = {
  name: string;
  type: string;
  selected: any;
  placeholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promiseFn?: (config?: iParams | undefined) => Promise<any>;
  optionLabel?: string;
  sortBy?: string;
  testId: string;
};

type iSelected = {
  [key: string]: any;
};

const getInitialSelected = (configs: iFilterConfig[]) => {
  return configs.reduce(
    (acc: any, config: iFilterConfig) => ({
      ...acc,
      [config.name]: config.selected,
    }),
    {},
  );
};

const useAdvancedSearchHooks = (configs: iFilterConfig[]) => {
  const [localSelected, setLocalSelected] = useState<iSelected>({});

  useEffect(() => {
    setLocalSelected({ ...getInitialSelected(configs) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(configs)]);

  const onConfirmSelect = (name: string, newValues: iLabelValuePair[] | string) => {
    if (!name) return;
    setLocalSelected({
      ...localSelected,
      [name]: newValues,
    });
  };

  const getAdvancedFilterStrArr = () => {
    const advancedFilterArr = Object.keys(localSelected).map((selectedName: string) => {
      // if selected outcome is an array, then join its elements to a string
      if (Array.isArray(localSelected[selectedName])) {
        return localSelected[selectedName].length === 0
          ? ''
          : `${selectedName}:${mapOptionToValue(localSelected[selectedName]).join('|')}`;
      }

      return localSelected[selectedName] ? `${selectedName}${localSelected[selectedName]}` : '';
    });

    return advancedFilterArr;
  };

  const onResetAdvancedSearch = () => {
    setLocalSelected({
      ...getInitialSelected(configs),
    });
  };

  const getSearchControllers = () => {
    const controllers = configs.map((c: iFilterConfig) => {
      const { type } = c;
      switch (type) {
        case 'multi':
          return (
            <GridColumn medium={3} key={c.name}>
              <ControllerWrapper>
                <MultiSelect
                  name={c.name}
                  defaultValue={localSelected[c.name]}
                  onConfirm={onConfirmSelect}
                  placeholder={c.placeholder}
                  promiseFn={c.promiseFn}
                  optionLabel={c.optionLabel}
                  sortBy={c.sortBy}
                  testId={c.testId}
                />
              </ControllerWrapper>
            </GridColumn>
          );
        case 'multi-text':
          return (
            <GridColumn medium={3} key={c.name}>
              <ControllerWrapper>
                <CreatableMultiSelect
                  name={c.name}
                  defaultValue={localSelected[c.name]}
                  onChange={onConfirmSelect}
                  placeholder={c.placeholder}
                  testId={c.testId}
                  isClearable
                  isMulti
                />
              </ControllerWrapper>
            </GridColumn>
          );
        case 'date':
          return (
            <GridColumn medium={3} key={c.name}>
              <ControllerWrapper>
                <DatePickerWithoutReadview
                  name={c.name}
                  dateFormat="DD MMM YYYY"
                  defaultValue={localSelected[c.name]}
                  onConfirm={onConfirmSelect}
                  placeholder={c.placeholder}
                  testId={c.testId}
                />
              </ControllerWrapper>
            </GridColumn>
          );
        default:
          return null;
      }
    });

    return (
      <>
        {controllers}
        <GridColumn medium={0.5}>
          <div style={{ paddingTop: '10px' }}>
            <ResetBtn onClick={onResetAdvancedSearch} />
          </div>
        </GridColumn>
      </>
    );
  };

  return {
    getAdvancedFilterStrArr,
    getSearchControllers,
  };
};

export default useAdvancedSearchHooks;
