import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import iJobAttribute from '../../../types/job/iJobAttribute';
import iProductAttributeValue from '../../../types/product/iProductAttributeValue';
import EmptyRollBody from './EmptyRollBody';
import RollPrintBody from './RollPrintBody';
import PrintBtn from '../../buttons/PrintBtn';
import ModalPage from '../../modalPage/ModalPage';
import PDFPrintPopup from '../../PDFPrintPopup';
import RollPrintUtils from './RollPrint.utils';
import PrintService from '../../../services/PrintService';
import { INCLUDE_LOGO } from '../../dynamicForm/labelPrinting/LabelTypeConstants';
import { apiErrorToast } from '../../toast/ToastHandler';
import { LABEL_TYPE, ROLL_PRINT } from './RollPrint.constants';
import { handleNullException } from '../../../services/UtilsService';
import ProductAttributeValueService from '../../../services/ProductAttributeValueService';

type iState = {
  isOpen: boolean;
  isLoading: boolean;
  isConfirming: boolean;
  labelType: string;
  pdfLocalUrl?: string;
};
const initialState: iState = {
  isOpen: false,
  isLoading: false,
  isConfirming: false,
  labelType: INCLUDE_LOGO,
};

const RollPrint = ({
  jobId,
  jobAttributes,
  productId,
  defaultLabelType,
}: {
  jobId: string;
  jobAttributes: Array<iJobAttribute>;
  productId: string;
  defaultLabelType?: string;
}) => {
  // when state changes, the extractJobAttribute will re-render, that is a waste
  const { length, rollNumber, nominalWeight } = RollPrintUtils.extractJobAttribute(jobAttributes);
  const [state, setState] = useState(initialState);
  const { control, setValue, errors, handleSubmit, watch } = useForm({});

  const openModal = async () => {
    if (defaultLabelType) {
      setState(prevState => ({
        ...prevState,
        labelType: defaultLabelType,
        isOpen: true,
      }));
      return;
    }
    try {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const prodAttrValues: Array<iProductAttributeValue> = await ProductAttributeValueService.getProdAttributeValues({
        advancedFilter: `ProductAttribute_attributeId.name:${LABEL_TYPE},productId:${productId}`,
      });
      if (prodAttrValues.length !== 1 || !prodAttrValues[0].value) {
        setState(prevState => ({
          ...prevState,
          isOpen: true,
          isLoading: false,
        }));
        return;
      }

      setState(prevState => ({
        ...prevState,
        labelType: prodAttrValues[0].value,
        isOpen: true,
        isLoading: false,
      }));
    } catch (error) {
      apiErrorToast(error);
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const closeModal = () => setState({ ...state, isOpen: false });
  const closePrint = () => setState({ ...state, pdfLocalUrl: undefined, isOpen: false });
  //  eslint-disable-next-line
  const convertData = (data: any) => {
    return {
      ...data,
      includeLogo: handleNullException(data, 'includeLogo').toString().toUpperCase() === INCLUDE_LOGO.toUpperCase(),
    };
  };
  //  eslint-disable-next-line
  const onPrint = async (data: any) => {
    const converted = convertData(data);
    setState({ ...state, isConfirming: true });
    try {
      const fileUrl = await PrintService.printRollLabel(jobId, converted);
      setState({
        ...state,
        isConfirming: false,
        pdfLocalUrl: fileUrl,
      });
    } catch (error) {
      setState({ ...state, isConfirming: false });
      apiErrorToast(error);
    }
  };

  return (
    <>
      <PrintBtn title={ROLL_PRINT} onClick={openModal} isLoading={state.isLoading} />
      {state.isOpen && (
        <ModalPage
          heading={'Print Roll Label'}
          onCancel={closeModal}
          isConfirming={state.isConfirming}
          isDisabled={rollNumber === 0 || Object.keys(errors).length > 0}
          confirmBtnName={'Print'}
          onConfirm={handleSubmit(onPrint)}
        >
          {rollNumber > 0 ? (
            <RollPrintBody
              control={control}
              errors={errors}
              onChange={setValue}
              watch={watch}
              defaultValues={{
                length,
                nominalWeight,
                rollNumber,
                includeLogo: state.labelType,
              }}
            />
          ) : (
            <EmptyRollBody />
          )}
        </ModalPage>
      )}
      <PDFPrintPopup pdfFileUrl={state.pdfLocalUrl} onClose={closePrint} />
    </>
  );
};

export default RollPrint;
