import React, { useState, useCallback } from 'react';
import SectionMessage from '@atlaskit/section-message';
import iAddress from '../../../../types/contactCompany/iAddress';
import iDispatchNoteItem from '../../../../types/dispatchNote/iDispatchNoteItem';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import CreateNoteItem from './CreateNoteItem';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import CompareAddressInfo from '../createDNItem/shared/CompareAddressInfo';
import { REMOVE_MODAL_HEADING } from '../../../stockTransfer/sharedComponents/StockTransfer.constants';
import { compareAddress, getHeads, getRows } from './TableHelper';
import { iDetailUpdateEagerLoadFn, iDetailUpdateFn, iParams } from '../../../../shared/UITypes/types';
import { addToastForAPIResponse, apiErrorToast } from '../../../../shared/toast/ToastHandler';
import AddressService from '../../../../services/AddressService';
import DispatchNoteItemService from '../../../../services/DispatchNoteItemService';
import { SERVICE_PRODIVDER_SHIPPING, SO_SHIPPING_WORDING } from '../createDNItem/shared/CreateDNItem.constants';
import DataTable from '../../../../shared/tableHelper/DataTable';

type iOverwriteState = {
  overwrite: boolean;
  isConfirming: boolean;
};
const initialState: iOverwriteState = {
  overwrite: false,
  isConfirming: false,
};

export type iDispatchNoteItemsProps = {
  dispatchNoteId: string;
  customerId?: string;
  columns: Array<string>;
  DNShippingAddress?: iAddress;
  onUpdateDetail: iDetailUpdateFn;
  onUpdateLocal: iDetailUpdateEagerLoadFn;
  isServiceProvider?: boolean;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  customerShippingAddress?: iAddress;
};
const DispatchNoteItems = ({
  dispatchNoteId,
  customerId,
  columns,
  DNShippingAddress,
  onUpdateDetail,
  onUpdateLocal,
  canCreate,
  canUpdate,
  canDelete,
  isServiceProvider = false,
  customerShippingAddress,
}: iDispatchNoteItemsProps) => {
  const [overwriteState, setOverwriteState] = useState(initialState);

  const getItems = useCallback(
    (config?: iParams) => DispatchNoteItemService.getDispatchNoteItemList(dispatchNoteId, config),
    [dispatchNoteId],
  );

  const { state, edit, onCloseModal, onDelete, onOpenDeleteModal, onCreate, onEdit } =
    useListCrudHook<iDispatchNoteItem>({
      getFn: getItems,
      deleteFn: DispatchNoteItemService.deactivateDispatchNoteItem,
      createFn: DispatchNoteItemService.createDispatchNoteItem,
      updateFn: DispatchNoteItemService.updateDispatchNoteItem,
      paginationApplied: true,
      sort: 'createdAt:DESC',
      createCallBack: (createId: string) => {
        if (state.data.length === 0) setOverwriteState({ ...overwriteState, overwrite: true });
        setTimeout(() => {
          //  eslint-disable-next-line
          document.getElementById(`${createId}-quantity`)?.click();
          addToastForAPIResponse('success');
        }, 200);
      },
      //  eslint-disable-next-line
      editCallBack: (createId: string) => addToastForAPIResponse('success'),
    });

  //  eslint-disable-next-line
  const overwriteAddressFrom = isServiceProvider
    ? customerShippingAddress
    : state.data.length > 0
    ? state.data[0].salesOrderItem?.salesOrder?.shippingAddress
    : undefined;

  const overwriteAddressWording = isServiceProvider ? SERVICE_PRODIVDER_SHIPPING : SO_SHIPPING_WORDING;

  const onOverwriteAddress = async () => {
    if (!overwriteAddressFrom) return;
    const content = {
      street: overwriteAddressFrom.street,
      suburb: overwriteAddressFrom.suburb,
      state: overwriteAddressFrom.state,
      country: overwriteAddressFrom.country,
      postcode: overwriteAddressFrom.postcode,
      name: overwriteAddressFrom.name,
    };
    try {
      const newAddress: iAddress = DNShippingAddress
        ? await AddressService.editAddress(DNShippingAddress.id, content)
        : await AddressService.createAddress(content);
      if (!DNShippingAddress) {
        await onUpdateDetail({
          shippingAddressId: newAddress.id,
        });
      }
      onUpdateLocal({
        shippingAddress: newAddress,
        shippingAddressId: newAddress.id,
      });

      setOverwriteState({
        ...overwriteState,
        isConfirming: false,
        overwrite: false,
      });
    } catch (error) {
      apiErrorToast(error);
      setOverwriteState({ ...overwriteState, isConfirming: false });
    }
  };
  return (
    <>
      <DataTable
        head={getHeads(columns, 'dispatchNoteItem')}
        rows={getRows(state.data, canDelete ? onOpenDeleteModal : undefined, onEdit, columns, !canUpdate, customerId)}
        testId={'despatchNote-Item-table'}
        isLoading={state.isLoading}
      />
      {canCreate && (
        <CreateNoteItem
          dispatchNoteId={dispatchNoteId}
          customerId={customerId}
          DNShippingAddress={DNShippingAddress}
          DNItemLength={state.data.length}
          onCreate={onCreate}
          isServiceProvider={isServiceProvider}
          customerShippingAddress={customerShippingAddress}
        />
      )}
      <CustomizeModal
        isOpen={edit.isModalOpen && !!edit.delTargetId}
        isConfirming={state.isConfirming}
        onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
        onCancel={onCloseModal}
        modalHeading={REMOVE_MODAL_HEADING}
        confirmBtnAppearance={'danger'}
      />
      {overwriteState.overwrite && state.data.length === 1 && !compareAddress(DNShippingAddress, overwriteAddressFrom) && (
        <CustomizeModal
          isOpen={overwriteState.overwrite && state.data.length === 1}
          onCancel={() => setOverwriteState({ ...overwriteState, overwrite: false })}
          onConfirm={onOverwriteAddress}
          modalBody={
            <SectionMessage appearance={'warning'}>
              <CompareAddressInfo
                DNShippingAddress={DNShippingAddress}
                SOShippingAddress={overwriteAddressFrom}
                SOWording={overwriteAddressWording}
              />
            </SectionMessage>
          }
          confirmBtnName={'Overwrite'}
          modalHeading={'Do you want to overwrite the shipping address?'}
        />
      )}
    </>
  );
};

export default DispatchNoteItems;
