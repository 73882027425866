import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import DynamicPhoto from '../../dynamicForm/Photo/DynamicPhoto';
import DynamicText from '../../dynamicForm/text/DynamicText';
import DynamicInput from '../../dynamicForm/input/DynamicInput';
import DynamicLabelPrinting from '../../dynamicForm/labelPrinting/DynamicLabelPrinting';
import DynamicCheckbox from '../../dynamicForm/checkbox/DynamicCheckbox';
import DynamicSelect from '../../dynamicForm/select/DynamicSelect';
import DynamicMultiInput from '../../dynamicForm/multiInput/DynamicMultiInput';
import { iDynamicItem } from './types';
import DynamicManufactureRows from '../../dynamicForm/dynamicManufactureRows/DynamicManufactureRows';
import iFormFieldValueSetManufacture from '../../../types/form/iFormFieldValueSetManufacture';
import iFormFieldValueSet from '../../../types/form/iFormFieldValueSet';
import iFormTaggable from '../../../types/form/iFormTaggable';

const DynamicFormItem = ({
  input,
  control,
  setValue,
  errors,
  getValues,
  rowIndex,
  children,
  jobShiftJobId,
  setError,
  clearErrors,
  formTaggable,
}: {
  input: iDynamicItem;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  getValues: (payload?: string | string[]) => any;
  //    eslint-disable-next-line
  setValue: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors: DeepMap<Record<string, any>, FieldError>;
  rowIndex: number;
  children: React.ReactNode;
  jobShiftJobId: string;
  setError: (
    name: string,
    error: {
      type?: string;
      message?: string;
    },
  ) => void;
  clearErrors: (name?: string | string[]) => void;
  formTaggable?: iFormTaggable;
}) => {
  //  eslint-disable-next-line
  const propsBase = (element: iDynamicItem) => ({
    key: element.id,
    id: element.id,
    defaultValue: element.defaultValue as iFormFieldValueSet[],
    onChange: setValue,
    control,
    errors,
    isCompulsory: element.isCompulsory,
  });

  const propsBaseForManufacture = (element: iDynamicItem) => ({
    key: element.id,
    id: element.id,
    defaultValue: element.defaultValue as iFormFieldValueSetManufacture[],
    onChange: setValue,
    control,
    manufactureHeads: element.manufactureHeads,
    setError,
    clearErrors,
    formTaggable,
  });

  //  normal item
  const dynamicItem = (element: iDynamicItem) => {
    let dynamicComponent = null;
    switch (element.type) {
      case 'ShiftSummary':
        dynamicComponent = children;
        break;
      case 'DynamicText':
        dynamicComponent = (
          <DynamicText
            key={element.id}
            title={element.title || element.name}
            description={element.description}
            isCompulsory={element.isCompulsory}
          />
        );
        break;
      case 'DynamicCheckbox':
        dynamicComponent = (
          <DynamicCheckbox
            //  eslint-disable-next-line
            {...propsBase(element)}
          />
        );
        break;
      case 'DynamicMultiInput':
        dynamicComponent = (
          <DynamicMultiInput
            //  eslint-disable-next-line
            {...propsBase(element)}
            prefix={element.prefix}
            suffix={element.suffix}
          />
        );
        break;
      case 'DynamicPhoto':
        dynamicComponent = (
          <DynamicPhoto
            // eslint-disable-next-line
            {...propsBase(element)}
          />
        );
        break;
      case 'DynamicSelect':
        dynamicComponent = (
          <DynamicSelect
            //  eslint-disable-next-line
            {...propsBase(element)}
            placeholder={element.placeholder}
            isMulti={element.isMulti}
            selectOptions={element.selectOptions}
            api={element.api}
          />
        );
        break;
      case 'DynamicLabelPrinting':
        dynamicComponent = (
          <DynamicLabelPrinting
            key={element.id}
            // eslint-disable-next-line
            title={element.title || element.description}
            icon={element.icon}
            params={element.params}
            getValues={getValues}
            api={element.api}
            jobId={element.jobId}
            rowIndex={rowIndex}
          />
        );
        break;

      case 'DynamicInput':
        dynamicComponent = (
          <DynamicInput
            //  eslint-disable-next-line
            {...propsBase(element)}
            placeholder={element.placeholder}
            isLarger={element.isLarger}
            prefix={element.prefix}
            suffix={element.suffix}
          />
        );
        break;
      case 'DynamicManufactureRows':
        dynamicComponent = (
          <DynamicManufactureRows
            //  eslint-disable-next-line
            {...propsBaseForManufacture(element)}
            jobId={element.jobId}
            jobShiftJobId={jobShiftJobId}
          />
        );
        break;
      default:
        break;
    }
    return element.classNames ? <div className={element.classNames}>{dynamicComponent}</div> : dynamicComponent;
  };

  return <>{dynamicItem(input)}</>;
};

export default DynamicFormItem;
