import iAccess, { iAccessCode } from '../../types/settings/UserAccess/iAccess';
import AppService from '../AppService';
import { iAccessCanFields } from '../../types/settings/UserAccess/iRoleAccess';
import iUser from '../../types/user/iUser';

const endPoint = '/access';

const getAllAccesses = (config = {}): Promise<iAccess[]> => {
  return AppService.get(endPoint, config).then(({ data }) => data);
};

const hasAccess = (accessCode: iAccessCode, canField: iAccessCanFields, user?: iUser) => {
  if (!user) {
    return false;
  }

  const accessMap = user.accessMap || {};
  if (!(accessCode in accessMap)) {
    return false;
  }
  return accessMap[accessCode][canField] || false;
};

export default {
  getAllAccesses,
  hasAccess,
};
