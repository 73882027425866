import AppService from './AppService';
import { iParams } from '../shared/UITypes/types';

export const uploadImage = (params: FormData) => {
  return AppService.uploadImage('/asset', params).then(res => {
    return res;
  });
};

export const uploadAttachment = (params: FormData) => {
  return AppService.uploadImage('/attachment', params).then(({ data }) => data);
};
export const deactivateAttachment = (id: string) => {
  return AppService.delete(`/attachment/${id}`).then(({ data }) => data);
};

export const getAttachmentList = (config?: iParams) => {
  return AppService.get('/attachment', { ...config, include: 'asset' }).then(({ data }) => data);
};
/**
 * a fake function for useCrudList
 * if really needed, pls update it
 */
//  eslint-disable-next-line
export const updateAttachment = (id: string, params: any) => {
  return AppService.put(`/attachment/${id}`, params).then(({ data }) => data);
};
export default {
  uploadImage,
  deactivateAttachment,
  uploadAttachment,
  updateAttachment,
  getAttachmentList,
};
