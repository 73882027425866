import AppService from './AppService';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';

const getDispatchNoteList = (config?: iParams) => {
  return AppService.get('/dispatchNote', {
    include: 'customer.type,shippingAddress,courier,status,createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

const getDispatchNoteDetail = (dispatchNoteId: string) => {
  return AppService.get(`/dispatchNote/${dispatchNoteId}`, {
    include: 'customer.type,customer.shippingAddress,shippingAddress,courier,status,status.entityStatusCategory',
  }).then(({ data }) => data);
};

const updateDispatchNote = (dispatchNoteId: string, updateData: iKeyValuePairs) => {
  return AppService.put(`/dispatchNote/${dispatchNoteId}`, updateData).then(({ data }) => data);
};

const createDispatchNote = (content: iKeyValuePairs) => {
  return AppService.post('/dispatchNote', content).then(({ data }) => data);
};

const removeDispatchNote = (id: string) => {
  return AppService.delete(`/dispatchNote/${id}`).then(({ data }) => data);
};

export default {
  getDispatchNoteList,
  getDispatchNoteDetail,
  updateDispatchNote,
  createDispatchNote,
  removeDispatchNote,
};
