/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { Control, Controller, useForm } from 'react-hook-form';
import Checkbox from '@atlaskit/checkbox';
import iFormTaggable from '../../../types/form/iFormTaggable';
import iFormFieldValueSetManufacture from '../../../types/form/iFormFieldValueSetManufacture';
import { iManufactureRow, iOperateBulkRow } from './shared/DynamicManufactureRows.types';
import ManufactureRows from './rows/ManufactureRows';
import ManufactureHeads from './head/ManufactureHeads';
import DynamicLabelPrinting from '../labelPrinting/DynamicLabelPrinting';
import ManufactureBottom from './bottom/ManufactureBottom';
import DynamicMRsUtils from './shared/DynamicManufactureRows.utils';
import ConflictModal from './conflicts/ConflictModal';
import FormService from '../../../services/FormService';
import { addExact } from '../../calculationHelper/calculationHelper';
import {
  ACTUAL_WEIGHT,
  IS_NO_ROW_DONE,
  IS_OVER_PRODUCED,
  IS_PROCESSED,
  IS_PROGRESS_COMPLETED,
} from './shared/DynamicManufactureRows.constants';

const DynamicManufactureRowWrapper = styled.div`
  width: 100%;
`;

const convertedDefaultValue = (defaultValueArr: iFormFieldValueSetManufacture[] | undefined) => {
  return defaultValueArr && Array.isArray(defaultValueArr)
    ? defaultValueArr.map(
        ({
          length,
          nominalWeight,
          actualWeight,
          images,
          id,
          sortOrder,
          jobShiftJobId,
          printedAt,
          createdAt,
          updatedAt,
          isProcessed,
        }) => {
          return {
            length: [{ value: length }],
            nominalWeight: [{ value: nominalWeight }],
            actualWeight: [{ value: actualWeight }],
            images: images.map((img: string) => ({ value: img })),
            id,
            sortOrder,
            jobShiftJobId,
            printedAt,
            createdAt,
            updatedAt,
            isProcessed,
          };
        },
      )
    : [];
};
const heads = ['Length(m)', 'Nominal Weight(Kg)', 'Actual Weight(Kg)'];

type iPrintLabelState = {
  totalQty: number;
  printedIds: Array<string>;
};
const initialPrintState: iPrintLabelState = {
  totalQty: 0,
  printedIds: [],
};

const DynamicManufactureRows = ({
  defaultValue,
  id,
  control,
  onChange,
  jobId,
  manufactureHeads,
  jobShiftJobId,
  formTaggable,
}: {
  defaultValue: iFormFieldValueSetManufacture[] | undefined;
  id: string;
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  jobId: string;
  manufactureHeads?: Array<string>;
  jobShiftJobId: string;
  formTaggable?: iFormTaggable;
}) => {
  const [localDefaultValue, setLocalDefaultValue] = useState(() => convertedDefaultValue(defaultValue) || []);
  const [printLabelState, setPrintLabelState] = useState(initialPrintState);
  const [conflictState, setConflictState] = useState<Array<iManufactureRow>>([]);
  const { control: selfControl, getValues, setValue, errors } = useForm({});

  const getBulkUpdateRows = () =>
    localDefaultValue.filter(d => !(d.isProcessed || (d.jobShiftJobId && d.jobShiftJobId !== jobShiftJobId))).length;

  const updateOutsideValue = (rows: Array<iManufactureRow>) => {
    const convertedValArr = rows.map((row: iManufactureRow) => ({
      ...row,
      length: row.length[0].value,
      actualWeight: row.actualWeight[0].value,
      nominalWeight: row.nominalWeight[0].value,
      images: row.images.map((i: { value: string }) => i.value),
      jobShiftJobId: row.jobShiftJobId,
      printedAt: row.printedAt,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
      isProcessed: row.isProcessed,
    }));
    onChange(id, convertedValArr, { shouldValidate: true });
    // wehn all rows are printed, manufacture progress is done
    onChange(IS_PROGRESS_COMPLETED, rows.filter((row: iManufactureRow) => !row.isProcessed).length);
    onChange(IS_NO_ROW_DONE, !rows.find((item: iManufactureRow) => item.jobShiftJobId || item.isProcessed));

    onChange(
      IS_OVER_PRODUCED,
      rows.reduce(
        (acc: number, cur: iManufactureRow) =>
          (!cur.isProcessed || Number.isNaN(Number(cur.actualWeight[0].value))
            ? 0
            : Number(cur.actualWeight[0].value)) + acc,
        0,
      ),
    );
  };

  const onAddRows = (filledValue: iOperateBulkRow) => {
    const newRow: iManufactureRow = {
      length: [{ value: filledValue.length }],
      nominalWeight: [{ value: filledValue.nominalWeight }],
      actualWeight: [{ value: filledValue.actualWeight }],
      images: [],
      id: uuidv4(),
      sortOrder: localDefaultValue.length,
      jobShiftJobId,
      printedAt: undefined,
      createdAt: filledValue.actualWeight ? moment().toISOString() : undefined,
      updatedAt: filledValue.actualWeight ? moment().toISOString() : undefined,
      isProcessed: false,
    };
    setLocalDefaultValue([...localDefaultValue, newRow]);
    setTimeout(() => {
      updateOutsideValue([...localDefaultValue, newRow]);
    }, 0);
  };

  const onFillRows = (filledValue: iOperateBulkRow) => {
    const newValue: Array<iManufactureRow> = [];
    let i = 0;
    localDefaultValue.forEach((row: iManufactureRow) => {
      const newRow = { ...row };
      if (
        i < Number(filledValue.rowNumber) &&
        (!newRow.jobShiftJobId || newRow.jobShiftJobId === jobShiftJobId) &&
        !row.isProcessed
      ) {
        newRow.length[0].value = filledValue.length;
        newRow.nominalWeight[0].value = filledValue.nominalWeight;
        newRow.actualWeight[0].value = filledValue.actualWeight;
        newRow.jobShiftJobId = jobShiftJobId;
        newRow.createdAt = filledValue.actualWeight ? moment().toISOString() : undefined;
        newRow.updatedAt = filledValue.actualWeight ? moment().toISOString() : undefined;
        newRow.isProcessed = true;
        newRow.sortOrder = row.sortOrder;
        i += 1;
      }
      newValue.push(newRow);
    });
    setLocalDefaultValue(newValue);
    setTimeout(() => {
      updateOutsideValue(newValue);
    }, 0);
  };

  const updateLocalValue = (
    name: string,
    //  eslint-disable-next-line
    valueObjArr: any,
    //  eslint-disable-next-line
    config?: Object,
  ) => {
    setValue(name, valueObjArr, config);
    const [targetIndex, targetInputKey] = name.split('-');
    const updatedLocalDefaultValue = localDefaultValue.map((val, index) => {
      if (`${index}` === targetIndex) {
        const { createdAt, updatedAt, jobShiftJobId: existedJSJId } = localDefaultValue[index];
        const isActualWeight = targetInputKey === ACTUAL_WEIGHT;

        const newJSJId =
          //  eslint-disable-next-line
          targetInputKey === IS_PROCESSED ? (valueObjArr ? jobShiftJobId : undefined) : existedJSJId;
        return {
          ...val,
          [targetInputKey]: valueObjArr,
          createdAt: isActualWeight ? createdAt || moment().toISOString() : createdAt,
          updatedAt: isActualWeight ? moment().toISOString() : updatedAt,
          // ONLY row with all fields filled in would be recorded with jobShiftJobId
          jobShiftJobId: newJSJId,
        };
      }
      return val;
    });
    setLocalDefaultValue(() => updatedLocalDefaultValue);
    setTimeout(() => {
      updateOutsideValue(updatedLocalDefaultValue);
    }, 0);
  };
  // eslint-disable-next-line
  const validateManufactureRows = (value: any) => {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  const handlePrintTargetsChange = (rowId: string, isAdd: boolean) =>
    setPrintLabelState({
      ...printLabelState,
      printedIds: isAdd
        ? [...printLabelState.printedIds, rowId]
        : printLabelState.printedIds.filter((item: string) => item !== rowId),
    });

  const sumTotalQty = () => {
    return printLabelState.printedIds.reduce((acc: number, cur: string) => {
      const curWeight = localDefaultValue.find((item: iManufactureRow) => item.id === cur)?.nominalWeight[0].value;
      if (curWeight && !Number.isNaN(Number(curWeight))) {
        return addExact(acc, Number(curWeight));
      }

      return acc;
    }, 0);
  };

  const clearPrintTargets = () => {
    const updatePrintedAt = localDefaultValue.map((item: iManufactureRow) =>
      printLabelState.printedIds.includes(item.id) ? { ...item, printedAt: moment().toString() } : item,
    );
    setPrintLabelState({ ...printLabelState, printedIds: [] });
    setLocalDefaultValue(updatePrintedAt);
    setTimeout(() => {
      updateOutsideValue(updatePrintedAt);
    }, 0);
  };

  const selectAllDones = (isCheck: boolean) => {
    const dones = localDefaultValue
      .filter((item: iManufactureRow) => item.isProcessed)
      .map((item: iManufactureRow) => item.id);
    const prints = [...printLabelState.printedIds];
    // dones.find((doneRowId: string) => !prints.includes(doneRowId))
    // select all
    setPrintLabelState({
      ...printLabelState,
      printedIds: isCheck ? _.union(prints, dones) : _.pullAll(prints, dones),
    });
  };

  useEffect(() => {
    if (!formTaggable) return;
    const interval = (Number(process.env.REACT_APP_MANUFACTURE_PULLING_INTERVAL_MINS) || 0.5) * 60 * 1000;
    const timer = setInterval(async () => {
      // load latest manufacture data
      const formAnswers = await FormService.getProgressFormFieldValues(formTaggable.id);
      // manufacture is a special step, it's a set
      if (formAnswers.length !== 1 || !formAnswers[0].value) {
        return;
      }
      const latest = convertedDefaultValue(formAnswers[0].value as iFormFieldValueSetManufacture[]);
      // no change
      if (_.isEqual(latest, localDefaultValue)) {
        return;
      }
      // conflicts
      if (
        latest.find(
          (item: iManufactureRow, index: number) =>
            DynamicMRsUtils.detectConflict({
              latest: item,
              local: localDefaultValue[index],
            }).isConflict,
        )
      ) {
        setConflictState(latest);
        return;
      }
      // no conflicts, directly merge
      const newLocal = DynamicMRsUtils.mergeBranches({
        local: localDefaultValue,
        latest,
      });
      // local is behind database's latest
      if (!_.isEqual(newLocal, localDefaultValue)) {
        setLocalDefaultValue(newLocal);
        setTimeout(() => {
          updateOutsideValue(newLocal);
        }, 0);
      }
    }, interval);
    //  eslint-disable-next-line
    return () => {
      clearInterval(timer);
    };
  }, [JSON.stringify(localDefaultValue)]);

  const solveConflictAndMerge = (isUseDatabaseVersion: boolean) => {
    // solve conflict then merge
    const newLocal = DynamicMRsUtils.mergeConflicts({
      local: localDefaultValue,
      latest: conflictState,
      isUseDatabaseVersion,
    });
    // update by merge result
    setLocalDefaultValue(newLocal);
    setTimeout(() => {
      updateOutsideValue(newLocal);
    }, 0);
    setConflictState([]);
  };

  return (
    <DynamicManufactureRowWrapper data-testid={'dynamic-manufacture-rows'}>
      <ManufactureHeads
        manufactureHeads={manufactureHeads || heads}
        leftRows={getBulkUpdateRows().toString()}
        fillRows={onFillRows}
        jobId={jobId}
      >
        <DynamicLabelPrinting
          jobId={jobId}
          title="Pallet"
          api={{
            serviceName: 'LabelPrintingService',
            functionName: 'printPalletLabel',
          }}
          rowIndex={0}
          totalQty={sumTotalQty()}
          afterPrintCallback={clearPrintTargets}
        />
      </ManufactureHeads>

      <Controller
        name={id}
        control={control}
        rules={{ validate: value => validateManufactureRows(value) }}
        defaultValue={defaultValue}
        as={
          <>
            <ManufactureRows
              data={localDefaultValue}
              control={selfControl}
              onChange={updateLocalValue}
              errors={errors}
              getValues={getValues}
              onGoingJobShiftJobId={jobShiftJobId}
              jobId={jobId}
              handleCheckboxAction={handlePrintTargetsChange}
              printTargets={printLabelState.printedIds}
              selectAllDones={
                localDefaultValue.filter((item: iManufactureRow) => item.isProcessed).length > 0 && (
                  <Checkbox
                    className="hideCheckboxForiOS"
                    // _.union(prints, dones)
                    isChecked={
                      _.pullAll(
                        localDefaultValue
                          .filter((item: iManufactureRow) => item.isProcessed)
                          .map((item: iManufactureRow) => item.id),
                        printLabelState.printedIds,
                      ).length === 0
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      selectAllDones(event.currentTarget.checked)
                    }
                  />
                )
              }
            />
          </>
        }
      />
      <Controller
        name={IS_NO_ROW_DONE}
        control={control}
        defaultValue={!localDefaultValue.find((item: iManufactureRow) => item.jobShiftJobId || item.isProcessed)}
        as={<div />}
      />
      <Controller
        name={IS_OVER_PRODUCED}
        control={control}
        defaultValue={localDefaultValue.reduce(
          (acc: number, cur: iManufactureRow) =>
            (!cur.isProcessed || Number.isNaN(Number(cur.actualWeight[0].value))
              ? 0
              : Number(cur.actualWeight[0].value)) + acc,
          0,
        )}
        as={<div />}
      />
      <ManufactureBottom addRows={onAddRows} jobId={jobId} />
      {conflictState.length > 0 && (
        <ConflictModal local={localDefaultValue} latest={conflictState} onClick={solveConflictAndMerge} />
      )}
    </DynamicManufactureRowWrapper>
  );
};

export default DynamicManufactureRows;
