import React, { useState } from 'react';
import Button from '@atlaskit/button';
import { PopupSelect } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { LabelValuePair } from '../../../job/jobDetail/types/LabelValuePair';
import { FormRowContainer } from '../../styleContainer';
import DynamicText from '../../text/DynamicText';
import OperateBulkRows from './OperateBulkRows';
import { iOperateBulkRow } from '../shared/DynamicManufactureRows.types';

const options: Array<LabelValuePair> = [
  {
    value: 'Update',
    label: 'BulkUpdate',
  },
  // {
  //   value: 'Add',
  //   label: 'BulkAdd',
  // },
];
const ManufactureHeads = ({
  manufactureHeads,
  leftRows,
  fillRows,
  jobId,
  children,
}: {
  manufactureHeads?: Array<string>;
  leftRows: string;
  jobId: string;
  fillRows: (filledValue: iOperateBulkRow) => void;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [operationType, setOperationType] = useState('');

  const callback = (filledValue: iOperateBulkRow) => {
    fillRows(filledValue);
    setIsOpen(false);
  };
  const getBulkActions = () => (
    <PopupSelect
      options={options}
      //  eslint-disable-next-line
      onChange={(selected: any) => {
        setIsOpen(true);
        setOperationType(selected.value);
      }}
      target={({ ref }) => (
        <Button
          spacing={'compact'}
          iconAfter={<ChevronDownIcon primaryColor={'white'} label={'down'} size={'small'} />}
          ref={ref}
          style={{
            backgroundColor: `${colors.N600}`,
          }}
        />
      )}
    />
  );

  return (
    <>
      <FormRowContainer>
        <div className="col-1">{getBulkActions()}</div>
        {manufactureHeads &&
          manufactureHeads.map((head: string) => (
            <div className="col-1-8" key={`manufactures-head-${head}`}>
              <DynamicText title={''} description={head} />
            </div>
          ))}
        <div className="col-2 display-flex">{children}</div>
        <div className="col-1-8" />
        <div className="col-1-8" />
      </FormRowContainer>
      {isOpen && (
        <OperateBulkRows
          operationType={operationType}
          callback={callback}
          initialRowNumber={operationType === 'Add' ? '1' : leftRows}
          onCancel={() => setIsOpen(false)}
          jobId={jobId}
        />
      )}
    </>
  );
};

export default ManufactureHeads;
