import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';
import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getProducts = (config?: { [key: string]: string }) => {
  return AppService.get('/product', {
    include: 'measurement,createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

const getProductDetail = (id: string) => {
  return AppService.get(`/product/${id}`, {
    include: 'measurement,bomItems',
  }).then(({ data }) => data);
};

const getWasteProducts = (keyword?: string) => {
  const basicConfig = {
    filter: 'isForWaste:1',
    include: 'measurement',
  };
  const config = keyword
    ? {
        ...basicConfig,
        like: `productCode:${keyword},name:${keyword}`,
      }
    : basicConfig;

  return AppService.get('/product', config).then(({ data }) => data);
};

const getProductListAsyncSearch = (config?: iParams) => {
  return AppService.get('/product', {
    include: 'measurement',
    ...config,
    perPage: ASYNC_SEARCH_PER_PAGE,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
  }).then(({ data }) => data);
};

export default {
  getProducts,
  getProductDetail,
  getWasteProducts,
  getProductListAsyncSearch,
};
