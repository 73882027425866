import styled from 'styled-components';

export const CollapseTextWrapper = styled.div`
  font-weight: bold;
  font-size: 16;
  &.finished {
    color: rgb(165, 173, 186) !important;
  }
  &.wip {
    color: #172b4d;
  }
`;
