import React from 'react';
import Icon from '@atlaskit/icon';

const customGlyph = () => (
  <svg version="1.1" viewBox="0 0 470.187 470.187">
    <g>
      <path
        d="M416.291,104.057h-43.644V10c0-5.523-4.477-10-10-10H107.54c-5.523,0-10,4.477-10,10v94.057H53.896
		c-21.947,0-39.802,17.855-39.802,39.802v180.006c0,5.523,4.477,10,10,10H97.54v126.322c0,5.523,4.477,10,10,10h255.107
		c5.523,0,10-4.477,10-10V333.865h73.446c5.523,0,10-4.477,10-10V143.859C456.093,121.912,438.238,104.057,416.291,104.057z
		 M117.54,20h235.107v84.057H308.87V60.35c0-5.523-4.477-10-10-10s-10,4.477-10,10v43.707h-43.777V60.35c0-5.523-4.477-10-10-10
		s-10,4.477-10,10v43.707h-43.777V60.35c0-5.523-4.477-10-10-10s-10,4.477-10,10v43.707H117.54V20z M352.647,250.684H117.54v-11.458
		c0-10.919,8.883-19.802,19.802-19.802h195.503c10.919,0,19.802,8.883,19.802,19.802V250.684z M352.647,450.187H117.54V270.684
		h235.107V450.187z M436.093,313.865h-63.446v-53.181v-21.458c0-21.947-17.855-39.802-39.802-39.802H137.342
		c-21.947,0-39.802,17.855-39.802,39.802v21.458v53.181H34.093V143.859c0-10.919,8.883-19.802,19.802-19.802h362.396
		c10.919,0,19.802,8.883,19.802,19.802V313.865z"
      />
      <path
        d="M395.497,151.836c-5.79,0-10.5,4.71-10.5,10.5c0,5.79,4.71,10.5,10.5,10.5s10.5-4.71,10.5-10.5
		C405.997,156.547,401.287,151.836,395.497,151.836z"
      />
      <path
        d="M355.497,151.836c-5.79,0-10.5,4.71-10.5,10.5c0,5.79,4.71,10.5,10.5,10.5s10.5-4.71,10.5-10.5
		C365.997,156.547,361.287,151.836,355.497,151.836z"
      />
      <path
        d="M314.963,400.311h-159.74c-5.523,0-10,4.477-10,10s4.477,10,10,10h159.74c5.523,0,10-4.477,10-10
		S320.486,400.311,314.963,400.311z"
      />
      <path
        d="M314.963,350.435h-159.74c-5.523,0-10,4.478-10,10c0,5.523,4.477,10,10,10h159.74c5.523,0,10-4.477,10-10
		C324.963,354.913,320.486,350.435,314.963,350.435z"
      />
      <path
        d="M314.963,300.56h-79.87c-5.523,0-10,4.477-10,10s4.477,10,10,10h79.87c5.523,0,10-4.477,10-10
		S320.486,300.56,314.963,300.56z"
      />
    </g>
  </svg>
);
type iSize = 'small' | 'medium' | 'large' | 'xlarge' | undefined;

const CustomIconPrinter = ({ size }: { size?: iSize }) => (
  <Icon glyph={customGlyph} label="printer" size={size} testId={'custom-glyph'} />
);

export default CustomIconPrinter;
