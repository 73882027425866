import React, { useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import iStockTake from '../../../../types/stockTake/iStockTake';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import { apiErrorToast } from '../../../../shared/toast/ToastHandler';
import StockTakeService from '../../../../services/StockTakeService';
import { iBulkStockTakeLocalStorage } from '../shared/BulkStockTake.type';

type iState = {
  isOpen: boolean;
  isLoading: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  isOpen: false,
  isLoading: false,
  isConfirming: false,
};

const StartButton = ({
  //  eslint-disable-next-line
  locationId,
  callback,
}: {
  locationId?: string;
  callback: ({ stockTakeIds, parentLocationId, finishedStockTake }: iBulkStockTakeLocalStorage) => void;
}) => {
  const [state, setState] = useState(initialState);
  const onStartBulk = async () => {
    if (!locationId) return;
    setState({ ...state, isConfirming: true });
    try {
      // start bulk get stocktake list
      const stockTakes: Array<iStockTake> = await StockTakeService.createBulkStockTake({
        warehouseLocationId: locationId,
      });
      setState({ ...state, isConfirming: false, isOpen: false });
      if (typeof callback === 'function') {
        callback({
          stockTakeIds: stockTakes.map((item: iStockTake) => item.id),
          parentLocationId: locationId,
          finishedStockTake: 0,
        });
      }
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  return (
    <>
      <LoadingButton
        appearance={'primary'}
        onClick={() => setState({ ...state, isOpen: true })}
        isLoading={state.isLoading}
        isDisabled={!locationId}
        testId={'start-button'}
      >
        Start
      </LoadingButton>
      {
        // use state.isOpen&&CustomizeModal to reduce the unnecessary re-render of CustomizeMdal
        state.isOpen && (
          <CustomizeModal
            isOpen={state.isOpen}
            onConfirm={onStartBulk}
            onCancel={() => setState({ ...state, isOpen: false })}
            isConfirming={state.isConfirming}
          />
        )
      }
    </>
  );
};

export default StartButton;
