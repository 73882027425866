import React from 'react';
import { formatDate } from '../../services/UtilsService';

const CreatedOrUpdated = ({ operatedAt, operator }: { operatedAt?: string; operator?: string }) => {
  return (
    <>
      {operator && <div data-testid={'record-operator'}>{operator}</div>}
      {operatedAt && <div data-testid={'record-operatedAt'}>@{formatDate(operatedAt)}</div>}
    </>
  );
};

export default CreatedOrUpdated;
