import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AppState = {
  pageTitle?: string;
};
/**
 * Initial State
 */
const initialState: AppState = {};

/**
 * Actions
 */
const actions = {
  changePageTitle: (state: AppState, action: PayloadAction<AppState>) => ({
    ...state,
    pageTitle: action.payload.pageTitle,
  }),
};
/**
 * Slice
 */
const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: actions,
});
/**
 * action
 */
export const { changePageTitle } = AppSlice.actions;
/**
 * reducer
 */
export default AppSlice.reducer;
