import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import { ButtonItem, Section } from '@atlaskit/menu';
import { JOBS_CURRENT_URL, JOBS_FUTURE_URL } from '../UrlMap';

type iState = {
  isOpen: boolean;
};
const initialState: iState = {
  isOpen: false,
};
const PopupWrapper = styled.div``;

export default () => {
  const [state, setState] = useState(initialState);
  const history = useHistory();

  const showPopup = (isOpen: boolean) => {
    setState({
      ...state,
      isOpen,
    });
  };

  const navToTodayJobs = () => {
    history.push(JOBS_CURRENT_URL);
  };
  const navToFutureJobs = () => {
    history.push(JOBS_FUTURE_URL);
  };

  const getMenuItems = () => {
    return (
      <PopupWrapper>
        <Section>
          <ButtonItem onClick={navToTodayJobs}>Current</ButtonItem>
        </Section>
        <Section hasSeparator>
          <ButtonItem onClick={navToFutureJobs}>Future</ButtonItem>
        </Section>
      </PopupWrapper>
    );
  };

  return (
    <Popup
      testId={'jobs-popup'}
      isOpen={state.isOpen}
      placement={'bottom-start'}
      content={getMenuItems}
      onClose={() => showPopup(false)}
      trigger={(
        triggerProps: any, //eslint-disable-line
      ) => (
        <PrimaryDropdownButton
          testId={'jobs-dropdown'}
          {...triggerProps} //eslint-disable-line
          tooltip={''}
          onClick={() => showPopup(!state.isOpen)}
        >
          Jobs
        </PrimaryDropdownButton>
      )}
    />
  );
};
