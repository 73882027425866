import React, { useEffect, useState } from 'react';
import CustomizeModal from './modal/CustomizeModal';

type iParam = {
  pdfFileUrl?: string;
  onClose?: () => void;
};
type iState = {
  isOpen: boolean;
  pdfLocalUrl?: string;
};
const initialState: iState = {
  isOpen: true,
};

const PDFPrintPopup = ({ pdfFileUrl, onClose }: iParam) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    if (!pdfFileUrl || pdfFileUrl.trim() === '') return;

    const getContentFromUrl = async () => {
      if (isCancelled === true) return;
      const blob = await fetch(pdfFileUrl);
      const pdfContent = await blob.blob();
      const newBlobOjb = new Blob([pdfContent], { type: 'application/pdf' });
      setState(s => ({
        ...s,
        pdfLocalUrl: URL.createObjectURL(newBlobOjb),
      }));
    };
    getContentFromUrl();

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true;
    };
  }, [pdfFileUrl]);

  const handleCancel = () => {
    setState({
      ...state,
      isOpen: false,
    });
    if (onClose) {
      onClose();
    }
  };

  const openUrl = () => {
    const newWin = window.open(state.pdfLocalUrl, 'print-pdf');
    if (newWin) {
      newWin.focus();
      newWin.onload = () => {
        setTimeout(() => {
          newWin.print();
        }, 400);
      };
    }
    return newWin;
  };

  if (state.isOpen !== true) return null;
  if (!state.pdfLocalUrl || state.pdfLocalUrl.trim() === '') {
    return null;
  }

  return (
    <CustomizeModal
      isOpen={state.isOpen}
      onConfirm={() => openUrl()}
      onCancel={() => handleCancel()}
      modalHeading={'Window popup blocked by your device.'}
      modalBody={
        <div data-testid={'popup-content'}>
          <div>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Don't worry, click <b>Print</b> btn to print.
          </div>
          <div>or copy this url into your browser:</div>
          <small>{pdfFileUrl}</small>
        </div>
      }
      confirmBtnName={'Print'}
      width={'medium'}
      testIdPrefix={'pdf-print-popup'}
    />
  );
};

export default PDFPrintPopup;
