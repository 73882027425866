import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { ButtonGroup } from '@atlaskit/button';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import BreadcrumbsTwoLayers from '../../shared/heading/BreadcrumbsTwoLayers';
import iDispatchNote from '../../types/dispatchNote/iDispatchNote';
import Activities from './detail/Activities';
import DetailEdit from './detail/DetailEdit';
import iCourier from '../../types/courier/iCourier';
import iEntityCategory from '../../types/status/iEntityCategory';
import StatusMenu from '../../shared/statusComponent/StatusMenu';
import useDetailHook from '../../shared/hooks/useDetailHook/useDetailHook';
import GeneralPrintBtn from '../../shared/buttons/GeneralPrintBtn';
import GeneralAttachments from '../../shared/generalAttachments/GeneralAttachments';
import DispatchNoteItems from './detail/dispatchNoteItems/DispatchNoteItems';
import EntityStatusCategoryService from '../../services/EntityStatusCategoryService';
import LabelPrintingService from '../../services/PrintService';
import { apiErrorToast } from '../../shared/toast/ToastHandler';
import { iRouteTypeId } from '../../shared/UITypes/types';
import { DISPATCH_NOTES_URL } from '../../shared/UrlMap';
import { DispatchNoteItemsWrapper } from './shared/DispatchNote.style';
import CourierService from '../../services/CourierService';
import DispatchNoteService from '../../services/DispatchNoteService';
import { DETAIL_SUB_NAME_PREFIX, ITEM_COLUMNS, PAGE_NAME } from './shared/DispatchNote.constant';
import { AttachmentsWrapper, FlexContainer, PageTitle, SpaceVerticalWrapper } from '../../shared/styles/styles';
import { classifyEntityCategoryByStatusId } from '../../services/UtilsService';
import { SERVICE_PROVIDER } from '../../shared/constants/ContactCompanyConstants';
import { STATUS_CATEGORY_IN_PROGRESS, STATUS_CATEGORY_NEW } from '../../shared/constants/StatusCategories';
import DNSentBtn from './detail/DNSentBtn';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/UserAccess/AccessService';
import { ACCESS_CODE_DISPATCH_NOTES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';

type iSupportState = {
  couriers: Array<iCourier>;
  categories: Array<iEntityCategory>;
  isLoading: boolean;
};
const initialSupportState: iSupportState = {
  couriers: [],
  categories: [],
  isLoading: true,
};
const DispatchNoteDetail = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_UPDATE, user);

  const { id } = useParams<iRouteTypeId>();
  const history = useHistory();
  const { state, onUpdate, onUpdateEagerLoadObject } = useDetailHook<iDispatchNote>({
    id,
    getFn: DispatchNoteService.getDispatchNoteDetail,
    updateFn: DispatchNoteService.updateDispatchNote,
    deleteFn: DispatchNoteService.removeDispatchNote,
    deleteCallback: () => history.push(DISPATCH_NOTES_URL),
  });
  const [supportState, setSupportState] = useState(initialSupportState);
  const breadCrumbsSubName = `${DETAIL_SUB_NAME_PREFIX}: ${state.data?.dispatchNoteNumber}`;

  useEffect(() => {
    let isCancelled = false;
    const fetchDetail = async () => {
      try {
        const couriers: Array<iCourier> = await CourierService.getCouriers({
          sort: 'name:ASC',
        });
        const result: Array<iEntityCategory> = await EntityStatusCategoryService.getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:DispatchNote',
        });
        const categories: Array<iEntityCategory> = result.filter(category => {
          return category.code === STATUS_CATEGORY_NEW || category.code === STATUS_CATEGORY_IN_PROGRESS;
        });
        if (isCancelled) return;
        setSupportState({ couriers, categories, isLoading: false });
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setSupportState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchDetail();
    return () => {
      isCancelled = true;
    };
  }, []);

  if (state.isLoading || supportState.isLoading) {
    return (
      <BackendPage>
        <Spinner />
      </BackendPage>
    );
  }
  const { isFinished } = classifyEntityCategoryByStatusId(supportState.categories, state.data?.statusId);

  const onSuccessfullyMarkedAsSent = () => {
    setTimeout(() => {
      history.push(DISPATCH_NOTES_URL);
    }, 300);
  };

  const getPageHeader = () => {
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers
            name={PAGE_NAME}
            onClick={() => history.push(DISPATCH_NOTES_URL)}
            subName={breadCrumbsSubName}
          />
          <FlexContainer>
            <PageTitle className={'small space-right'} data-testid={'breadcrumbs'}>
              {breadCrumbsSubName}
            </PageTitle>
            <StatusMenu
              defaultValue={state.data?.statusId}
              categories={supportState.categories}
              onSelect={(newValue: string) => onUpdate({ statusId: newValue })}
              isDisabled={isFinished || !canUpdate}
            />
          </FlexContainer>
        </div>
        <ButtonGroup>
          {state.data && canUpdate ? (
            <DNSentBtn dispatchNote={state.data} onUpdated={onSuccessfullyMarkedAsSent} />
          ) : null}
          <GeneralPrintBtn serviceFunc={() => LabelPrintingService.printDispatchNote(id)}>Print</GeneralPrintBtn>
        </ButtonGroup>
      </FlexContainer>
    );
  };

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <DetailEdit
        detail={state.data}
        onUpdateDetail={onUpdate}
        onUpdateLocal={onUpdateEagerLoadObject}
        couriers={supportState.couriers}
        isDisabled={isFinished || !canUpdate}
      />
      <DispatchNoteItemsWrapper>
        <DispatchNoteItems
          dispatchNoteId={id}
          customerId={state.data?.customerId}
          columns={ITEM_COLUMNS}
          DNShippingAddress={state.data?.shippingAddress}
          onUpdateDetail={onUpdate}
          onUpdateLocal={onUpdateEagerLoadObject}
          canCreate={!isFinished && canUpdate}
          canUpdate={!isFinished && canUpdate}
          canDelete={!isFinished && canUpdate}
          isServiceProvider={state.data?.customer.type.name.toUpperCase() === SERVICE_PROVIDER.toUpperCase()}
          customerShippingAddress={state.data?.customer.shippingAddress}
        />
      </DispatchNoteItemsWrapper>
      <SpaceVerticalWrapper className={'small'} />
      <AttachmentsWrapper>
        <GeneralAttachments
          entityId={id}
          entityName={'DispatchNote'}
          canDelete={!isFinished && canUpdate}
          canCreate={canUpdate}
        />
      </AttachmentsWrapper>
      <Activities dispatchNoteId={id} />
    </BackendPage>
  );
};
export default DispatchNoteDetail;
