import axios from 'axios';
import { iKeyValuePairs } from '../shared/UITypes/types';
import LocalStorageService from './LocalStorageService';

type iFormParam = {
  [key: string]: string | boolean | Array<string> | number;
};
export type iFormParams = {
  [key: string]: string | iFormParam;
};

type iParams = {
  [key: string]: string | boolean | number | null | undefined | string[];
};
type iConfigParams = {
  [key: string]: string;
};
const getEndPointUrl = (url: string) => {
  return `${process.env.REACT_APP_API_END_POINT}${url}`;
};

const getHeaders = () => {
  const token = LocalStorageService.getToken();
  if (!token || token === '') {
    return undefined;
  }
  const companyId = LocalStorageService.getCompanyId();

  if (!companyId || companyId === '') {
    return undefined;
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      comid: companyId,
    },
  };
};

const getUrlParams = (params: iConfigParams = {}) => {
  const paramString =
    typeof params === 'object' && Object.keys(params).length > 0 ? new URLSearchParams(params).toString() : '';
  return paramString === '' ? '' : `?${paramString}`;
};

const get = (url: string, params: iConfigParams = {}) => {
  return axios.get(`${getEndPointUrl(url)}${getUrlParams(params)}`, getHeaders());
};

const post = (url: string, params: iParams) => {
  return axios.post(getEndPointUrl(url), params, getHeaders());
};

const put = (url: string, params: iParams | iFormParams | iKeyValuePairs) => {
  return axios.put(getEndPointUrl(url), params, getHeaders());
};

const remove = (url: string, params: iConfigParams = {}) => {
  return axios.delete(`${getEndPointUrl(url)}${getUrlParams(params)}`, getHeaders());
};

const getUploadHeaders = () => {
  const headers = getHeaders();
  if (!headers) {
    return headers;
  }
  return {
    headers: {
      ...headers.headers,
      'Content-Type': 'multipart/form-data',
    },
  };
};
const uploadImage = (url: string, params: FormData) => {
  return axios.post(getEndPointUrl(url), params, getUploadHeaders());
};

export default {
  get,
  post,
  put,
  delete: remove,
  uploadImage,
};
