import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getProdAttributeValues = (config?: iKeyValuePairs) => {
  return AppService.get('/productAttributeValue', {
    include: '',
    ...config,
  }).then(({ data }) => data);
};

const updateProdAttributeValue = (id: string, updateContent: { [key: string]: string | number | boolean | null }) => {
  return AppService.put(`/productAttributeValue/${id}`, updateContent).then(({ data }) => data);
};

const createProdAttributeValue = (postContent: iParams) => {
  return AppService.post('/productAttributeValue', postContent).then(({ data }) => data);
};

const deleteProdAttributeValue = (id: string) => {
  return AppService.delete(`/productAttributeValue/${id}`).then(({ data }) => data);
};

export default {
  getProdAttributeValues,
  createProdAttributeValue,
  updateProdAttributeValue,
  deleteProdAttributeValue,
};
