import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iFormTaggable from '../../../types/form/iFormTaggable';
import DynamicFormItem from './DynamicFormItem';
import { iJobProgressRow, iDynamicItem } from './types';
import { FormRowContainer, FormRowContainerVertical } from '../../dynamicForm/styleContainer';

const DynamicFormRow = ({
  row,
  control,
  getValues,
  setValue,
  errors,
  rowIndex,
  children,
  jobShiftJobId,
  setError,
  clearErrors,
  formTaggable,
}: {
  row: iJobProgressRow;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  getValues: (payload?: string | string[]) => any;
  //    eslint-disable-next-line
  setValue: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors: DeepMap<Record<string, any>, FieldError>;
  rowIndex: number;
  children: React.ReactNode;
  jobShiftJobId: string;
  // eslint-disable-next-line
  setError: (
    name: string,
    error: {
      type?: string;
      message?: string;
    },
  ) => void;
  clearErrors: (name?: string | string[]) => void;
  formTaggable?: iFormTaggable;
}) => {
  const isVerticalRowMode = row.inputs ? row.inputs.some(input => input.type === 'DynamicManufactureRows') : false;
  const rowItems = row.inputs ? (
    row.inputs.map((input: iDynamicItem) => (
      <DynamicFormItem
        key={`item-${input.id}`}
        input={input}
        control={control}
        getValues={getValues}
        setValue={setValue}
        rowIndex={rowIndex}
        jobShiftJobId={jobShiftJobId}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
        formTaggable={formTaggable}
      >
        {children}
      </DynamicFormItem>
    ))
  ) : (
    <></>
  );
  const buildDynamicFormRow = () =>
    isVerticalRowMode === false ? (
      <FormRowContainer>{rowItems}</FormRowContainer>
    ) : (
      <FormRowContainerVertical>{rowItems}</FormRowContainerVertical>
    );
  return <>{buildDynamicFormRow()}</>;
};
export default DynamicFormRow;
