import Button from '@atlaskit/button';
import React, { useState } from 'react';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import MoreIconPopup from '../../../../popup/MoreIconPopup';
import CreateDNServiceProvider from './CreateDNServiceProvider';
import CreatePOServiceProvider from './CreatePOServiceProvider';
import { PopUpContentContainer } from '../../../../styles/styles';
import { CREATE_DN, CREATE_PO } from '../ServiceProvider.constants';

type iState = {
  operationItem?: string;
  isLoading: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  isLoading: false,
  isConfirming: false,
};
const DotOperation = ({ jobId, ccp }: { jobId: string; ccp: iContactCompanyProduct }) => {
  const [state, setState] = useState(initialState);
  const onCloseModal = () => setState({ ...state, operationItem: undefined });
  const onOpenModal = (item: string) => setState({ ...state, operationItem: item });

  const getOperationModal = () => {
    let Component;
    switch (state.operationItem) {
      case CREATE_PO:
        Component = CreatePOServiceProvider;
        break;
      case CREATE_DN:
        Component = CreateDNServiceProvider;
        break;
      default:
        break;
    }
    if (!Component) return null;
    return <Component jobId={jobId} ccp={ccp} onCancel={onCloseModal} />;
  };
  return (
    <>
      <MoreIconPopup>
        <PopUpContentContainer>
          {[CREATE_PO, CREATE_DN].map((item: string) => (
            <Button key={item} className={'popup-item'} onClick={() => onOpenModal(item)} testId={item}>
              {item}
            </Button>
          ))}
        </PopUpContentContainer>
      </MoreIconPopup>
      {getOperationModal()}
    </>
  );
};

export default DotOperation;
