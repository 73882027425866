import React from 'react';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import iStockTake from '../../../../types/stockTake/iStockTake';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import iItemLocation from '../../../../types/warehouse/iItemLocation';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import InlineEdit from '../../../../shared/form/InlineEdit';
import DeleteButton from '../bulkItem/DeleteButton';
import { iCombine } from './BulkStockTake.type';
import { handleNullException } from '../../../../services/UtilsService';
import { iConfigColumn, iKeyValuePairs } from '../../../../shared/UITypes/types';

/**
 * prepare data for table- construct warehouseItemLocations and stockTakeItems together
 * at first, loop stockTakeDetail.warehouseLocation.warehouseItemLocations and find connected stockTakeItems
 * at second, loop left stockTakeItems
 */
export const combine = ({
  stockTakeDetail,
  stockTakeItems,
}: {
  stockTakeDetail?: iStockTake;
  stockTakeItems: Array<iStockTakeItem>;
}): Array<iCombine> => {
  const itemLocations = handleNullException(stockTakeDetail, 'warehouseLocation.warehouseItemLocations') || [];
  const itemLocationBase: Array<iCombine> = itemLocations.map((item: iItemLocation) => {
    const targetSTItem = stockTakeItems.find(
      (stItem: iStockTakeItem) => stItem.reference === item.reference && stItem.productId === item.productId,
    );
    if (targetSTItem) {
      return { itemLocation: item, stockTakeItem: targetSTItem };
    }
    return { itemLocation: item };
  });
  const stItemBase: Array<iCombine> = stockTakeItems
    .filter((item: iStockTakeItem) => !itemLocationBase.find((base: iCombine) => base.stockTakeItem?.id === item.id))
    .map((item: iStockTakeItem) => ({ stockTakeItem: item }));
  return [...itemLocationBase, ...stItemBase];
};

export const getRows = ({
  stockTakeId,
  data,
  columns,
  createFn,
  editFn,
  deleteFn,
}: {
  stockTakeId?: string;
  data: Array<iCombine>;
  columns: Array<iConfigColumn>;
  createFn: (data: iKeyValuePairs) => Promise<void>;
  editFn: (targetId: string, data: iKeyValuePairs) => Promise<void>;
  deleteFn: (targetId: string) => Promise<void>;
}) => {
  return data.map((item: iCombine) => {
    const { itemLocation, stockTakeItem } = item;
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'itemLocation-unit-text':
          return {
            key: handleNullException(item, key),
            content: `${handleNullException(item, key)} ${handleNullException(itemLocation, dataSource[0])} `,
          };

        case 'both-secondary-text':
          return {
            key: handleNullException(itemLocation || stockTakeItem, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(itemLocation || stockTakeItem, dataSource[0])}>
                {handleNullException(itemLocation || stockTakeItem, key)}
              </ComposeSecondaryText>
            ),
          };
        case 'both-inlineEdit-reference':
          return {
            key: handleNullException(itemLocation || stockTakeItem, key),
            content: (
              <InlineEdit
                name={`${handleNullException(itemLocation, 'id')} ${handleNullException(
                  stockTakeItem,
                  'id',
                )} reference`}
                defaultValue={handleNullException(stockTakeItem || itemLocation, 'reference')}
                isDisabled={!!itemLocation}
                hideActionButtons
                onConfirm={(name: string, value: string) =>
                  stockTakeItem
                    ? editFn(stockTakeItem.id, { reference: value })
                    : createFn({
                        reference: value,
                        stockTakeId,
                        qtyFrom: 0,
                        isUserInput: true,
                        productId: itemLocation?.productId,
                        qty: 0,
                      })
                }
              />
            ),
          };
        case 'stockTakeItem-inlineEdit-qty':
          return {
            key: handleNullException(item, key),
            content: (
              <InlineEdit
                key={`${handleNullException(itemLocation, 'id')}
                  ${handleNullException(stockTakeItem, 'id')} qty`}
                name={handleNullException(item, 'id')}
                defaultValue={handleNullException(item, key)}
                onConfirm={(name: string, value: string) =>
                  stockTakeItem
                    ? editFn(stockTakeItem.id, { qty: value })
                    : createFn({
                        qty: value,
                        stockTakeId,
                        qtyFrom: 0,
                        isUserInput: true,
                        productId: itemLocation?.productId,
                        reference: handleNullException(itemLocation, 'reference') || null,
                      })
                }
                postfix={handleNullException(stockTakeItem, dataSource[0])}
                type={'number'}
                hideActionButtons
              />
            ),
          };
        case 'stockTakeItem-delete':
          return {
            content: (
              <DeleteButton
                icon={itemLocation && <SelectClearIcon label={'clear'} />}
                onDelete={() => stockTakeItem && deleteFn(stockTakeItem.id)}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};
