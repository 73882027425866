import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import iJobAttribute from '../../types/job/iJobAttribute';
import iJobCategory from '../../types/job/iJobCategory';
import JobList from '../../shared/jobList/JobList';
import JobAttributeService from '../../services/JobAttributeService';
import JobService from '../../services/JobService';
import JobListUtils from '../../shared/jobList/JobList.utils';
import { apiErrorToast } from '../../shared/toast/ToastHandler';

type iState = {
  isLoading: boolean;
  allJobAttributes: Array<iJobAttribute>;
  jobCategoryList: Array<iJobCategory>;
};
const initialState: iState = {
  isLoading: true,
  allJobAttributes: [],
  jobCategoryList: [],
};

const JobListPage = ({ isCurrent = true }: { isCurrent?: boolean }) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const allJobAttributes = await JobAttributeService.getJobAttributes({ include: '', sort: 'sortOrder:ASC' });
        const jobCategoryList = await JobService.getJobStatus();
        if (isCancelled) return;
        setState(prevState => ({ ...prevState, isLoading: false, allJobAttributes, jobCategoryList }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  if (state.isLoading) return <Spinner />;
  return (
    <JobList
      isCurrent={isCurrent}
      fullColumns={JobListUtils.getJobListColumns({ jobAttributes: state.allJobAttributes, isCurrent })}
      jobCategoryList={state.jobCategoryList}
    />
  );
};

export default JobListPage;
