import React from 'react';
import HorizontalLabel from './HorizontalLabel';
import Title from './styles/Title';
import { LabelValuePair } from './types/LabelValuePair';
import { FlexContainer } from '../../styles/styles';

const Breadcrumb = ({ children }: { children: React.ReactNode }) => <div>{children}</div>;
const JobKeyInfo = ({
  jobNum,
  machineName,
  currentJobStatus,
}: {
  jobNum: string;
  machineName: string;
  currentJobStatus?: LabelValuePair;
}) => (
  <FlexContainer className={'space-between'} data-testid={'flex-container'}>
    <div>
      <Breadcrumb>Job / Job# {jobNum}</Breadcrumb>
      <Title>Job #: {jobNum}</Title>
    </div>
    <HorizontalLabel label="Machine:" value={machineName} />
    {currentJobStatus && <HorizontalLabel label="Status:" value={currentJobStatus.label} />}
  </FlexContainer>
);

export default JobKeyInfo;
